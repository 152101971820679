import React, {Component} from 'react';
import {Table} from 'reactstrap';
import {Link} from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/ru';
import {POSITIVE_ACTION_STATUSES, UsersAPI} from "../../api/Client";

const FRANCHAISE_STATUS = {
    deciding: 'новая',
};

const FRANCHAISE_SOURCE = {
    odnoklassniki: 'одноклассники',
};

const FRANCHAISE_STATUS_CLASS = {
    new: 'badge-danger',
}

export class TouristCabinetList extends Component {
    constructor(props) {
        super(props);
        this.handlerSort = this.handlerSort.bind(this);
    }

    handlerSort(e) {
      this.props.handlerSort(e.target.title);
    }

    render() {
        const {
            items,
            selected,
            toggleItem,
        } = this.props;


        return (
            <Table striped bordered size="sm" className="font-xs table--orange">
                <thead>
                <tr>
                    <th className="text-center tableItem">Номер туриста</th>
                    <th className="text-center tableItem">ФИО</th>
                    <th className="text-center tableItem">Дата регистрации</th>
                    <th className="text-center tableItem">Дата последнего взаимодействия</th>
                    <th className="text-center tableItem">Телефон</th>
                    <th className="text-center tableItem">Email</th>
                    <th className="text-center tableItem" onClick={this.handlerSort} style={{cursor: 'pointer'}} title="count_seen">Просмотрено <i className="fa fa-sort ml-2" title="count_seen"></i></th>
                    <th className="text-center tableItem" onClick={this.handlerSort} style={{cursor: 'pointer'}} title="count_likes">Понравилось <i className="fa fa-sort ml-2" title="count_likes"></i></th>
                    <th className="text-center tableItem" onClick={this.handlerSort} style={{cursor: 'pointer'}} title="count_cart">В корзине <i className="fa fa-sort ml-2" title="count_cart"></i></th>
                    <th className="text-center tableItem" onClick={this.handlerSort} style={{cursor: 'pointer'}} title="count_orders">Всего заказов <i className="fa fa-sort ml-2" title="count_orders"></i></th>
                </tr>
                </thead>
                <tbody>
                {items.length ? items.map(item => (
                    <tr key={item.id}>
                        <td className="text-center tableItem">
                                {item.id}
                        </td>
                        <td className="text-center tableItem">
                            <Link to={`/touristcabinets/${item.id}/`} itemID={item.id}>
                            {(item.last_name || item.name || + item.middle_name) ? item.last_name + ' ' + item.name + ' ' + item.middle_name : "Отсутствует"}
                            </Link>
                        </td>
                        <td className="text-center tableItem">
                            {moment(item.dt_registered).format('DD.MM.YY HH:mm')}
                        </td>
                        <td className="text-center tableItem">
                            {moment(item.dt_sync).format('DD.MM.YY HH:mm')}
                        </td>
                        <td className="text-center tableItem">
                            {item.phone_settings}
                        </td>
                        <td className="text-center tableItem">
                            {item.email_registered}
                        </td>
                        <td className="text-center tableItem">
                            {item.count_seen}
                        </td>
                        <td className="text-center tableItem">
                          {item.count_likes
                            ? <span className="badge badge-brown badge-pill">{item.count_likes}</span>
                            :  null
                          }
                        </td>
                        <td className="text-center tableItem">
                          {item.count_cart
                            ? <span className="badge badge-order badge-pill">{item.count_cart}</span>
                            :  null
                          }
                        </td>
                        <td className="text-center tableItem">
                          {item.orders_ids && item.orders_ids.length > 0
                            ? <span className="badge badge-blue badge-pill">{item.orders_ids.length}</span>
                            :  null
                          }
                        </td>
                    </tr>
                )) : null}
                </tbody>
            </Table>
        );
    }
}
