import React, { PureComponent } from "react";
import Select from "react-select-plus";

import { POSITIVE_ACTION_STATUSES, MailingAPI } from "../../../api/Client";

class CampaignFilter extends PureComponent {
  constructor(props) {
    super(props);

    this.fetchCreators = this.fetchCreators.bind(this);
    this.fetchOffices = this.fetchOffices.bind(this);
    this.fetchRecipients = this.fetchRecipients.bind(this);
    this.fetchSenders = this.fetchSenders.bind(this);
    this.fetchStatuses = this.fetchStatuses.bind(this);
    this.fetchTypes = this.fetchTypes.bind(this);
    this.handleCreator = this.handleCreator.bind(this);
    this.handleOffice = this.handleOffice.bind(this);
    this.handleRecipient = this.handleRecipient.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleSender = this.handleSender.bind(this);
    this.handleStatus = this.handleStatus.bind(this);
    this.handleType = this.handleType.bind(this);
    this.onFilter = this.onFilter.bind(this);

    this.state = {
      creator: "",
      creators: [],
      office: "",
      offices: [],
      recipient: "",
      recipients: [],
      sender: "",
      senders: [],
      status: "",
      statuses: [],
      type: "",
      types: [],
    };
  }

  componentDidMount() {
    this.fetchCreators();
    this.fetchOffices();
    this.fetchRecipients();
    this.fetchSenders();
    this.fetchStatuses();
    this.fetchTypes();
  }

  fetchCreators() {
    let status;
    let { filter } = this.state;

    MailingAPI.fetchList(1, filter, "filter/creators/")
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.list) {
          this.setState({
            creators: r || [],
          });
        }
      });
  }

  fetchOffices() {
    let status;
    let { filter } = this.state;

    MailingAPI.fetchList(1, filter, "filter/offices/")
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.list) {
          this.setState({
            offices: r || [],
          });
        }
      });
  }

  fetchRecipients() {
    let status;
    let { filter } = this.state;

    MailingAPI.fetchList(1, filter, "filter/recipients/")
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.list) {
          this.setState({
            recipients: r || [],
          });
        }
      });
  }

  fetchSenders() {
    let status;
    let { filter } = this.state;

    MailingAPI.fetchList(1, filter, "filter/senders/")
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.list) {
          this.setState({
            senders: r || [],
          });
        }
      });
  }

  fetchStatuses() {
    let status;
    let { filter } = this.state;

    MailingAPI.fetchList(1, filter, "filter/statuses/")
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.list) {
          this.setState({
            statuses: r || [],
          });
        }
      });
  }

  fetchTypes() {
    let status;
    let { filter } = this.state;

    MailingAPI.fetchList(1, filter, "filter/types/")
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.list) {
          this.setState({
            types: r || [],
          });
        }
      });
  }

  handleCreator(creator) {
    this.setState({ creator: creator || "" });
  }

  handleOffice(office) {
    this.setState({ office: office || "" });
  }

  handleRecipient(recipient) {
    this.setState({ recipient: recipient || "" });
  }

  handleSender(sender) {
    this.setState({ sender: sender || "" });
  }

  handleStatus(status) {
    this.setState({ status: status || "" });
  }

  handleType(type) {
    this.setState({ type: type || "" });
  }

  handleReset() {
    this.setState(
      {
        creator: "",
        office: "",
        recipient: "",
        sender: "",
        status: "",
        type: "",
      },
      this.onFilter
    );
  }

  onFilter() {
    let filter = {};
    if (this.state.creator) {
      filter["creator"] = this.state.creator;
    }
    if (this.state.office) {
      filter["office"] = this.state.office;
    }
    if (this.state.recipient) {
      filter["entity_type"] = this.state.recipient;
    }
    if (this.state.sender) {
      filter["sender_type"] = this.state.sender;
    }
    if (this.state.status) {
      filter["status"] = this.state.status;
    }
    if (this.state.type) {
      filter["dispatch_type"] = this.state.type;
    }
    this.props.onFilter(filter);
  }

  render() {
    const {
      creator,
      creators,
      office,
      offices,
      recipient,
      recipients,
      sender,
      senders,
      status,
      statuses,
      type,
      types,
    } = this.state;

    const CREATOR_OPTIONS = creators.length
      ? creators.map((c) => {
          return { value: c.value, label: c.label };
        })
      : [];
    const OFFICE_OPTIONS = offices.length
      ? offices.map((o) => {
          return { value: o.value, label: o.label };
        })
      : [];
    const RECIPIENT_OPTIONS = recipients.length
      ? recipients.map((r) => {
          return { value: r.value, label: r.label };
        })
      : [];
    const SENDER_OPTIONS = senders.length
      ? senders.map((s) => {
          return { value: s.value, label: s.label };
        })
      : [];
    const STATUS_OPTIONS = statuses.length
      ? statuses.map((s) => {
          return { value: s.value, label: s.label };
        })
      : [];
    const TYPE_OPTIONS = types.length
      ? types.map((t) => {
          return { value: t.value, label: t.label };
        })
      : [];

    return (
      <div className="animated fadeIn">
        <div className="filter filter--blue">
          <div className="card card--search-bg">
            <div className="card-header">
              <strong>Отправленные рассылки</strong>
            </div>
            <div className="card-block">
              <div className="filter-row">
                {TYPE_OPTIONS.length > 1 ? (
                  <div className="filter-field">
                    <label htmlFor="campaignType">Тип</label>
                    <Select
                      id="campaignType"
                      name="type"
                      placeholder="Укажите тип"
                      closeOnSelect={true}
                      removeSelected={true}
                      value={type}
                      simpleValue={true}
                      onChange={this.handleType}
                      options={TYPE_OPTIONS}
                    />
                  </div>
                ) : null}
                {/* {SENDER_OPTIONS.length > 1 ? (
                  <div className="filter-field">
                    <label htmlFor="campaignSender">Провайдер</label>
                    <Select
                      id="campaignSender"
                      name="sender"
                      placeholder="Укажите провайдера"
                      closeOnSelect={true}
                      removeSelected={true}
                      value={sender}
                      simpleValue={true}
                      onChange={this.handleSender}
                      options={SENDER_OPTIONS}
                    />
                  </div>
                ) : null} */}
                {STATUS_OPTIONS.length > 1 ? (
                  <div className="filter-field">
                    <label htmlFor="campaignStatus">Статус</label>
                    <Select
                      id="campaignStatus"
                      name="status"
                      placeholder="Укажите статус"
                      closeOnSelect={true}
                      removeSelected={true}
                      value={status}
                      simpleValue={true}
                      onChange={this.handleStatus}
                      options={STATUS_OPTIONS}
                    />
                  </div>
                ) : null}
              </div>
              <div className="filter-row">
                {RECIPIENT_OPTIONS.length > 1 ? (
                  <div className="filter-field">
                    <label htmlFor="campaignRecipient">Тип получателей</label>
                    <Select
                      id="campaignRecipient"
                      name="recipient"
                      placeholder="Укажите тип получателей"
                      closeOnSelect={true}
                      removeSelected={true}
                      value={recipient}
                      simpleValue={true}
                      onChange={this.handleRecipient}
                      options={RECIPIENT_OPTIONS}
                    />
                  </div>
                ) : null}
                {OFFICE_OPTIONS.length > 1 ? (
                  <div className="filter-field">
                    <label htmlFor="campaignOffice">Офис</label>
                    <Select
                      id="campaignOffice"
                      name="office"
                      placeholder="Укажите офис"
                      closeOnSelect={true}
                      removeSelected={true}
                      value={office}
                      simpleValue={true}
                      onChange={this.handleOffice}
                      options={OFFICE_OPTIONS}
                    />
                  </div>
                ) : null}
                {CREATOR_OPTIONS.length > 1 ? (
                  <div className="filter-field">
                    <label htmlFor="campaignCreator">Создатель</label>
                    <Select
                      id="campaignCreator"
                      name="creator"
                      placeholder="Укажите создателя"
                      closeOnSelect={true}
                      removeSelected={true}
                      value={creator}
                      simpleValue={true}
                      onChange={this.handleCreator}
                      options={CREATOR_OPTIONS}
                    />
                  </div>
                ) : null}
              </div>
              <div className="card-footer">
                <button
                  className="btn btn-sm btn-primary"
                  onClick={this.onFilter}
                >
                  Найти
                </button>
                <button
                  className="btn btn-sm btn-danger"
                  style={{ backgroundColor: "transparent" }}
                  onClick={this.handleReset}
                >
                  Сбросить
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CampaignFilter;
