import React, {Component} from 'react';
import {IssuesAPI} from '../../../api/Client';

export default class IssuesCount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
    };
  }

  componentDidMount() {
    IssuesAPI.
        fetchJSON(`new_count/`).
        then(r => {
          if (r.status === 200) {
            return r.json();
          }
          return 0;
        }).
        then(r => this.setState({count: r}));
  }

  render() {
    let {count} = this.state;
    return !!count ? <span className="float-right badge badge-primary badge-pill">{count}</span> : <span> </span>;
  }

}
