import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Alert, Button, Card, CardBlock } from 'reactstrap';
import ClientAPI, {
  TelebotAPI,
} from '../../api/Client';
import moment from 'moment';
import 'moment/locale/ru';
import { getUserRole } from '../../connect/auth';

const userRole = getUserRole();

export default class Telebot extends PureComponent {
  constructor(props) {
    super(props);

    this.handleChangeType = this.handleChangeType.bind(this);
    this.onCheckboxTourist = this.onCheckboxTourist.bind(this);
    this.handleMessage = this.handleMessage.bind(this);
    this.handleTheme = this.handleTheme.bind(this);
    this.sendTelegramMail = this.sendTelegramMail.bind(this);

    this.state = {
      count: 0,
      tourists: [],
      campaigns: [],
      selectedTourists: [],
      theme: '',
      message: '',
      type: 'all', // all || concrete
      isSuccess: false,
      isError: false,
      msg: '',
    };
  }

  handleChangeType(e) {
    // console.log(e.target.value);
    this.setState({
      type: e.target.value,
    });
  }
  handleTheme(e) {
    this.setState({
      theme: e.target.value,
    });
  }
  handleMessage(e) {
    this.setState({
      message: e.target.value,
    });
  }
  sendTelegramMail() {
    const state = {
      to: 'all',
      recipients: this.state.selectedTourists,
      theme: this.state.theme,
      message: this.state.message,
      send_date: 'now',
    };
    ClientAPI.create('/telegram_campaigns/create_campaign', state)
      .then((r) => r.json())
      .then((res) => {
        if (res.status == 'success') {
          this.showSuccessMessage(res.data);
        } else {
          this.showErrorMessage(res.error);
        }
      });
  }

  showErrorMessage(msg) {
    this.setState({
      isSuccess: false,
      isError: true,
      msg: msg || 'возникла ошибка',
    });
  }

  showSuccessMessage(msg) {
    this.setState({
      isError: false,
      isSuccess: true,
      msg: msg || 'кампания создана',
    });
  }

  onCheckboxTourist(e) {
    const telegramId = e.target.value;
    const newState = [...this.state.selectedTourists];
    if (e.target.checked) {
      if (!~newState.indexOf(telegramId)) {
        // console.log('добавляю ' +  telegramId);
        this.setState(
          {
            ...this.state,
            selectedTourists: [...this.state.selectedTourists, telegramId],
          },
          () => console.log(this.state)
        );
      } else {
        // console.log('уже есть ' +  telegramId);
      }
    } else {
      if (~newState.indexOf(telegramId)) {
        const elementIndex = newState.indexOf(telegramId);
        newState.splice(elementIndex, 1);
        // console.log('удаляю ' +  telegramId);
      } else {
        // console.log('не найден ' +  telegramId);
      }
    }
  }

  fetchItems(page = 1) {
    const filters = this.state.filter ? { q: this.state.filter } : {};
    filters.has_phones = true;
    TelebotAPI.getList(page, filters).then((result) =>
      this.setState({ count: result.count, tourists: result.results || [] })
    );
  }
  componentDidMount() {
    this.fetchItems();
    ClientAPI.getJSON('/telegram_campaigns/campaign_list/').then((result) =>
      this.setState({ campaigns: result.data || [] })
    );
  }

  render() {
    const { tourists, msg, isSuccess, isError, count, campaigns } = this.state;
    const type = this.state.type == 'all' ? true : false;
    let style = {};
    if (type) {
      style = { backgroundColor: '#c4e2c4' };
    }
    return (
      <div className="row">
        <div className="col-6 mb-5">
          <div>
            <label htmlFor="" className="col-form-label">
              Название
            </label>
            <input
              className="form-control mb-4"
              placeholder="Укажите название рассылки"
              value={this.theme}
              onChange={this.handleTheme}
            />

            <label htmlFor="" className="col-form-label">
              <i className="icon-envelope-letter mr-1 ml-2" aria-hidden="true">
                {''}
              </i>
              Текст сообщения
            </label>
            <textarea
              className="form-control mb-4"
              name="textarea-input"
              rows="10"
              placeholder="Content.."
              onChange={this.handleMessage}
            >
              {this.message}
            </textarea>
          </div>
          <Button color="success" onClick={this.sendTelegramMail}>
            Отправить
          </Button>
          <div className="mt-2">
            {isSuccess && <Alert color="success"> {msg} </Alert>}
            {isError && <Alert color="danger"> {msg} </Alert>}
          </div>
        </div>

        <div className="col-6">
          <div className="type-switch row">
            <p className="col-6">
              <label>
                <input
                  type="radio"
                  name="type"
                  className="mr-2"
                  value="all"
                  onChange={this.handleChangeType}
                />
                Для всех
              </label>
            </p>
            <p className="col-6">
              <label>
                <input
                  type="radio"
                  className="mr-2"
                  name="type"
                  value="concrete"
                  onChange={this.handleChangeType}
                />
                Выбрать туристов
              </label>
            </p>
          </div>
          <p>Список туристов: {count}</p>
          <div className="col-md-12">
            <ul className="list-group col-md-12 mb-4">
              {tourists.map((t, idx) => (
                <li
                  key={`item${idx}`}
                  className="justify-content-between list-group-item item-header"
                  style={style}
                >
                  <div>
                    {t.user ? (
                      <div>
                        {!type && (
                          <input
                            type="checkbox"
                            defaultChecked={type}
                            onChange={this.onCheckboxTourist}
                            className="mr-2"
                            value={t.telegram_id}
                          />
                        )}
                        <img
                          src="/img/avatars/touristfp-avatar.png"
                          className="mr-2"
                          width="32"
                          height="32"
                        />
                        {t.user.tourist ? (
                          <span>{t.user.tourist.full_name}</span>
                        ) : (
                          <span>{t.user.person.full_name}</span>
                        )}
                        <span className="float-right">
                          {t.user_phone_number}
                        </span>
                      </div>
                    ) : (
                      <div>
                        {!type && (
                          <input
                            type="checkbox"
                            defaultChecked={type}
                            onChange={this.onCheckboxTourist}
                            className="mr-2"
                            value={t.telegram_id}
                          />
                        )}
                        <img
                          src="/img/avatars/tourist-avatar.png"
                          className="mr-2"
                          width="32"
                          height="32"
                        />
                        <span>{t.telegram_id}</span>
                      </div>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div>
          <hr />
        </div>
        <div className="mailing-list p-3">
          <h5>Созданные рассылки</h5>
          <div>
            <Card>
              <CardBlock>
                <table
                  className="table table--blue table-bordered table-striped
                    table-sm animated fadeIn"
                >
                  <thead>
                    <tr>
                      <th className="text-center tableItem hidden-mobile">
                        номер рассылки
                      </th>
                      <th className="text-center tableItem">Название</th>
                      <th className="text-center tableItem  hidden-mobile">
                        Текст
                      </th>
                      <th className="text-center tableItem">Получателей</th>
                      <th className="text-center tableItem">Статус</th>
                      <th className="text-center tableItem">Создана</th>
                      <th className="text-center tableItem">Офис</th>
                    </tr>
                  </thead>
                  <tbody>
                    {campaigns.map((item, idx) => (
                      <tr key={idx}>
                        <td className="text-center tableItem  hidden-mobile">
                          {item.id}
                        </td>
                        <td className="text-center tableItem">
                          <Link to={`/tourists/${item.id}`}>{item.theme}</Link>
                        </td>
                        <td className="text-center tableItem  hidden-mobile">
                          {item.message_text}
                        </td>
                        <td className="text-center tableItem hidden-mobile">
                          <b>{item.recipients.length}</b>
                        </td>
                        <td className="text-center tableItem hidden-mobile">
                          {item.status}
                        </td>
                        <td className="text-center tableItem hidden-mobile">
                          {moment(item.send_date).format('DD.MM.YYYY')}
                        </td>
                        <td className="text-center tableItem hidden-mobile">
                          {item.sender}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </CardBlock>
            </Card>
          </div>
        </div>
      </div>
    );
  }
}
