import React from 'react';
import {Link} from 'react-router-dom';
import CalendarDayNotificationsPanel from './CalendarDayNotificationsPanel';

const CalendarTable = ({onSubmit = f => f, onRemove = f => f, onDayId = f => f, settings}) => {
  const submit = (data) => onSubmit(data);
  const removeEvent = (e) => {
    e.preventDefault();
    return onRemove(e.target.id);
  };
  const createEvent = (e) => {
    e.preventDefault();
    return onDayId(e.target.id);
  };
  const iStyle = {
    display: 'inline-block',
  };
  const EVENT_TYPE_LANG = {
    meet: 'Событие',
    deffered: 'Напомнить',
    call: 'Позвонить'
  }
  const EVENT_TYPE_CLASS = {
    meet: "fa fa-thumb-tack",
    deffered: "fa fa-bell",
    call: "fa fa-phone",
  };
  return (
      <div className="container">
        <div id="calendar-table">
          <table>
            <tbody>

            <tr className="calendar-head" style={{cursor: "auto"}}>
              <th>ПН</th>
              <th>ВТ</th>
              <th>СР</th>
              <th>ЧТ</th>
              <th>ПТ</th>
              <th>СБ</th>
              <th>ВС</th>
            </tr>
            {settings.data.map((row, i) => {
              return (
                  <tr key={i}>
                    {row.map((event, j) => {
                      return (
                        <td key={j} className={event.style} style={{cursor: "auto"}}>
                          <Link to={`calendar/${event.dateId}`}>
                              <div style={{ textAlign: "right" }}>
                                {event.events.length ? (
                                  <span className="numeric-day task">
                                    {event.date}
                                  </span>
                                ) : (
                                  <span className="numeric-day">
                                    {event.date}
                                  </span>
                                )}
                              </div>
                              {/* <span>{settings.local.week[event.day]}</span> */}
                              {/*!!event.events.length && (
                                <div className="item-count">
                                  Событие: {event.events.length}
                                </div>
                              ) */}
                              <div className="day-notes">
                                {event.events.length ? (
                                  event.events.map(({ comment_type, id, notes, start_time, user }, key) => (
                                  <Link key={key} to={`calendar/${event.dateId}#${id}`}>
                                    <div title={`Исполнитель: ${user && user.full_name}`}>
                                      {comment_type ? (
                                        <div className="item-count">
                                          <i
                                            className={
                                              EVENT_TYPE_CLASS[comment_type]
                                            }
                                          ></i>
                                          <span
                                            className="item-label"
                                            style={{ marginLeft: 5 }}
                                          >
                                            {start_time ? `${start_time.substring(0, 5)} ` : ''}{EVENT_TYPE_LANG[comment_type]}
                                          </span>
                                        </div>
                                      ) : (
                                        <div className="item-count">
                                          <i className="fa fa-bookmark"></i>
                                          <span
                                            className="item-label"
                                            style={{ marginLeft: 5 }}
                                          >
                                            {start_time.substring(0, 5)} {notes.length > 9 ? `${notes.substring(0, 10)}...` : notes }
                                          </span>
                                        </div>
                                      )}
                                    </div>
                                  </Link>
                                  ))
                                ) : (
                                  <div></div>
                                )}
                              </div>
                                <CalendarDayNotificationsPanel event={event} iStyle={iStyle}/>
                              {!event.style.includes('past') && (
                                <i
                                  id={event.dateId}
                                  onClick={createEvent}
                                  className="add-event-btn fa fa-plus-circle"
                                  title="Добавить событие"
                                >{""}</i>
                              )}
                          </Link>
                        </td>
                      );
                    })}
                  </tr>
              );
            })}
            </tbody>
          </table>
        </div>
      </div>
  );
};

export default CalendarTable;
