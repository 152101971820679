import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { HashRouter, Switch, Route } from 'react-router-dom'

import { createBrowserHistory } from 'history';
// ...
import App from './app'
import configureStore from './store/configureStore'

// // ..<remove (!!!!!!)>
// import '../images/favicon.png'
// import '../styles/style.scss'
// // ..</remove (!!!!!!)>


let store = configureStore();
const history = createBrowserHistory();

ReactDOM.render((
  <Provider store={store}>
    <HashRouter history={history} >
      <Switch>
        <Route path="/" component={App} />
      </Switch>
    </HashRouter>
  </Provider>
), document.getElementById('root'))
