import React, {Component} from 'react';
import TouristsFpTable from '../../views/TouristsFpTable';
import 'moment/locale/ru';

export default class TouristsFp extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    // TODO убрать избыточность
    return <TouristsFpTable/>;
  }
}
