import React, { PureComponent } from "react";
import { CalendarApi, POSITIVE_ACTION_STATUSES } from "../../../../api/Client";

export class CalendarDayNotificationsPanel extends PureComponent {
  constructor(props) {
    super(props);
    this.isStyle = this.props.isStyle;
    this.onShowNotifications = this.props.onShowNotifications;
    this.fetchNotificationCountByDay =
      this.fetchNotificationCountByDay.bind(this);
    this.state = {
      isNotificationsOpened: false,
      event: this.props.event,
      notifications: {},
      by_notification: {
        birthday: 0,
        comefromrest: 0,
        docforvizaexpires: 0,
        flytomorrow: 0,
        passportexpires: 0,
        payexpires: 0,
        duringrest: 0,
      },
      isLoading: false,
    };
  }

  fetchNotificationCountByDay(day) {
    let status;
    this.setState({ isLoading: true });
    CalendarApi.fetchList(1, { day: day }, `count`)
      .then((r) => {
        status = r.status;
        if (status === POSITIVE_ACTION_STATUSES.list) {
          return r.json();
        }
        return {};
      })
      .then((res) => {
        if (status === POSITIVE_ACTION_STATUSES.list) {
          this.setState((prevState) => {
            const { notifications } = prevState;
            notifications[day] = res;
            console.log(notifications)
            return { notifications: notifications };
          });
        }
      })
      .then((req, res) => {
        this.setState({ isNotificationsOpened: true, isLoading: false });
      });
  }

  setNotificationsClosed() {
    this.setState({ isNotificationsOpened: false });
  }

  render() {
    const dayId = this.state.event.dateId;
    const { by_notification } =
      this.state.notifications[dayId] || this.state.by_notification;
    const { isLoading } = this.state;

    if (this.state.isNotificationsOpened && by_notification) {
      console.log(by_notification);
      return (
        <div className="calendar-day__notify-wrapper opened">
          <div className="calendar-day__notify-icons">
            <i
              className="fa fa-angle-down calendar-day__notify-close-btn"
              onClick={(e) => {
                e.preventDefault();
                this.setNotificationsClosed();
              }}
            ></i>
            <p className="notify-icon-item birthday">
              <i className="calendar-day__notify-icon fa fa-birthday-cake"></i>{" "}
              <span>{by_notification.birthday}</span>
            </p>
            <p className="notify-icon-item flytomorrow">
              <i className="calendar-day__notify-icon fa fa-clock-o"></i>{" "}
              <span>{by_notification.flytomorrow}</span>
            </p>
            <p className="notify-icon-item comefromrest">
              <i className="calendar-day__notify-icon fa fa-check-circle-o"></i>{" "}
              <span>{by_notification.comefromrest}</span>
            </p>
            <p className="notify-icon-item payexpires">
              <i className="calendar-day__notify-icon fa fa-money"></i>{" "}
              <span>{by_notification.payexpires}</span>
            </p>
            <p className="notify-icon-item passportexpires">
              <i className="calendar-day__notify-icon fa fa-calendar-times-o"></i>{" "}
              <span>{by_notification.passportexpires}</span>
            </p>
            <p className="notify-icon-item docforvizaexpires">
              <i className="calendar-day__notify-icon fa fa-clone"></i>{" "}
              <span>{by_notification.docforvizaexpires}</span>
            </p>
          </div>
        </div>
      );
    } else {
      return (
        <div className="calendar-day__notify-wrapper closed">
          <p
            className="calendar-day__notify-header"
            onClick={(e) => {
              e.preventDefault();
              this.fetchNotificationCountByDay(this.state.event.dateId);
            }}
          >
            <i className="fa fa-envelope"></i>
            <span>{`${isLoading ? "Загрузка..." : "Уведомления"}`}</span>
            <span className="float-right">
              {!isLoading ? (
                <i
                  id={this.state.event.dateId}
                  style={this.iStyle}
                  className={`fa ${isLoading ? "fa-clock" : "fa-angle-up"}`}
                  title="Показать уведомления"
                >
                  {""}
                </i>
              ) : (
                <div className="calendar-day__loading-icon">{""}</div>
              )}
            </span>
          </p>
        </div>
      );
    }
  }
}

export default CalendarDayNotificationsPanel;
