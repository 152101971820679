import React from 'react'
import {Link} from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/ru';
import {Col, Row, Card, CardBlock, CardHeader, Button, Alert} from 'reactstrap';

import Toggle from '../../Notify/components/Toggle';

export default class TouristsList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      toggleErrors: {}
    }
  }
  render() {
    const {isSuperAdmin, items, selected} = this.props;
    // console.log("пропсы турист лист Потенциальные клиенты", this.props)
    const {toggleErrors} = this.state;
    return <ul className="list-group">
      <li className="justify-content-between list-group-item item-header">
        <div  style={{display: "flex"}}>
          <div style={{width: "56"}}></div>
          <div style={{width: "220"}}><small><b>Турист</b></small></div>
          <div style={{width: "150"}}><small><b>Метки</b></small></div>
          <div style={{width: "200"}}><small><b>Контакты</b></small></div>
          <div style={{width: "120"}}><small><b>Уведомления</b></small></div>
          <div style={{width: "200"}}><small><b>Менеджер/офис</b></small></div>
        </div>
      </li>
        {items.map((item, idx) => {
          const info_id = item.tourist_fp_info.id;
          return (
              <li key={idx} className="justify-content-between list-group-item item-header" style={{
                padding: "0.5em 0.5em"
              }}>
                <div
                  style={{
                    display: "flex"
                  }}
                >

                <p className="p-2 hidden-mobile" style={{
                  alignSelf: "flex-start",
                  marginTop: 12
                }}>
                  <input id={`tourist-${item.id}`} type="checkbox"
                          checked={selected.includes(
                            item.id)}
                            onChange={() => this.toggleItem(item)}/>
                </p>
                <p style={{
                  alignSelf: "flex-start",
                  marginTop: 16
                }}>
                  {item.is_active ?
                    <img
                    src="/img/avatars/touristfp-avatar.png"
                    className="mr-2"
                    width="32"
                    height="32"
                    /> : (
                      <img
                      src="/img/avatars/tourist-avatar.png"
                      className="mr-2"
                      width="32"
                      height="32"
                      />
                    ) }
                </p>
                <div style={{width: "220"}}>
                  <small><b># {item.fp_pk}</b></small><br/>
                  <p className="mb-0">

                  <Link
                      to={`/tourists/${item.id}`}>
                    <b>{item.full_name}</b>
                  </Link>
                  </p>
                  <p className="mb-0">
                   <small> {moment(item.born).format('DD.MM.YYYY')}</small>
                   {Array.isArray(item.phone) &&
                  item.phone.length ? (
                    <i className="fa fa-mobile ml-3 p-1" style={{
                      background: "#94c596",
                      color: "#fff",
                      width: 22,
                      borderRadius: 15,
                      textAlign: "center"
                    }}></i>
                  ) : (null) }
                  {Array.isArray(item.email) &&
                  item.email.length ? (
                   <i className="fa fa-at ml-1 p-1" style={{
                     background: "#e4c569",
                     color: "#fff",
                     width: 22,
                     borderRadius: 15,
                     textAlign: "center"
                   }}></i>
                  ) : (null) }
                  </p>
                </div>
                <div style={{width: "150"}}>
                  <p className="mb-0">
                    {item.tag ? item.tag.map((tag, key) => {
                      return <p
                      className="badge badge-success badge-pill mr-1"
                      key={key}>{tag.name}</p>;
                    }) : null}
                  </p>
                </div>
                <p style={{width: "200"}}>
                  {Array.isArray(item.phone) &&
                  item.phone.map((i, idx) => (
                    <a key={idx} href={`tel://${i.value}`}>
                        {i.value}<br/>
                      </a>
                  ))}
                  {Array.isArray(item.email) && item.email.length
                  ?
                  item.email.
                  map(i => i.value).
                          filter(i => i).
                          join(`, `)
                          : null }
                </p>
                <div style={{width: 120}}>
                  <div
                      className="text-small-mobile flex-column-mobile">
                    <Toggle
                        id={info_id}
                        lang="Email"
                        checked={item.tourist_fp_info.is_email_notify}
                        onChange={this.toggleEmailNotify}
                        />
                    <Toggle
                        id={info_id}
                        lang=" SMS "
                        checked={item.tourist_fp_info.is_sms_notify}
                        onChange={this.toggleSmsNotify}
                        />
                    {!!toggleErrors[info_id] &&
                    Object.keys(toggleErrors[info_id]).length
                    ? Object.keys(toggleErrors[info_id]).map(
                      key => (
                        <Alert color="danger" key={key}>
                                  {toggleErrors[info_id][key]}
                                </Alert>
                            ),
                            ) : null
                          }
                  </div>
                </div>
                <p className="hidden-mobile">
                  {
                  item.managers ? item.managers.map((m, i) => {
                    return (
                      <div key={i}>
                          <b>{m.name ? m.name : m.username}</b>
                        </div>
                    );
                  }) : null
                }</p>
                <p className="hidden-mobile">
                  <small><b style={{color: "#777"}}>менеджер</b></small><br/>
                  {
                  item.managers_fp ? item.managers_fp.map((m, i) => {
                    return <div key={i}><b>{m.name}</b></div>;
                  }) : null
                }
                <br/>
                {item.office && <span>{item.office.address}</span>}
                </p>
                </div>
              </li>
          );
        })}
    </ul>
  }
}
