import React, {Component} from 'react';
import {CardHeader, CardFooter, CardBlock, Row, Col, Button, Alert} from 'reactstrap';
import {EmployeeAPI, OrderFpAPI, POSITIVE_ACTION_STATUSES, UsersAPI} from '../../api/Client';
import BackButton from '../../components/BackButton';
import moment from 'moment';
import 'moment/locale/ru';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-select-plus/dist/react-select-plus.css';
import Select from 'react-select-plus';
import {ADMIN, getUserRole, SUPER_ADMIN} from '../../connect/auth';
import {getCurrencyIcon} from '../OrdersFpTable/CurrencySelector';

export default class OrderFp extends Component {
  constructor(props) {
    super(props);

    this.getInfo = this.getInfo.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.getUsers = this.getUsers.bind(this);
    this.handleOnSave = this.handleOnSave.bind(this);

    this.state = {
      data: {},
      managers: [],
      selectedManagers: '',
      isDisabled: true,
      isChangedForm: false,
      isError: false,
      isSuccess: false,
    };
  }

  getInfo() {
    let status;
    OrderFpAPI.
        fetchJSON(`${this.props.match.params.number}/`).
        then(r => {
          status = r.status;
          return r.json();
        }).
        then((result) => {
          if (status === POSITIVE_ACTION_STATUSES.list) {
            this.setState({
              data: result,
              selectedManagers: result.manager.length ? result.manager.map((m) => m.id).join(`,`) : ``,
            });
          }
        });
  }

  getUsers() {
    let status;
    UsersAPI.
        fetchJSON(`full_list/`).
        then(r => {
          status = r.status;
          return r.json();
        }).
        then((result) => {
          if (status === POSITIVE_ACTION_STATUSES.list) {
            this.setState({
              managers: result,
              isDisabled: false,
            });
          }
        });
  }

  handleOnSave(v) {
    let status;
    const {selectedManagers} = this.state;
    OrderFpAPI.
        update(`${OrderFpAPI.resource_url}${this.props.match.params.number}/order_managers`, {
          manager: selectedManagers ? selectedManagers.split(',').map(i => parseInt(i, 10)) : [],
        }).
        then(r => {
          status = r.status;
          return r.json();
        }).
        then((result) => {
          if (status === POSITIVE_ACTION_STATUSES.update) {
            this.setState({
              selectedManagers: result.manager.length ? result.manager.join(`,`) : ``,
              isError: false,
              isSuccess: true,
              errorMessages: {},
            });
          } else {
            this.setState({
              isError: true,
              isSuccess: false,
              errorMessages: result,
            });
          }
        });
  }

  handleOnChange(v) {
    this.setState({
      selectedManagers: v,
      isChangedForm: true,
      isError: false,
      isSuccess: false,
      errorMessages: {},
    });
  };

  componentDidMount() {
    this.getInfo();
    this.getUsers();
  }

  render() {
    if (!this.state.data) {
      return null;
    }
    const {
      fp_pk, office, status, tourists_fp, manager_fp, create_date, begin_date, end_date, depart_city,
      destination_country, summ, currency, customer,
    } = this.state.data;
    const {isDisabled, isChangedForm, selectedManagers, errorMessages, isError, isSuccess, managers} = this.state;
    const userRole = getUserRole();
    const isAdmin = userRole === ADMIN;
    const isSuperAdmin = userRole === SUPER_ADMIN;
    return (
        <div className="animated fadeIn">
          <Row>
            <Col md="12">
              <div className="card card-accent-primary">
                <CardHeader>
                  <i className="icon-paper-plane">{''}</i>
                  Заявка &#x02116; {fp_pk} / создана менеджером из ФСУ - {!!manager_fp ? manager_fp.name : ``}
                  <span className="badge badge-info float-right">{
                    moment(create_date).format('DD.MM.YYYY, HH:mm')
                  }</span>
                </CardHeader>
                <CardBlock>
                  <Row>
                    <label className="text-right col-md-2">Офис:</label>
                    <p className="col-md-8">{!!office ? office.title : ``}</p>
                  </Row>
                  <Row>
                    <label className="text-right col-md-2">Статус:</label>
                    <p className="col-md-8">{!!status ? status.name : ``}</p>
                  </Row>
                  <Row>
                    <label className="text-right col-md-2">Инфо:</label>
                    <p className="col-md-8">
                      {depart_city} - {destination_country} с {moment(begin_date).
                        format('DD.MM.YY')} по {moment(end_date).format('DD.MM.YY')} на {
                      moment.duration(moment(end_date).diff(moment(begin_date))).asDays()
                    } ночей за {summ} {!!currency && getCurrencyIcon(currency.name)}
                    </p>
                  </Row>
                  {!!customer && (
                      <Row>
                        <label className="text-right col-md-2">Заказчик:</label>
                        <p className="col-md-8">{customer.full_name}</p>
                      </Row>
                  )}
                  <h5>Список туристов:</h5>
                  {!!tourists_fp && <ul>{tourists_fp.map(t => <li key={t.id}>{t.full_name}</li>)}</ul>}
                  {!!manager_fp && (
                      <Row>
                        <label className="text-right col-md-2">Менеджер ФП:</label>
                        <p className="col-md-8">{`${manager_fp.fp_pk} (${manager_fp.name})`}</p>
                      </Row>
                  )}
                  <Row>
                    <div className="col-md-6">
                      <div className="input-group">
                        <span className="input-group-addon" title="Наличие телефонов">
                          Менеджеры заявки
                        </span>
                        <Select
                            id="manager-selector"
                            placeholder="Менеджеры заявки"
                            name="hasPhones"
                            removeSelected={true}
                            multi={true}
                            value={selectedManagers}
                            simpleValue={true}
                            onChange={this.handleOnChange}
                            options={
                              managers.map(m => ({value: `${m.id}`, label: `${m.id} (${m.last_name} ${m.first_name})`}))
                            }
                            className="form-control"
                            disabled={!(isSuperAdmin || isAdmin) || isDisabled}
                        />
                      </div>
                    </div>
                  </Row>
                  {isSuccess && (
                      <Row>
                        <div className="col-md-6">
                          <label className="text-right col-md-2"> </label>
                          <Alert>Успешно</Alert>
                        </div>
                      </Row>
                  )}
                  {(isError && !!errorMessages && !!errorMessages.manager) && errorMessages.manager.map((item, idx) => (
                      <Row key={idx}>
                        <div className="col-md-6">
                          <label className="text-right col-md-2"> </label>
                          <Alert className="bg-danger">{item}</Alert>
                        </div>
                      </Row>
                  ))}
                </CardBlock>
                <CardFooter>
                  <BackButton {...this.props}/>
                  {(isSuperAdmin || isAdmin) && (
                      <Button color={isChangedForm ? 'success' : 'secondary'} onClick={this.handleOnSave}
                              disabled={isDisabled || !isChangedForm}>Сохранить</Button>
                  )}
                </CardFooter>
              </div>
            </Col>
          </Row>
        </div>
    );
  }
}
