import React, {Component} from 'react';
import IssueMerger from './components/IssueMerger';

class IssuesMergers extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {items = [], handleSkipIssue} = this.props;
    return (
        <ul id="dashboard__orders" className="list-group">
          {items.map((item, idx) => <IssueMerger issue={item} key={idx} handleSkipIssue={handleSkipIssue}/>)}
        </ul>
    );
  }
}

export default IssuesMergers;
