import React, {PureComponent} from 'react'
import Swal from 'sweetalert'
import {Card, CardBlock, CardHeader, Col, Row} from 'reactstrap'
import {Link} from 'react-router-dom'

import {POSITIVE_ACTION_STATUSES, SubscriptionAPI } from '../../api/Client'
import Paginator from '../../components/Paginator';
import ProgressBar from '../../components/ProgressBar';

import SubscriptionFilter from './components/SubscriptionFilter';
import SubscriptionTable from './components/SubscriptionTable';


class Subscription extends PureComponent {
  constructor(props) {
    super(props);

    this.createMail = this.createMail.bind(this);
    this.handleFetch = this.handleFetch.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);

    this.state = {
        items: [],
        filter: '',
        page: 1,
        count: 0,
        isSuccess: false,
        isLoading: false,
        errors: null,
    };

        localStorage.removeItem('abandonedcustomers_filter');
        localStorage.removeItem('franchise_filter');
        localStorage.removeItem('franchise_leads_filter');
        localStorage.removeItem('leads_filter');
        localStorage.removeItem('persons_filter');
        localStorage.removeItem('subscription_filter');
        localStorage.removeItem('tourist_filter');
        localStorage.removeItem('tourists_cabinet_filter');
        localStorage.removeItem('tourists_filter');
        localStorage.removeItem('tourists_report_filter');
        localStorage.removeItem('touristsfp_filter');

        localStorage.removeItem('leads_total');
        localStorage.removeItem('tourists_total');
        localStorage.removeItem('tourist_total');
        localStorage.removeItem('touristsfp_total');
        localStorage.removeItem('persons_total');
        localStorage.removeItem('tourists_cabinet_total');
        localStorage.removeItem('franchise_total');
        localStorage.removeItem('subscription_total');
        localStorage.removeItem('franchise_leads_total');
        localStorage.removeItem('tourists_report_type');
        localStorage.removeItem('tourists_report_address_books');
        localStorage.removeItem('abandonedcustomers_total');
        localStorage.removeItem('type');
  }

  componentDidMount() {
    const {page} = this.state;
    this.handleFetch(page)
  }

  createMail() {
    const {count, filter} = this.state;
    console.log(this.state);
    Swal(`Создаем рассылку для раздела агенты и клиенты на ${this.state.count} записей`);
    if (filter) {
      localStorage.setItem('subscription_filter', JSON.stringify(filter));

    } else {
      localStorage.setItem('subscription_filter', JSON.stringify({}));
    }
    localStorage.setItem('subscription_total', JSON.stringify(count));
    localStorage.setItem('type', 'subscription');
    return false;
  }

  handleFetch(page = 1) {
    this.setState({
        isLoading: true,
    }, () => {
        let status;
        let {filter} = this.state;
        SubscriptionAPI.fetchList(page, filter).then(r => {
            status = r.status;
            return r.json();
        }).then(r => {
            if (status === POSITIVE_ACTION_STATUSES.list) {
                this.setState({
                    items: r.results || [],
                    count: r.count,
                    page: page,
                    isSuccess: false,
                    errors: null,
                    isLoading: false,
                });
            } else {
                this.setState({
                    items: [],
                    count: 0,
                    page: 1,
                    isLoading: false,
                });
            }
        });
    });
  }

  handleFilter(filter) {
      this.setState({
          filter
      }, () => this.handleFetch())
  }

  handlePageChange(data) {
    this.handleFetch(data.selected + 1)
  }

  render() {
    const {
        items,
        page,
        count,
        isLoading,
    } = this.state;
    return (
      <div>

        <SubscriptionFilter onFilter={this.handleFilter} />

        <Row>
            <Col lg="12" style={{position: "unset"}}>
                <Card style={{position: "unset"}}>
                    <CardHeader>
                        <i className="fa fa-align-justify">{''}</i> Всего&nbsp;
                        <span className="badge badge-info">{count}</span>
                        <Link className="btn btn-success ml-3 mt-0" onClick={this.createMail}
                          to="templates"
                          style={{display: 'inline-block'}}>
                            <span>
                              <i className="fa fa-send mr-2">{''}</i>
                              Создать рассылку
                            </span>
                          </Link>

                    </CardHeader>
                    {isLoading ? <ProgressBar/> : (
                        <CardBlock className="card-block_responsive">
                            <SubscriptionTable items={items}/>
                            <Paginator total={count}
                                       onPageChange={this.handlePageChange}
                                       forcePage={0}
                                       initialPage={page - 1}
                            />
                        </CardBlock>
                    )}
                </Card>
            </Col>
        </Row>

      </div>
    )
  }
}

export default Subscription
