import React, { Component } from 'react';
import BackButton from '../../../components/BackButton';
import ClientAPI, { PersonAPI } from '../../../api/Client';
import { Button} from 'reactstrap';
import Select from 'react-select-plus';
import "react-phone-input-2/lib/style.css";
import EditInputPhone from "../../../components/EditInputPhone/EditInputPhone";

export default class PersonEdit extends Component {
    constructor(props) {
        super(props);

        this.handlePageChange = this.handlePageChange.bind(this);
        this.fetchItems = this.fetchItems.bind(this);
        this.fetchTags = this.fetchTags.bind(this)
        this.handleTagsOnChange = this.handleTagsOnChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.getChangedValues = this.getChangedValues.bind(this);

        this.state = {
            id: 0,
            info: {},
            items: [],
            count: 0,
            personId: this.props.match.params.number,
            form_changed: false,
            name: '',
            middle_name: '',
            last_name: '',
            phones: '',
            emails: '',
            phone: [],
            email: [],
            born: '',
            passport_series: '',
            passport_number: '',
            passport_expires: '',
            person_info: '',
            activeTab: '1',
            tag: [{name: 'ВИП', id: 12}],
            tags: [],
            user_tags: [],
            tags_options: [],
            errors: {}
        };
    }

    handleChange(event) {
        const target = event.target;
        const name = target.name;
        this.setState({
            [name]: target.value,
            form_changed: true
        });
    }

    toggleTab = (tab) => {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }
    handleTagsOnChange = (tags) => {
        this.setState({
            ...this.state,
            tag: tags.map((tag) => {
                return { value: tag.value, label: tag.label }
            }),
            formChanged: true,
            form_changed: true
        })
        let filters = {
            tags: tags.map(tag => tag.value)
        }
    }
    fetchTags() {
        let self = this
        ClientAPI.getJSON('/tags/full_list/')
            .then(res => (
                self.setState({
                    user_tags: res || []
                })
            ))
    }
    handleSave() {
        const self = this;
        PersonAPI.modify(this.state.personId, this.getChangedValues()).then(
            (r) => r.json()
        ).then(result => {
          {
            if (!result.id) {
              this.setState({
                errors: result,
              });
            } else {
              self.setState({ form_changed: false });
            }
          }
        })
    }

    getChangedValues() {
        let result = {};
        if (this.state.name !== this.state.info.name) {
            result.name = this.state.name || null;
        }
        if (this.state.last_name !== this.state.info.last_name) {
            result.last_name = this.state.last_name;
        }
        if (this.state.middle_name !== this.state.info.middle_name) {
            result.middle_name = this.state.middle_name;
        }
        if (this.state.phone !== this.state.info.phone) {
          if (this.state.phone) {
            result.phone = this.state.phone;
          } else {
            result.phone = [];
          }
        }
        if (this.state.emails !== this.state.info.emails) {
            if (this.state.emails) {
                result.email = this.state.emails.split(',').map(x => x.trim()).filter(x => x).map(x => ({value: x}));
            } else {
                result.email = []
            }
        }
        if (this.state.born !== this.state.info.born) {
            result.born = this.state.born;
        }
        if (this.state.passport_series !== this.state.info.passport_series) {
            result.passport_series = this.state.passport_series;
        }
        if (this.state.passport_number !== this.state.info.passport_number) {
            result.passport_number = this.state.passport_number;
        }
        if (this.state.passport_expires !== this.state.info.passport_expires) {
            result.passport_expires = this.state.passport_expires;
        }
        if (this.state.person_info !== this.state.info.info) {
            result.info = this.state.person_info;
        }
        // const { tags } = this.state;
        // if ((tags) && (tags.length) && (tags.length > 0)) {
        //     result.tags = tags.map(tag => {
        //         return { id: tag.value, name: tag.label }
        //     })
        // } else {
        //     result.tags = []
        // }
        const { tag } = this.state;
        if ((tag) && (tag.length) && (tag.length > 0)) {
            result.tag = tag.map(tag => tag.value)
        }
        for (let k in result) {
            if (result[k] === '') {
                result[k] = null
            }
        }
        return result;
    }

    handlePageChange(data) {
        let page = data.selected + 1;
        this.setState(
            { pageNumber: page },
            () => this.fetchItems(page)
        );
    }

    updateIssues = () => {
    }

    fetchItems(page = 1) {
        const self = this;

        // Заявки клиента
        // PersonAPI.getList(page, {}, `${this.state.personId}/leads/`)
        //     .then((result) => {
        //         self.setState({
        //             items: result.results || [],
        //             count: result.count
        //         })
        //     });
    }

    componentDidMount() {
        // Клиент
        PersonAPI.getInfo(this.state.personId).then((result) => {
          if (!result.id) {
            this.setState({
              errors: result
            })
          } else {
            this.setState({
              id: result.id,
              info: result || {},
              name: result.name || "",
              middle_name: result.middle_name || "",
              last_name: result.last_name || "",
              phones: result.phones || "",
              emails: Array.isArray(result.email) && result.email.length
                      ? result.email.filter(x => x.value).map(x => x.value).join(', ')
                      : "",
              phone: result.phone || [],
              email: result.email || [],
              born: result.born || "",
              passport_series: result.passport_series || "",
              passport_number: result.passport_number || "",
              passport_expires: result.passport_expires || "",
              person_info: result.info || "",
              tag: result.tag,
              errors: {}
            })
          }
        });

        // this.fetchItems(1)
        this.fetchTags()
    }

    render() {
        // let person = this.state.info;
        const tags = this.state.tag.map((tag) => {
            return { value: tag.id || tag.value, label: tag.label || tag.name }
        })
        const TAGS_OPTIONS = this.state.user_tags.map((tag) => {
            return { value: tag.id, label: tag.name }
        })
        const {errors} = this.state
        return (
          <div className="animated fadeIn filter filter--green">
            <div className="row">
              <div className="col-sm-6 col-md-6">
                <div className="card card-accent-primary">
                  <div className="card-block">
                    <div className="form-group row">
                      <label className="text-right col-md-2 col-form-label">
                        Фамилия:
                      </label>
                      <div className="col-md-8">
                        <input
                          type="text"
                          id="last-name-input"
                          name="last_name"
                          value={this.state.last_name}
                          onChange={this.handleChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="text-right col-md-2 col-form-label">
                        Имя:
                      </label>
                      <div className="col-md-8">
                        <input
                          type="text"
                          id="name-input"
                          name="name"
                          value={this.state.name}
                          onChange={this.handleChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="text-right col-md-2 col-form-label">
                        Отчество:
                      </label>
                      <div className="col-md-8">
                        <input
                          type="text"
                          id="middle-name-input"
                          name="middle_name"
                          value={this.state.middle_name}
                          onChange={this.handleChange}
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="text-right col-md-2 col-form-label">
                        Телефон:
                      </label>
                      <div className="col-md-8">
                        <input
                          type="phone"
                          id="phone-input"
                          name="phones"
                          value={this.state.phones}
                          onChange={this.handleChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="text-right col-md-2 col-form-label">
                        Телефоны:
                      </label>
                      <EditInputPhone
                        phones={this.state.phone}
                        onChange={(value) =>
                          this.setState(
                            { phone: value, form_changed: true, errors: {} },
                            () => console.log(this.state.phone)
                          )
                        }
                        errors = {(errors && errors.phone !== undefined) ? errors.phone : null}
                      />
                    </div>
                    <div className="form-group row">
                      <label className="text-right col-md-2 col-form-label">
                        Эл.почта:
                      </label>
                      <div className="col-md-8">
                        <input
                          type="email"
                          id="email-input"
                          name="emails"
                          value={this.state.emails}
                          onChange={this.handleChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="text-right col-md-2 col-form-label">
                        Д.Р.:
                      </label>
                      <div className="col-md-8">
                        <input
                          type="date"
                          id="born-input"
                          name="born"
                          value={this.state.born}
                          onChange={this.handleChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="text-right col-md-2 col-form-label">
                        Метки:
                      </label>
                      <div className="col-md-8">
                        <Select
                          id="tagsTouristID"
                          className="mb-3"
                          name="tags"
                          placeholder="Выберите из спиcка..."
                          multi={true}
                          closeOnSelect={true}
                          removeSelected={true}
                          value={tags}
                          onChange={this.handleTagsOnChange}
                          options={TAGS_OPTIONS}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="text-right col-md-2 col-form-label">
                        Паспорт:
                      </label>
                      <div className="col-md-2">
                        <input
                          type="number"
                          id="passport-series-input"
                          name="passport_series"
                          value={this.state.passport_series}
                          onChange={this.handleChange}
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-2">
                        <input
                          type="number"
                          id="passport-number-input"
                          name="passport_number"
                          value={this.state.passport_number}
                          onChange={this.handleChange}
                          className="form-control"
                        />
                      </div>
                      <div className="col-md-4">
                        <input
                          type="date"
                          id="passport-expires-input"
                          name="passport_expires"
                          value={this.state.passport_expires}
                          onChange={this.handleChange}
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="text-right col-md-2 col-form-label">
                        Информация:
                      </label>
                      <div className="col-md-8">
                        <textarea
                          id="person_info"
                          name="person_info"
                          rows="9"
                          value={this.state.person_info}
                          onChange={this.handleChange}
                          className="form-control"
                          placeholder="Информация о клиенте..."
                        />
                      </div>
                    </div>
                    <BackButton {...this.props} />
                    <Button
                      color={this.state.form_changed ? "success" : "secondary"}
                      disabled={!this.state.form_changed}
                      onClick={this.handleSave}
                    >
                      <i className="fa fa-dot-circle-o">{}</i> Сохранить
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    }
}
