import React, {Component} from 'react';
import Select from 'react-select-plus';

export default class UserRoles extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {userRoles, userRole, handleUserRole} = this.props;
    return (
        <Select
            id="employeeType"
            className="filter-field"
            name="userRole"
            placeholder="Выберите тип сотрудника из спиcка..."
            closeOnSelect={true}
            removeSelected={true}
            value={userRole}
            simpleValue={true}
            onChange={handleUserRole}
            options={userRoles}
        />
    );
  }
}
