import React, {PureComponent} from 'react';
import {Card, CardBlock, CardHeader, Col, Row} from 'reactstrap';
import moment from 'moment';
import 'moment/locale/ru';
import {InvoicesAPI, POSITIVE_ACTION_STATUSES} from '../../api/Client';
import Paginator from '../../components/Paginator';
import BackButton from '../../components/BackButton';

export default class Invoice extends PureComponent {
  constructor(props) {
    super(props);

    this.fetchItems = this.fetchItems.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);

    this.state = {
      data: {},
      items: [],
      count: 0,
    };
  }

  fetchData() {
    let status;

    InvoicesAPI.
        fetchJSON(`${this.props.match.params.number}/`).
        then(r => {
          status = r.status;
          return r.json();
        }).
        then(r => {
          if (status === POSITIVE_ACTION_STATUSES.retrieve) {
            this.setState({
              data: r || {},
            });
          }
        });
  }

  fetchItems(page = 1) {
    let status;

    InvoicesAPI.
        fetchList(1, {}, `${this.props.match.params.number}/payments`).
        then(r => {
          status = r.status;
          return r.json();
        }).
        then(r => {
          if (status === POSITIVE_ACTION_STATUSES.list) {
            this.setState({
              items: r.results || [],
              count: r.count || 0,
            });
          }
        });
  }

  handlePageChange(data) {
    let page = data.selected + 1;
    this.setState(
        {pageNumber: page},
        () => this.fetchItems(page),
    );
  }

  componentDidMount() {
    this.fetchData();
    this.fetchItems();
  }

  render() {
    const {items, count, data} = this.state;

    return (
        <div className="animated fadeIn">
          {
            typeof data === 'object' && data !== null && Object.keys(data).length ? (
                <Row>
                  <Col lg="12">
                    <h2>Cчет #{data.id}</h2>
                    <p>
                      Создан: {moment(data.created).format('DD.MM.YY HH:mm')}
                    </p>
                    <p>
                      Создатель:
                      {`${['first_name', 'last_name'].map(i => data.creator[i]).join(' ')} (${data.creator.username})`}
                    </p>
                    <p>
                      Обновлен: {moment(data.created).format('DD.MM.YY HH:mm')}
                    </p>
                    <p>
                      Статус: {data.status.name}
                    </p>
                    {!!data.link ? (
                        <p>
                          <a href={data.link} rel={`nofollow noopener noreferrer`}>Ссылка на оплату</a>
                        </p>
                    ) : null}
                  </Col>
                </Row>
            ) : null
          }
          <Row>
            <Col lg="12">
              <Card>
                <CardHeader>
                  <i className="fa fa-align-justify">{}</i> Всего оплат&nbsp;
                  <span className="badge badge-info">{count}</span>
                </CardHeader>
                <CardBlock>
                  <table className="table table-bordered table-striped table-sm">
                    <thead>
                    <tr>
                      <th>Id</th>
                      <th>Попала в CRM</th>
                      <th>Сумма</th>
                      <th>Валюта</th>
                    </tr>
                    </thead>
                    <tbody>
                    {items.map(item => (
                        <tr key={`payment-${item.id}`}>
                          <td>{item.id}</td>
                          <td>{moment(item.created).format('DD.MM.YY HH:mm')}</td>
                          <td>{item.value}</td>
                          <td>{item.currency.name}</td>
                        </tr>
                    ))}
                    </tbody>
                  </table>
                  <Paginator total={count} onPageChange={this.handlePageChange}/>
                </CardBlock>
              </Card>
            </Col>
          </Row>
          <BackButton {...this.props}/>
        </div>
    );
  }
}