import 'moment/locale/ru';
import moment from 'moment';
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import Select from 'react-select-plus';
import Swal from 'sweetalert';
import {Alert, Button, Card, CardBlock, CardHeader, Col, Row} from 'reactstrap';
import {Link} from 'react-router-dom';

import AgencyForm from './components/AgencyForm';
import FilterAgencies from './components/FilterAgencies';
import Paginator from '../../components/Paginator';
import {AgenciesAPI, POSITIVE_ACTION_STATUSES} from '../../api/Client';
import {getUserRole, SUPER_ADMIN} from '../../connect/auth';

class MergeSelect extends Component {

  constructor(props) {
    super(props);
    this.changeSource = this.changeSource.bind(this);
    this.changeTarget = this.changeTarget.bind(this);
    this.changeValues = this.changeValues.bind(this);
    this.state = {
      source: this.props.source,
      target: this.props.target,
      MERGE_OPTIONS: [
        {value: this.props.source.id, label: this.props.source.public_name},
        {value: this.props.target.id, label: this.props.target.public_name},
      ],
    }
  }

  changeSource(source) {
    if (source != this.state.source.id) {
      this.changeValues()
    }
  }

  changeTarget(target) {
    if (target != this.state.target.id) {
      this.changeValues()
    }
  }

  changeValues() {
    this.setState({
      source: this.state.target,
      target: this.state.source,
    })
    Swal.setActionValue(`${this.state.source.id}:${this.state.target.id}`)
  }

  render() {
    const {source, target, MERGE_OPTIONS} = this.state
    return (
      <div>
        <div>
          <Select
              id="mergeSource"
              name="source"
              placeholder="Источник"
              clearable={false}
              value={source.id}
              onChange={this.changeSource}
              options={MERGE_OPTIONS}
          />
        </div>
        <div>
          <label htmlFor="mergeTarget">В</label>
          <Select
              id="mergeTarget"
              name="target"
              placeholder="Цель"
              clearable={false}
              value={target.id}
              onChange={this.changeTarget}
              options={MERGE_OPTIONS}
          />
        </div>
      </div>
    )
  }
}

export default class Agencies extends Component {
  constructor(props) {
    super(props);

    this.createMail = this.createMail.bind(this);
    this.createMergers = this.createMergers.bind(this);
    this.fetchItems = this.fetchItems.bind(this);
    this.filterItems = this.filterItems.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlerSort = this.handlerSort.bind(this);
    this.handleToggleItem = this.handleToggleItem.bind(this);
    this.onCreateAgency = this.onCreateAgency.bind(this);

    this.state = {
      count: 0,
      errors: {},
      filters: null,
      filters: {},
      isSuccess: false,
      items: [],
      order: '',
      orderPrev: '',
      page: 1,
      selected: [],
      selectedItems: [],
      sortToggle: true,
    };

        localStorage.removeItem('abandonedcustomers_filter');
        localStorage.removeItem('franchise_filter');
        localStorage.removeItem('franchise_leads_filter');
        localStorage.removeItem('leads_filter');
        localStorage.removeItem('persons_filter');
        localStorage.removeItem('subscription_filter');
        localStorage.removeItem('tourist_filter');
        localStorage.removeItem('tourists_cabinet_filter');
        localStorage.removeItem('tourists_filter');
        localStorage.removeItem('tourists_report_filter');
        localStorage.removeItem('touristsfp_filter');

        localStorage.removeItem('leads_total');
        localStorage.removeItem('tourists_total');
        localStorage.removeItem('tourist_total');
        localStorage.removeItem('touristsfp_total');
        localStorage.removeItem('persons_total');
        localStorage.removeItem('tourists_cabinet_total');
        localStorage.removeItem('franchise_total');
        localStorage.removeItem('subscription_total');
        localStorage.removeItem('franchise_leads_total');
        localStorage.removeItem('tourists_report_type');
        localStorage.removeItem('tourists_report_address_books');
        localStorage.removeItem('abandonedcustomers_total');
        localStorage.removeItem('type');
  }

  componentDidMount() {
    this.fetchItems();
  }

  createMail() {
    const {count, filters, selected} = this.state;
    let _count = (selected.length) ? selected.length : count,
        _filter = (selected.length) ? {id__in: selected.join()} : filters || {};
    Swal(`Создаем рассылку для ${_count} франчей и субов`);
    localStorage.setItem('franchise_filter', JSON.stringify(_filter));
    localStorage.setItem('franchise_total', JSON.stringify(_count));
    localStorage.setItem('type', 'franchise');
    return false;
  }

  createMergers() {
    let wrapper = document.createElement('div'),
        source = this.state.selectedItems[0],
        target = this.state.selectedItems[1]
    ReactDOM.render(
        <MergeSelect
          source={source}
          target={target}
        />
      , wrapper
    );
    let el = wrapper.firstChild;

    Swal({
      text: "Слить",
      content: el,
      buttons: {
        cancel: "Отмена",
        confirm: {
          text: "Слить",
          value: `${source.id}:${target.id}`,
        },
      },
    })
    .then((value) => {
      if (value) {
        let ids = value.split(':'),
            source = ids[0],
            target = ids[1],
            status
        AgenciesAPI.
          merge({source: source, target: target}).
          then(r => {
            status = r.status;
            return r.json();
          }).
          then(r => {
            if (status === POSITIVE_ACTION_STATUSES.create) {
            this.setState({
              selected: [],
              selectedItems: [],
            }, () => this.fetchItems());
          } else {
            Swal({
              title: "Не получилось слить!",
              text: r,
              icon: "warning",
              dangerMode: true,
            })
          }
          });
      }
    });
  }

  fetchItems(page = 1) {
    let status;
    let {filters} = this.state;
    if (this.state.order) {
        filters = {
          ...filters,
          ordering: `${this.state.sortToggle?'-':''}${this.state.order}`
        };
      }
    AgenciesAPI.
        fetchList(page, filters).
        then(r => {
          status = r.status;
          return r.json();
        }).
        then(r => {
          if (status === POSITIVE_ACTION_STATUSES.list) {
            this.setState({
              items: r.results || [],
              count: r.count,
              errors: {},
            });
          }
        });
  }

  filterItems(st) {
    let result = {};
    [
      'address',
      'agency_type',
      'country',
      'id',
      'fp_city',
      'fp_index',
      'fp_pk',
      'fp_status',
      'offices',
      'opened_date__gte',
      'opened_date__lte',
      'public_name',
     ].forEach(i => {
      if (st[i]) {
        if (i == 'opened_date__gte' || i == 'opened_date__lte') {
          let date = st[i].format('DD.MM.YYYY').toString();
          result[i] = date;
        } else if (i == 'offices') {
          result['office'] = st.offices.map(office => office.value).join(',');
        } else {
          result[i] = st[i];
        }
      }
    });

    if (result) {
      this.setState(
          {filters: result},
          () => this.fetchItems(),
      );
    }
  }

  handlePageChange(data) {
    let page = data.selected + 1;
    this.setState(
        {page: page},
        () => this.fetchItems(page),
    );
  }

  handlerSort(e) {
    e.preventDefault();
    let param = e.target.title;
    this.setState({
      order: param,
      orderPrev: this.state.order,
      sortToggle: this.state.order != param ? true : !this.state.sortToggle,
    }, () => this.fetchItems(1, this.state.filters))
  }

  handleToggleItem(item) {
    let selected = [...this.state.selected],
        selectedItems = [...this.state.selectedItems];


    if (selected.includes(item.id)) {
      selected = selected.filter((pk) => pk !== item.id);
    } else {
      selected = selected.concat(item.id);
    }
    selected.sort((a, b) => {return b - a})

    if (selectedItems.includes(item)) {
      selectedItems = selectedItems.filter((i) => i !== item);
    } else {
      selectedItems = selectedItems.concat(item);
    }
    selectedItems.sort((a, b) => {return a.id - b.id})

    this.setState({selected: selected, selectedItems: selectedItems});
  }

  onCreateAgency(st) {
    let status;
    const {fp_pk, offices} = st;
    const officeIds = Array.isArray(offices) && offices.length ? offices.map(i => {
      return i.value;
    }) : [];

    AgenciesAPI.
        create({fp_pk: fp_pk, offices: officeIds}).
        then(r => {
          status = r.status;
          return r.json();
        }).
        then(r => {
          if (status === POSITIVE_ACTION_STATUSES.create) {
            this.setState({
              isSuccess: true,
            }, () => this.fetchItems());
          } else {
            this.setState({
              errors: r,
              isSuccess: false,
            });
          }
        });
  }



  render() {
    const userRole = getUserRole();
    const {items, count, errors, isSuccess, selected} = this.state;
    const fieldNames = {
      fp_pk: 'ФСУ Id',
      offices: 'Офисы',
    };

    const FP_STATUS_MAP = {
      accepted: 'регистрация подтверждена',
      rejected: 'в регистрации отказано',
      waiting: 'решение не принято'
    };
    const AGENCY_TYPE_LIST = [null,'Фирменный', 'Франчайзи', 'Субагент', 'Фрилансер'];

    return (
        <div className="animated fadeIn">
          {/*userRole === SUPER_ADMIN ? (
              <div className="filter">
                <AgencyForm onCreateAgency={this.onCreateAgency}/>
                {
                  isSuccess ? (
                      <Alert>
                        Агентство создано
                      </Alert>
                  ) : null
                }
                {typeof errors === 'object' && !!errors && Object.keys(errors).length ?
                    Object.keys(errors).map((e, index) => {
                      console.log(e);
                      let mess = errors[e];
                      const fieldName = Object.keys(fieldNames).find(i => i === e);
                      if (fieldName !== undefined) {
                        mess = `${fieldNames[fieldName]} / ${mess}`;
                      }
                      return <Alert className="bg-danger" key={`alert-danger-${index}`}>{mess}</Alert>;
                    }) : null}
              </div>
          ) : null*/}
          <div className="filter">
            <FilterAgencies onFilter={this.filterItems}/>
          </div>
          <Row>
            <Col lg="12">
              <Card>
                <CardHeader>
                  <i className="fa fa-align-justify">{''}</i> Всего&nbsp;
                  <span className="badge badge-info">{count}</span>
                  <Link className="btn btn-success ml-3 mt-0" onClick={this.createMail}
                  to="templates"
                  style={{display: 'inline-block'}}>
                    <span>
                      <i className="fa fa-send mr-2">{''}</i>
                      Создать рассылку
                    </span>
                  </Link>
                  <div className="float-right" hidden={!(selected.length == 2)}>
                    <Button className="btn btn-info" onClick={this.createMergers} type="button">
                      <span><i className="fa fa-tasks mr-2">{''}</i>Создать слияние</span>
                    </Button>
                  </div>
                </CardHeader>
                <CardBlock className="card-block_responsive">
                  <table className="agencies__table table table--pink table-bordered table-striped table-sm" style={{fontSize: '12px'}}>
                    <thead>
                    <tr>
                      <th className="text-center tableItem">*</th>
                      <th
                        onClick={this.handlerSort}
                        title="id"
                        style={{cursor: 'pointer'}}>номер в crm<i className="fa fa-sort ml-2" title="id"></i></th>
                      <th
                        onClick={this.handlerSort}
                        title="fp_index"
                        style={{cursor: 'pointer'}}>номер в фсу<i className="fa fa-sort ml-2" title="fp_index"></i></th>
                      <th
                        onClick={this.handlerSort}
                        title="agency_type"
                        style={{cursor: 'pointer'}}>статус<i className="fa fa-sort ml-2" title="agency_type"></i></th>
                      <th
                        onClick={this.handlerSort}
                        title="public_name"
                        style={{cursor: 'pointer'}}>название<i className="fa fa-sort ml-2" title="public_name"></i></th>
                      <th
                        onClick={this.handlerSort}
                        title="director"
                        style={{cursor: 'pointer'}}>директор<i className="fa fa-sort ml-2" title="director"></i></th>
                      <th
                        onClick={this.handlerSort}
                        title="country"
                        style={{cursor: 'pointer'}}>страна<i className="fa fa-sort ml-2" title="country"></i></th>
                      <th
                        onClick={this.handlerSort}
                        title="fp_city"
                        style={{cursor: 'pointer'}}>город<i className="fa fa-sort ml-2" title="fp_city"></i></th>
                      <th
                        onClick={this.handlerSort}
                        title="email"
                        style={{cursor: 'pointer'}}>email<i className="fa fa-sort ml-2" title="email"></i></th>
                      <th
                        onClick={this.handlerSort}
                        title="phones"
                        style={{cursor: 'pointer'}}>телефон<i className="fa fa-sort ml-2" title="phones"></i></th>
                      <th
                        onClick={this.handlerSort}
                        title="opened_date"
                        style={{cursor: 'pointer'}}>дата регистрации<i className="fa fa-sort ml-2" title="opened_date"></i></th>
                      <th
                        onClick={this.handlerSort}
                        title="orders_total"
                        style={{cursor: 'pointer'}}>продаж всего<i className="fa fa-sort ml-2" title="orders_total"></i></th>
                      <th
                        onClick={this.handlerSort}
                        title="sales_per_year"
                        style={{cursor: 'pointer'}}>продаж за текущий год<i className="fa fa-sort ml-2" title="sales_per_year"></i></th>
                      <th
                        onClick={this.handlerSort}
                        title="sales_per_month"
                        style={{cursor: 'pointer'}}>продаж за текущий месяц<i className="fa fa-sort ml-2" title="sales_per_month"></i></th>
                      <th
                        onClick={this.handlerSort}
                        title="fp_status"
                        style={{cursor: 'pointer'}}>решение по регистрации<i className="fa fa-sort ml-2" title="fp_status"></i></th>
                      <th title="address">Комментарий</th>
                    </tr>
                    </thead>

                    <tbody>
                    {
                      items.map(item => (
                          <tr key={item.id}>
                            <td className="text-center tableItem hidden-mobile">
                              <input type="checkbox" onChange={() => this.handleToggleItem(item)} checked={selected.includes(item.id)}/>
                            </td>
                            <td>
                              <Link to={`/agencies/${item.id}`}>
                                {item.id}
                              </Link>
                            </td>
                            <td>{item.fp_index}</td>
                            <td>{AGENCY_TYPE_LIST[item.agency_type]}</td>
                            <td>{item.public_name}</td>
                            <td>{item.director}</td>
                            <td>{item.country}</td>
                            <td>{item.fp_city}</td>
                            <td>{item.email}</td>
                            <td>{item.phones}</td>
                            <td>
                              {item.opened_date?moment(item.opened_date).format('DD.MM.YY'):''}
                            </td>
                            <td>{item.sales_total}</td>
                            <td>{item.sales_per_year}</td>
                            <td>{item.sales_per_month}</td>
                            <td>{FP_STATUS_MAP[item.fp_status]}</td>
                            <td>{item.last_comment}</td>
                          </tr>
                      ))
                    }
                    </tbody>
                  </table>
                  <Paginator total={count} onPageChange={this.handlePageChange}/>
                </CardBlock>
              </Card>
            </Col>
          </Row>
        </div>
    );
  }
}
