import React, { Component } from 'react'

export default class IssueFinally extends Component {
  render() {
    return (
      <div>
        <h2>[ИМЯ КЛИЕНТА], добрый день!</h2>
        <ul className="list-group">
          <li className="list-group-item">
            Это [ИМЯ МЕНЕДЖЕРА], туристическое агентство (название компании).
          </li>
          <li className="list-group-item">
            Наверное, ни один из предложенных вариантов не пришелся вам по душе. Может быть, я не учла какие-то ваши пожелания относительно отдыха.
          </li>
          <li className="list-group-item">
            Мне искренне жаль, что так получилось, но есть решение.
          </li>
          <li className="list-group-item">
            Если Вы сможете подъехать к нам в офис, то мы вместе сможем подобрать тур из числа акционных.
          </li>
          <li className="list-group-item">
            Бывают на редкость удачные туры, но в очень малом количестве, т.к. их быстро выкупают. И их важно уметь найти и успеть зафиксировать за собой. Вместе мы это сможем сделать.
          </li>
          <li className="list-group-item">
            В любом случае, если отдых еще актуален или если приняли решение не ехать – сообщите мне! Жду ваш ответ
          </li>
        </ul>
      </div>
    )
  }
}
