import React from 'react'
import { Route } from 'react-router-dom'
import routes from './routes.json'

const style = {
  fontWeight:'bold',
  color:'rgb(32, 168, 216)',
  marginLeft:20,
  fontSize:'1rem',
}

// ..
const getPaths = (pathname) => {
  // ..
  const paths = ['/'];
  // ...
  if (pathname === '/') return paths;
  // ..
  pathname.split('/').reduce((prev, curr, index) => {
    const currPath = `${prev}/${curr}`;
    paths.push(currPath);
    return currPath;
  })
  // ..
  return paths
}


const Title = ({ location : { pathname }}) => {
  // ..
  const paths = getPaths(pathname)
  const url = paths[1]
  const routeName = routes[url]
  // ..
  return (
    <div style={style}>
      {routeName}
    </div>
  )
}

// ..HeaderTitle
export default (props) => (
  <div className="header-title">
    <Route path="/:path" component={Title} {...props} />
  </div>
)
