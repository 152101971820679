import React from 'react'
import { Switch, Route } from 'react-router-dom'

import App from './sections/app'
import Login from './sections/login'
import Officein from './sections/login/officein'
import Register from './sections/register'
import Page404 from './sections/page404'
import Page500 from './sections/page500'

export default (props) => {
  return (
    <div>
      <Switch>
        <Route exact path="/login" component={Login}/>
        <Route exact path="/officein" component={Officein}/>
        <Route exact path="/register" component={Register}/>
        <Route exact path="/404" component={Page404}/>
        <Route exact path="/500" component={Page500}/>
        <Route path="/" component={App}/>
      </Switch>    
    </div>
  )
}
