import React, { Component } from 'react';
import ProgressBar from '../../../components/ProgressBar';

export default class SearchBlock extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      labelText,
      title,
      name,
      value,
      onChange,
      handleSearch,
      handleUpdate,
      isLoading,
    } = this.props;

    return (
      <div className="geopick__select-container">
        <label htmlFor="personData">{labelText}</label>

        <input
          type="text"
          className="form-control"
          id="personData"
          name={name}
          value={value}
          title={title}
          placeholder="ФИО, email, телефон"
          onChange={onChange}
          autoFocus
        />
        {isLoading ? (
          <ProgressBar />
        ) : (
          <button className="btn btn-success" onClick={handleSearch}>
            Искать
          </button>
        )}
        <button className="btn btn-danger" onClick={handleUpdate}>
          Отмена
        </button>
      </div>
    );
  }
}
