import React from 'react';
import {NavLink} from 'react-router-dom';

import IssuesCount from '../../views/Issues/components/IssuesCount';
import LeadsCount from '../../views/Leads/components/LeadsCount';
import {getUserRole, SUPER_ADMIN, ADMIN, SUBAGENT} from '../../connect/auth';

const screenWidth = window.screen.width;
const mobileSidebarHideOnClick = (e) => {
  // e.preventDefault();
  if (screenWidth < 680) {
    document.body.classList.remove('sidebar-mobile-show');
  }
}

export default (props) => {
  const userRole = getUserRole();
  return (
      <div className="sidebar">
        <nav className="sidebar-nav">
          <ul className="nav mb-5 pb-5">

            <li className="nav-title">Меню</li>

            {userRole !== SUBAGENT &&
            <li className="nav-item">
              <NavLink to={'/dashboard'} className="nav-link  nav-link--green" activeClassName="active" onClick={mobileSidebarHideOnClick}>
                <i className="fa fa-desktop">{''}</i> Рабочий стол менеджера
              </NavLink>
            </li>}

            <li className="nav-item">
              <NavLink to={'/calendar'} className="nav-link nav-link--green" activeClassName="active"  onClick={mobileSidebarHideOnClick}>
                <i className="fa fa-calendar">{''}</i> <span>Мой календарь</span>
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink to={'/instructions'} className="nav-link nav-link--green" activeClassName="active"  onClick={mobileSidebarHideOnClick}>
                <i className="fa fa-star">{''}</i> <span>Инструкция по CRM</span>
              </NavLink>
            </li>

            {userRole !== SUBAGENT &&
            <li className="nav-item">
              <NavLink to={'/issues'} className="nav-link nav-link--green" activeClassName="active"  onClick={mobileSidebarHideOnClick}>
                <i className="fa fa-star">{''}</i> <span>Запросы на туры</span> <IssuesCount/>
              </NavLink>
            </li>}

            {(userRole === SUPER_ADMIN || userRole === ADMIN) &&
            <li className="nav-item">
              <NavLink to={'/issues-report'} className="nav-link nav-link--green" activeClassName="active"  onClick={mobileSidebarHideOnClick}>
                <i className="fa fa-bar-chart">{''}</i> <span>Отчет по запросам</span>
              </NavLink>
            </li>}

            {userRole !== SUBAGENT &&
            <li className="nav-item">
              <NavLink to={'/leads'} className="nav-link nav-link--green" activeClassName="active"  onClick={mobileSidebarHideOnClick}>
                <i className="icon-paper-plane">{''}</i> Заявки с сайта <LeadsCount/>
              </NavLink>
            </li>}

            <li className="nav-item">
              <NavLink to={'/quotes'} className="nav-link nav-link--pink" activeClassName="active"  onClick={mobileSidebarHideOnClick}>
                <i className="fa fa-check-square-o">{''}</i> Геоподборка
              </NavLink>
            </li>


            {userRole !== SUBAGENT &&
            <li className="nav-item">
              <NavLink to={'/sellbook'} className="nav-link nav-link--yellow" activeClassName="active"  onClick={mobileSidebarHideOnClick}>
                <i className="icon-book-open">{''}</i> Книга продаж
              </NavLink>
            </li>}

            {userRole === SUPER_ADMIN &&
            <li className="nav-item">
              <NavLink to={'/touristcabinets'} className="nav-link nav-link--blue" activeClassName="active"  onClick={mobileSidebarHideOnClick}>
                <i className="icon-user-following">{''}</i> Кабинет туриста
              </NavLink>
            </li>}

            {userRole !== SUBAGENT &&
            <li className="nav-item">
              <NavLink to={'/tourists'} className="nav-link nav-link--blue" activeClassName="active"  onClick={mobileSidebarHideOnClick}>
                <i className="icon-people">{''}</i>Клиентская база
              </NavLink>
            </li>}
            {userRole !== SUBAGENT &&
            <li className="nav-item">
              <NavLink to={'/persons'} className="nav-link nav-link--blue" activeClassName="active"  onClick={mobileSidebarHideOnClick}>
                <i className="fa fa-user">{''}</i>Потенциальные клиенты
              </NavLink>
            </li>}
            {userRole !== SUBAGENT &&
            <li className="nav-item">
              <NavLink to={'/ordersfp'} className="nav-link nav-link--blue" activeClassName="active"  onClick={mobileSidebarHideOnClick}>
                <i className="fa fa-archive">{''}</i>Забронированные заявки
              </NavLink>
            </li>}
            {userRole !== SUBAGENT &&
            <li className="nav-item">
              <NavLink to={'/tags'} className="nav-link  nav-link--blue" activeClassName="active"  onClick={mobileSidebarHideOnClick}>
                <i className="fa fa-tags">{''}</i> Создать метку
              </NavLink>
            </li>}
            {userRole !== SUBAGENT &&
            <li className="nav-item">
              <NavLink to={'/templates'} className="nav-link nav-link--yellow" activeClassName="active"  onClick={mobileSidebarHideOnClick}>
                <i className="fa fa-envelope-o">{''}</i> СМС, Email рассылка
              </NavLink>
            </li>}
            {userRole !== SUBAGENT &&
            <li className="nav-item">
              <NavLink to={'/mailbox'} className="nav-link nav-link--yellow" activeClassName="active"  onClick={mobileSidebarHideOnClick}>
                <i className="icon-docs">{''}</i> Отправленные рассылки
              </NavLink>
            </li>}
            {userRole !== SUBAGENT &&
            <li className="nav-item">
              <NavLink to={'/notification'} className="nav-link nav-link--yellow" activeClassName="active"  onClick={mobileSidebarHideOnClick}>
                <i className="icon-docs">{''}</i> Отправленные уведомления
              </NavLink>
            </li>}
            {userRole !== SUBAGENT &&
            <li className="nav-item">
              <NavLink to={'/notify'} className="nav-link nav-link--yellow" activeClassName="active"  onClick={mobileSidebarHideOnClick}>
                <i className="icon-bell">{''}</i> Уведомления клиентам
              </NavLink>
            </li>}

            {(userRole === SUPER_ADMIN || userRole === ADMIN) &&
            <li className="nav-item">
              <NavLink to={'/cashaccounts'} className="nav-link nav-link--yellow" activeClassName="active"  onClick={mobileSidebarHideOnClick}>
                <i className="fa fa-credit-card">{''}</i> Оплата рассылок
              </NavLink>
            </li>}
            {(userRole === SUPER_ADMIN || userRole === ADMIN) &&
            <li className="nav-item">
              <NavLink to={'/offices'} className="nav-link nav-link--pink" activeClassName="active"  onClick={mobileSidebarHideOnClick}>
                <i className="fa fa-institution">{''}</i> Мои офисы
              </NavLink>
            </li>}
            {(userRole === SUPER_ADMIN || userRole === ADMIN) &&
            <li className="nav-item">
              <NavLink to={'/employees'} className="nav-link nav-link--pink" activeClassName="active"  onClick={mobileSidebarHideOnClick}>
                <i className="fa fa-street-view">{''}</i> Мои сотрудники
              </NavLink>
            </li>}

            {userRole === SUPER_ADMIN &&
            <li className="nav-item">
              <NavLink to={'/agencies'} className="nav-link nav-link--pink" activeClassName="active"  onClick={mobileSidebarHideOnClick}>
                <i className="fa fa-globe">{''}</i> Франчи и субы, контроль за продажами
              </NavLink>
            </li>}
            {userRole === SUPER_ADMIN &&
            <li className="nav-item">
              <NavLink to={'/summaries'} className="nav-link nav-link--pink" activeClassName="active"  onClick={mobileSidebarHideOnClick}>
                <i className="fa fa-bar-chart">{''}</i> Франчи, контроль за работой в CRM
              </NavLink>
            </li>}
            {userRole === SUPER_ADMIN &&
            <li className="nav-item">
              <NavLink to={'/franchising'} className="nav-link nav-link--blue" activeClassName="active"  onClick={mobileSidebarHideOnClick}>
                <i className="icon-people">{''}</i>Заявки на франчайзинг
              </NavLink>
            </li>}
            {userRole === SUPER_ADMIN &&
            <li className="nav-item">
              <NavLink to={'/subscription'} className="nav-link nav-link--blue" activeClassName="active"  onClick={mobileSidebarHideOnClick}>
                <i className="icon-people">{''}</i>Агенты и клиенты подписка
              </NavLink>
            </li>}
            { userRole === SUPER_ADMIN &&
              <li className="nav-item">
                <NavLink to={'/abandonedcustomers'} className="nav-link nav-link--blue" activeClassName="active"  onClick={mobileSidebarHideOnClick}>
                  <i className="icon-people">{''}</i>Клиенты закрывшихся агентов
                </NavLink>
              </li>}

            <li className="divider">
            </li>

          </ul>
        </nav>
      </div>
  );
}
