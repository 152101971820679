import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { Card, CardHeader, CardBlock } from 'reactstrap';
import {
  TouristFpAPI,
  DocumentsAPI,
  POSITIVE_ACTION_STATUSES,
} from '../../api/Client';
import OrdersFpTable from '../../views/OrdersFpTable';
// import SendMessage from "../../views/SendMessage";
import moment from 'moment';
import 'moment/locale/ru';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-select-plus/dist/react-select-plus.css';
import Issues from '../Issues/Issues';
import Mergers from '../Mergers/Mergers';
import Passports from '../Passports';
import TouristFiles from './components/TouristFiles';

export default class TouristFp extends Component {
  constructor(props) {
    super(props);

    this.getInfo = this.getInfo.bind(this);
    this.getIssues = this.getIssues.bind(this);
    this.getOrders = this.getOrders.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.fetchTags = this.fetchTags.bind(this);
    this.checkPassport = this.checkPassport.bind(this);
    this.updateIssues = this.updateIssues.bind(this);
    this.onChangeActivity = this.onChangeActivity.bind(this);
    this.toggleTab = this.toggleTab.bind(this);

    this.state = {
      born: '',
      fullName: '',
      fullNameEng: '',
      emails: [],
      phones: [],
      phone: [],
      email: [],
      registration: '',
      address: '',
      fpPk: '',
      itemId: this.props.match.params.number,
      documents: [],
      issues: {
        results: [],
        count: 0,
      },
      orders: {
        results: [],
        count: 0,
      },
      tags: [],
      activeTab: '1',
      isNeedNewPassport: false,
      comment: '',
    };
  }

  toggleTab(tab) {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  fetchTags() {
    const { itemId } = this.state;
    let status;
    let id = parseInt(itemId, 10);
    if (id) {
      TouristFpAPI.fetchList(1, {}, `${itemId}/tourist_tags/`)
        .then((r) => {
          status = r.status;
          return r.json();
        })
        .then((r) => {
          if (status === POSITIVE_ACTION_STATUSES.list) {
            this.setState({
              tags: r || [],
            });
          }
        });
    }
  }

  checkPassport(items) {
    if (Array.isArray(items) && items.length) {
      this.setState({
        isNeedNewPassport: items.some((item) => item.is_need_new),
      });
    }
  }

  handlePageChange(data) {
    let page = data.selected + 1;
    this.setState({ pageNumber: page }, () => this.getIssues(page));
  }

  downloadFile(e) {
    e.preventDefault();
    DocumentsAPI.get(e.target.title).then((res) => console.log(res));
  }

  getInfo() {
    const self = this;
    const { itemId } = self.state;
    let id = parseInt(itemId, 10);
    if (id) {
      TouristFpAPI.fetchJSON(`${id}/`)
        .then((r) => {
          return r.json();
        })
        .then((r) => {
          if (POSITIVE_ACTION_STATUSES.retrieve) {
            self.setState(
              {
                born: moment(r.born).format('DD.MM.YYYY') || '',
                fullName: r.full_name || '',
                fullNameEng: r.full_name_eng || '',
                emails: r.email || [],
                phones: r.phone || [],
                email: r.email || [],
                phone: r.phone || [],
                registration: r.registration || '',
                address: r.address || '',
                fpPk: r.fp_pk,
                comment: r.comment || '',

                documents: r.documents,
              },
              () => self.fetchTags()
            );
          }
        });
    }
  }

  getIssues(page = 1) {
    const { itemId } = this.state;
    let id = parseInt(itemId, 10);
    if (id) {
      TouristFpAPI.fetchList(page, {}, `${id}/issues/`)
        .then((r) => {
          return r.json();
        })
        .then((r) => {
          if (POSITIVE_ACTION_STATUSES.list) {
            this.setState({
              issues: r || {
                results: [],
                count: 0,
              },
            });
          }
        });
    }
  }

  getOrders(page = 1) {
    const { itemId } = this.state;
    let id = parseInt(itemId, 10);
    if (id) {
      TouristFpAPI.fetchList(page, {}, `${id}/orders/`)
        .then((r) => {
          return r.json();
        })
        .then((result) => {
          if (POSITIVE_ACTION_STATUSES.list) {
            this.setState({
              orders: result || {
                results: [],
                count: 0,
              },
            });
          }
        });
    }
  }

  updateIssues(page = 1) {
    this.getIssues();
  }

  onChangeActivity() {
    this.props.history.push('/issues/');
  }

  componentDidMount() {
    this.getInfo();
    this.getIssues();
    this.getOrders();
  }

  render() {
    let issues = this.state.issues.results || [];
    let orders = this.state.orders.results || [];
    const {
      activeTab,
      fpPk,
      fullName,
      itemId,
      born,
      tags,
      phones,
      emails,
      documents,
      isNeedNewPassport,
      comment,
    } = this.state;

    return (
      <div className="filter filter--orange">
        <Card>
          <div>
            <CardHeader>
              <h4>{fullName}</h4>
            </CardHeader>
            <CardBlock>
              <div className="row">
                <div className="col-md-6 col-lg-6">
                  {isNeedNewPassport ? (
                    <p>
                      <span className="badge badge-danger">
                        ЗАПРОСИТЬ НОВЫЙ ЗАГРАНПАСПОРТ
                      </span>
                    </p>
                  ) : null}
                  <p>
                    Идентификатор из ФСУ: <b>{fpPk}</b>
                  </p>
                  <p>
                    Телефоны:{' '}
                    <b>
                      {Array.isArray(phones) && phones.length
                        ? phones
                            .filter((x) => x.value)
                            .map((x) => x.value)
                            .join(', ')
                        : null}
                    </b>
                  </p>
                  <p>
                    С ошибками:{' '}
                    <b>
                      {Array.isArray(phones) && phones.length
                        ? phones
                            .filter((x) => !x.value)
                            .map((x) => x.origin)
                            .join(', ')
                        : null}
                    </b>
                  </p>
                  <p>
                    Электронная почта:{' '}
                    <b>
                      {Array.isArray(emails) && emails.length
                        ? emails
                            .filter((x) => x.value)
                            .map((x) => x.value)
                            .join(', ')
                        : null}
                    </b>
                  </p>
                  <p>
                    С ошибками:{' '}
                    <b>
                      {Array.isArray(emails) && emails.length
                        ? emails
                            .filter((x) => !x.value)
                            .map((x) => x.origin)
                            .join(', ')
                        : null}
                    </b>
                  </p>
                  <p>
                    Дата рождения: <b>{born}</b>
                  </p>
                  <p>
                    Метки:{' '}
                    {tags.length
                      ? tags.map((t) => (
                          <span
                            className="badge badge-pill badge-success ml-2"
                            key={`tag-${t.id}`}
                          >
                            {t.name}
                          </span>
                        ))
                      : null}
                  </p>
                  <p>
                    Комментарий: <b className="comment__client">{comment}</b>
                  </p>
                </div>
                <Link
                  to={`/tourists/${itemId}/edit/`}
                  style={{ height: 'min-content' }}
                >
                  <button className="btn btn-primary">
                    Редактировать данные, назначить метку
                  </button>
                </Link>
              </div>
              <Nav tabs className={'mt-3'}>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => this.toggleTab('1')}
                  >
                    <i className="fa fa-plane">{''}</i> Куда летал
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => this.toggleTab('2')}
                  >
                    <i className="fa fa-list">{''}</i> Запросы
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '3' })}
                    onClick={() => this.toggleTab('3')}
                  >
                    <i className="fa fa-list-alt">{''}</i> Паспорта
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '4' })}
                    onClick={() => this.toggleTab('4')}
                  >
                    <i className="fa fa-file-text-o">{''}</i> Документы
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === "5" })}
                    onClick={() => this.toggleTab("5")}
                  >
                    <i className="fa fa-file-text-o">{""}</i> Сообщения
                  </NavLink>
                </NavItem> */}
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '6' })}
                    onClick={() => this.toggleTab('6')}
                  >
                    <i className="fa fa-tasks">{''}</i> Слияния
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink>
                    <Link to={`/ordersfp/${fpPk}`}>
                      <i className="fa fa-users">{''}</i> Сменить менеджера
                    </Link>
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <div>
                    <OrdersFpTable
                      count={this.state.orders.count}
                      items={orders}
                      handlePageChange={this.handlePageChange}
                    />
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <Issues
                    tourist={itemId}
                    type="tourist"
                    issues={issues || []}
                    info={this.state}
                    onCreate={this.updateIssues}
                    onChangeActivity={this.onChangeActivity}
                  />
                </TabPane>
                <TabPane tabId="3">
                  <Passports
                    tourist={itemId}
                    checkPassport={this.checkPassport}
                  />
                </TabPane>
                <TabPane tabId="4">
                  <div className="doc">
                    <TouristFiles tourist={itemId} />
                  </div>
                </TabPane>
                {/* <TabPane tabId="5">
                  <div className="">
                    <SendMessage />
                  </div>
                </TabPane> */}
                <TabPane tabId="6">
                  <div>
                    <Mergers client_id={itemId} client={'person_tourist'} />
                  </div>
                  <div>
                    <Mergers client_id={itemId} client={'tourist'} />
                  </div>
                </TabPane>
              </TabContent>
            </CardBlock>
          </div>
        </Card>
      </div>
    );
  }
}
