import React, { Component } from "react";
import { connect } from "react-redux";
import { Alert } from "reactstrap";

import OfficeSelectContainer from "../../../components/OfficeSelectContainer";
import UserTelegramBotSettings from "../../../views/Options/components/UserTelegramBotSettings";
import { getUserRole, SUPER_ADMIN, ADMIN } from "../../../connect/auth";
import { POSITIVE_ACTION_STATUSES, UsersAPI } from "../../../api/Client";

import UserRoles from "./UserRoles";
import ManagerManagerFp from "./ManagerManagerFp";

const userImage = "/img/add-user2.jpg";

class FormSubagent extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.formTextChange = this.formTextChange.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.changeOffice = this.changeOffice.bind(this);
    this.changeOfficeCity = this.changeOfficeCity.bind(this);
    this.successMessage = this.successMessage.bind(this);
    this.handleUserRole = this.handleUserRole.bind(this);
    this.fetchUser = this.fetchUser.bind(this);
    this.fetchRoles = this.fetchRoles.bind(this);
    this.handleChangePassword = this.handleChangePassword.bind(this);

    this.state = {
      username: "",
      email: "",
      first_name: "",
      last_name: "",
      offices: null,
      offices_city: null,
      userRole: null,
      userRoles: [],
      errors: {},
      showSuccessMessage: false,
      telegrambotsettings: null,
      password: '',
    };
  }

  handleUserRole(val) {
    this.setState({
      userRole: val,
    });
  }

  handleSubmit() {
    let status;
    let { isFormCreate } = this.props;
    const {
      username,
      email,
      first_name,
      last_name,
      offices,
      userRole,
      manager_fp,
    } = this.state;
    const { onCreate } = this.props;

    const data = {
      username: username,
      email: email,
      first_name: first_name,
      last_name: last_name,
      offices:
        Array.isArray(offices) && offices.length
          ? offices.map((i) => i.value)
          : [],
      profile: {
        role: userRole,
      },
    };

    if (!isFormCreate) {
      UsersAPI.update(
        `${UsersAPI.resource_url}${this.props.match.params.number}`,
        data
      )
        .then((r) => {
          status = r.status;
          return r.json();
        })
        .then((result) => {
          if (status === POSITIVE_ACTION_STATUSES.partial_update) {
            this.successMessage();
          } else {
            this.setState({
              errors: result,
            });
          }
        });
    } else {
      UsersAPI.create(data)
        .then((r) => {
          status = r.status;
          return r.json();
        })
        .then((result) => {
          this.setState({password: ''});
          if (status === POSITIVE_ACTION_STATUSES.create) {
            let password = (result.hasOwnProperty("password")) ? result.password : '';
            this.successMessage(this.handleReset(onCreate, password));
          } else {
            this.setState({
              errors: result,
            });
          }
        });
    }
  }

  handleReset(callback, password='') {
    this.setState(
      {
        username: "",
        email: "",
        first_name: "",
        last_name: "",
        offices: null,
        offices_city: null,
        userRole: null,
        errors: {},
        password: password,
      },
      callback
    );
  }

  changeOffice(offices) {
    this.setState({
      offices: offices,
      errors: {},
      showSuccessMessage: false,
    });
  }
  changeOfficeCity(offices_city) {
    this.setState({ offices_city });
  }

  successMessage(callback = () => null) {
    this.setState(
      {
        showSuccessMessage: true,
        errors: {},
      },
      callback
    );
  }

  formTextChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
      showSuccessMessage: false,
    });
  }

  fetchUser(id) {
    let status;
    UsersAPI.fetchJSON(`${id}`)
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.retrieve) {
          const offices =
            Array.isArray(r.offices) && r.offices
              ? r.offices.map((i) => ({ value: i.id, label: i.name }))
              : [];
          const profile =
            typeof r.profile === "object" && r.profile !== null
              ? r.profile
              : {};
          const userRole =
            typeof profile.role === "object" &&
            profile.role !== null &&
            profile.role.id
              ? profile.role.id.toString()
              : null;
          this.setState({
            id: r.id,
            is_active: r.is_active,
            username: r.username,
            email: r.email,
            first_name: r.first_name,
            last_name: r.last_name,
            offices: offices,
            profile: profile,
            userRole: userRole,
            managerManagerFp: r.manager_manager_fp,
            telegrambotsettings: r.telegrambotsettings,
          });
        }
      });
  }

  fetchRoles() {
    let status;
    UsersAPI.fetchJSON(`roles/`)
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.list) {
          this.setState({
            userRoles: r || {},
          });
        }
      });
  }

  handleChangePassword() {
    let status;
    const id = this.props.match.params.number;
    UsersAPI.update(
      `${UsersAPI.resource_url}${this.props.match.params.number}/passwordchanger`,
      {}
    )
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        this.setState({password: ''});
        if (status === POSITIVE_ACTION_STATUSES.partial_update) {
          if (r.hasOwnProperty("password")) {this.setState({password: r.password})};
          this.successMessage();
        } else {
          this.setState({
            errors: r,
            showSuccessMessage: false,
          });
        }
      });
  }

  componentDidMount() {
    const { isFormCreate } = this.props;
    if (!isFormCreate) {
      this.fetchUser(this.props.match.params.number);
      this.fetchRoles();
    }
  }

  render() {
    const {
      username,
      email,
      first_name,
      last_name,
      offices,
      errors,
      showSuccessMessage,
      userRole,
      managerManagerFp,
    } = this.state;
    let { isFormCreate, userRoles } = this.props;
    if (!isFormCreate) {
      userRoles = this.state.userRoles;
    }
    const currentUserRole = getUserRole();
    return (
      <div>
        <div className="card">
          <div className="card-header">
            <strong>Сотрудник</strong>
          </div>
          <div className="card-block">
            <form
              action=""
              method="post"
              className="form-horizontal"
              onSubmit={this.handleSubmit}
            >
              <div className="row">
                <div className="col-md-8 col-sm-12">
                  {showSuccessMessage ? (
                    <Alert color="success">
                      <strong>Успешно!</strong>
                    </Alert>
                  ) : null}

                  {Array.isArray(errors.non_field_errors) &&
                  errors.non_field_errors.length ? (
                    <Alert color="danger">
                      <strong>{errors.non_field_errors}</strong>
                    </Alert>
                  ) : null}

                  <div className="form-group row">
                    <label className="text-right col-md-2 col-form-label">
                      Логин
                    </label>
                    <div className="col-md-8">
                      <input
                        type="text"
                        className="form-control"
                        id="employeeUsername"
                        placeholder="Логин"
                        name="username"
                        value={username}
                        onChange={this.formTextChange}
                      />
                      <p className="bg-danger">{errors.username}</p>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="text-right col-md-2 col-form-label">
                      Фамилия
                    </label>
                    <div className="col-md-8">
                      <input
                        type="text"
                        className="form-control"
                        id="EmployeeLastname"
                        placeholder="Введите фамилию"
                        name="last_name"
                        value={last_name}
                        onChange={this.formTextChange}
                      />
                      <p className="bg-danger">{errors.last_name}</p>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="text-right col-md-2 col-form-label">
                      Имя
                    </label>
                    <div className="col-md-8">
                      <input
                        type="text"
                        className="form-control"
                        id="personName"
                        placeholder="Введите имя"
                        name="first_name"
                        value={first_name}
                        onChange={this.formTextChange}
                      />
                      <p className="bg-danger">{errors.first_name}</p>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="text-right col-md-2 col-form-label">
                      Почта
                    </label>
                    <div className="col-md-8">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Электронная почта"
                        name="email"
                        value={email}
                        onChange={this.formTextChange}
                      />
                      <p className="bg-danger">{errors.email}</p>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="text-right col-md-2 col-form-label">
                      Выберите офис
                    </label>
                    <div className="col-md-8">
                      <OfficeSelectContainer
                        multi_office={true}
                        selected_city={this.state.offices_city}
                        selected_office={offices}
                        changeCity={this.changeOfficeCity}
                        changeOffice={this.changeOffice}
                      />
                      <p className="bg-danger">{errors.offices}</p>
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="text-right col-md-2 col-form-label">
                      Тип сотрудника
                    </label>
                    <div className="col-md-8">
                      <UserRoles
                        handleUserRole={this.handleUserRole}
                        userRole={userRole}
                        userRoles={userRoles}
                      />
                      <p className="bg-danger">
                        {errors.profile && errors.profile.role}
                      </p>
                    </div>
                  </div>

                  {currentUserRole === SUPER_ADMIN ? (
                    <div className="form-group row">
                      <div className="col-md-6">
                        <button
                          type="button"
                          className="btn btn-sm btn-info"
                          onClick={this.handleChangePassword}
                        >
                          <i className="fa fa-dot-circle-o">{""}</i>
                          Изменить пароль и выслать на email
                        </button>
                      </div>
                      {this.state.password ? (
                        <div className="col-md-6">
                          <Alert color="success">
                            Новый пароль: <strong>{this.state.password}</strong>
                          </Alert>
                        </div>
                      ) : null}
                    </div>
                  ) : null}

                  {!isFormCreate ? null : (
                    <div className="col-lg-4 col-md-6 col-sm-6">
                      <img src={userImage} width="100%" />
                    </div>
                  )}
                </div>
              </div>
            </form>
            <div className="card-footer">
              <button
                type="submit"
                className="btn btn-sm btn-primary"
                onClick={this.handleSubmit}
              >
                <i className="fa fa-dot-circle-o"></i>
                {!isFormCreate ? `Обновить` : `Создать`}
              </button>
              <button
                type="reset"
                className="btn btn-sm btn-danger"
                onClick={() => this.handleReset(() => null)}
              >
                <i className="fa fa-ban">{""}</i> Очистить
              </button>
            </div>
          </div>
        </div>

        {!isFormCreate && (
          <ManagerManagerFp userId={this.props.match.params.number} />
        )}
        {!isFormCreate &&
          (currentUserRole === SUPER_ADMIN || currentUserRole === ADMIN) &&
          this.state.telegrambotsettings && (
            <UserTelegramBotSettings
              user_id={this.props.match.params.number}
              data={this.state.telegrambotsettings}
            />
          )}
      </div>
    );
  }
}

export default FormSubagent;
