import React, { Component } from "react";
import { Col, Row, Card, CardBlock, CardHeader, CardFooter } from "reactstrap";
import moment from "moment";
import "moment/locale/ru";

export default class FilterOffices extends Component {
  constructor(props) {
    super(props);

    // form button actions
    this.handleReset = this.handleReset.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.formTextChange = this.formTextChange.bind(this);

    moment.locale("ru");

    this.state = {
      email: "",
      address: "",
    };
  }

  handleReset() {
    this.setState(
      {
        email: "",
        address: "",
      },
      this.handleSubmit
    );
  }

  handleSubmit() {
    this.props.onFilter(this.state);
  }

  formTextChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return (
      <Card>
        <CardHeader>
          <strong>Поиск</strong> офисов
        </CardHeader>
        <CardBlock>
          <form action="" method="post" className="filter-row">
            <div className="filter-field">
              <label htmlFor="email">Email</label>
              <input
                type="text"
                className="form-control"
                id="email"
                placeholder="E-mail"
                name="email"
                value={this.state.email}
                onChange={this.formTextChange}
              />
            </div>
            <div className="filter-field">
              <label htmlFor="address" className="col-2 col-form-label">
                Адрес
              </label>
              <input
                type="text"
                className="form-control"
                name="address"
                id="address"
                placeholder="Адрес"
                value={this.state.address}
                onChange={this.formTextChange}
              />
            </div>
          </form>
          <CardFooter>
            <button
              type="submit"
              className="btn btn-sm btn-primary"
              onClick={this.handleSubmit}
            >
              <i className="fa fa-dot-circle-o"></i> Поиск
            </button>
            <button
              type="reset"
              className="btn btn-sm btn-danger"
              onClick={this.handleReset}
            >
              <i className="fa fa-ban"></i> Очистить
            </button>
          </CardFooter>
        </CardBlock>
      </Card>
    );
  }
}
