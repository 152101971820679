import React, {Component} from 'react';
import {Alert} from 'reactstrap';
import {Action} from './Action';
import {APPLICANT_ACTION_STRUCTURE} from './Applicant';
import {CITY_ACTION_STRUCTURE} from './City';
import {OFFICE_ACTION_STRUCTURE} from './Office';
import {COMMENT_ACTION_STRUCTURE} from './Comment';
import {
  AgenciesAPI,
  FranchiseApplicationAPI,
  POSITIVE_ACTION_STATUSES,
} from '../../api/Client';
import BackButton from '../../components/BackButton';
import OfficeSelectContainer from '../../components/OfficeSelectContainer';
import FieldErrorViewer from '../Passports/components/FieldErrorViewer';
import Select from 'react-select-plus';

export default class FranchiseApplicationAction extends Component {
  constructor(props) {
    super(props);
    this.createFranchiseApplication = this.createFranchiseApplication.bind(this);
    this.fetchFranchiseApplication = this.fetchFranchiseApplication.bind(this);
    this.fetchAgencies = this.fetchAgencies.bind(this);
    this.onChange = this.onChange.bind(this);
    this.updateItem = this.updateItem.bind(this);
    this.updateOfficeAgency = this.updateOfficeAgency.bind(this);
    // this.fetchManagers = this.fetchManagers.bind(this);

    this.state = {
      source: "",
      applicant: {
        name: '',
        phone: '',
        email: '',
        age: 0,
        have_tourism_experience: null,
        countries: '',
        business_experience: '',
        legal_entity: null,
        additional_information: '',
        fp_registration: '',
        contract: '',
        training: '',
        site_publication: '',
        communicator: '',
        bonuses: '',
        rating: '',
      },
      office: {
        address: 'не задан',
        affiliation: null,
        is_separate_entrance: false,
        is_possibility_of_placing_signage: false,
        area: null,
      },
      city: {
        name: 'не задан',
        population: null,
      },
      agency: null,
      agency_office: null,
      agency_office_city: null,
      agencies: [],
      errors: {},
      success: {},
      managers: [],
      comment:   {
        comment: '',
      }
    }
  }

  fetchFranchiseApplication() {
    const franchiseApplicationId = this.props.match.params.number;
    let status;
    FranchiseApplicationAPI.
        fetchJSON(`${franchiseApplicationId}/`).
        then(r => {
          status = r.status;
          return r.json();
        }).
        then(r => {
          if (status === POSITIVE_ACTION_STATUSES.retrieve) {
            if (typeof r === 'object' && Object.keys(r).length) {
              // console.log(r.applicant)
              // console.log(r.comment)
              this.setState({
                applicant: r.applicant || {},
                office: r.office || {},
                comment: {comment: `${r.comment}`},
                // city: r.office.city || {},
                city: r.office ? r.office.city : '',
                source: r.source,
                agency: r.agency,
                agency_office: r.agency_office,
                success: {},
                manager: r.manager ? r.manager.id : '',
              });
            }
          }
        });
  }

  fetchAgencies() {
    let status;
    AgenciesAPI.
        fetchList(1, {}, '/full_list').
        then(r => {
          status = r.status;
          return r.json();
        }).
        then(r => {
          if (status === POSITIVE_ACTION_STATUSES.list) {
            this.setState({
              agencies: Array.isArray(r) && r.length
                  ? r.map(item => ({
                    value: item.id,
                    label: `${item.public_name} (ФСУ id ${item.fp_pk})`,
                  }))
                  : null,
            });
          }
        });
  }

  createFranchiseApplication() {
    const {onSuccess} = this.props;
    const {applicant, office, city, source, manager, comment} = this.state;
    let status;
    FranchiseApplicationAPI.
        create({
          applicant,
          source,
          manager,
          office: Object.assign(office, {city: city}),
          comment: (comment) ? comment.comment : '',
        }).
        then(r => {
          status = r.status;
          return r.json();
        }).
        then(r => {
          if (status === POSITIVE_ACTION_STATUSES.create) {
            this.setState({
              applicant: r.applicant || {},
              office: r.office || {},
              // city: r.office.city || {},
              city: r.office ? r.office.city : 'не задан',
              // source: r.office.source || {},
              source: r.office ? r.office.source  : 'не задан',
              errors: {},
            }, () => {
              if (typeof onSuccess === 'function') {
                onSuccess();
              }
            });
          } else {
            this.setState({
              errors: {
                applicant: r.applicant || {},
                city: r.office ? r.office.city : {},
                office: r.office || {},
                source: r.source || {},
              },
            });
          }
        });
  }

  updateItem(name, data) {
    let status;
    FranchiseApplicationAPI.
        update(`${FranchiseApplicationAPI.resource_url}${data.id}/${name}`, data).
        then(r => {
          status = r.status;
          return r.json();
        }).
        then(r => {
          if (status === POSITIVE_ACTION_STATUSES.partial_update) {
            this.setState({
              [name]: data,
              errors: {},
              success: {[name]: true},
            });
          } else {
            this.setState({
              errors: {[name]: r},
              success: {},
            });
          }
        });
  }

  updateOfficeAgency(name, data) {
    let status;
    const franchiseApplicationId = this.props.match.params.number;
    FranchiseApplicationAPI.
        update(`${FranchiseApplicationAPI.resource_url}${franchiseApplicationId}`, data).
        then(r => {
          status = r.status;
          return r.json();
        }).
        then(r => {
          if (status === POSITIVE_ACTION_STATUSES.partial_update) {
            this.setState({
              agency: r.agency,
              agency_office: r.agency_office,
              errors: {},
              success: {[name]: true},
            });
          } else {
            this.setState({
              errors: r,
              success: {},
            });
          }
        });
  }

  onChange(name, newData) {
    console.log('onchange', name, newData.source)
    this.setState({
      [name]: newData,
    });
  }

  componentDidMount() {
    const {isCreation} = this.props;
    if (!isCreation) {
      this.fetchFranchiseApplication();
    }
    this.fetchAgencies();
    // this.fetchManagers();
    // console.log("this.state from FranchiseApplicationAction",this.state)
  }

  render() {
    const {isCreation} = this.props;
    const {
      applicant,
      office,
      city,
      agency,
      agency_office,
      agencies,
      errors,
      success,
      source,
      manager,
      comment
    } = this.state;
    const franchiseApplicationId = (this.props.match && this.props.match.params.number) ? this.props.match.params.number : null;

    const FRANCHAISE_MANAGER_OPTIONS = [
      {label: 'Бородина Оксана', value: '59'},
      {label: 'Горкуша Алексей', value: '398'},
      {label: 'Малофеева Алёна', value: '487'},
      {label: 'Оборин Максим', value: '546'},
      {label: 'Петрищева Ирина', value: '633'},
      {label: 'Талипова Анжелика', value: '709'},
      {label: 'Уймина Алена', value: '22'},
      {label: 'Яшпаева Нина Михайловна ', value: '400'},
    ];

    return (
        <div className="animated fadeIn">
          {!isCreation ? (
              <div>
                <Alert color="info">Агентство из ФСУ</Alert>
                <div className="form-group row">
                  <label className="col-md-3 col-form-label" htmlFor="input-agency">
                    Агентство
                  </label>
                  <div className="col-md-9">
                    <Select
                        id="input-agency"
                        onChange={data => this.onChange('agency', data)}
                        options={agencies}
                        name="agency"
                        value={agency}
                        simpleValue={true}
                        placeholder="ИП Иванов"
                    />
                    <FieldErrorViewer errorMessages={errors} field="agency"/>
                  </div>
                </div>

                <div>
                  <button className="btn btn-success" type="button"
                          onClick={
                            () => this.updateOfficeAgency('agency', {agency: agency})
                          }>
                    Обновить
                  </button>
                  {!!success.agency
                      ? <div><Alert color="success">Успешно</Alert></div>
                      : null}
                  <br/>
                </div>
                <br/>
              </div>
          ) : null}

          {!isCreation ? (
              <div>
                <Alert color="info">Офис с сайта</Alert>
                <div className="form-group row">
                  <label className="col-md-3 col-form-label">
                    Офис
                  </label>
                  <div className="col-md-9">
                    <OfficeSelectContainer
                        selected_city={this.state.agency_office_city}
                        selected_office={agency_office}
                        changeCity={data => this.onChange('agency_office_city', data)}
                        changeOffice={data => this.onChange('agency_office', data)}
                        multi_office={false}
                    />
                    <FieldErrorViewer errorMessages={errors} field="agency_office"/>
                  </div>
                </div>
                {!isCreation ? (
                    <div>
                      <button className="btn btn-success" type="button"
                              onClick={() => this.updateOfficeAgency(
                                  'agency_office',
                                  {
                                    agency_office: agency_office !== null
                                        ? agency_office.value
                                        : null,
                                  },
                                  true,
                              )}>
                        Обновить
                      </button>
                      {!!success.agency_office
                          ? <div><Alert color="success">Успешно</Alert></div>
                          : null}
                      <br/>
                    </div>
                ) : null}
                <br/>
              </div>
          ) : null}

          {[
            {
              name: 'applicant',
              data: applicant,
              structure: APPLICANT_ACTION_STRUCTURE,
              lang: 'Заявитель',
            },
            {
              name: 'city',
              data: city,
              structure: CITY_ACTION_STRUCTURE,
              lang: 'Город',
            },
            {
              name: 'comment',
              data: comment,
              structure: COMMENT_ACTION_STRUCTURE,
              lang: 'Примечание',
            },
            {
              name: 'office',
              data: office,
              structure: OFFICE_ACTION_STRUCTURE,
              lang: 'Офис',
            },
          ].map((item, idx) => (
              <div key={idx}>
                <Alert color="info">{item.lang}</Alert>
                <Action data={item.data}
                        // applicantID={applicant}
                        structureType={item.name}
                        errors={errors[item.name]}
                        isSuccess={success[item.name]}
                        isCreation={isCreation}
                        id={`form_action_${item.name}`}
                        franchiseApplicationId={franchiseApplicationId}
                        // applicantId={item}
                        structure={item.structure}
                        actions={{
                          onChangeSelect: (newData) => this.onChange(item.name, newData),
                          onChangeInput: (newData) => this.onChange(item.name, newData),
                          onUpdateData: (data) => this.updateItem(item.name, data),
                        }}
                />
              </div>
          ))}
          <div style={{paddingBottom: '100px'}}>
            <Alert color="info">источник</Alert>
            <Select
                id='source'
                multi={false}
                onChange={val => this.onChange('source', val.value)}
                options={[
                  {value: 'odnoklassniki', label: 'Одноклассники'},
                  {value: 'lead_from_site', label: 'Заявка с сайта'},
                  {value: 'mailing', label: 'Рассылка'},
                  {value: 'instagram', label: 'Инстаграм'},
                  {value: 'facebook', label: 'Facebook'},
                  {value: 'VK', label: 'В контакте'},
                  {value: 'google', label: 'Контекстная Гугл'},
                  {value: 'yandex', label: 'Контекстная Яндекс'},
                  {value: 'headhunter', label: 'Head Hunter'},
                  {value: 'geo_site_tourschool', label: 'Туршкола'},
                ]}
                placeholder='Источник'
                name='source'
                value={source}
            />
            <FieldErrorViewer errorMessages={errors} field="source"/>
            <div>&nbsp;</div>
            <Alert color="info">менеджер</Alert>
            <Select
                id='manager'
                multi={false}
                onChange={val => this.onChange('manager', val.value)}
                // onChange={val => console.log(val)}
                options={FRANCHAISE_MANAGER_OPTIONS}
                placeholder='Менеджер'
                name='manager'
                value={manager}
            />

          {!isCreation ? (
              <BackButton {...this.props}/>
          ) : (
              <div className="row">
                <button className="btn btn-success m-4"
                        onClick={this.createFranchiseApplication}
                        type="submit">
                  Создать
                </button>
              </div>
          )}
          </div>
        </div>
    );
  }
}
