import React, { Component } from "react";
import BackButton from "../../../components/BackButton";
import Select from "react-select-plus";
import APIClient, {
  POSITIVE_ACTION_STATUSES,
  TouristFpAPI,
} from "../../../api/Client";
import { Alert, Button } from "reactstrap";
import moment from "moment";
import "moment/locale/ru";
import FieldErrorViewer from "../../Passports/components/FieldErrorViewer";
import DatePicker from "react-datepicker";
import OfficeSelectContainer from "../../../components/OfficeSelectContainer";
import EditInputPhone from "../../../components/EditInputPhone/EditInputPhone";
import ProgressBar from "../../../components/ProgressBar";

const BACKEND_DATE_FORMAT = "YYYY-MM-DD";
const FRONTEND_DATE_FORMAT = "DD.MM.YYYY";
const TIMEOUT = 2000;

export default class TouristFpEdit extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.fetchTags = this.fetchTags.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.fetchItem = this.fetchItem.bind(this);
    this.handleDate = this.handleDate.bind(this);
    this.handleTagsOnChange = this.handleTagsOnChange.bind(this);
    this.transformStateValues = this.transformStateValues.bind(this);

    this.state = {
      id: 0,
      office: 0,
      born: "",
      fullName: "",
      fullNameEng: "",
      emails: "",
      comment: "",
      phones: [],
      registration: "",
      address: "",
      tags: [],
      selectedTags: "",
      errors: {},
      isLoading: false,

      errorMessages: {},
      formChanged: false,
      isSuccessSave: false,
      isErrorSave: false,
      isItemDownloaded: false,
      isTagsDownloaded: false,
    };
  }

  handleChange(event) {
    let self = this;
    const target = event.target;
    const name = target.name;
    self.setState({
      [name]: target.value,
      formChanged: true,
      isSuccessSave: false,
      isErrorSave: false,
      errorMessages: {},
    });
  }

  handleDate(date) {
    let self = this;
    self.setState({
      born: date,
      formChanged: true,
      isSuccessSave: false,
      isErrorSave: false,
      errorMessages: {},
    });
  }

  handleTagsOnChange(tags) {
    let self = this;
    self.setState({
      selectedTags: tags,
      formChanged: true,
      isSuccessSave: false,
      isErrorSave: false,
      errorMessages: {},
    });
  }

  fetchItem() {
    let self = this;
    const touristId = parseInt(this.props.match.params.number, 10);
    let status;
    if (!isNaN(touristId)) {
      this.setState({ isLoading: true });
      TouristFpAPI.fetchJSON(`${touristId}/`)
        .then((r) => {
          status = r.status;
          return r.json();
        })
        .then((r) => {
          if (status === POSITIVE_ACTION_STATUSES.retrieve) {
            self.setState(
              Object.assign(self.transformStateValues(r, "fetch"), {
                formChanged: false,
                isItemDownloaded: true,
              })
            );
            this.setState({ isLoading: false });
          }
        });
    }
  }

  transformEnumerations(v, action = "fetch") {
    // action = 'fetch' || 'push'
    if (action === "fetch") {
      const result = v
        .map((item) => (!!item.value ? item.value : item.origin))
        .join(", ");
      return result.length ? result : null;
    }

    if (!v) {
      return [];
    }

    const result = v
      .trim()
      .split(",")
      .map((item) => item.trim())
      .filter((item) => item)
      .map((item) => ({ value: item }));
    return result.length ? result : [];
  }

  transformNames(v) {
    const result = v
      .trim()
      .split(" ")
      .map((item) => item.trim())
      .filter((item) => item)
      .join(" ");
    return result || null;
  }

  transformStateValues(result, action = "fetch") {
    // action = 'fetch' || 'push'
    return action === "fetch"
      ? {
          id: result.id,
          office: result.office || 0,
          born: result.born ? moment(result.born) : null,
          fullName: result.full_name,
          comment: result.comment ? result.comment : "",
          fullNameEng: result.full_name_eng,
          emails: this.transformEnumerations(result.email, "fetch"),
          phones: result.phone,
          registration: result.registration || "",
          address: result.address || "",
          selectedTags: result.tag ? result.tag.join(",") : "",
        }
      : {
          born:
            result.born !== null
              ? result.born.format(BACKEND_DATE_FORMAT)
              : null,
          full_name: this.transformNames(result.fullName),
          comment: result.comment ? result.comment : null,
          full_name_eng: this.transformNames(result.fullNameEng),
          email: this.transformEnumerations(result.emails, "push"),
          phone: result.phones,
          registration: result.registration.trim() || null,
          address: result.address.trim() || null,
          tag: result.selectedTags ? result.selectedTags.split(",") : [],
        };
  }

  fetchTags() {
    let status;
    APIClient.fetchJSON("/tags/full_list/")
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.list) {
          this.setState({
            tags: r || [],
            isTagsDownloaded: true,
          });
        }
      });
  }

  handleSave() {
    let self = this;
    let status;
    TouristFpAPI.modify(
      this.state.id,
      self.transformStateValues(this.state, "push")
    )
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        self.setState(
          status === POSITIVE_ACTION_STATUSES.update
            ? Object.assign(self.transformStateValues(r, "fetch"), {
                formChanged: false,
                isSuccessSave: true,
                isErrorSave: false,
                errorMessages: {},
              })
            : {
                errorMessages: r,
                isErrorSave: true,
                isSuccessSave: false,
              },
          () => {
            if (status === POSITIVE_ACTION_STATUSES.update) {
              setTimeout(
                () =>
                  self.setState({
                    isSuccessSave: false,
                  }),
                TIMEOUT
              );
            }
          }
        );
      });
  }

  componentDidMount() {
    this.fetchItem();
    this.fetchTags();
  }

  render() {
    const {
      office,
      fullName,
      fullNameEng,
      born,
      phones,
      emails,
      tags,
      address,
      registration,
      selectedTags,
      isItemDownloaded,
      isTagsDownloaded,
      errorMessages,
      comment,
      errors,
      formChanged,
      isLoading,
    } = this.state;
    const TAGS_OPTIONS = tags.map((tag) => {
      return { value: `${tag.id}`, label: tag.name };
    });
    const isDisabled = !isItemDownloaded && !isTagsDownloaded;
    return (
      <div className="animated fadeIn filter filter--green">
        <div className="row">
          <div className="col-sm-6 col-md-6">
            <div className="card card-accent-primary">
              {!isLoading ? (
                <div className="card-block">
                  <div className="form-group row">
                    <label className="text-right col-md-2 col-form-label">
                      Офис:
                    </label>
                    <div className="col-md-8">
                      <OfficeSelectContainer
                        disabledSelect={true}
                        officeId={office}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="text-right col-md-2 col-form-label"
                      htmlFor="full-name-input"
                    >
                      ФИО:
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        type="text"
                        id="full-name-input"
                        name="fullName"
                        value={fullName}
                        onChange={this.handleChange}
                        disabled={isDisabled}
                      />
                      <FieldErrorViewer
                        errorMessages={errorMessages}
                        field="full_name"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="text-right col-md-2 col-form-label"
                      htmlFor="full-name-eng-input"
                    >
                      ФИО[ENG]:
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        type="text"
                        id="full-name-eng-input"
                        name="fullNameEng"
                        value={fullNameEng}
                        onChange={this.handleChange}
                        disabled={isDisabled}
                      />
                      <FieldErrorViewer
                        errorMessages={errorMessages}
                        field="full_name_eng"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="text-right col-md-2 col-form-label"
                      htmlFor="address-input"
                    >
                      Адрес:
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        type="text"
                        id="address-input"
                        name="address"
                        value={address}
                        onChange={this.handleChange}
                        disabled={isDisabled}
                      />
                      <FieldErrorViewer
                        errorMessages={errorMessages}
                        field="address"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="text-right col-md-2 col-form-label"
                      htmlFor="registration-input"
                    >
                      Регистрация:
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        type="text"
                        id="registration-input"
                        name="registration"
                        value={registration}
                        onChange={this.handleChange}
                        disabled={isDisabled}
                      />
                      <FieldErrorViewer
                        errorMessages={errorMessages}
                        field="registration"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="text-right col-md-2 col-form-label">
                      Дата рождения:
                    </label>
                    <div className="col-md-8">
                      <DatePicker
                        className="form-control-label"
                        dateFormat={FRONTEND_DATE_FORMAT}
                        selected={born}
                        onChange={this.handleDate}
                        disabled={isDisabled}
                      />
                      <FieldErrorViewer
                        errorMessages={errorMessages}
                        field="born"
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="text-right col-md-2 col-form-label">
                      Телефон:
                    </label>
                    <EditInputPhone
                      phones={phones}
                      onChange={(value) =>
                        this.setState(
                          { phones: value, formChanged: true, errors: {} },
                          () => console.log(phones)
                        )
                      }
                      errors={
                        errors && errors.phone !== undefined
                          ? errors.phone
                          : null
                      }
                    />
                  </div>

                  <div className="form-group row">
                    <label
                      className="text-right col-md-2 col-form-label"
                      htmlFor="email-input"
                    >
                      Эл.почта:
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        type="email"
                        id="email-input"
                        name="emails"
                        value={emails || ""}
                        onChange={this.handleChange}
                        disabled={isDisabled}
                      />
                      {typeof errorMessages === "object" &&
                      !!errorMessages &&
                      Object.keys(errorMessages).length &&
                      !!errorMessages.email
                        ? errorMessages.email.map((i, n) => {
                            if (!i.value || !Array.isArray(i.value)) {
                              return null;
                            }
                            return (
                              <div key={`error-${n}`}>
                                {i.value.map((j, m) => (
                                  <div
                                    className="invalid-feedback"
                                    key={`error-${n}-${m}`}
                                  >
                                    {j}
                                  </div>
                                ))}
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="text-right col-md-2 col-form-label"
                      htmlFor="tourist-tags-select"
                    >
                      Метки:
                    </label>
                    <div className="col-md-8">
                      <Select
                        id="tourist-tags-select"
                        className="mb-3"
                        name="tags"
                        placeholder="Выберите из спиcка..."
                        multi={true}
                        closeOnSelect={true}
                        removeSelected={true}
                        value={selectedTags}
                        onChange={this.handleTagsOnChange}
                        simpleValue={true}
                        options={TAGS_OPTIONS}
                        disabled={isDisabled}
                      />
                    </div>
                    <FieldErrorViewer
                      errorMessages={errorMessages}
                      field="tag"
                    />
                  </div>
                  <div className="form-group row">
                    <label
                      className="text-right col-md-2 col-form-label"
                      htmlFor="tourist-tags-select"
                    >
                      Комментарий:
                    </label>
                    <div className="col-md-8">
                      <input
                        className="form-control"
                        type="text"
                        id="comment-input"
                        name="comment"
                        value={comment}
                        onChange={this.handleChange}
                        disabled={isDisabled}
                      />
                      <FieldErrorViewer
                        errorMessages={errorMessages}
                        field="full_name"
                      />
                    </div>
                    <FieldErrorViewer
                      errorMessages={errorMessages}
                      field="tag"
                    />
                  </div>
                  <BackButton {...this.props} />
                  <Button
                    color={formChanged ? "success" : "secondary"}
                    disabled={!formChanged || isDisabled}
                    onClick={this.handleSave}
                  >
                    <i className="fa fa-dot-circle-o"> </i> Сохранить
                  </Button>
                </div>
              ) : (
                <div
                  className="card-block"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "722px",
                  }}
                >
                  <ProgressBar />
                </div>
              )}
              <div className="card-footer">
                {this.state.isErrorSave ? (
                  <Alert color="warning">
                    <strong>Внимание</strong>ошибка
                  </Alert>
                ) : null}
                {this.state.isSuccessSave ? (
                  <Alert color="success">
                    <strong>Данные туриста</strong> успешно сохранены
                  </Alert>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
