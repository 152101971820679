import React, { Component } from "react";

import IssueAddForm from "./components/IssueAddForm";
import IssuesList from "./components/IssuesList";

export default class Issues extends Component {
  constructor(props) {
    super(props);
    this.onCreate = this.onCreate.bind(this);
  }
  onCreate() {
    this.props.onCreate();
  }
  render() {
    return (
      <div className="issues mt-2">
        <IssueAddForm tourist={this.props.tourist} {...this.props} />
        <IssuesList
          items={this.props.issues}
          info={this.props.info}
          onCreate={this.onCreate}
          onChangeActivity={this.props.onChangeActivity}
        />
      </div>
    );
  }
}
