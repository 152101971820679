export const NOT_REGISTERED = 'not_registered';
export const INDIVIDUAL_ENTREPRENEUR = 'individual entrepreneur';
export const LIMITED_LIABILITY_COMPANY = 'limited_liability_company';
export const ANOTHER_FORM = 'another_form';
export const LEGAL_ENTITIES = {
  [NOT_REGISTERED]: 'Не зарегистрировано',
  [INDIVIDUAL_ENTREPRENEUR]: 'ИП',
  [LIMITED_LIABILITY_COMPANY]: 'ООО',
  [ANOTHER_FORM]: 'Другая форма',
};

export const APPLICANT_ACTION_STRUCTURE = [
  {
    name: 'name',
    lang: 'ФИО',
    type: 'text',
    placeholder: 'ФИО',
  },
  {
    name: 'phone',
    lang: 'телефон',
    type: 'phone',
    placeholder: '+79122252525',
  },
  {
    name: 'email',
    lang: 'email',
    type: 'email',
    placeholder: 'email@email.com',
  },
  {
    name: 'age',
    lang: 'возраст (года)',
    type: 'number',
    placeholder: '36',
  },
  {
    name: 'have_tourism_experience',
    lang: 'Имеет опыт работы в туризме',
    type: 'select',
    options: [{value: true, label: 'Да'}, {value: false, label: 'Нет'}],
  },
  {
    name: 'countries',
    lang: 'Посещенные страны',
    type: 'textarea',
    placeholder: 'Занзибар ...',
  },
  {
    name: 'business_experience',
    lang: 'Опыт ведения бизнеса',
    type: 'textarea',
    placeholder: ' 10 лет туризм',
  },
  {
    name: 'legal_entity',
    lang: 'Тип юридического лица',
    type: 'select',
    options: Object.keys(LEGAL_ENTITIES).map(key => (
        {value: key, label: LEGAL_ENTITIES[key]}
    )),
  },
  {
    name: 'additional_information',
    lang: 'Дополнительные сведения',
    type: 'textarea',
    placeholder: '',
  },
  {
    name: 'country',
    lang: 'Страна',
    type: 'select',
    options: [
      {value: 'russia', label: 'Россия'},
      {value: 'belorussia', label: 'Беларусь'},
      {value: 'kazakhstan', label: 'Казахстан'},
    ],
  },
  {
    name: 'fp_registration',
    lang: 'Регистрация в ФСУ',
    type: 'textarea',
    placeholder: '',
  },
  {
    name: 'contract',
    lang: 'Договор',
    type: 'textarea',
    placeholder: '',
  },
  {
    name: 'contract_date',
    lang: 'Дата договора',
    type: 'date',
    placeholder: '',
  },
  {
    name: 'training',
    lang: 'Обучение',
    type: 'textarea',
    placeholder: '',
  },
  {
    name: 'site_publication',
    lang: 'Публикация на сайте',
    type: 'textarea',
    placeholder: '',
  },
  {
    name: 'communicator',
    lang: 'Коммуникатор',
    type: 'textarea',
    placeholder: '',
  },
  {
    name: 'bonuses',
    lang: 'Бонусы',
    type: 'textarea',
    placeholder: '',
  },
  {
    name: 'rating',
    lang: 'Рейтинг в сети',
    type: 'textarea',
    placeholder: '',
  },
];
