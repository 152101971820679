import React, { Component } from "react";
import { POSITIVE_ACTION_STATUSES, TemplatesAPI } from "../../../api/Client";
import swal from "sweetalert";

import EmailTemplateCreate from "./EmailTemplateCreate";
import EmailTemplateItem from "./EmailTemplateItem";
import ProgressBar from "../../../components/ProgressBar";

export default class EmailTemplatesEditor extends Component {
  constructor(props) {
    super(props);
    this.getTemplates = this.getTemplates.bind(this);

    this.swithToCreateMode = this.swithToCreateMode.bind(this);
    this.swithToEditMode = this.swithToEditMode.bind(this);
    this.swithToDeleteMode = this.swithToDeleteMode.bind(this);
    this.swithToListMode = this.swithToListMode.bind(this);
    this.handleEmailTemplateCreate = this.handleEmailTemplateCreate.bind(this);
    this.handleEmailTemplateDelete = this.handleEmailTemplateDelete.bind(this);

    this.state = {
      email_templates: [],
      selectedTemplate: null,
      mode: "list",
      page: 1,
      limit: 20,
      isLoading: false,
    };
  }

  swithToEditMode(template) {
    this.setState({
      mode: "edit",
      selectedTemplate: template,
    });
  }

  swithToListMode(template) {
    this.setState({
      mode: "list",
      selectedTemplate: template,
    });
  }

  swithToCreateMode(template) {
    this.setState({
      mode: "create",
      selectedTemplate: template,
    });
  }

  // перед удалением проверить связан ли шаблон с другими моделями
  swithToDeleteMode(template) {
    this.setState({
      mode: "delete",
      selectedTemplate: template,
    });
  }

  switchLoadingStatus() {
    this.setState({
      ...this.state,
      isLoading: !this.state.isLoading,
    });
  }

  getTemplates() {
    let status;
    this.switchLoadingStatus();
    TemplatesAPI.getAll()
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((result) => {
        if (status === POSITIVE_ACTION_STATUSES.list) {
          this.setState({
            email_templates: result,
          });
          this.switchLoadingStatus();
        }
      });
  }

  handleEmailTemplateCreate(template) {
    swal(`Шаблон ${template.title} успешно добавлен`);
    setTimeout(this.getTemplates, 3000);
  }

  handleEmailTemplateEdit(template) {
    const newTemplate = { ...template };
    delete newTemplate.sms_template;

    TemplatesAPI.modify(newTemplate.id, newTemplate)
      .then((res) => {
        if (res.ok) {
          swal(`Шаблон "${template.title}" сохранен.`);
          setTimeout(this.getTemplates, 100);
        }
      })
      .catch((e) => {
        swal(`Возникла ошибка: ${e}, попробуйте еще раз`);
      });
  }

  handleEmailTemplateDelete(template) {
    swal(`Шаблон ${template.name} успешно удалён `);
    TemplatesAPI.delete(template.id);
    setTimeout(this.getTemplates, 100);
  }

  componentDidMount() {
    this.getTemplates();
  }

  render() {
    const { email_templates, mode, isLoading } = this.state;
    return (
      <div>
        <h2>Редактор Email шаблонов</h2>
        {mode === "list" && (
          <section>
            <EmailTemplateCreate onCreate={this.handleEmailTemplateCreate} />
            {isLoading ? (
              <div style={{ margin: "40px auto" }}>
                <ProgressBar />
              </div>
            ) : (
              !!email_templates.length &&
              email_templates.map((template) => (
                <EmailTemplateItem
                  key={template.id}
                  template={template}
                  onEdit={(item) => this.handleEmailTemplateEdit(item)}
                  onDelete={(item) => this.handleEmailTemplateDelete(item)}
                />
              ))
            )}
          </section>
        )}
      </div>
    );
  }
}
