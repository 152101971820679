import React, { PureComponent } from 'react';
import Select from 'react-select-plus';
import {
  TouristFpAPI,
  POSITIVE_ACTION_STATUSES,
  UsersAPI,
} from '../../../api/Client';
import { ADMIN, getUserRole, SUPER_ADMIN } from '../../../connect/auth';
import ManagerFpSelector from '../../OrdersFpTable/MangerFpSelector';
import OfficeSelectContainer from '../../../components/OfficeSelectContainer/OfficeSelectContainer';

class TouristsFpFilter extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      managers: [],
      managers_fp: [],
      offices: [],
      offices_city: null,
      queryInput: '',
      orderings: '',
      selectedTags: '',
      manager: null,
      manager_fp: null,
      hasEmails: null,
      hasPhones: null,
      phone: '',
      email: '',
      cityDeparture: '',
      selectedItems: '',
      closedAgent: null,
    };
    this.fetchTags = this.fetchTags.bind(this);
    this.handleTagsOnChange = this.handleTagsOnChange.bind(this);
    this.handleManagersOnChange = this.handleManagersOnChange.bind(this);
    this.handleManagersFpOnChange = this.handleManagersFpOnChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleInputEmail = this.handleInputEmail.bind(this);
    this.handleCityDeparture = this.handleCityDeparture.bind(this);
    this.handleInputPhone = this.handleInputPhone.bind(this);
    this.fetchManagers = this.fetchManagers.bind(this);
    this.changeManagerFp = this.changeManagerFp.bind(this);
    this.handleHasEmailsOnChange = this.handleHasEmailsOnChange.bind(this);
    this.handleHasPhonesOnChange = this.handleHasPhonesOnChange.bind(this);
    this.handleIsActiveOnChange = this.handleIsActiveOnChange.bind(this);
    this.handleOrderingOnChange = this.handleOrderingOnChange.bind(this);
    this.handleClosedAgentOnChange = this.handleClosedAgentOnChange.bind(this);
    this.changeOffice = this.changeOffice.bind(this);
    this.changeOfficeCity = this.changeOfficeCity.bind(this);
  }

  changeOffice(offices) {
    this.setState({ offices });
  }

  changeOfficeCity(offices_city) {
    this.setState({ offices_city });
  }

  changeManagerFp(val) {
    this.setState({
      manager_fp: +val,
    });
  }

  handleInput(e) {
    this.setState({
      queryInput: e.target.value,
    });
  }

  handleInputEmail(e) {
    this.setState({
      email: e.target.value,
    });
  }

  handleCityDeparture(e) {
    this.setState({
      cityDeparture: e.target.value,
    });
  }

  handleInputPhone(e) {
    this.setState({
      phone: e.target.value,
    });
  }

  handleHasEmailsOnChange(v) {
    this.setState({
      hasEmails: v,
    });
  }

  handleHasPhonesOnChange(v) {
    this.setState({
      hasPhones: v,
    });
  }
  handleIsActiveOnChange(v) {
    this.setState({
      isActive: v,
    });
  }
  handleTagsOnChange(v) {
    this.setState({
      ...this.state,
      selectedTags: v,
    });
  }
  handleClosedAgentOnChange(v) {
    this.setState({
      ...this.state,
      closedAgent: v,
    });
  }
  handleManagersOnChange(v) {
    this.setState({
      ...this.state,
      manager: v,
    });
  }

  handleManagersFpOnChange(v) {
    this.setState({
      ...this.state,
      manager_fp: v,
    });
  }

  handleOrderingOnChange(v) {
    this.setState({
      ...this.state,
      orderings: v,
    });
  }

  handleSubmit() {
    this.props.onFilter(this.state);
  }

  handleReset() {
    this.setState(
      {
        queryInput: '',
        orderings: '',
        selectedTags: '',
        manager: null,
        manager_fp: null,
        hasEmails: null,
        hasPhones: null,
        hasViber: null,
        isActive: null,
        offices: [],
        offices_city: null,
        phone: '',
        email: '',
        cityDeparture: '',
      },
      () => {
        this.props.onFilter(this.state);
      }
    );
  }

  fetchTags() {
    let self = this;
    let status;
    TouristFpAPI.fetchList(1, {}, `tags/`)
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        self.setState({
          tags: status === POSITIVE_ACTION_STATUSES.list ? r : [],
        });
      });
  }

  fetchManagers() {
    let self = this;
    let status;
    UsersAPI.fetchJSON(`full_list/`)
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        self.setState({
          managers: status === POSITIVE_ACTION_STATUSES.list ? r : [],
        });
      });
  }

  componentDidMount() {
    this.fetchTags();
    this.fetchManagers();
  }

  render() {
    const userRole = getUserRole();

    const isDisabled = this.props.disabled;
    const {
      selectedTags,
      queryInput,
      manager,
      manager_fp,
      hasEmails,
      hasPhones,
      offices,
      offices_city,
      cityDeparture,
      closedAgent,
    } = this.state;

    const TAGS_OPTIONS = this.state.tags.map((tag) => {
      return { value: tag.id.toString(), label: tag.name };
    });
    const HAS_EMAILS_OPTIONS = [
      { value: 1, label: 'Есть емейлы' },
      { value: 0, label: 'Нет емейлов' },
    ];
    const CLOSED_AGENT_OPTIONS = [
      { value: true, label: 'Да' },
      { value: false, label: 'Нет' },
    ];
    const HAS_PHONES_OPTIONS = [
      { value: 1, label: 'Есть телефоны' },
      { value: 0, label: 'Нет телефонов' },
    ];
    const MANAGER_OPTIONS = this.state.managers.map((manager) => {
      return {
        value: manager.id,
        label:
          !manager.last_name && !manager.first_name
            ? manager.username
            : [manager.last_name, manager.first_name].join(' '),
      };
    });
    const SORT_OPTIONS = [
      { value: 'full_name', label: 'ФИО' },
      { value: 'email', label: 'Email' },
      { value: 'phone', label: 'Телефоны' },
    ];
    return (
      <div className="card">
        <div className="card-header">
          <strong>Работа с постоянными клиентами</strong>
        </div>
        <div className="card-block">
          <div className="filter-row"></div>
          {(userRole === SUPER_ADMIN || userRole === ADMIN) && (
            <OfficeSelectContainer
              changeCity={this.changeOfficeCity}
              changeOffice={this.changeOffice}
              multi_office={true}
              selected_city={offices_city}
              selected_office={offices}
            >
              <div className="filter-field">
                <Select
                  id="manager-selector"
                  placeholder="Выберите менеджера из спиcка..."
                  removeSelected={true}
                  name="manager"
                  value={manager}
                  simpleValue={true}
                  onChange={this.handleManagersOnChange}
                  options={MANAGER_OPTIONS}
                  disabled={isDisabled}
                />
              </div>
            </OfficeSelectContainer>
          )}

          <div className="filter-row">
            {userRole === SUPER_ADMIN && userRole === ADMIN && (
              <div className="filter-field">
                <ManagerFpSelector
                  managerFpSelected={manager_fp}
                  officeSelected={offices.length ? offices[0] : null}
                  isDisabled={false}
                  updateManagerFp={(v) => this.changeManagerFp(v)}
                  identifier="order-manager-fp-selector"
                />
              </div>
            )}
            <div className="filter-field">
              <label htmlFor="" className="col-form-label">
                Город вылета
              </label>
              <input
                type="text"
                className="form-control"
                id="cityDeparture"
                placeholder="Город вылета"
                name="cityDeparture"
                value={cityDeparture}
                onChange={this.handleCityDeparture}
                disabled={isDisabled}
              />
            </div>
            <div className="filter-field">
              <Select
                disabled={isDisabled}
                id="tourist-ordering"
                name="orderings"
                placeholder="Выберите сортировку из спиcка..."
                multi={true}
                closeOnSelect={true}
                removeSelected={true}
                simpleValue={true}
                value={this.state.orderings}
                onChange={this.handleOrderingOnChange}
                options={SORT_OPTIONS}
              />
            </div>
            <div className="filter-field">
              <Select
                id="tags-selector"
                placeholder="Выберите метки из спиcка..."
                multi={true}
                name="selectedTags"
                closeOnSelect={false}
                removeSelected={true}
                value={selectedTags}
                simpleValue={true}
                onChange={this.handleTagsOnChange}
                options={TAGS_OPTIONS}
                disabled={isDisabled}
              />
            </div>
          </div>
          <div className="filter-row pb-0">
            Поиск клиента по номеру телефона, Email
          </div>

          <div className="filter-row">
            <div className="filter-field">
              <Select
                id="has-emails-selector"
                placeholder="Наличие email-ов"
                name="hasEmails"
                removeSelected={true}
                value={hasEmails}
                simpleValue={true}
                searchable={false}
                onChange={this.handleHasEmailsOnChange}
                options={HAS_EMAILS_OPTIONS}
                disabled={isDisabled}
              />
            </div>
            <div className="filter-field">
              <Select
                id="has-phones-selector"
                placeholder="Наличие телефонов"
                name="hasPhones"
                removeSelected={true}
                value={hasPhones}
                simpleValue={true}
                searchable={false}
                onChange={this.handleHasPhonesOnChange}
                options={HAS_PHONES_OPTIONS}
                disabled={isDisabled}
              />
            </div>
            <div className="filter-field">
              <input
                type="text"
                className="form-control"
                value={queryInput}
                onChange={this.handleInput}
                placeholder="ФИО, email, телефон"
                title="ФИО, номер телефона или email"
                disabled={isDisabled}
              />
            </div>
            {userRole === SUPER_ADMIN && (
              <div className="filter-field">
                <Select
                  id="closed-agent-selector"
                  placeholder="Закрывшийся агент"
                  name="closedAgent"
                  removeSelected={true}
                  value={closedAgent}
                  simpleValue={true}
                  searchable={false}
                  onChange={this.handleClosedAgentOnChange}
                  options={CLOSED_AGENT_OPTIONS}
                  disabled={isDisabled}
                />
              </div>
            )}
          </div>
          <div className="card-footer">
            <button
              className="btn btn-primary"
              onClick={this.handleSubmit}
              style={{ backgroundColor: '#16A6E3' }}
              disabled={isDisabled}
            >
              Найти
            </button>
            <button
              className="btn btn-danger"
              onClick={this.handleReset}
              disabled={isDisabled}
            >
              Сбросить
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default TouristsFpFilter;
