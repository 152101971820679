import React, { PureComponent } from 'react';
import {Link} from 'react-router-dom';


import moment from 'moment';
import 'moment/locale/ru';
import CalendarEventList from './CalendarEventList';

class CalendarEventsList extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const currentDay = moment();
    const Events = {
        count: this.props.events.length || 0,
        next: [],
        prev: [],
        done: [],
        today: []
      }
    if (Events.count > 0) {
        Events.today = this.props.events.filter(event =>
          event.day === currentDay.format('YYYY-MM-DD'))
        Events.next = this.props.events.filter(event =>
          event.day > currentDay.format('YYYY-MM-DD'))
        Events.prev = this.props.events.filter(event =>
          (event.day <= currentDay.format('YYYY-MM-DD')) && 
          event.done === false)
        Events.done = this.props.events.filter(event =>
          event.done=== true)
    } else {
      return (
        <p>
          <span>В календаре нет ни одной заметки</span>
          <span className="float-right">
            <Link to={`/calendar`} >
                  перейти в календарь
            </Link>
          </span>
        </p>
      )
    }
  
    return (
    <div>
      <CalendarEventList eventList={Events.next}  title="Запланировано" color="success" />
      <CalendarEventList eventList={Events.done}  title="Выполнено" color="success" />
      <CalendarEventList eventList={Events.prev}  title="Просрочено" color="danger" />
      <CalendarEventList eventList={Events.today} title="Сегодня" color="success" />
    </div>
    )
  }
}

export default CalendarEventsList;