import React from 'react';
import ClientAPI, { POSITIVE_ACTION_STATUSES } from '../../../api/Client';
import Swal from 'sweetalert';

export default class OfficeShowOrderButtonToggler extends React.Component {
  constructor(props) {
    super(props);
    this.handleShowOrderButton = this.handleShowOrderButton.bind(this);

  }

  handleShowOrderButton(e) {
    const show_order_button = !this.props.value;
    const pk = this.props.agencyId;
    let status;
    const office = this.props.office;
    if (!office) {
      return Swal('Офис не найден');
    }
    const url = `/agencies/${pk}`;
    ClientAPI.update(url, {show_order_button: show_order_button}).then(r => {
                status = r.status;
                return r.json();
            }).then(res => {
                if (status === POSITIVE_ACTION_STATUSES.update) {
                  if (show_order_button) {
                    Swal('Кнопка "Подать заявку на тур" включена ✅')
                  } else {
                    Swal('Кнопка "Подать заявку на тур" отключена ❌')
                  }
                  this.props.onChange();
                } else {
                  Swal('Возникла ошибка, обратитесь к администратору crm системы');
                }
            });
    this.setState({
      hasLeadFromSite: e.target.value
    })
  }

  render() {
    const office = this.props.office || false;
    const value = this.props.value || false;
    return <div>
      { office ? (
          <label>
          Показывать кнопку "Подать заявку на тур" на детальной странице
          <select value={value} onChange={this.handleShowOrderButton} className="form-control" style={{width: '100px'}} >
            <option value="true">Да</option>
            <option value="false">Нет</option>
          </select>
          </label>
        ) : null}
    </div>
  }
}
