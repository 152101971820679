import React, {Component} from 'react';
import {Table} from 'reactstrap';

import { ISSUE_SOURCE_MAP } from '../../Issues/components/IssueItem';


export default class IssuesSourceReportTable extends Component {
  constructor(props) {
    super(props);

    this.handlerSort = this.handlerSort.bind(this);

  }

  handlerSort(e) {
    this.props.onSort(e.target.title);
  }
  render() {
    const { items, total } = this.props;
    let summ = 0;
    for( let k in total) {
      summ += total[k];
    }
    return <div>
      <Table striped bordered size="sm" className="font-xs table--green">
        <thead>
        <tr style={{fontSize: 10}}>
          <th>источник</th>
          <th onClick={this.handlerSort} style={{cursor: 'pointer', width: '90px'}} title="new">новый<i className="fa fa-sort ml-2"></i></th>
          <th onClick={this.handlerSort} style={{cursor: 'pointer', width: '90px'}} title="active">открыт<i className="fa fa-sort ml-2"></i></th>
          <th onClick={this.handlerSort} style={{cursor: 'pointer', width: '90px'}} title="call">звонок<i className="fa fa-sort ml-2"></i></th>
          <th onClick={this.handlerSort} style={{cursor: 'pointer', width: '90px'}} title="meet">встреча<i className="fa fa-sort ml-2"></i></th>
          <th onClick={this.handlerSort} style={{cursor: 'pointer', width: '90px'}} title="deffered">отложен<i className="fa fa-sort ml-2"></i></th>
          <th onClick={this.handlerSort} style={{cursor: 'pointer', width: '90px'}} title="we_cancel">мы отказали<i className="fa fa-sort ml-2"></i></th>
          <th onClick={this.handlerSort} style={{cursor: 'pointer', width: '90px'}} title="client_cancel">клиент отказался<i className="fa fa-sort ml-2"></i></th>
          <th onClick={this.handlerSort} style={{cursor: 'pointer', width: '90px'}} title="cancel">аннулирована<i className="fa fa-sort ml-2"></i></th>
          <th onClick={this.handlerSort} style={{cursor: 'pointer', width: '90px'}} title="tours">подборка<i className="fa fa-sort ml-2"></i></th>
          <th onClick={this.handlerSort} style={{cursor: 'pointer', width: '90px'}} title="order">покупка<i className="fa fa-sort ml-2"></i></th>
          <th onClick={this.handlerSort} style={{cursor: 'pointer', width: '90px'}} title="total">всего<i className="fa fa-sort ml-2"></i></th>
        </tr>
        </thead>
        <tbody>
      { items.length && items.map( (item, idx) => <tr key={idx} >
        <td><b>{ISSUE_SOURCE_MAP[item.source] ? ISSUE_SOURCE_MAP[item.source].name : item.source_label }</b></td>
        <td>{item.new}</td>
        <td>{item.active}</td>
        <td>{item.call}</td>
        <td>{item.meet}</td>
        <td>{item.deffered}</td>
        <td>{item.we_cancel}</td>
        <td>{item.client_cancel}</td>
        <td>{item.cancel}</td>
        <td>{item.tours}</td>
        <td>{item.order}</td>
        <td><b>{item.total}</b></td>
        </tr>)}
        <tr style={{background: "#5c9353", color: "#fff"}}>
          <td><b>ВСЕГО</b></td>
          <td><b>{total.new_total}</b></td>
          <td><b>{total.active_total}</b></td>
          <td><b>{total.call_total}</b></td>
          <td><b>{total.meet_total}</b></td>
          <td><b>{total.deffered_total}</b></td>
          <td><b>{total.we_cancel_total}</b></td>
          <td><b>{total.client_cancel_total}</b></td>
          <td><b>{total.cancel_total}</b></td>
          <td><b>{total.tours_total}</b></td>
          <td><b>{total.order_total}</b></td>
          <td><big><b>{summ}</b></big></td>
        </tr>
                  </tbody>
                  </Table>
    </div>
  }
}
