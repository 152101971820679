import React, { Component } from 'react';
import ClientAPI, { PersonAPI } from '../../../api/Client';
import { Link } from 'react-router-dom';
import ProgressBar from '../../../components/ProgressBar';

export default class PersonWithQuote extends Component {
  constructor(props) {
    super(props);
    this.handleCopyMessageToBuffer = this.handleCopyMessageToBuffer.bind(this);
    this.handleLoadingState = this.handleLoadingState.bind(this);

    this.state = {
      id: 0,
      fio: '',
      is_buffer: false,
      isLoading: false,
    };
  }

  handleCopyMessageToBuffer() {
    //10.10, 7н, Bm Beach Resort 4*, HB - Завтрак, Ужин, 193 783 RUB
    const params = `?p=${this.state.id}`;
    let text = `${this.props.title}

      \nhttps://www.geograftour.com/geoclient/${this.props.quote_id}/${params}
      `;
    this.setState({ is_buffer: true }, () => this.copyToClipboard(text));
  }

  copyToClipboard = (text) => {
    console.log('text', text);
    var textField = document.createElement('textarea');
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    this.setState({
      is_buffer: true,
    });
  };

  handleLoadingState(state) {
    this.setState({ isLoading: state });
  }

  componentDidMount() {
    // Клиент
    this.handleLoadingState(true);
    PersonAPI.getInfo(this.props.person_id).then((result) => {
      if (!result.id) {
        this.setState({
          errors: result,
        });
      } else {
        this.setState({
          id: result.id,
          fio: result.fio,
        });
      }
      this.handleLoadingState(false);
    });
  }

  render() {
    const { id, fio, is_buffer, isLoading } = this.state;
    return (
      <div className='geopick__person'>
        <Link to={`/persons/${id}`} target="_blank" rel="noopener noreferrer">
          {isLoading ? <ProgressBar/> : fio}
        </Link>
        <button
          className="btn btn-sm btn-primary float-right"
          disabled={is_buffer}
          onClick={this.handleCopyMessageToBuffer}
        >
          {is_buffer ? 'Скопировано' : 'Скопировать ссылку'}
        </button>
      </div>
    );
  }
}
