import React, { PureComponent } from 'react';
import APIClient from '../../../api/Client/Client';
import ToursList from '../../Tours/components/ToursList';

import moment from 'moment';
import 'moment/locale/ru';

export default class QuotesOptions extends PureComponent {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleShowTours = this.handleShowTours.bind(this);
    this.state = {
      items: [],
      showTours: false,
      current: {},
    };
  }

  handleChange(event) {
    let Current = this.state.items.filter(
      (item) => item.quote_id == event.target.value
    );
    this.setState({
      ...this.state,
      current: Current[0],
    });
    this.props.onSelectQuote(event.target.value);
  }

  handleShowTours(e) {
    this.setState({ showTours: e.target.checked });
  }

  componentDidMount() {
    APIClient.getJSON('/quotes/').then((res) => {
      this.setState({
        ...this.state,
        items: res.results,
      });
    });
  }

  render() {
    const { showTours, items } = this.state;
    return (
      <div className="col-md-12 mt-2 mb-3">
        <select className="form-control p-2" onChange={this.handleChange}>
          <option value={""}>Выбрать геоподборку</option>
          {items.map((item, idx) => (
            <option key={idx} value={item.quote_id}>
              {item.manager_name} #{item.title} {item.quote_id} от{' '}
              {moment(item.created_at).format('DD.MM.YY H:mm')}
            </option>
          ))}
        </select>
        {this.props.quoteData ? (
          <div className='mt-3'>
            <input
              type="checkbox"
              value={showTours}
              checked={showTours}
              onClick={this.handleShowTours}
              id="tourstoggle"
              className='clickable'
            />
            <label htmlFor="tourstoggle" className="ml-2 clickable">
              Показать туры в подборке?
            </label>
            {showTours ? (
              <div>
                <ToursList
                  items={this.props.quoteData ? this.props.quoteData.tours : []}
                />
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
}
