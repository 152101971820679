import React, {PureComponent} from 'react'

export default class Instructions extends PureComponent {

  render() {
    return (
        <div className="row">
          <section className="col-10">
            <p><b>Геоклиент</b> - система управления эффективной коммуникацией с клиентами в офисах туристической
              сети "Географии". Внедрение программы позволит вам оперативно и сегментировано работать с
              клиентской базой, автоматизировать отправку типовых и обязательных обращений клиентам. А
              также сохранить в копилке агентства каждое обращение и не потерять ни одного контакта
              потенциального клиента.</p>
<p><a href="/docs/geocrm(update 25.12.23).docx" target="_blank" rel="noopener noreferrer">Скачать полную инструкцию по CRM</a></p>
<p> </p>
<ul>
  <li><p><a href="/docs/Data_exchange_with_booking_system.pdf" target="_blank" rel="noopener noreferrer">Вход в CRM и выгрузка данных из СБ</a></p></li>
  <li><p><a href="/docs/Ways_to_contact_customers.pdf" target="_blank" rel="noopener noreferrer">Способы взаимодействия с клиентами</a></p></li>
  <li><p><a href="/docs/Regualr_and_potential_customers.pdf" target="_blank" rel="noopener noreferrer">Работа с постоянными и потенциальными клиентами</a></p></li>
  <li><p><a href="/docs/Segmentations_clients_Database.pdf" target="_blank" rel="noopener noreferrer">Сегментирование всей базы клиентов и работа с заявками</a></p></li>
  <li><p><a href="/docs/geoclient.pdf" target="_blank" rel="noopener noreferrer">Работа с геоподборками</a></p></li>
  <li><p><a href="/docs/Telegram_notifications.pdf" target="_blank" rel="noopener noreferrer">Настройка уведомлений CRM в телеграмм бот</a></p></li>
</ul>
<p> </p>
<p>CRM решает следующие задачи в работе туристической фирмы</p>
<ol>
  <li>
    <b>Работа с постоянными клиентами</b><br/>
    Клиентская база автоматически оперативно пополняемая, с возможностью сегментировать клиента
  </li>
  <li>
    <b>Работа с потенциальными клиентами</b><br/>
    Фиксация любого контакта в CRM, последующий контакт с «теплой базой»
  </li>
  <li>
    <b>Учет лидов</b><br/>
    Источник новых клиентов (понимание откуда приходит (лид) потенциальный клиент. Дает
возможность сосредоточится только на эффективных источниках продвижения)
  </li>
  <li>
    <b>Учет конверсии</b><br/>
    Дает понимание собственной эффективности (сколько контактов было, сколько перешло в покупку)
  </li>
  <li>
    <b>Рост конверсии</b><br/>
    Скорость и точность обработки заявки
  </li>
</ol>


{/* <h3>Оглавление</h3>
<p>Вход в систему</p>
<p>Выгрузка заявок из системы бронирования в CRM</p>
<p>Работа с постоянными клиентами</p>
<p>Работа с потенциальными клиентами</p>
<p>Заведение нового потенциального клиента</p>
<p>Редактирование данных</p>
<p>Слияние дублей потенциальных клиентов</p>
<p>Сегментирование базы клиентов</p>
<p>Работа с запросами</p>
<p>Завершение запроса</p>
<p>Перевод клиента из потенциальных в постоянные</p>
<p>Заявки с сайта</p>
<p>Способы взаимодействия с туристом</p>
<p>Настройка шаблона для email рассылки</p>
<p>Как делать рассылки</p>
<p>Email рассылка</p>
<p>Viber рассылка</p>
<p>SMS рассылка</p>
<p>Отправленные рассылки</p>
<p>Статистика отправленных рассылок</p>
<p>Сколько стоят рассылки</p>
<p>Как оплатить рассылки</p>
<p>Автоматические уведомления</p>
<p>Геоподборка</p>
<p>Добавление менеджера</p>
<p>Книга продаж</p> */}

<h3>Вход в систему</h3>
<p>Для входа в систему в CRM Геоклиент нужно перейти по ссылке https://crm.geograftour.com/
Ввести логин и пароль, выданный вам Управляющей компанией «География» и нажать кнопку
«Войти»</p>
<p><img alt="" src="/img/instructions/image2.png" height="200" /></p>
<p>Вторым этапом система предложит выбрать офис, к которому, привязан ваш аккаунт.<br/>
Если у вас два и более офиса, вы сможете выбрать нужный в выпадающем меню.</p>
<p><img alt="" src="/img/instructions/image3.png" height="150" /></p>
<p>После входа в систему, Вы попадаете на рабочий стол менеджера, где отображаются напоминания
из календаря, последние запросы от клиентов, список заявок в работе в системы бронирования
География, а также есть возможность оперативного заведения нового запроса.</p>
<p><img alt="" src="/img/instructions/image4.png" height="250" /></p>
<h3 id="link-002">Выгрузка заявок из системы бронирования в CRM</h3>
<p>Для синхронизации данных по заявкам и туристам из системы бронирования География необходимо
зайти в раздел «Все мои заявки» и кликнуть на кнопку «Загрузить мои заявки из СБ». Нажимаем
только один раз и ждем, процесс может занять некоторое время.</p>
<p><img alt="" src="/img/instructions/image5.png" height="250" /></p>
<p>Все туристы из заявок в системе бронирования будут автоматически перенесены в раздел
«Клиентская база».</p>
<h3>Работа с постоянными клиентами</h3>
<p>База туристов, которые уже покупали тур в вашем агентстве, хранится в разделе «Клиентская база».</p>
<p><img alt="" src="/img/instructions/image6.png" height="250" /></p>
<p>После загрузки клиентской базы необходимо привести её в порядок:</p>
<ul>
  <li>проверить данные туристов;</li>
  <li>добавить телефоны и электронные почты;</li>
  <li>исключения дублирующиеся контакты;</li>
  <li>проставить метки для сегментирования базы.</li>
</ul>
<p>При клике на ФИО туриста вы проваливаетесь в карточку, где можете посмотреть куда летал данный
клиент, завести новый запрос от него, проверить данные паспорта, а также отправить сообщение. По
необходимости можно посмотреть сделанные слияния и отменить их.</p>
<p>Чтобы внести изменения в данные туриста, нужно кликнуть на ссылку «редактировать»</p>
<p><img alt="" src="/img/instructions/image7.png" height="250" /></p>
<p>В открывшейся форме можно корректировать информацию (ФИО, адрес, номер телефона и прочее).</p>
<p><img alt="" src="/img/instructions/image8.png" height="350" /></p>
<p>В пункте «Метки» вы можете проставить любое количество меток. Есть метки системные (например,
с сайта, эконом, постоянный), но Вы можете придумать и создать собственные метки для разных
категорий своих туристов, это поможет делать рассылку согласно запросам.
Создать дополнительные метки можно в разделе «Клиенты по группам».</p>
<p><img alt="" src="/img/instructions/image9.png" height="250" /></p>

<h3>Работа с потенциальными клиентами</h3>


<h4>Заведение нового потенциального клиента</h4>
<p><b>1 вариант</b></p>
<p>С рабочего стола менеджера из раздела «Заведите нового потенциального клиента» выбираете
источник, откуда пришел клиент, вводите ФИО, контактные данные и ваши комментарии, далее
нажимаете кнопку «Создать заявку»</p>
<p><img alt="" src="/img/instructions/image10.png" height="250" /></p>
<p>На рабочий стол менеджера вынесены популярные источники для оперативного заведения запроса:</p>
<ul>
  <li>Соцсети</li>
  <li>Звонок в офис</li>
  <li>Звонок на сотовый</li>
  <li>Посетитель</li>
  <li>Обращение по рекомендации</li>
</ul>
<p><b>2 вариант</b></p>

<p>Выберите раздел «Запросы на туры» и кликните по кнопке «Добавить запрос». Введите всю
необходимую информацию о клиенте и название и текст запроса.</p>
<p><img alt="" src="/img/instructions/image11.png" height="250" /></p>
<p><img alt="" src="/img/instructions/image12.png" height="250" /></p>
<p>Все контактные данные обратившихся к вам клиентов, которые ещё не покупали у вас тур, хранятся в
разделе «Потенциальные клиенты». Здесь вы можете отключить автоматические уведомления или
отсортировать список, для отправки сегментированной рассылки.</p>
<p><img alt="" src="/img/instructions/image13.png" height="250" /></p>

<h2>Редактирование данных</h2>
<p>Чтобы отредактировать данные потенциально клиента, необходимо кликнуть на ФИО и далее на
ссылку «Отредактировать». В открывшемся окне внести необходимые корректировки. Обязательно
проставляй метки для сегментирования базы клиентов.</p>
<p><img alt="" src="/img/instructions/image14.png" height="250" /></p>

<p>Если потенциальный клиент уже к вам обращался, но ещё не покупал тур, из его карточки вы также
можете добавить новый запрос.</p>
<p><img alt="" src="/img/instructions/image16.png" height="250" /></p>

<h2>Слияние дублей потенциальных клиентов</h2>
<p>Часто бывает, что на одного человека заведено несколько карточек в разделе «Потенциальные
клиенты».</p>
<p>Чтобы произвести деуникализацию клиентов, заходим в раздел &quot;Потенциальные клиенты&quot;,
отмечаем галочками нужные строки дублирующихся данных клиента и нажимаем на кнопку
&quot;Создать слияние&quot;. Процесс слияния в среднем занимает 5 минут.</p>
<p><img alt="" src="/img/instructions/image17.png" height="100" /></p>

<p>В результате слияния, у вас останется только один контакт, в котором сохраняются все запросы из
разных карточек. Посмотреть или отметить слияние можно в карточке клиента в разделе &quot;Слияния&quot;.</p>
<p><img alt="" src="/img/instructions/image18.png" height="250" /></p>






<h2>Сегментирование базы клиентов</h2>
<p>В карточке потенциального или постоянного клиента вы можете проставить метки для
сегментирования клиентской базы. Например, для отправки туристам рассылки в соответствии с их
интересами и запросами.</p>

<h2>Работа с запросами</h2>
<p>Все запросы</p>
<h3>Завершение запроса</h3>
<p>Выбираем нужный запрос в разделе «Запросы на туры» и далее переходим на вкладку
&quot;Покупка/Отложить/Отказ&quot;. Выбираем пункт «Покупка тура» и пустом поле вводим номер заявки из
системы бронирования География и сохраняем.</p>
<p><img alt="" src="/img/instructions/image19.png" height="250" /></p>

<h3>Перевод клиента из потенциальных в постоянные</h3>
<p>На следующее утро на рабочем столе менеджера в блоке &quot;Перевод клиента из потенциальных в
постоянные&quot; вы увидите новый запрос на слияние.</p>
<p><img alt="" src="/img/instructions/image20.png" height="250" /></p>


<ul>
  <li>Правильный алгоритм действий:<br/> сначала сливаем дубли потенциальных клиентов. А уже
потом делаем слияние потенциального клиента и постоянного туриста.</li>
  <li>Когда работа с клиентом завершена и вы завели заявку в систему бронирования
  География, нужно закрыть запрос и объединить данные потенциального клиента и
  постоянного туриста.</li>
</ul>
<p><img alt="" src="/img/instructions/image22.jpeg" height="250" /></p>

<p>Кликаем на запрос и переходим во вкладку &quot;Слияние&quot; на голубом фоне. Раскрываем список туристов
из заявки и выбираем с каким туристом будем сливать данные потенциального клиента. Сохраняем.
Запрос будет выполнен в течение 5 минут.</p>
<p><img alt="" src="/img/instructions/image23.jpeg" height="250" /></p>

<p>Этот функционал создан для исключения дублирующихся контактов и сохранению единой статистики
по запросам и поездкам ваших туристов.</p>

<h2>Заявки с сайта</h2>
<p>Все заявки с сайта geograftour.com поступают только в CRM в раздел «Заявки с сайта».
Информирование о поступлении заявки в ваш офис производится в sms сообщении.</p>
<p>Очень важно правильно обрабатывать полученные заявки в CRM, чтобы Управляющая компания
видела, что работа по заявкам ведется.</p>
<p>Заявки с сайта автоматически фиксируются в базу потенциальных клиентов.</p>
<p><img alt="" src="/img/instructions/image24.jpg" height="250" /></p>

<p>Заявки с сайта могут поступать из источников:</p>
<ul>
  <li>чат – запрос от клиента из чата;</li>
  <li>горящая заявка – запрос от клиента на подбор тура в свободной форме;</li>
  <li>ЛК (офис) – отправка подобранного клиентом тура в офис;</li>
  <li>ЛК (покупка) – покупка тура на сайте.</li>
</ul>
<p><img alt="" src="/img/instructions/image25.png" height="250" /></p>

<p>После поступления новой заявки с сайта необходимо зайти в раздел «Заявки с сайта» нажать
на номер заявки со статусом «Новая» и в открывшемся окне в пункте «Назначить», выбрать
ФИО менеджера, который будет обрабатывать данный запрос.</p>
<p><img alt="" src="/img/instructions/image26.jpg" height="250" /></p>

<p>После этого этапа статус заявки изменится и станет «В работе», а в разделе «Запросы на
туры» появится новый запрос, с которым в дальнейшем нужно работать. Вы автоматически
будете перенаправлены в окно данного запроса.</p>
<p><img alt="" src="/img/instructions/image27.jpg" height="250" /></p>

<p>В запросе вы можете использовать вкладки:</p>
<ul>
  <li>
    «Выбрать менеджера» - назначить/ переназначить менеджера по заявке;
  </li>
  <li>
    «Комментарий» - оставить примечание/комментарий по работе с заявкой;
  </li>
  <li>
    «Назначить звонок» - выбрать дату, когда нужно перезвонить клиенту;
  </li>
  <li>
    «Встреча» - выбрать дату, когда назначена встреча с клиентом;
  </li>
  <li>
    «Отправить сообщение» - отправить email и SMS сообщение клиенту (услуга платная);
  </li>
  <li>
    «Покупка/Отложить/Отказ» - выбор нужно статуса запросу
  </li>
  <li>
    Покупка тура – завершить работу по запрос у при покупке тура, вводим номер заявки из системы
бронирования География
  </li>
  <li>
    Отложить до - перенести работу по заявке на более поздний срок
  </li>
  <li>
    Мы отказали - закрыть заявку в связи с невозможностью подобрать тур по запросу клиента
  </li>
  <li>
Клиент отказался - закрыть заявку в связи с отказом клиента
  </li>
  <li>
    По разделам «Назначить звонок» «Встреча» и «Отложить» в назначенные даты в календаре будет
отображаться напоминание.
  </li>
  <li>
«Выявление потребностей» - возможность сделать пометки по основным критериям подбора тура
для клиента.
  </li>
  <li>
«Подборка туров» - возможность отправки Геоподборки на email или по смс.
  </li>
</ul>
<p><img alt="" src="/img/instructions/image28.png" height="250" /></p>
 <h2>Способы взаимодействия с туристом</h2>
 <p>
Основные каналы для взаимодействия с туристом:
 </p>

 <ol>
   <li>E-mail рассылки</li>
   <li>SMS рассылки</li>
   <li>Viber рассылки</li>
   <li>Автоматические уведомления</li>
 </ol>
<p>
  Перед началом общения с клиентами по средствам рассылок, обязательно настройте контакты в
своем шаблоне для обращения клиента в ваш офис. Для этого нажимаем на значок шестеренки в
верхнем правом углу.
</p>
<p><img alt="" src="/img/instructions/image29.png" height="80" /></p>
<p>
  Выберите файл с фото или аватарку и подгрузите. Заполните ваш личный телефон, адрес эл. почты,
контакты для мессенджеров, а так же ссылки соцсетей и нажмите на кнопку «Сохранить».
</p>
<p><img alt="" src="/img/instructions/image30.png" height="250" /></p>

<p>Спустившись ниже на данной странице, вы сможете настроить шаблон письма для email рассылок.</p>
<h5>Настройка шаблона для email рассылки</h5>
<p><img alt="" src="/img/instructions/image31.png" height="100" /></p>

<p>В дальнейшем в этом же разделе можно будет внести изменения в шаблон.</p>
<p><img alt="" src="/img/instructions/image32.png" height="250" /></p>
<h2>Как делать рассылки</h2>

<p>Рассылки можно делать несколькими способами:</p>
<p>1) из раздела «СМС, Email рассылка»</p>
<p><img alt="" src="/img/instructions/image33.png" height="250" /></p>
<h4>Email рассылка</h4>
<p>В центре выберите подходящий для вас шаблон, справа укажите получателей рассылки (например,
по меткам, все туристы или через поиск туристов по имени, телефону, электронной почте).
Напишите тему рассылки и текст письма. Редактор позволяет отформатировать текст выделить текст
курсивом или жирным шрифтом, фирменными цветами, вставить ссылку, оформить списки и прочее.</p>
<p><img alt="" src="/img/instructions/image34.png" height="250" /></p>
<p>Кнопка «Предпросмотр» дает возможность увидеть письмо в том виде, в каком его получит ваш
клиент.</p>

<p>После проверки письма, нажимаем кнопку «Отправить»</p>

<h4>Viber рассылка</h4>

<p>Вводим заголовок рассылки, прикрепляем картинку. Рекомендуется загружать изображение с
размером 400x400 пикселей.</p>
<p>Пишем основной текст сообщения, указываем адрес офиса, контактный телефон и ФИ менеджера.</p>
<p>Заполняем Текст кнопки и вставляем ссылку для перехода при клике на кнопку.</p>
<p>В разделе «Предпросмотр» вы можете просмотреть как будет выглядеть ваше сообщение.</p>
<p>Если всё верно нажимаем «Отправить».</p>
<p><img alt="" src="/img/instructions/image35.png" height="250" /></p>

<h4>SMS рассылка</h4>
<p>В верхнем поле заполняем тему рассылки, эта информация только для вас. В центральной части
пишем сам текст сообщения. В правом поле выбираем туристов по метками, ФИО или всю базу.</p>
<p><img alt="" src="/img/instructions/image36.png" height="250" /></p>

<p>Любую рассылку вы можете отправить также из разделов «Клиентская база» или «Потенциальные
клиенты», сделав нужную выборку с помощью фильтров</p>
<p><img alt="" src="/img/instructions/image37.png" height="100" /></p>


<h4>Отправленные рассылки</h4>
<p>Все отправленные рассылки из CRM фиксируются в разделе «отправленные рассылки». Здесь можно
увидеть таблицу со всеми сформированными рассылками и увидеть их тип, тему, дату создания и
статус отправки.</p>
<p><img alt="" src="/img/instructions/image38.png" height="250" /></p>

<p>Более подробную статистику можно посмотреть при клике на тему рассылки или eё идентификатор.</p>


<h4>Статистика отправленных рассылок</h4>
<br/><br/>
<p>E mail рассылка</p>
<p><img alt="" src="/img/instructions/image39.png" height="250" /></p>
<p><img alt="" src="/img/instructions/image40.png" height="250" /></p>
<p>Вайбер рассылка</p>
<p><img alt="" src="/img/instructions/image41.png" height="250" /></p>
<h4>Сколько стоят рассылки</h4>
<p>Отправка сообщений осуществляется от имени География, вы не платите абонентскую плату и
фиксированные тарифы, только за отправленные вами сообщения.</p>
<p>Стоимость одного сообщения по каналам связи:</p>
<ul>
  <li>
E-mail - 0.2 руб.
  </li>
  <li>
Viber - 2.5 руб.
  </li>
  <li>
SMS - 4.0 руб.
  </li>
</ul>

<p>Списание (заморозка) средств с вашего баланса происходит автоматически, после получение
статистики система делает перерасчет, согласно доставленным сообщениям.</p>


<h4>Как оплатить рассылки</h4>
<p>Оплатить рассылки вы можете в разделе «Оплата рассылок». Если у вас ещё не добавлен Денежный счет просто нажмите на кнопку «Добавить денежный счет»</p>
<p><img alt="" src="/img/instructions/image42.png" height="250" /></p>
<p>При нажатий на Id денежного счёта или дату создания в открывщейся каточке есть возможность добавить счёт на оплату, указав сумму платежа. Или ознакомиться с информацией о внесенных платежах и расходах по рассылкам.</p>
<p><img alt="" src="/img/instructions/image43.png" height="250" /></p>
<p>Текущий баланс отображается в верхней строке.</p>
<p><img alt="" src="/img/instructions/image44.png" height="250" /></p>
<h4>Автоматические уведомления</h4>
<p>Всем клиентам автоматически рассылаются уведомления:</p>
<ul>
  <li>поздравление с днём рождения</li>
  <li>Вылет через 3 дня</li>
  <li>Вернулись с отдыха (2-3 день после возвращения)</li>
  <li>Срок оплаты тура истекает в течение 3 дней</li>
  <li>В течение 90 дней истекает срок загранпаспорта</li>
  <li>На 2 день отдыха</li>
</ul>
<p><img alt="" src="/img/instructions/image45.jpeg" height="250" /><img alt="" src="/img/instructions/image46.png" height="250" /></p>
<p><img alt="" src="/img/instructions/image47.png" height="250" /></p>

<p>Список уведомлений на текущую дату отражается в разделе «Уведомления клиентов»</p>
<p><img alt="" src="/img/instructions/image48.png" height="250" /></p>
<p>Список отправленных автоматических уведомлений вы можете посмотреть в разделе «Отправленные уведомления»</p>
<p><img alt="" src="/img/instructions/image49.png" height="250" /></p>
<p>Отметить отправку автоматических уведомлений можно для каждого туриста индивидуально в разделе «Клиентская база»</p>
<p><img alt="" src="/img/instructions/image50.png" height="120" /></p>
<h2>Геоподборка</h2>
<p>Для работы с Геоподборкой необходимо зайти в поисковик туров для агентов на сайте
турагентства География https://www.geograftour.com/agency/tour/</p>

<p>В правом верхнем углу нажимаем на значок и вводим логин и пароль от CRM Геоклиент</p>
<p><img alt="" src="/img/instructions/image52.jpg" height="100" /></p>
<p><img alt="" src="/img/instructions/image53.jpg" height="150" /></p>
<p>Если вы хотите запомнить логин и пароль – просто проставьте галочку в поле «Запомнить»</p>
<p>Теперь вы можете создавать собственные Геоподборки.</p>
<p>Задаем в поисковике туров нужные и в отображаемой выдаче, возле интересующего тура нажимаем на кнопку «Добавить».</p>
<p><img alt="" src="/img/instructions/image54.jpg" height="250" /></p>
<p>Добавляем необходимое количество туров для подборки. В дальнейшем вы сможете сохранить подборку, отредактировать её и внести дополнительные комментарии.</p>
<p><b>Редактирование подборки:</b></p>

<ul>
  <li>
<p>при клике на кнопку «Изменить», около выбранного предложения, можно назначить свою цену или прописать комментарий по отелю;</p>
<p><img alt="" src="/img/instructions/image55.jpg" height="250" /></p>
  </li>
  <li>
<p>при клике на кнопку «Добавлен» предложение можно удалить из подборки.</p>
<p><img alt="" src="/img/instructions/image56.jpg" height="150" /></p>
  </li>
</ul>

<p>Удалить один отель из подборки можно кликом на крестик возле названия отеля.</p>
<p><img alt="" src="/img/instructions/image57.jpg" height="250" /></p>
<p>Текущая подборка отображается в правом верхнем углу. Там же её можно очистить.</p>
<p><img alt="" src="/img/instructions/image58.jpg" height="250" /></p>

<p>После окончания формирования Геоподборки нажимаем на копку «Создать подборку в CRM».</p>
<p>Теперь переходим в CRM в раздел «Геоподборка», где вы сможете посмотреть и отредактировать созданную Геоподборку.</p>
<p><img alt="" src="/img/instructions/image59.jpg" height="250" /></p>

<p>При клике на название открывается подробная информация. Здесь Вы можете изменить название и внеси свои комментарии.</p>
<p><img alt="" src="/img/instructions/image60.jpg" height="250" /></p>

<p>Посмотреть, как выглядит Геоподборка, можно при нажатии на кнопку «Просмотр».</p>
<p><img alt="" src="/img/instructions/image61.jpg" height="250" /></p>

<p>Когда все изменения и комментарии сохранены, Вы можете оправить данную Геоподборку клиенту в WhatsApp, Viber, sms сообщением или на электронную почту непосредственно из CRM Геоклиент:</p>
<ol>
  <li><p>Отправка на WhatsApp осуществляется внизу из окна просмотра Геоподборки;</p>
  <p><img alt="" src="/img/instructions/image62.jpg" height="250" /></p>
  </li>
  <li><p>Отправка подборки по смс или электронной почты осуществляется из Запросов на туры во вкладке «Подборка туров». Просто выберите нужную Геоподборку, канал для отправки и нажмите на кнопку «Отправить».</p>
  <p><img alt="" src="/img/instructions/image63.jpg" height="250" /></p>
  </li>
</ol>


<h2>Добавление менеджера</h2>
<p>Добавление нового сотрудника вашего офиса производится в разделе «Мои сотрудники»</p>
<h4>Тип сотрудника</h4>
<p><b>Админ</b> – видит все заявки офиса, все запросы на туры, может распределять их между менеджерами.</p>
<p><b>Менеджер</b> – видит только свои заявки из ФСУ и свои запросы на туры</p>


<p>При клике в таблице на ФИО сотрудника и далее на ссылку «Редактировать» возможно
откорректировать данные (имя, фамилию, логин, электронную почту или тип сотрудника).</p>
<p>Если вы забыли или потеряли доступ к CRM можно восстановить его, обратившись в Управляющую компанию Геогарфия.</p>
<h2>Книга продаж</h2>
<p>В разделе Книга продаж собрана обучающая информация по продажам для менеджеров.</p>





          </section>
        </div>
    );

  }
}
