import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select-plus';
import { POSITIVE_ACTION_STATUSES, UsersAPI } from '../../api/Client';
import * as actions from "../../actions/actions";
import {
  requestManagersByStateLoaded,
} from "../../actions/actions";

class ManagersSelect extends Component {
  constructor(props) {
    super(props);
    this.handleManagersOnChange = this.handleManagersOnChange.bind(this);
  }

  handleManagersOnChange(v) {
    const selectedManager = v.map((manager) => {
      if (manager.hasOwnProperty('value')) {
        return manager.value;
      } else {
        return manager;
      }
    });
    this.props.changeManager(selectedManager);
  }

  componentDidMount() {
    let self = this;
    let status;
    UsersAPI.fetchJSON(`full_list/`)
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        let data = status === POSITIVE_ACTION_STATUSES.list ? r : [],
          managers_data = [];
        let managers_opt = [];
        data.forEach(function (manager) {
          managers_opt.push({
            value: manager.id,
            label:
              !manager.last_name && !manager.first_name
                ? manager.username
                : [manager.last_name, manager.first_name].join(' '),
          });
        });

        managers_data.push({
          options: managers_opt,
        });
        self.props.requestManagersByStateLoaded(managers_data);
      });

    if (this.props.manager_selected) {
      this.handleManagersOnChange(this.props.manager_selected);
    }
  }

  render() {
    return (
      <div>
        <Select
          multi={this.props.multi}
          disabled={!!this.props.disabledSelect}
          onChange={this.handleManagersOnChange}
          options={this.props.managers.data}
          placeholder="Выберите менеджера"
          isLoading={!this.props.managers.isFetching}
          value={this.props.manager_selected}
        />
      </div>
    );
  }
}

ManagersSelect.displayName = "ManagersSelect";

const mapStateToProps = function (store) {
  return {
    managers: store.managers,
  };
};

const mapDispatchToProps = {
  requestManagersByStateLoaded,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManagersSelect);
