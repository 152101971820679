import React, {Component} from 'react'
import ClientAPI, { AnswersAPI } from '../../api/Client';
import BackButton from '../../components/BackButton';

export default class Issue extends Component {
    constructor(props) {
        super(props);
        this.onCreate = this.onCreate.bind(this);
        this.state = {
            id: this.props.match.params.number,
            title: '',
            text: '',
            response: { 
                source: null,
                text: null,
                id: null,
                status: null,
                title: null,
                type: null,
                person: null,
                tourist: null,
                manager: null,
                created: null,
                resolved: null,
                answers: null
            }
        }
    }
    onCreate() {
        this.props.onCreate()
    }
    componentDidMount() {
        const self = this
        ClientAPI.getJSON('/issues/' + this.props.match.params.number)
            .then((response) => {
                console.log(response);
                self.setState({
                    ...self.state,
                    response
                })
            });
    }
    render() {
        let { source, text, id, status, title, type, person, tourist, manager, created, resolved, answers } = this.state.response
        console.log(this.state)
        return (
            <div className="issues">
                <h2>Запрос # {id}</h2>
                <p>источник: {source}</p>
                <p>статус: {status}</p>
                <p>название: {title}</p>
                <p>текст запроса: {text}</p>
                <p>клиент: {type}</p>
                <p>создан: {created}</p>
                <p>выполнен: {resolved}</p>
                <BackButton  {...this.props}/>
            </div>
        )
    }
}