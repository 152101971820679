import React from 'react';
import {Link} from 'react-router-dom';
import {Collapse} from 'reactstrap';
import Paginator from '../../../components/Paginator';
import APIClient, {
  CalendarApi,
  POSITIVE_ACTION_STATUSES,
  RequestTouristNotifyAPI,
} from '../../../api/Client';
import moment from 'moment';
import 'moment/locale/ru';
import Toggle from './Toggle';

export const BIRTHDAY = 'birthday';
export const PASSPORT_EXPIRES = 'passportexpires';
export const PAY_EXPIRES = 'payexpires';
export const VISA_APPLICATION_DEADLINE = 'docforvizaexpires';
export const ARRIVED_FROM_REST = 'comefromrest';
export const DEPARTURE_TOMORROW = 'flytomorrow';
export const DURING_REST = 'duringrest';

export const NOTIFICATION_FIELD_LANG = {
  [BIRTHDAY]: 'Дата рождения',
  [PASSPORT_EXPIRES]: 'Действителен до',
  [PAY_EXPIRES]: 'Дата оплаты',
  [VISA_APPLICATION_DEADLINE]: 'Дата вылета',
  [ARRIVED_FROM_REST]: 'Обратный вылет',
  [DEPARTURE_TOMORROW]: 'Дата вылета',
  [DURING_REST]: 'Дата вылета',
};
const PER_PAGE = 5;

class NotifyType extends React.Component {
  constructor(props) {
    super(props);
    this.toggleEmailNotify = this.toggleEmailNotify.bind(this);
    this.toggleSmsNotify = this.toggleSmsNotify.bind(this);
    this.toggleNotify = this.toggleNotify.bind(this);
    this.toggleRequestNotify = this.toggleRequestNotify.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.fetchItems = this.fetchItems.bind(this);
    this.toggle = this.toggle.bind(this);

    this.state = {
      count: 0,
      results: [],
      next: '',
      prev: '',
      pageNumber: 1,
      collapse: false,
    };
  }

  toggle() {
    this.setState({collapse: !this.state.collapse});
  }

  toggleNotify(e, type) {
    const self = this;
    let status;
    const infoId = parseInt(e.target.title, 10);
    if (!isNaN(infoId)) {
      APIClient.
          update(`/touristsfp_info/${infoId}`, {[`is_${type}_notify`]: e.target.checked}).
          then(r => {
            status = r.status;
            return r.json();
          }).
          then(res => {
            if (status === POSITIVE_ACTION_STATUSES.update && self.state.results) {
              let isUpdate = false;
              const results = self.state.results.map((item) => {
                const data = item;
                if (data.tourist_fp_info.id === infoId) {
                  data.tourist_fp_info[`is_${type}_notify`] = res[`is_${type}_notify`];
                  isUpdate = true;
                }
                return data;
              });

              if (isUpdate) {
                self.setState({
                  results: results,
                });
              }
            }
          });
    }
  }

  toggleRequestNotify(e) {
    const self = this;
    const infoId = parseInt(e.target.title, 10);

    if (!isNaN(infoId)) {
      let status;
      const {type} = self.props;
      let index = -1;
      const tourist = self.state.results.find((item, i) => {
        if (item.id === infoId) {
          index = i;
          return true;
        }
        return false;
      });
      if (index !== -1) {
        if (!!tourist.request_notifies && tourist.request_notifies.id) {
          RequestTouristNotifyAPI.
              delete(tourist.request_notifies.id).
              then(r => {
                status = r.status;
                if (status === POSITIVE_ACTION_STATUSES.destroy) {
                  return {};
                }
                return r.json();
              }).
              then(r => {
                const results = self.state.results.slice(0);
                if (status === POSITIVE_ACTION_STATUSES.destroy) {
                  results[index].request_notifies = r;
                  self.setState({
                    ...self.state,
                    results: results,
                  });
                }
              });
        } else {
          RequestTouristNotifyAPI.
              create({tourist: infoId, notification_type: type}).
              then(r => {
                status = r.status;
                return r.json();
              }).
              then(r => {
                const results = self.state.results.slice(0);
                if (status === POSITIVE_ACTION_STATUSES.create) {
                  results[index].request_notifies = r;
                  self.setState({
                    results: results,
                  });
                }
              });
        }
      }
    }
  }

  toggleEmailNotify(e) {
    this.toggleNotify(e, 'email');
  }

  toggleSmsNotify(e) {
    this.toggleNotify(e, 'sms');
  }

  fetchItems(page = 1) {
    const day = moment(new Date());
    CalendarApi.
        fetchList(page, {day: day.format('YYYY-MM-DD')}, `${this.props.type}/`).
        then(r => r.json()).
        then((res) => {
          this.setState({
            count: res.count,
            results: res.results,
            next: res.next,
            prev: res.prev,
          }, () => {
            this.props.calc(res.count);
          });
        });
  }

  handlePageChange(data) {
    let page = data.selected + 1;
    this.setState({
          pageNumber: page,
        }, () => this.fetchItems(page),
    );
  }

  componentDidMount() {
    this.fetchItems(1);
  }

  render() {
    const special = [VISA_APPLICATION_DEADLINE, PAY_EXPIRES, PASSPORT_EXPIRES];
    const {type, iconClass, source} = this.props;
    const isShowPanel = special.indexOf(type) !== -1;
    const {results, count, collapse} = this.state;
    return (
        <div>
          <div className="clickable" onClick={this.toggle}>
            <i className={`fa ${iconClass} fa-sm mr-2`}> </i>
            <span className="">{source}</span>
            <span className="float-right pink-bg badge badge-success badge-pill">{count || 0}</span>
          </div>
          <Collapse isOpen={collapse}>
            {count ? (
                <div>
                  <table className="table table-responsive-sm table-sm">
                    <tbody>
                    <tr>
                      <th>Фамилия Имя</th>
                      <th>{NOTIFICATION_FIELD_LANG[type]}</th>
                      <th>Для всех оповещений</th>
                      <th hidden={!isShowPanel}>Оповестить сегодня</th>
                      <th>Оповещен</th>
                    </tr>
                    {results.map((item, idx) => {
                      const requestNotifies = !!item.request_notifies ? item.request_notifies : {};
                      const touristInfo = !!item.tourist_fp_info ? item.tourist_fp_info : {};
                      const notifies = !!item.notifies ? item.notifies : [];

                      return (
                          <tr key={idx}>
                            <td>
                              <Link to={`/tourists/${item.id}`}>{item.full_name}</Link>
                            </td>
                            <td>{
                              !item.char_value ? `` : moment(item.char_value).format('DD.MM.YYYY')
                            }</td>
                            <td>
                              <div>
                                <Toggle
                                    id={touristInfo.id}
                                    lang="Email"
                                    checked={touristInfo.is_email_notify}
                                    onChange={this.toggleEmailNotify}
                                />
                                <Toggle
                                    id={touristInfo.id}
                                    lang="SMS"
                                    checked={touristInfo.is_sms_notify}
                                    onChange={this.toggleSmsNotify}
                                />
                              </div>
                            </td>
                            <td hidden={!isShowPanel}>
                              <div>
                                <Toggle
                                    switchClass="switch-warning"
                                    id={item.id}
                                    lang=""
                                    checked={!!requestNotifies.id}
                                    onChange={this.toggleRequestNotify}
                                />
                              </div>
                            </td>
                            <td>
                              {notifies.length ? notifies.map((item, idx) => {
                                return (
                                    <div key={idx}>
                                      <span className="badge badge-success">
                                        {moment(item.created).format('DD.MM.YYYY')} {item.mailing_type}
                                      </span>
                                    </div>
                                );
                              }) : <span className="badge badge-default">-</span>}
                            </td>
                          </tr>
                      );
                    })}
                    </tbody>
                  </table>
                  <Paginator total={this.state.count} onPageChange={this.handlePageChange} perPage={PER_PAGE}/>
                </div>
            ) : ``}
          </Collapse>
        </div>
    );
  }
}

export default NotifyType;
