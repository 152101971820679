import React, {Component} from 'react';
import {Card, CardBlock, CardFooter, CardHeader, Row} from 'reactstrap';

import FieldErrorViewer from '../../Passports/components/FieldErrorViewer';
import OfficeSelectContainer from '../../../components/OfficeSelectContainer';

export default class AgencyForm extends Component {
  constructor(props) {
    super(props);
    this.formTextChange = this.formTextChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onChangeOffice = this.onChangeOffice.bind(this);
    this.onChangeOfficeCity = this.onChangeOfficeCity.bind(this);
    this.state = {
      fp_pk: '',
      offices: [],
      offices_city: null,
    };
  }

  onChangeOffice(offices) {this.setState({offices})}
  onChangeOfficeCity(offices_city) {this.setState({offices_city})}

  formTextChange(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  handleSubmit() {
    const {onCreateAgency} = this.props;
    onCreateAgency(this.state);
  }

  render() {
    return (
        <Card>
          <CardHeader>
            <strong>Создать</strong> агентство
          </CardHeader>
          <CardBlock>
            <form action="" method="post">
              <div className="form-group">
                <label htmlFor="public_name">ФСУ Id</label>
                <input type="text" className="form-control" id="fp_pk" placeholder="4000"
                       name="fp_pk" value={this.state.fp_pk} onChange={this.formTextChange}/>
                <label className="col-md-2 col-form-label">
                  Выберите офис
                </label>
                <OfficeSelectContainer
                    selected_city={this.state.offices_city}
                    selected_office={this.state.offices}
                    changeCity={this.onChangeOfficeCity}
                    changeOffice={this.onChangeOffice}
                    multi_office={true}
                />

              </div>
            </form>
          </CardBlock>
          <CardFooter>
            <button type="submit" className="btn btn-sm btn-warning" onClick={this.handleSubmit}>
              <i className="fa fa-dot-circle-o">{''}</i> Создать
            </button>
          </CardFooter>
        </Card>
    );
  }
}
