import React, {Component} from 'react';
import {Alert} from 'reactstrap';
import BackButton from '../../components/BackButton';
import {Redirect} from 'react-router-dom';
import {SendPulseAPI, OfficeAPI, POSITIVE_ACTION_STATUSES} from '../../api/Client';
import FieldErrorViewer from '../Passports/components/FieldErrorViewer';

const TIMEOUT = 2000;

export default class SendPulseSetting extends Component {
  constructor(props) {
    super(props);

    this.fetchData = this.fetchData.bind(this);
    this.onSaveData = this.onSaveData.bind(this);
    this.onHandleChecked = this.onHandleChecked.bind(this);
    this.onHandleInput = this.onHandleInput.bind(this);
    this.onDeleteData = this.onDeleteData.bind(this);
    this.getDefaultState = this.getDefaultState.bind(this);
    this.getDataByResult = this.getDataByResult.bind(this);

    this.state = this.getDefaultState();
  }

  getDefaultState() {
    return {
      id: 0,
      key: '',
      secret_key: '',
      sms_sender_name: '',
      sender_name: '',
      sending_email: '',

      errorMessages: {},
      isSuccess: false,
      isError: false,
      isDisabledButtons: false,
      successMessage: '',
      isRedirect: false,
      action: '',
    };
  }

  getDataByResult(result) {
    return {
      id: result.id,
      key: result.key,
      secret_key: result.secret_key,
      sms_sender_name: result.sms_sender_name,
      sender_name: result.sender_name,
      sending_email: result.sending_email,
    };
  }

  fetchData() {
    let status;
    OfficeAPI.
        fetchJSON(`${this.props.match.params.number}/sendpulse/`).
        then((r) => {
          status = r.status;
          if (status === POSITIVE_ACTION_STATUSES.retrieve) {
            return r.json();
          } else {
            return {};
          }
        }).
        then((result) => {
          if (status === POSITIVE_ACTION_STATUSES.retrieve) {
            this.setState(Object.assign(
                !!result && !!result.sendpulse_sendpulsesetting
                    ? this.getDataByResult(result.sendpulse_sendpulsesetting)
                    : {},
                {
                  name: result.name,
                  errorMessages: {},
                  successMessage: '',
                  isSuccess: false,
                  isError: false,
                },
            ));
          }
        });
  }

  onSaveData(e) {
    e.preventDefault();
    let status;
    const responseHandler = (r) => {
      status = r.status;
      return r.json();
    };
    let action;
    const resultHandler = (r) => {
      if (POSITIVE_ACTION_STATUSES[action] === status) {
        this.setState({
          errorMessages: {},
          successMessage: action === 'create'
              ? 'Настройки созданы. Вы будете перенаправлены.'
              : 'Настройки обновлены.',
          isSuccess: true,
          isError: false,
          isDisabledButtons: action === 'create',
        }, () => {
          if (action === 'create') {
            setTimeout(() => {
              this.props.history.push(`/options/`);
            }, TIMEOUT);
          }
          setTimeout(() => {
            this.fetchData();
          }, TIMEOUT);
        });
      } else {
        this.setState({
          errorMessages: r,
          successMessage: '',
          isDisabledButtons: false,
          isSuccess: false,
          isError: true,
        });
      }
    };
    const data = {
      key: this.state.key,
      secret_key: this.state.secret_key,
      sms_sender_name: this.state.sms_sender_name,
      sender_name: this.state.sender_name,
      sending_email: this.state.sending_email,
    };

    if (this.state.id) {
      action = `partial_update`;
      this.setState({
        action: action,
        isDisabledButtons: true,
      }, () => {
        SendPulseAPI.
            update(this.state.id, data).
            then(responseHandler).
            then(resultHandler);
      });
    } else if (!this.state.id) {
      action = `create`;
      data.office = this.props.match.params.number;
      this.setState({
        action: action,
        isDisabledButtons: true,
      }, () => {
        SendPulseAPI.
            create(data).
            then(responseHandler).
            then(resultHandler);
      });
    }
  }

  onDeleteData(e) {
    e.preventDefault();
    let status;
    this.setState({
      action: 'destroy',
      isDisabledButtons: true,
    }, () => {
      SendPulseAPI.
          delete(this.state.id).
          then((r) => {
            status = r.status;
            if (POSITIVE_ACTION_STATUSES.destroy !== status) {
              return r.json();
            }
            return {};
          }).
          then((r) => {
            if (POSITIVE_ACTION_STATUSES.destroy === status) {
              this.setState({
                errorMessages: {},
                successMessage: 'Удаление произведено успешно. Вы будете перенаправлены.',
                isSuccess: true,
                isError: false,
              }, () => {
                setTimeout(() => {
                  this.props.history.push(`/options/`);
                }, TIMEOUT);
              });
            } else {
              this.setState({
                errorMessages: r,
                successMessage: '',
                isSuccess: false,
                isError: true,
              });
            }
          });
    });
  }

  onSubmit(e) {
    e.preventDefault();
    this.onSaveData();
  }

  onHandleChecked(e) {
    this.setState({
      [e.target.name]: e.target.checked,
      isSuccess: false,
      isError: false,
    });
  }

  onHandleInput(e) {
    this.setState({
      [e.target.name]: e.target.value,
      isSuccess: false,
      isError: false,
    });
  }

  componentDidMount() {
    this.fetchData();
  }

  render() {
    const {
      errorMessages,
      isSuccess,
      isError,
      successMessage,
      isDisabledButtons,
      id,
      name,
      isRedirect,
      action,
    } = this.state;

    return (
        <div className="card">
          <form id="send-pulse-settings" onSubmit={this.onSubmit}>
            <div className="card-block">
              <div className="form-group row">
                <label className="text-right col-md-2 ">Офис: </label>
                <div className="col-md-6">
                  {name}
                  <FieldErrorViewer errorMessages={errorMessages} field="office"/>
                </div>
              </div>
              {
                [
                  {
                    name: 'line_null',
                    lang: 'Ключи',
                    isField: false,
                  },
                  {
                    name: 'key',
                    type: 'text',
                    lang: 'Ключ API',
                    isField: true,
                  },
                  {
                    name: 'secret_key',
                    type: 'text',
                    lang: 'Секретный ключ',
                    isField: true,
                  },
                  {
                    name: 'line_one',
                    lang: 'Настройки для СМС рассылок',
                    isField: false,
                  },
                  {
                    name: 'sms_sender_name',
                    type: 'text',
                    lang: 'Наименование смс отправителя',
                    isField: true,
                  },
                  {
                    name: 'line_two',
                    lang: 'Настройки для Email рассылок',
                    isField: false,
                  },
                  {
                    name: 'sender_name',
                    type: 'text',
                    lang: 'Наименование отправителя',
                    isField: true,
                  },
                  {
                    name: 'sending_email',
                    type: 'text',
                    lang: 'Email отправитель',
                    isField: true,
                  },
                ].map((item, idx) => {
                  if (item.isField) {
                    if (item.type === 'checkbox') {
                      return (
                          <div className="form-group form-check row" key={idx}>
                            <div className="ml-4 mb-4 col-md-6">
                              <input
                                  type={item.type}
                                  id={`input-${item.name}-${idx}`}
                                  name={item.name}
                                  checked={this.state[item.name]}
                                  onChange={this.onHandleChecked}
                                  className="form-check-input"
                              />
                              <label className="form-check-label" htmlFor={`input-${item.name}-${idx}`}>{
                                item.lang
                              }:</label>
                              <FieldErrorViewer errorMessages={errorMessages} field={item.name}/>
                            </div>
                          </div>
                      );
                    }

                    return (
                        <div className="form-group row" key={idx}>
                          <label htmlFor={`input-${item.name}-${idx}`} className="text-right col-md-2 col-form-label">{
                            item.lang
                          }:</label>
                          <div className="col-md-6">
                            <input
                                type={item.type}
                                id={`input-${item.name}-${idx}`}
                                name={item.name}
                                value={this.state[item.name]}
                                onChange={this.onHandleInput}
                                className="form-control"
                            />
                            <FieldErrorViewer errorMessages={errorMessages} field={item.name}/>
                          </div>
                        </div>
                    );
                  }

                  return <Alert color="info" key={idx}>{item.lang}</Alert>;
                })
              }

              {isSuccess ? <Alert color="success">{successMessage}</Alert> : ``}
              {isError && !!errorMessages.non_field_errors
                  ? errorMessages.non_field_errors.map((item, idx) => {
                    return <Alert color="warning" key={idx}>{item}</Alert>;
                  }) : ``}
              {isError && !!errorMessages.detail
                  ? <Alert color="warning">Ошибка запроса: {errorMessages.detail}</Alert>
                  : ``}
            </div>
            <div className="card-footer">
              <BackButton {...this.props}/>
              <button
                  id="button-apply"
                  className="btn btn-success"
                  type="submit"
                  onClick={this.onSaveData}
                  disabled={isDisabledButtons}
              >
                Применить
              </button>
              {id ? (
                  <button
                      id="button-delete"
                      className="btn btn-danger float-right"
                      onClick={this.onDeleteData}
                      type="button"
                      disabled={isDisabledButtons}
                  >
                    Удалить
                  </button>
              ) : ``}
            </div>
          </form>
        </div>
    );
  }
}
