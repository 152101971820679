import React, { Component } from 'react';
import 'moment/locale/ru';
import Select from 'react-select-plus';
import {
  POSITIVE_ACTION_STATUSES,
  CabinetAPI,
  TouristFpAPI,
  OrderFpAPI,
  PersonAPI,
  IssuesAPI,
} from '../../api/Client';
import { Alert, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { Card, CardBlock, CardHeader } from 'reactstrap';
import OrdersFpTable from '../../views/OrdersFpTable';
import Issues from '../Issues/Issues';
import classnames from 'classnames';
import moment from 'moment';
import {
  AiFillCreditCard,
  AiFillEye,
  AiFillHeart,
  AiFillProfile,
  AiFillShopping,
} from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { getUserRole } from '../../connect/auth';
const CLIENTS_TYPES = [
  { label: 'Постоянный клиент', value: 'tourist' },
  { label: 'Потенциальный клиент', value: 'person' },
];

export default class TouristCabinet extends Component {
  constructor(props) {
    super(props);
    this.fetchTouristCabinet = this.fetchTouristCabinet.bind(this);
    this.handleComment = this.handleComment.bind(this);
    this.handleCommentSave = this.handleCommentSave.bind(this);
    this.handleCommentReset = this.handleCommentReset.bind(this);
    this.handleRedirect = this.handleRedirect.bind(this);
    this.findCabinetTouristInTouristFP =
      this.findCabinetTouristInTouristFP.bind(this);
    this.findTouristId = this.findTouristId.bind(this);
    this.getOrders = this.getOrders.bind(this);
    this.updateIssues = this.updateIssues.bind(this);
    this.handleChangeClientType = this.handleChangeClientType.bind(this);
    this.handlePersonSearch = this.handlePersonSearch.bind(this);
    this.connectPersonToCabinet = this.connectPersonToCabinet.bind(this);
    this.setErrorMessage = this.setErrorMessage.bind(this);
    this.getIssues = this.getIssues.bind(this);
    this.touristCabinetId = this.state = {
      comment: '',
      isCommentSuccess: false,
      data: {},
      status: '',
      linkToLK: '',
      activeTab: '1',
      touristCabinetId: this.props.match.params.number,
      email: '',
      userRole: getUserRole(),
      touristsFp: [],
      clientType: '',
      searchValue: '',
      personData: [],
      searchError: '',
    };
  }

  fetchTouristCabinet() {
    const { touristCabinetId, data } = this.state;
    CabinetAPI.getInfo(`${touristCabinetId}`).then((r) => {
      this.setState({
        data: r || {},
        status: r.status,
        email: r.email_registered,
        cabinetOrders: r.orders_ids,
      });
      if (r.orders_ids.length > 0) {
        this.findTouristId(r.orders_ids);
      }
      if (r.touristfp || r.person) {
        this.getOrders();
        this.getIssues();
      }
      this.findCabinetTouristInTouristFP(r.email_registered);
    });
  }

  findTouristId = async (orders) => {
    const filter = {
      fp_pk: orders[0],
    };
    OrderFpAPI.fetchList(1, filter)
      .then((r) => {
        if (r.ok) {
          return r.json();
        }
      })
      .then((r) => {
        const { results, count } = r;
        this.setState({
          results: results || [],
          count,
        });
        if (count >= 1) {
          this.setState({
            touristsFp: results[0].tourists_fp,
          });
          console.log(this.state.touristsFp);
        }
      });
  };

  findCabinetTouristInTouristFP(email) {
    const filter = { q: email };

    TouristFpAPI.fetchList(1, filter)
      .then((r) => {
        return r.json();
      })
      .then((result) => {
        if (0 < result.count > 2) {
          this.setState({
            items: result.results || [],
            count: result.count || 0,
            pageNumber: page,
            selected: [],
            mergerIsError: false,
            mergerIsSuccess: false,
            deactivateIsSuccess: false,
            errorMessages: [],
            toggleErrors: {},
            isDisabledFilter: status !== POSITIVE_ACTION_STATUSES.list,
            isLoading: false,
          });
        }
      });
  }

  getOrders(page = 1) {
    const { touristfp } = this.state.data;
    if (touristfp) {
      TouristFpAPI.fetchList(page, {}, `${touristfp.id}/orders/`)
        .then((r) => {
          return r.json();
        })
        .then((result) => {
          if (POSITIVE_ACTION_STATUSES.list) {
            this.setState({
              orders: result.results || {
                results: [],
                count: 0,
              },
            });
          }
        });
    }
  }

  handleComment(e) {
    this.setState({ comment: e.target.value });
  }

  handleCommentSave() {
    const pk = this.props.match.params.number;
    const data = {
      text: this.state.comment,
      type: 'comment',
    };
    CabinetAPI.comment(pk, data)
      .then((r) => r.json())
      .then((res) => {
        this.setState({
          data: {
            ...data,
            comments: [res, ...this.state.data.comments],
          },
        });
        if (res.text) {
          this.setState(
            {
              isCommentSuccess: true,
            },
            () => {
              setTimeout(this.handleCommentReset, 3000);
            }
          );
        }
      });
  }

  handleCommentReset() {
    this.setState(
      {
        comment: '',
        isCommentSuccess: false,
      },
      this.props.onUpdate
    );
  }

  handleRedirect() {
    const { touristCabinetId } = this.state;
    CabinetAPI.getInfo(`${touristCabinetId}/login_to_user_cabinet`).then(
      (r) => {
        this.setState({
          linkToLK: r.redirect || {},
          status: r.status,
          email: r.email_registered,
        });
      }
    );
  }

  connectTouristToCabinet(id) {
    const { touristCabinetId } = this.state;
    const data = { touristfp: id };
    CabinetAPI.modify(touristCabinetId, data).then((r) => {
      this.setState({
        data: r,
        status: r.status,
        email: r.email_registered,
        cabinetOrders: r.orders_ids,
      });
      this.fetchTouristCabinet();
    });
  }

  connectPersonToCabinet(id) {
    const { touristCabinetId } = this.state;
    const data = { person: id };
    CabinetAPI.modify(touristCabinetId, data).then((r) => {
      this.setState({
        data: r,
        status: r.status,
        email: r.email_registered,
        cabinetOrders: r.orders_ids,
      });
      this.fetchTouristCabinet();
    });
  }

  updateIssues() {
    this.getIssues();
  }

  getIssues(page = 1) {
    const { touristfp, person } = this.state.data;
    if (touristfp) {
      TouristFpAPI.fetchJSON(`${touristfp.id}/`)
        .then((r) => {
          return r.json();
        })
        .then((r) => {
          this.setState({
            touristInfo: {
              fullName: r.full_name || '',
              emails: r.email || [],
              phones: r.phone || [],
              email: r.email || [],
              phone: r.phone || [],
              comment: r.comment || '',
              fpPk: r.fp_pk,
              itemId: touristfp.id,
            },
          });
        });

      TouristFpAPI.fetchList(page, {}, `${touristfp.id}/issues/`)
        .then((r) => {
          return r.json();
        })
        .then((r) => {
          if (POSITIVE_ACTION_STATUSES.list) {
            this.setState({
              touristIssues: r.results || {
                results: [],
                count: 0,
              },
            });
          }
        });
    }
    if (person) {
      PersonAPI.getInfo(person.id).then((r) => {
        this.setState({
          personInfo: {
            id: r.id,
            info: r || {},
            name: r.name || '',
            middle_name: r.middle_name || '',
            last_name: r.last_name || '',
            phones: r.phones || [],
            emails: r.emails || [],
            phone: r.phone || [],
            email: r.email || [],
            born: r.born || '',
            passport_series: r.passport_series || '',
            passport_number: r.passport_number || '',
            passport_expires: r.passport_expires || '',
            person_info: r.info || '',
            tag: r.tag || [],
          },
        });
      });
      IssuesAPI.getList(page, { person: person.id }).then((result) => {
        this.setState({
          ...this.state,
          personIssues: result.results,
        });
      });
    }
  }

  handleChangeClientType(name, value) {
    this.setState({
      [name]: value,
    });
  }

  handlePersonSearchInput(value) {
    this.setState({
      searchValue: value,
    });
  }

  setErrorMessage(value) {
    this.setState({ searchError: value });
  }

  handlePersonSearch(value) {
    this.setErrorMessage('');
    PersonAPI.fetchList(1, { q: value })
      .then((r) => {
        return r.json();
      })
      .then((res) => {
        if (res.count === 0) {
          this.setErrorMessage(`По запросу "${value}", ничего не найдено`);
        }
        this.setState({
          personData: res.results,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentDidMount() {
    this.fetchTouristCabinet();
    this.handleRedirect();
  }

  toggleTab(tab) {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const {
      data,
      linkToLK,
      activeTab,
      personIssues,
      touristIssues,
      touristsFp,
      cabinetOrders,
      orders,
      clientType,
      searchError,
    } = this.state;

    if (!Object.keys(data).length) {
      return null;
    }
    return (
      <div className="animated fadeIn filter filter--green">
        <Card>
          <CardHeader>
            <h4>
              {data.last_name} {data.name}
            </h4>
          </CardHeader>
          <CardBlock>
            <div className="row">
              <div className="col-md-6 col-lg-6 mb-4">
                <h5>Инфо о туристе</h5>
                <p>
                  ID в кабинете: <b>{data.id}</b>
                </p>
                <p>
                  Фамилия: <b>{data.last_name}</b>
                </p>
                <p>
                  Имя: <b>{data.name}</b>
                </p>
                <p>
                  Последняя активность:{' '}
                  <b> {moment(data.dt_sync).format('DD.MM.YY HH:mm')} </b>
                </p>
                <p>
                  Зарегистрировался:{' '}
                  <b> {moment(data.dt_registered).format('DD.MM.YY HH:mm')}</b>
                </p>
                <p>
                  Электронная почта при регистрации:{' '}
                  <b>{data.email_registered}</b>
                </p>
                <p>
                  Электронная почта из настроек ЛК: <b>{data.email_settings}</b>
                </p>
                <p>
                  Логин: <b>{data.username}</b>
                </p>
                <p>
                  Телефон: <b>{data.phone_settings}</b>
                </p>
              </div>
              <div className="col-md-3 col-lg-3 mb-4">
                <h5>Инфо из ЛК</h5>
                <div>
                  <p>
                    <AiFillEye />
                    &nbsp; Просмотрено: <b>&nbsp; {data.count_seen}</b>
                  </p>
                  <p>
                    <AiFillHeart />
                    &nbsp; Понравилось: <b>&nbsp; {data.count_likes}</b>
                  </p>
                  <p>
                    <AiFillShopping />
                    &nbsp; В корзине: <b>&nbsp; {data.count_cart}</b>
                  </p>
                  <p>
                    <AiFillCreditCard />
                    &nbsp; Заказов: <b>&nbsp; {cabinetOrders.length}</b>
                  </p>
                  <p>
                    <AiFillProfile />
                    &nbsp; Постоянный клиент:{' '}
                    <b>
                      &nbsp;{' '}
                      {data.touristfp ? (
                        <Link
                          target="_blank"
                          to={`../../tourists/${data.touristfp.id}`}
                        >
                          {data.touristfp.full_name}
                        </Link>
                      ) : (
                        'Не привязан'
                      )}
                    </b>
                  </p>
                  <p>
                    <AiFillProfile />
                    &nbsp; Потенциальный клиент:{' '}
                    <b>
                      &nbsp;{' '}
                      {data.person ? (
                        <Link
                          target="_blank"
                          to={`../../persons/${data.person.id}`}
                        >
                          {data.person.fio}
                        </Link>
                      ) : (
                        'Не привязан'
                      )}
                    </b>
                  </p>
                </div>
              </div>
              <div className="col-md-1 col-lg-1 mb-1">
                <a
                  href={linkToLK}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-sm btn-primary"
                >
                  Перейти в ЛК на сайте
                </a>
              </div>
            </div>
            <div className="mb-3">
              <h5>Комментарий:</h5>

              <div className="form-group row">
                <div className="col-md-6">
                  <textarea
                    autoFocus
                    name="textarea-input"
                    rows="7"
                    className="form-control mb-2"
                    placeholder="Введите сообщение"
                    value={this.state.comment}
                    onChange={this.handleComment}
                  ></textarea>
                  <button
                    className="btn btn-success mb-2"
                    onClick={this.handleCommentSave}
                  >
                    Сохранить
                  </button>
                  {this.state.isCommentSuccess ? (
                    <Alert color="success">Комментарий успешно добавлен</Alert>
                  ) : null}
                </div>
              </div>

              {data.comments && data.comments.length ? (
                <div>
                  {data.comments.map((comment) => (
                    <div
                      key={comment.created}
                      className="p-2 mb-1"
                      style={{
                        backgroundColor: '#d9eee0',
                        display: 'flex',
                        borderRadius: '5px',
                        justifyContent: 'space-between',
                      }}
                    >
                      <div>{comment.text}</div>
                      <div>{moment(comment.created).format('DD.MM.YYYY')}</div>
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
            <Nav tabs>
              {data.touristfp ? (
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '1' })}
                    onClick={() => this.toggleTab('1')}
                  >
                    <i className="fa fa-plane">{''}</i> Куда летал
                  </NavLink>
                </NavItem>
              ) : null}
              {data.person ? (
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '2' })}
                    onClick={() => this.toggleTab('2')}
                  >
                    <i className="fa fa-list">{''}</i> Запросы потенциального
                    клиента
                  </NavLink>
                </NavItem>
              ) : null}
              {data.touristfp ? (
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '3' })}
                    onClick={() => this.toggleTab('3')}
                  >
                    <i className="fa fa-list">{''}</i> Запросы постоянного
                    клиента
                  </NavLink>
                </NavItem>
              ) : null}
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '7' })}
                  onClick={() => this.toggleTab('7')}
                >
                  <i className="fa fa-tasks">{''}</i> Связать кабинет с клиентом
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent className="col-md-12 mb-10" activeTab={activeTab}>
              {data.touristfp ? (
                <TabPane tabId="1">
                  <div>
                    <OrdersFpTable
                      count={orders ? orders.length : 0}
                      items={orders || []}
                      // handlePageChange={this.handlePageChange}
                    />
                  </div>
                </TabPane>
              ) : null}
              {data.person ? (
                <TabPane tabId="2">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <Issues
                      person={data.person.id}
                      type="person"
                      issues={personIssues || []}
                      info={this.state.personInfo}
                      onCreate={this.updateIssues}
                      onChangeActivity={this.updateIssues}
                    />
                  </div>
                </TabPane>
              ) : null}
              {data.touristfp ? (
                <TabPane tabId="3">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <Issues
                      tourist={data.touristfp.id}
                      type="tourist"
                      issues={touristIssues || []}
                      info={this.state.touristInfo}
                      onCreate={this.updateIssues}
                      onChangeActivity={this.updateIssues}
                    />
                  </div>
                </TabPane>
              ) : null}
              <TabPane tabId="7">
                <div className="tourists col-md-6">
                  <Select
                    placeholder="Прикрепить потенциального или постоянного клиента"
                    name="status"
                    multi={false}
                    joinValues={true}
                    simpleValue={true}
                    onChange={(data) =>
                      this.handleChangeClientType('clientType', data)
                    }
                    options={CLIENTS_TYPES}
                    value={clientType}
                  />
                  {clientType === 'tourist' ? (
                    <div className="tourists">
                      <div className="tourists__alert">
                        К кабинету туриста может быть привязан только один
                        постоянный клиент
                      </div>
                      {touristsFp.length > 0 ? (
                        touristsFp.map((tourist) => {
                          let isConnected = false;
                          if (data.touristfp) {
                            isConnected = tourist.id === data.touristfp.id;
                          }
                          return (
                            <div
                              className={`tourist ${
                                !!isConnected ? 'tourist_connected' : ''
                              }`}
                              key={tourist.id}
                            >
                              <div className="tourist__id">{tourist.id}</div>
                              <div className="tourist__name">
                                {tourist.full_name}
                              </div>
                              <div className="tourist__actions">
                                <Link
                                  className="tourist__button"
                                  target="_blank"
                                  to={`../../tourists/${tourist.id}`}
                                >
                                  Посмотреть подробные данные
                                </Link>
                                {isConnected ? (
                                  <div className="tourist__connected-msg">
                                    Этот турист привязан к кабинету
                                  </div>
                                ) : (
                                  <button
                                    className="tourist__button"
                                    onClick={(e) =>
                                      this.connectTouristToCabinet(tourist.id)
                                    }
                                  >
                                    Привязать туриста к этому кабинету
                                  </button>
                                )}
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="tourists__alert">Заказы не найдены</div>
                      )}
                    </div>
                  ) : null}
                  {clientType === 'person' ? (
                    <div className="tourists">
                      <div className="tourists__alert">
                        К кабинету туриста может быть привязан только один
                        потенциальный клиент
                      </div>
                      <div className="search filter-row">
                        <input
                          onChange={(e) =>
                            this.handlePersonSearchInput(e.target.value)
                          }
                          placeholder="ФИО, email, телефон"
                          type="text"
                          className="form-control"
                        ></input>
                        <button
                          className="btn btn-success"
                          type="button"
                          onClick={(e) =>
                            this.handlePersonSearch(this.state.searchValue)
                          }
                        >
                          Искать
                        </button>
                      </div>
                      {this.state.personData.length > 0
                        ? this.state.personData.map((person) => {
                            let isConnected = false;
                            if (data.person) {
                              isConnected = person.id === data.person.id;
                            }
                            return (
                              <div
                                className={`tourist ${
                                  !!isConnected ? 'tourist_connected' : ''
                                }`}
                                key={person.id}
                              >
                                <div className="tourist__id">{person.id}</div>
                                <div className="tourist__name">
                                  {person.fio}
                                </div>
                                <div className="tourist__actions">
                                  <Link
                                    className="tourist__button"
                                    target="_blank"
                                    to={`../../persons/${person.id}`}
                                  >
                                    Посмотреть подробные данные
                                  </Link>
                                  {isConnected ? (
                                    <div className="tourist__connected-msg">
                                      Этот клиент привязан к кабинету
                                    </div>
                                  ) : (
                                    <button
                                      className="tourist__button"
                                      onClick={(e) =>
                                        this.connectPersonToCabinet(person.id)
                                      }
                                    >
                                      Привязать клиента к этому кабинету
                                    </button>
                                  )}
                                </div>
                              </div>
                            );
                          })
                        : null}
                      {searchError ? (
                        <div
                          className="tourists__alert"
                          style={{ borderColor: 'red' }}
                        >
                          {searchError}
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              </TabPane>
            </TabContent>
          </CardBlock>
        </Card>
      </div>
    );
  }
}
