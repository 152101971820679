import React, {Component} from 'react';
import Select from 'react-select-plus';
import {OrderFpAPI, POSITIVE_ACTION_STATUSES} from '../../api/Client';
import {equivalentSelectedOfficesByStates, transformSelectedOffice} from '../Employees/components/EmployeesSelector';
import PropTypes from 'prop-types';

export default class ManagerFpSelector extends Component {
  constructor(props) {
    super(props);
    this.getManagersFp = this.getManagersFp.bind(this);
    this.changeManagerFp = this.changeManagerFp.bind(this);

    this.state = {
      managersFp: [],
    };
  }

  getManagersFp() {
    const {officeSelected} = this.props;
    const office = transformSelectedOffice(officeSelected);
    let status;

    if (office) {
      OrderFpAPI.
          fetchList(1, {office: office}, 'fpmanagers/').
          then(r => {
            status = r.status;
            return r.json();
          }).
          then(r => {
            if (status === POSITIVE_ACTION_STATUSES.list) {
              this.setState({
                managersFp: r || [],
              });
            } else {
              this.setState({
                managersFp: [],
              });
            }
          });
    }
  }

  componentDidMount() {
    this.getManagersFp();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const {officeSelected} = this.props;
    if (!equivalentSelectedOfficesByStates(prevProps.officeSelected, officeSelected)) {
      this.getManagersFp();
    }
  }

  changeManagerFp(v) {
    const {updateManagerFp} = this.props;
    updateManagerFp(v);
  }

  render() {
    const {managerFpSelected, isDisabled, officeSelected, identifier} = this.props;
    const {managersFp} = this.state;
    return (
        <Select
            clearable={true}
            multi={true}
            disabled={!!isDisabled}
            onChange={this.changeManagerFp}
            options={managersFp.map(m => ({value: `${m.id}`, label: `${m.id} (${m.name})`}))}
            placeholder={!officeSelected ? `Выберите менеджера ФП` : `Выберите менеджера ФП`}
            value={managerFpSelected}
            simpleValue={true}
            id={identifier}
        />
    );
  }
}

// ManagerFpSelector.propTypes = {
//   managerFpSelected: PropTypes.string.isRequired,
//   updateManagerFp: PropTypes.func.isRequired,
//   officeSelected: PropTypes.oneOfType([
//     PropTypes.number,
//     PropTypes.shape({
//       label: PropTypes.string,
//       value: PropTypes.number,
//     }),
//     PropTypes.instanceOf(null),
//   ]),
//   isDisabled: PropTypes.bool,
//   identifier: PropTypes.string,
// };
//
// ManagerFpSelector.defaultProps = {
//   isDisabled: false,
//   identifier: `manager-fp-select`,
// };
