import React, {Component} from 'react';
import {OfficeAPI, OrderFpAPI, POSITIVE_ACTION_STATUSES, UsersAPI} from '../../../api/Client';
import Select from 'react-select-plus';

const TRANSFER_STATUS_NEW = 'new';
const TRANSFER_STATUS_SUCCESS = 'success';
const TRANSFER_STATUS_ERROR = 'error';
const TRANSFER_STATUS_DELETE = 'delete';
const TRANSFER_STATUS_CHOICES = {
  TRANSFER_STATUS_NEW: 'Новый',
  TRANSFER_STATUS_SUCCESS: 'Успех',
  TRANSFER_STATUS_ERROR: 'Ошибка',
  TRANSFER_STATUS_DELETE: 'Удаление',
};

class ManagerManagerFp extends Component {
  constructor(props) {
    super(props);

    this.fetchManagersFp = this.fetchManagersFp.bind(this);
    this.fetchUser = this.fetchUser.bind(this);
    this.changeItem = this.changeItem.bind(this);
    this.fetchOffices = this.fetchOffices.bind(this);
    this.addRecord = this.addRecord.bind(this);
    this.removeRecord = this.removeRecord.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      managersFp: [],
      officeManagerFp: [],
      errors: {},
      showSuccessMessage: false,
    };
  }

  addRecord() {
    this.setState(prevState => {
      const newOfficeManagerFp = [...prevState.officeManagerFp];
      newOfficeManagerFp.unshift({manager_fp: null, office: null});
      return {
        officeManagerFp: newOfficeManagerFp,
        showSuccessMessage: false,
        errors: {},
      };
    });
  }

  removeRecord(index) {
    this.setState(prevState => {
      const newOfficeManagerFp = [...prevState.officeManagerFp];
      newOfficeManagerFp.splice(index, 1);
      return {
        officeManagerFp: newOfficeManagerFp,
        showSuccessMessage: false,
        errors: {},
      };
    });
  }

  handleSubmit() {
    let status;
    const {officeManagerFp} = this.state;
    const {userId} = this.props;
    UsersAPI.
        update(`${UsersAPI.resource_url}${userId}/managersfp`, {
          manager_manager_fp: officeManagerFp,
        }).
        then(r => {
          status = r.status;
          return r.json();
        }).
        then(result => {
          if (status === POSITIVE_ACTION_STATUSES.update) {
            this.setState({
              errors: {},
              showSuccessMessage: true,
            });
          } else {
            this.setState({
              errors: result,
              showSuccessMessage: false,
            });
          }
        });
  }

  changeItem(property, val, index) {
    this.setState(prevState => {
      const newOfficeManagerFp = prevState.officeManagerFp;
      newOfficeManagerFp[index][property] = val;
      return {
        officeManagerFp: newOfficeManagerFp,
        showSuccessMessage: false,
        errors: {},
      };
    });
  }

  successMessage(callback) {
    this.setState({
      showSuccessMessage: true,
    }, callback);
  }

  fetchManagersFp() {
    let status;
    OrderFpAPI.
        fetchJSON(`fpmanagers/`).
        then(r => {
          status = r.status;
          return r.json();
        }).
        then(r => {
          if (status === POSITIVE_ACTION_STATUSES.list) {
            this.setState({
              managersFp: r || [],
              showSuccessMessage: false,
              errors: {},
            });
          }
        });
  }

  fetchOffices() {
    let status;
    OfficeAPI.
        fetchJSON(`full_list/`).
        then(r => {
          status = r.status;
          return r.json();
        }).
        then(r => {
          if (status === POSITIVE_ACTION_STATUSES.list) {
            this.setState({
              offices: r || [],
              showSuccessMessage: false,
              errors: {},
            });
          }
        });
  }

  fetchUser() {
    const {userId} = this.props;
    let status;
    UsersAPI.
        fetchJSON(`${userId}/user_managersfp/`).
        then(r => {
          status = r.status;
          return r.json();
        }).
        then(r => {
          if (status === POSITIVE_ACTION_STATUSES.list) {
            this.setState({
              officeManagerFp: r.manager_manager_fp || [],
              showSuccessMessage: false,
              errors: {},
            });
          }
        });
  }

  componentDidMount() {
    this.fetchUser();
    this.fetchManagersFp();
    this.fetchOffices();
  }

  render() {
    const {
      managersFp,
      officeManagerFp,
      offices,
      errors,
      showSuccessMessage,
    } = this.state;

    const managers = Array.isArray(managersFp) && managersFp.length
        ? managersFp.map(i => ({value: i.id, label: `${i.name} (${i.fp_pk})`}))
        : [];
    const officeList = Array.isArray(offices) && offices.length ? offices.map(i => ({value: i.id, label: i.name})) : [];
    const errorList = Array.isArray(errors.manager_manager_fp) && errors.manager_manager_fp.length
        ? errors.manager_manager_fp
        : [];
    const errorStatuses = [TRANSFER_STATUS_DELETE, TRANSFER_STATUS_ERROR];
    return (
        <div className="card">
          <div className="card-header">
            <strong>Менеджер из ФСУ</strong>
          </div>
          <div className="card-block">
            <form action="" method="post" className="form-horizontal" onSubmit={this.handleSubmit}>
              <div className="row">
                <div className="col-6 col-md-8 col-sm-12">
                  <button type="button" className="btn btn-sm btn-primary" onClick={this.addRecord}>
                    <i className="fa fa-plus-circle">{''}</i>
                  </button>
                  {
                    Array.isArray(officeManagerFp) && officeManagerFp.length ? officeManagerFp.map((item, index) => {
                      if (errorStatuses.indexOf(item.status) !== -1) {
                        return null;
                      }

                      const localErrors = errorList.length && typeof errorList[index] === 'object' &&
                      Object.keys(errorList[index]).length ? errorList[index] : {};

                      return (
                          <div className="form-group row" key={index}>
                            <div className="col-md-6">
                              <label className="text-right col-md-6 col-form-label">
                                Менеджер из ФСУ
                              </label>
                              <Select
                                  id="fpManager"
                                  className="mb-3"
                                  name="fpManager"
                                  placeholder="Выберите менеджера из спиcка..."
                                  simpleValue={true}
                                  closeOnSelect={true}
                                  removeSelected={true}
                                  value={item.manager_fp}
                                  onChange={val => this.changeItem('manager_fp', val, index)}
                                  options={managers}
                              />
                              {Array.isArray(localErrors.manager_fp) && localErrors.manager_fp.length ? (
                                  <p className="bg-danger">
                                    {localErrors.manager_fp}
                                  </p>
                              ) : null}
                            </div>
                            <div className="col-md-5">
                              <label className="text-right col-md-5 col-form-label">
                                Офис
                              </label>
                              <Select
                                  id="office"
                                  className="mb-3"
                                  name="office"
                                  placeholder="Выберите офис из спиcка..."
                                  simpleValue={true}
                                  closeOnSelect={true}
                                  removeSelected={true}
                                  value={item.office}
                                  onChange={val => this.changeItem('office', val, index)}
                                  options={officeList}
                              />
                              {Array.isArray(localErrors.office) && localErrors.office.length ? (
                                  <p className="bg-danger">
                                    {localErrors.office}
                                  </p>
                              ) : null}
                            </div>
                            <div className="col-md-1">
                              <label className="text-right col-md-1 col-form-label">{''}</label>
                              <button type="button"
                                      className="btn btn-sm btn-warning"
                                      onClick={() => this.removeRecord(index)}>
                                <i className="fa fa-minus-circle">{''}</i>
                              </button>
                            </div>
                          </div>
                      );
                    }) : null
                  }
                  {Array.isArray(errors.non_field_errors) && errors.non_field_errors.length ? (
                      <p className="bg-danger">
                        {errors.non_field_errors}
                      </p>
                  ) : null}
                </div>
              </div>
              <div className="row">
                <div className="col-4 col-md-8 col-sm-12">
                  {showSuccessMessage ? (
                      <p className="bg-success">
                        Успешно
                      </p>
                  ) : null}
                </div>
              </div>
            </form>
          </div>

          <div className="card-footer">
            <button type="submit" className="btn btn-sm btn-primary" onClick={this.handleSubmit}>
              <i className="fa fa-dot-circle-o">{''}</i> Обновить
            </button>
          </div>
        </div>
    );
  }
}

export default ManagerManagerFp;
