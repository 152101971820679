import React from 'react';


class EmailTemplatePreview extends React.Component {
  state = {
    data: null,
    isShow: false
  };

  showTemplate = () => {
    this.setState({
      isShow: true
    })
  }

  hideTemplate = () => {
    this.setState({
      isShow: false
    })
  }

  render() {

    const {isShow} = this.state;

    return <div style={{padding: 10, border: '1px solid #cccccc', background: "#fff", margin: '10px 0px'}}>
      { !isShow && <button onClick={this.showTemplate} className="btn btn-primary">Просмотр email шаблона</button>}
      { isShow && <div>
         <div>
           <button onClick={this.hideTemplate} className="btn btn-success">Свернуть</button>
         </div>

          <div>
            { this.props.url  && <div style={{margin: "10px 0", color: '#ccc'}}>
                <b>{this.props.url}</b>
              </div>
            }

            {/*{ this.props.url  && <iframe src={this.props.url} width="100%" height="400" title="description"></iframe> }*/}
            {/*{ this.props.url  && <iframe srcDoc={html} width="100%" height="400" title="description"></iframe> }*/}
            <div dangerouslySetInnerHTML={{__html:this.props.html}}></div>
            { !this.props.url && <div>Шаблон не доступен</div> }
          </div>
        </div>}
      </div>
  }
}

export default EmailTemplatePreview;
