const filterParams = [
  "abandonedcustomers_filter",
  "franchise_filter",
  "franchise_leads_filter",
  "leads_filter",
  "persons_filter",
  "subscription_filter",
  "tourist_filter",
  "tourists_cabinet_filter",
  "tourists_filter",
  "tourists_report_filter",
  "touristsfp_filter",
  "leads_total",
  "tourists_total",
  "tourist_total",
  "touristsfp_total",
  "persons_total",
  "tourists_cabinet_total",
  "franchise_total",
  "subscription_total",
  "franchise_leads_total",
  "tourists_report_type",
  "tourists_report_address_books",
  "abandonedcustomers_total",
  "type"
];

export function clearAllFilterParams() {
  filterParams.forEach((param) => {
    localStorage.removeItem(param);
  })
}