import React from 'react';

import NotifyTable from './components/NotifyTable';

export default class Notify extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
        <div>
          <NotifyTable items={this.state}/>
        </div>
    );
  }
}