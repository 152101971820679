import React, { Component } from 'react';

import { Button } from 'reactstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import OfficeSelectContainer from '../../../components/OfficeSelectContainer';

class IssuesSourceReportFilter extends Component {
  constructor(props) {
    super(props);
    this.startDateChange = this.startDateChange.bind(this);
    this.endDateChange = this.endDateChange.bind(this);
    this.filterItems = this.filterItems.bind(this);
    this.changeOffice = this.changeOffice.bind(this);
    this.changeOfficeCity = this.changeOfficeCity.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);

    this.state = {
      startDate: '',
      endDate: '',
      offices: [],
      offices_city: null,
    };
  }
  componentDidMount() {
    if (this.props.initialFilter) {
      if (this.props.initialFilter.hasOwnProperty('created__gte')) {
        this.startDateChange(
          moment(this.props.initialFilter.created__gte, 'DD.MM.YYYY')
        );
      }
      if (this.props.initialFilter.hasOwnProperty('created__lte')) {
        this.endDateChange(
          moment(this.props.initialFilter.created__lte, 'DD.MM.YYYY')
        );
      }
    }
  }
  changeOffice(offices) {
    this.setState({ offices });
  }
  changeOfficeCity(offices_city) {
    this.setState({ offices_city });
  }

  filterItems(st) {
    let result = {};

    if (st.startDate) {
      result.created__gte = st.startDate.format('DD.MM.YYYY');
    }
    if (st.endDate) {
      result.created__lte = st.endDate.format('DD.MM.YYYY');
    }
    if (st.offices) {
      result.office = st.offices.map((office) => office.value);
    }

    return result;
  }
  handleReset() {
    this.setState({
      startDate: '',
      endDate: '',
      offices: [],
      offices_city: null,
    });
  }
  handleSubmit() {
    this.props.onFilter(this.filterItems(this.state));
  }
  startDateChange(date) {
    this.setState({ startDate: date });
  }

  endDateChange(date) {
    this.setState({ endDate: date });
  }

  render() {
    return (
      <div>
        <div className="filter__office">
          <OfficeSelectContainer
            changeCity={this.changeOfficeCity}
            changeOffice={this.changeOffice}
            multi_office={true}
            selected_city={this.state.offices_city}
            selected_office={this.state.offices}
          >
            <div className="filter-field date-from">
              <label htmlFor="leadDate" className="col-form-label">
                Дата от
              </label>
              <DatePicker
                dateFormat="DD.MM.YYYY"
                selected={this.state.startDate}
                selectsStart
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onChange={this.startDateChange}
                className="d-block"
                placeholderText="Дата начала"
              />
            </div>
            <div className="filter-field date-to">
              <label htmlFor="leadDate" className="col-form-label">
                Дата до
              </label>
              <DatePicker
                dateFormat="DD.MM.YYYY"
                selected={this.state.endDate}
                selectsEnd
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onChange={this.endDateChange}
                placeholderText="Дата окончания"
              />
            </div>
          </OfficeSelectContainer>
        </div>
        <div className="card-footer" style={{ paddingLeft: 0 }}>
          <Button
            className="btn btn-sm btn-success mr-2"
            style={{ width: '120px' }}
            onClick={this.handleSubmit}
          >
            Найти
          </Button>
          <Button
            className="btn btn-sm btn-success"
            style={{
              width: '120px',
              color: '#009436',
              backgroundColor: 'transparent',
            }}
            onClick={this.handleReset}
          >
            Сбросить
          </Button>
        </div>
      </div>
    );
  }
}

export default IssuesSourceReportFilter;
