import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import Paginator from '../../../components/Paginator';
import APIClient, {POSITIVE_ACTION_STATUSES, TouristListsAPI} from '../../../api/Client';
import 'moment/locale/ru';

class TouristLists extends Component {
    constructor(props) {
        super(props);

        this.handlePageChange = this.handlePageChange.bind(this);
        this.fetchItems = this.fetchItems.bind(this);

        this.state = {
            items: [],
            pageNumber: 1,
            count: 0,
        };
    }

    handlePageChange(data) {
        let page = data.selected + 1;
        this.setState(
            {pageNumber: page},
            () => this.fetchItems(page),
        );
    }

    fetchItems(page = 1) {
        let status;
        const {campaign} = this.props;

        TouristListsAPI.fetchList(page, {}, `${campaign.address_book.id}/entries/`).then(r => {
            status = r.status;
            return r.json();
        }).then(r => {
            if (status === POSITIVE_ACTION_STATUSES.list) {
                console.log('r.results==>', r.results)
                this.setState({
                    items: r.results,
                    count: r.count,
                });
            }
        });
    }


    toggleEmailNotify(e) {
        let url = `persons_info`;
        if (e.target.dataset.clientType === 'natural_customer_info') {
            url = `natural_customers_info`;
        } else if (e.target.dataset.clientType === 'tourist_fp_info') {
            url = `touristsfp_info`;
        }

        APIClient.update(`/${url}/${e.target.dataset.clientId}`, {
            'is_email_notify': e.target.checked,
        }).then(res => {
        });
    }

    toggleSmsNotify(e) {
        let url = `persons_info`;
        if (e.target.dataset.clientType === 'natural_customer_info') {
            url = `natural_customers_info`;
        } else if (e.target.dataset.clientType === 'tourist_fp_info') {
            url = `touristsfp_info`;
        }
        APIClient.update(`/${url}/${e.target.dataset.clientId}`, {
            'is_sms_notify': e.target.checked,
        }).then(res => {
        });
    }

    componentDidMount() {
        this.fetchItems(1);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        console.log("111", this.props.campaign)
        if (this.props.campaign.items[0].sms_campaign) {
            console.log("sms")
        }

    }

    render() {
        const {items, count} = this.state;
        const {campaign} = this.props;


        return (
            <div>
                <div className="card-header">
                    <i className="fa fa-align-justify">{}</i> Всего&nbsp;
                    <span className="badge badge-info">{count}</span>
                </div>
                <div className="card-block card-block_responsive">
                    <table className="table table--green table-bordered table-striped table-sm">
                        <thead>
                        <tr>
                            <th>Id</th>
                            <th>ФИО</th>
                            <th>Контакт</th>
                            <th>Тип</th>
                            <th>Состояние</th>
                            <th>Статус</th>
                        </tr>
                        </thead>
                        <tbody>
                        {items.map(item => {
                            const field = campaign.dispatch_type.id === 1 ? 'phones' : 'emails';
                            let client = item.tourist;
                            let url = `tourists`;
                            let type = 'турист';

                            if (item.client_type.id === 2) {
                                client = item.person;
                                url = `persons`;
                                type = 'потен. клиент';
                            }


                            if (Array.isArray(item[field]) && item[field].length) {
                                return item[field].map((contact, idx) => (
                                    <tr key={`${item.id}-${client.id}-${contact.entry}-${idx}`}>
                                        <td>{<Link to={`/${url}/${client.id}`}>{client.id}</Link>}</td>
                                        <td>{<Link to={`/${url}/${client.id}`}>{client.full_name}</Link>}</td>
                                        <td>{contact.contact.value}</td>
                                        <td>{type}</td>
                                        <td>{contact.status.name}</td>
                                        <td>{contact.report_status.name}</td>
                                    </tr>
                                ));
                            }

                            return (
                                <tr key={`${item.id}-${client.id}`}>
                                    <td>{<Link to={`/${url}/${client.id}`}>{client.id}</Link>}</td>
                                    <td>{<Link to={`/${url}/${client.id}`}>{client.full_name}</Link>}</td>
                                    <td>нет контакта</td>
                                    <td>{type}</td>
                                    <td>{}</td>
                                    <td>нет статуса</td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                    <Paginator total={count} onPageChange={this.handlePageChange}/>
                </div>
            </div>
        );
    }
}

export default TouristLists;
