import React, { Component } from 'react';
import Select from 'react-select-plus';
import { connect } from 'react-redux';

import { getLabelByOfficeType, getLabelByOfficeTypeAsAdmin } from './utils';
import { ADMIN, getUserRole, SUPER_ADMIN } from '../../connect/auth';
import { OfficeAPI } from '../../api/Client/Client';

export class OfficeSelect extends Component {
  constructor(props) {
    super(props);

    this._changeOffice = this._changeOffice.bind(this);
    this._changeCity = this._changeCity.bind(this);
    this._changeCountry = this._changeCountry.bind(this);
    this._fillCountriesAndCitiesAndOffices =
      this._fillCountriesAndCitiesAndOffices.bind(this);
    this._getCitiesAndOffices = this._getCitiesAndOffices.bind(this);
    this._getOfficesList = this._getOfficesList.bind(this);
    this._getInitialData = this._getInitialData.bind(this);

    this.state = {
      fetchedOffices: null,
      office_list: [],
      city_list: [],
      selected_city: null,
      country_list: [],
      selected_country: null,
      selected_office: null,
    };
  }

  componentDidMount() {
    this._getInitialData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.selected_office !== this.props.selected_office) {
      this.setState({ selected_office: this.props.selected_office });
    }
    if (prevProps.selected_country !== this.props.selected_country) {
      this.setState({ selected_country: this.props.selected_country });
    }
    if (prevProps.selected_city !== this.props.selected_city) {
      this.setState({ selected_city: this.props.selected_city });
    }

    if (
      // Здесь проверяем, подгрузился ли лист городов и если да, то пытаемся подставить город
      JSON.stringify(prevState.city_list) !==
        JSON.stringify(this.state.city_list) &&
      this.state.selected_city
    ) {
      let city = this.state.selected_city;
      if (city.label) {
        const _city = city.label; // Получить метку выбранного города
        const _filteredCities = this.state.city_list.filter(
          (item) => item.label === _city
        ); // Найти соответствующий объект города из списка city_list

        if (_filteredCities.length > 0) {
          this.setState({ office_list: _filteredCities[0].offices }); // Установить отфильтрованные офисы для выбранного города
        }
      } else if (typeof city === 'string') {
        const _filteredCities = this.state.city_list.filter(
          (item) => item.label == city
        ); // Найти соответствующий объект города из списка city_list

        if (_filteredCities.length > 0) {
          this.setState({ office_list: _filteredCities[0].offices }); // Установить отфильтрованные офисы для выбранного города
        }
      }
    }
  }

  _getInitialData() {
    let filter_for_fetch = {
      active:
        this.props.fetch_active !== undefined ? this.props.fetch_active : true,
    };
    if (this.props.fetch_accessed !== undefined) {
      filter_for_fetch['accessed'] = this.props.fetch_accessed;
    }
    const encodedFetchParams = OfficeAPI.encodeObject(filter_for_fetch),
      self = this;
    OfficeAPI.getJSON(`/offices/by_country/?${encodedFetchParams}`)
      .then(function (data) {
        self.setState({ fetchedOffices: data });
        self._fillCountriesAndCitiesAndOffices(data);

        if (self.props.selected_country && self.props.changeCountry) {
          self._changeCountry(self.props.selected_country);
        }

        if (self.props.selectedCity) {
          self._changeCity(self.props.selectedCity);
        }
      })
      .then((r) => {
        const { officeId } = this.props;
        if (officeId) {
          const office = this.state.office_list.find((office) => {
            if (office.value == officeId) return office;
          });
          this._changeOffice(office);
        }
      });

    if (this.props.selected_city) {
      this._changeCity(this.props.selected_city);
    }
    if (this.props.selected_office) {
      this._changeOffice(this.props.selected_office);
    }
  }

  _changeCity(city) {
    this.setState({ selected_city: city });
    if (this.props.hasOwnProperty('changeCity')) {this.props.changeCity(city)}
    if (city && city.label) {
      const _city = city.label; // Получить метку выбранного города
      const _filteredCities = this.state.city_list.filter(
        (item) => item.label === _city
      ); // Найти соответствующий объект города из списка city_list

      if (_filteredCities.length > 0) {
        this.setState({ office_list: _filteredCities[0].offices }); // Установить отфильтрованные офисы для выбранного города
      }
    } else {
      this._changeCountry(this.state.selected_country); // Если город не выбран, вызвать метод для изменения выбранной страны
    }
  }

  _changeOffice(office) {
    this.setState({ selected_office: office });
    if (this.props.hasOwnProperty('changeOffice')) {this.props.changeOffice(office)}
    if (office) {
      const _office = office.hasOwnProperty('label') ? office.label : office;
      this.setState({ selected_office: _office });
    } else {
      this._fillCountriesAndCitiesAndOffices(this.state.fetchedOffices);
    }
  }

  _changeCountry(country) {
    this.setState({ selected_country: country });
    if (this.props.hasOwnProperty('changeCountry')) {this.props.changeCountry(country)}
    if (country) {
      const _country = country.label; // Получить метку выбранной страны
      const _filteredCities = this.state.fetchedOffices[_country]; // Найти соответствующие города для выбранной страны из списка fetchedOffices
      if (_filteredCities) {
        const { _cities, _offices } = this._getCitiesAndOffices(
          _country,
          _filteredCities
        ); // Получить отфильтрованные города и офисы
        this.setState({ city_list: _cities, office_list: _offices }); // Установить отфильтрованные города и офисы
      }
    } else {
      this._fillCountriesAndCitiesAndOffices(this.state.fetchedOffices); // Если страна не выбрана, заполнить список стран и городов снова
    }
  }

  _fillCountriesAndCitiesAndOffices(countries) {
    if (!countries) {
      return;
    } else {
      let country_list = [],
        city_list = [],
        office_list = [];
      Object.entries(countries).forEach(([country, cities]) => {
        country_list.push({ label: country, value: country });
        const { _cities, _offices } = this._getCitiesAndOffices(
          country,
          cities
        );
        city_list.push(..._cities);
        office_list.push(..._offices);
      });

      this.setState({ country_list, city_list, office_list });
    }
  }

  _getCitiesAndOffices(country, cities) {
    let _cities = [],
      _offices = [];
    Object.entries(cities).forEach(([city, offices]) => {
      let _office_current = this._getOfficesList(city, offices);
      _cities.push({
        label: city,
        value: city,
        country: country,
        offices: _office_current,
      });
      _offices.push(..._office_current);
    });
    return { _cities, _offices };
  }

  _getOfficesList(city, offices) {
    if (this.props.hasOwnProperty('sortOffices')) {
      offices.sort((a, b) => this.props.sortOffices(a, b));
    }

    let _offices = [];
    offices.forEach((office) => {
      let title = office.title;
      if (getUserRole() === SUPER_ADMIN) {
        title = getLabelByOfficeTypeAsAdmin(office);
      } else {
        title = getLabelByOfficeType(office);
      }
      _offices.push({ label: title, value: office.id, city: city });
    });

    return _offices;
  }

  render() {
    const { selected_office, selected_city, selected_country } = this.state;
    let userRole = getUserRole();
    return (
      <div className="filter-row">
        {userRole &&
          (userRole === SUPER_ADMIN || userRole === ADMIN) &&
          this.props.hasOwnProperty('changeCountry') && (
            <Select
              disabled={!!this.props.disabledSelect}
              onChange={this._changeCountry}
              options={this.state.country_list}
              placeholder="Страна агентства"
              value={selected_country}
              className="filter-field"
            />
          )}
        {userRole &&
          (userRole === SUPER_ADMIN || userRole === ADMIN) &&
          this.props.hasOwnProperty('changeCity') && (
            <Select
              disabled={!!this.props.disabledSelect}
              onChange={this._changeCity}
              options={this.state.city_list}
              placeholder="Город агентства"
              value={selected_city}
              className="filter-field"
            />
          )}
        <Select
          multi={this.props.multi_office}
          disabled={!!this.props.disabledSelect}
          onChange={this._changeOffice}
          options={this.state.office_list}
          placeholder="Офис"
          value={selected_office}
          className="filter-field"
        />
        {this.props.children}
      </div>
    );
  }
}

OfficeSelect.displayName = 'OfficeSelect';

const mapStateToProps = function (store) {
  return {
    offices: store.offices,
  };
};

export default connect(mapStateToProps)(OfficeSelect);
