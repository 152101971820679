import React, {Component} from 'react';
import {Button} from 'reactstrap';

import {getUserRole, ADMIN, SUPER_ADMIN} from '../../../connect/auth';

export default class InvoiceAddForm extends Component {
  constructor(props) {
    super(props);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.addInvoice = this.addInvoice.bind(this);
    this.state = {
      value: 0.0,
      uuid: 0,
    };
  }

  onChangeInput(e) {
    e.preventDefault();
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  addInvoice(e) {
    e.preventDefault();
    const {addInvoice} = this.props;
    addInvoice(this.state);
  }

  render() {
    const {isShow, value, uuid} = this.props;
    const userRole = getUserRole();

    return (
        <div>
          {isShow ? (
              <div className="card">
                <div className="card-block">
                  <form
                      action=""
                      method="post"
                      encType="multipart/form-data"
                      className="form-horizontal"
                  >
                    <div>
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label htmlFor="invoice-value">
                            Сумма
                          </label>
                          <input
                              type="number"
                              className="form-control"
                              placeholder="500"
                              value={value}
                              name="value"
                              onChange={this.onChangeInput}
                          />
                        </div>
                      </div>
                      {(userRole === SUPER_ADMIN) &&
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label htmlFor="invoice-value">
                              Идентификатор в ФСУ
                            </label>
                            <input
                                type="number"
                                className="form-control"
                                placeholder="ФСУ ID"
                                value={uuid}
                                name="uuid"
                                onChange={this.onChangeInput}
                            />
                          </div>
                        </div>
                      }
                    </div>
                    <div className="col-sm-12">
                      <Button onClick={this.addInvoice}>Добавить счет на оплату</Button>
                    </div>
                  </form>
                </div>
              </div>
          ) : null}
        </div>
    );
  }
}
