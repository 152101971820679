import {combineReducers} from 'redux'
import {routerReducer} from 'react-router-redux';

import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGOUT_SUCCESS,
    OFFICES_BY_STATE_REQUEST,
    OFFICES_BY_STATE_LOADED,
    LEADS_BY_STATE_REQUEST,
    LEADS_BY_STATE_LOADED,
    PERSONS_BY_STATE_REQUEST,
    PERSONS_BY_STATE_LOADED,
    MANAGERS_BY_STATE_REQUEST,
    MANAGERS_BY_STATE_LOADED,
    TOURIST_FILES_BY_REQUEST,
    TOURIST_FILES_BY_LOADED,
    TOURIST_FILES_BY_FAILURE,
    TOURIST_FILE_UPLOAD_SUCCESS,
    TOURIST_FILE_UPLOAD_REQUEST,
    // TOURIST_FILE_DELETE_REQUEST,
    TOURIST_FILE_DELETE_SUCCESS,
    TOURIST_FILE_UPLOAD_FAILURE,
    ISSUES_TABLE_PAGE_NUMBER,
} from '../actions/actions'
import Auth from '../Utils/Auth'


function auth(state = {
    isFetching: false,
    isAuthenticated: Auth.isUserAuthenticated()
}, action) {

    switch (action.type) {
        case LOGIN_REQUEST:
            return Object.assign({}, state, {
                isFetching: true,
                isAuthenticated: false,
                creds: action.creds
            });
        case LOGIN_FAILURE:
            return Object.assign({}, state, {
                isFetching: false,
                isAuthenticated: false,
                errorMessage: action.message
            });
        case LOGIN_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                isAuthenticated: true,
            });
        case LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isFetching: false,
                isAuthenticated: false
            });
        default:
            return state
    }
}

function offices(state = {
    'isFetching': false,
    'data': null
}, action) {
    switch (action.type) {
        case OFFICES_BY_STATE_REQUEST:
            return {
                isFetching: false,
                data: null
            };
        case OFFICES_BY_STATE_LOADED:
            return {
                isFetching: true,
                data: action.data,
            };
        default:
            return state
    }
}

function leads(state = {
    'isFetching': false,
    'data': null
}, action) {
    switch (action.type) {
        case LEADS_BY_STATE_REQUEST:
            return {
                isFetching: false,
                data: null
            };
        case LEADS_BY_STATE_LOADED:
            return {
                isFetching: true,
                data: action.data
            };
        default:
            return state
    }
}

function persons(state = {
    'isFetching': false,
    'data': null
}, action) {
    switch (action.type) {
        case PERSONS_BY_STATE_REQUEST:
            return {
                isFetching: false,
                data: null
            };
        case PERSONS_BY_STATE_LOADED:
            return {
                isFetching: true,
                data: action.data
            };
        default:
            return state
    }
}

function managers(state = {
    'isFetching': false,
    'data': null
}, action) {
    switch (action.type) {
        case MANAGERS_BY_STATE_REQUEST:
            return {
                isFetching: false,
                data: null
            };
        case MANAGERS_BY_STATE_LOADED:
            return {
                isFetching: true,
                data: action.data
            };
        default:
            return state
    }
}

function touristFiles(state = {
    isFetching: true,
    data: []
}, action) {
    switch (action.type) {
        case TOURIST_FILES_BY_REQUEST:
            return {
                isFetching: false,
                data: state.data
            };
        case TOURIST_FILES_BY_LOADED:
            return {
                isFetching: true,
                data: action.data
            };
        case TOURIST_FILES_BY_FAILURE:
            return {
                isFetching: true,
                message: action.message
            };
        case TOURIST_FILE_UPLOAD_REQUEST:
            return {
                isFetching: false,
                data: state.data
            }
        case TOURIST_FILE_UPLOAD_SUCCESS:
            return {
                isFetching: true,
                data: [
                    ...state.data,
                    touristFile({}, action)
                ]
            }
        case TOURIST_FILE_UPLOAD_FAILURE:
            return {
                isFetching: true,
                data: state.data,
                message: action.message
            }
        case TOURIST_FILE_DELETE_SUCCESS:
            let newState = [
                ...state.data
            ]
            let index = newState.indexOf(action.payload)
            newState.splice(index, 1)
            return {isFetching: true, data: newState}
        default:
            return state
    }
}

function touristFile(state = {}, action) {
    switch (action.type) {
        case TOURIST_FILE_UPLOAD_SUCCESS:
            return action.payload;
        default:
            return state
    }
}

const issuesReducerInitialState = {
    pageNumber: 1,
    filters: {
        status: ['new', 'active', 'meet', 'deffered', 'tours', 'call'],
    },
}

const issuesReducer = (state = issuesReducerInitialState, action) => {
    switch (action.type) {
        case ISSUES_TABLE_PAGE_NUMBER:
            return {
                ...state,
                pageNumber: action.pageNumber
            };
        default:
            return state;
    }
};

const rootReducer = combineReducers({
    auth,
    offices,
    leads,
    persons,
    managers,
    touristFiles,
    touristFile,
    issuesReducer,
    routing: routerReducer,
});

export default rootReducer;
