import React, {Component} from 'react';
import Select from 'react-select-plus';
import DatePicker from 'react-datepicker';
import 'moment/locale/ru';
import {CabinetAPI, POSITIVE_ACTION_STATUSES} from "../../api/Client";
import 'react-datepicker/dist/react-datepicker.css';

export class TouristCabinetFilter extends Component {
    constructor(props) {
        super(props);
        this.onFilter = this.onFilter.bind(this);
        this.handlePhone = this.handlePhone.bind(this);
        this.handleLikes = this.handleLikes.bind(this);
        this.handleCart = this.handleCart.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.handleLastName = this.handleLastName.bind(this);
        this.handleName = this.handleName.bind(this);
        this.handlePhone = this.handlePhone.bind(this);
        this.handleEmail = this.handleEmail.bind(this);
        this.fetchUsers = this.fetchUsers.bind(this);
        this.startDateInteractionChange = this.startDateInteractionChange.bind(this);
        this.endDateInteractionChange = this.endDateInteractionChange.bind(this);
        this.startDateRegistrationChange = this.startDateRegistrationChange.bind(this);
        this.endDateRegistrationChange = this.endDateRegistrationChange.bind(this);
        this.dtSyncChange = this.dtSyncChange.bind(this);

        this.state = {
            lastName: '',
            name: '',
            startDateInteraction: '',
            endDateInteraction: '',
            startDateRegistration: '',
            endDateRegistration: '',
            phone_settings: '',
            likes: '',
            cart: '',
            users: [],
            phone: '',
            email: ''
        }
    }

    startDateInteractionChange(date) {
        this.setState({startDateInteraction: date});
    }

    endDateInteractionChange(date) {
        this.setState({endDateInteraction: date});
    }

    startDateRegistrationChange(date) {
        this.setState({startDateRegistration: date});
    }

    endDateRegistrationChange(date) {
        this.setState({endDateRegistration: date});
    }

    dtSyncChange(date) {
        this.setState({dtSync: date});
    }

    handlePhone(phone_settings) {
        this.setState({phone_settings}, () => {
            this.onFilter();
        });
    }

    handleLikes(likes) {
        this.setState({likes}, () => {
            this.onFilter();
        });
    }

    handleCart(cart) {
        this.setState({cart}, () => {
            this.onFilter();
        });
    }

    handleLastName(e) {
        const lastName = e.target.value;
        this.setState({lastName});
    }

    handleName(e) {
        const name = e.target.value;
        this.setState({name});
    }

    handlePhone(e) {
        const phone = e.target.value;
        this.setState({phone});
    }

    handleEmail(e) {
      const email = e.target.value;
        this.setState({email});
    }

    handleReset() {
        this.setState({
            lastName: '',
            name: '',
            startDateInteraction: '',
            endDateInteraction: '',
            startDateRegistration: '',
            endDateRegistration: '',
            phone_settings: '',
            likes: '',
            cart: ''
        }, this.onFilter);
    }

    onFilter() {
        let filter = {}

        if (this.state.phone) {
          filter.phone_settings = this.state.phone;
        }
        if (this.state.email) {
          filter.email_settings = this.state.email;
        }
        if (this.state.lastName) {
            filter.last_name = this.state.lastName;
        }
        if (this.state.name) {
            filter.name = this.state.name;
        }
        if (this.state.likes === '') {
        } else if (this.likes !== 0) {
            filter.count_likes = this.state.likes.split(',')
            // console.log(filter.count_likes)
            ;
        }
        if (this.state.cart === '') {
        } else if (this.cart !== 0) {
            filter.count_cart = this.state.cart.split(',')
            // console.log(filter.count_likes)
            ;
        }
        if (this.state.startDateInteraction) {
            filter.dt_sync__gte = this.state.startDateInteraction.format('DD.MM.YYYY');
        }
        if (this.state.endDateInteraction) {
            filter.dt_sync__lte = this.state.endDateInteraction.format('DD.MM.YYYY');
        }
        if (this.state.startDateRegistration) {
            filter.dt_registered__gte = this.state.startDateRegistration.format('DD.MM.YYYY');
        }
        if (this.state.endDateRegistration) {
            filter.dt_registered__lte = this.state.endDateRegistration.format('DD.MM.YYYY');
        }
        this.props.onFilter(filter);
    }

    fetchUsers() {
        let self = this;
        let status;
        CabinetAPI.fetchJSON(`full_list/`).then(r => {
            status = r.status;
            return r.json();
        }).then(r => {
            self.setState({
                users: status === POSITIVE_ACTION_STATUSES.list ? r : [],
            });
        });
    }

    componentDidMount() {
        this.fetchUsers();
    }

    render() {
        const {
            phone,
            email,
            lastName,
            name,
            users,
            startDateInteraction,
            endDateInteraction,
            startDateRegistration,
            endDateRegistration,
            likes,
            cart,
        } = this.state;

        function removeDuplicates(users, field) {
            const output = []
            for (let i = 0; i < users.length; i++) {
                if (!output.includes(users[i][field])) {
                    output.push(users[i][field])
                }
            }
            return output.sort((a, z) => a - z)
        }

        const likesUnique = removeDuplicates(users, 'count_likes')
        const CABINET_LIKES = likesUnique.map((el) => {
            return {
                value: el.toString(),
                label: el.toString(),
            };
        });

        const cartUnique = removeDuplicates(users, 'count_cart')
        const CABINET_CART = cartUnique.map((el) => {
            return {
                value: el.toString(),
                label: el.toString(),
            };
        });

        return <div className="animated fadeIn">
            <div className="filter filter--blue">
                <div className="card card--search-bg">
                    <div className="card-header">
                        <strong>Кабинет туриста</strong>
                    </div>
                    <div className="card-block">
                        <div className="filter-row">
                            <div className="filter-field">
                                <label htmlFor="" className="col-form-label">
                                    Фамилия
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="lastname"
                                    placeholder="Фамилия"
                                    name="lastname"
                                    value={lastName}
                                    onChange={this.handleLastName}
                                />
                            </div>
                            <div className="filter-field">
                                <label htmlFor="" className="col-form-label">
                                    Имя
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    placeholder="Имя"
                                    name="name"
                                    value={name}
                                    onChange={this.handleName}
                                />
                            </div>
                            <div className="filter-field filter__count">
                                <Select
                                    id="likes"
                                    name="likes"
                                    placeholder="Понравилось"
                                    multi={true}
                                    simpleValue={true}
                                    closeOnSelect={true}
                                    removeSelected={true}
                                    value={likes}
                                    onChange={this.handleLikes}
                                    options={CABINET_LIKES}
                                />
                            </div>
                            <div className="filter-field filter__count">
                                <Select
                                    id="cart"
                                    name="cart"
                                    placeholder="В корзине"
                                    multi={true}
                                    simpleValue={true}
                                    closeOnSelect={true}
                                    removeSelected={true}
                                    value={cart}
                                    onChange={this.handleCart}
                                    options={CABINET_CART}
                                />
                            </div>
                        </div>
                        <div className="filter-row ">
                            <div className="filter-field date-from">
                                <div className="date-from">
                                    <DatePicker
                                        dateFormat="DD.MM.YYYY"
                                        selected={startDateInteraction}
                                        selectsStart
                                        startDate={startDateInteraction}
                                        endDate={endDateInteraction}
                                        onChange={this.startDateInteractionChange}
                                        className="d-block"
                                        placeholderText="Дата взаимодействия ОТ"
                                    />
                                </div>
                            </div>
                            <div className="filter-field date-to">
                                <div className="date-to">
                                    <DatePicker
                                        dateFormat="DD.MM.YYYY"
                                        selected={endDateInteraction}
                                        selectsEnd
                                        startDate={startDateInteraction}
                                        endDate={endDateInteraction}
                                        onChange={this.endDateInteractionChange}
                                        placeholderText="Дата взаимодействия ДО"
                                    />
                                </div>
                            </div>
                            <div className="filter-field date-from">
                                <div className="date-from">
                                    <DatePicker
                                        dateFormat="DD.MM.YYYY"
                                        selected={startDateRegistration}
                                        selectsStart
                                        startDate={startDateRegistration}
                                        endDate={endDateRegistration}
                                        onChange={this.startDateRegistrationChange}
                                        className="d-block"
                                        placeholderText="Дата регистрации ОТ"
                                    />
                                </div>
                            </div>
                            <div className="filter-field date-to">
                                <div className="date-to">
                                    <DatePicker
                                        dateFormat="DD.MM.YYYY"
                                        selected={endDateRegistration}
                                        selectsEnd
                                        startDate={startDateRegistration}
                                        endDate={endDateRegistration}
                                        onChange={this.endDateRegistrationChange}
                                        placeholderText="Дата регистрации ДО"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="filter-row">
                            <div className="filter-field">
                              <label htmlFor="" className="col-form-label">
                                  Телефон
                              </label>
                              <input
                                  type="text"
                                  className="form-control"
                                  id="phone"
                                  placeholder="Телефон"
                                  name="phone"
                                  value={phone}
                                  onChange={this.handlePhone}
                              />
                            </div>
                            <div className="filter-field">
                              <label htmlFor="" className="col-form-label">
                                  Email
                              </label>
                              <input
                                  type="text"
                                  className="form-control"
                                  id="email"
                                  placeholder="Email"
                                  name="email"
                                  value={email}
                                  onChange={this.handleEmail}
                              />
                            </div>
                        </div>
                        <div className="card-footer">
                                <button className="btn btn-sm btn-primary" onClick={this.onFilter}>Найти</button>
                                <button
                                    className="btn btn-sm btn-danger"
                                    style={{backgroundColor: 'transparent'}}
                                    onClick={this.handleReset}
                                >
                                    Сбросить
                                </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    }
}
