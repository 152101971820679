import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Col, Row, Card, CardBlock, CardHeader} from 'reactstrap';

import Paginator from '../../components/Paginator';
import FilterOffices from '../Components/FilterOffices';
import {OfficeAPI, POSITIVE_ACTION_STATUSES} from '../../api/Client';
import {getUserRole, SUPER_ADMIN} from '../../connect/auth';
import Toggle from '../Notify/components/Toggle';

export default class Offices extends Component {
  constructor(props) {
    super(props);

    this.handlePageChange = this.handlePageChange.bind(this);
    this.fetchItems = this.fetchItems.bind(this);
    this.filterItems = this.filterItems.bind(this);
    this.toggleModerationForOffice = this.toggleModerationForOffice.bind(
        this,
    );

    this.state = {
      items: [],
      hasNext: false,
      hasPrev: false,
      pageNumber: 1,
      count: 0,
      filters: null,
      isError: false,
    };
  }

  handlePageChange(data) {
    this.fetchItems(data.selected + 1);
  }

  toggleModerationForOffice(index) {
    let status;
    const {items} = this.state;
    const current = items[index];

    OfficeAPI.
        update(
            `${OfficeAPI.resource_url}${current.id}`,
            {
              is_moderate_campaign: !current.is_moderate_campaign,
            },
        ).
        then(r => {
          status = r.status;
          return r.json();
        }).
        then(r => {
          if (status === POSITIVE_ACTION_STATUSES.partial_update) {
            this.setState(prevState => {
              const newItems = [...prevState.items];
              newItems[index] = r;
              return {
                items: newItems,
                isError: false,
              };
            });
          } else {
            this.setState({
              isError: true,
            });
          }
        });
  }

  toggleinSiteForOffice(index) {
    let status;
    const {items} = this.state;
    const current = items[index];

    OfficeAPI.
    update(
        `${OfficeAPI.resource_url}${current.id}`,
        {
          in_site_forms: !current.in_site_forms,
        },
    ).
    then(r => {
      status = r.status;
      return r.json();
    }).
    then(r => {
      if (status === POSITIVE_ACTION_STATUSES.partial_update) {
        this.setState(prevState => {
          const newItems = [...prevState.items];
          newItems[index] = r;
          return {
            items: newItems,
            isError: false,
          };
        });
      } else {
        this.setState({
          isError: true,
        });
      }
    });
  }

  filterItems(st) {
    let result = {};

    if (st.email) {
      result.email = st.email;
    }
    if (st.address) {
      result.address = st.address;
    }

    if (result) {
      this.setState(
          {filters: result},
          () => this.fetchItems(1),
      );
    }
  }

  fetchItems(page = 1) {
    let status;

    OfficeAPI.
        fetchList(page, this.state.filters).
        then(r => {
          status = r.status;
          return r.json();
        }).
        then(result => {
          if (status === POSITIVE_ACTION_STATUSES.partial_update) {
            this.setState({
              pageNumber: page,
              items: result.results || [],
              hasNext: result.next != null,
              hasPrev: result.previous != null,
              count: result.count,
              isError: false,
            });
          }
        });
  }

  componentDidMount() {
    this.fetchItems(this.state.pageNumber);
  }

  render() {
    const userRole = getUserRole();

    return (
        <div className="animated fadeIn">
          <div className="filter">
            <FilterOffices name="" onFilter={this.filterItems}/>
          </div>
          <Row>
            <Col lg="12">
              <Card>
                <CardHeader>
                  <i className="fa fa-align-justify">{''}</i> Всего&nbsp;
                  <span className="badge badge-info">{this.state.count}</span>
                </CardHeader>
                <CardBlock className="card-block_responsive">
                  <table
                      className="table table-bordered table-striped table-sm">
                    <thead>
                    <tr>
                      <th>Id</th>
                      <th>Область</th>
                      <th>Адрес</th>
                      <th>email</th>
                      <th>Работающий</th>
                      <th hidden={userRole !== SUPER_ADMIN}>Модерация</th>
                      <th hidden={userRole !== SUPER_ADMIN}>На сайте</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                      this.state.items.map((item, idx) => (
                          <tr key={item.id}>
                            <td><Link
                                to={`/offices/${item.id}`}>{item.id}</Link></td>
                            <td>{item.state}</td>
                            <td>{item.address}</td>
                            <td>{item.email}</td>
                            <td>{item.active ? 'Да' : 'Нет'}</td>
                            <td hidden={userRole !== SUPER_ADMIN}>
                              <Toggle
                                  id={item.id}
                                  lang=""
                                  checked={item.is_moderate_campaign}
                                  onChange={
                                    () => this.toggleModerationForOffice(idx)
                                  }
                              />
                            </td>
                            <td hidden={userRole !== SUPER_ADMIN}>
                              <Toggle
                                  id={item.id}
                                  lang=""
                                  checked={item.in_site_forms}
                                  onChange={
                                    () => this.toggleinSiteForOffice(idx)
                                  }
                              />
                            </td>
                          </tr>
                      ))
                    }
                    </tbody>
                  </table>
                  <Paginator total={this.state.count}
                             onPageChange={this.handlePageChange}/>
                </CardBlock>
              </Card>
            </Col>
          </Row>
        </div>
    );
  }
}
