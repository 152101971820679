import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Col, Row, Card, CardBlock, CardHeader, Alert} from 'reactstrap';

import Paginator from '../../components/Paginator';
import FilterSummaries from './FilterSummaries';
import {OfficeSummaryAPI, POSITIVE_ACTION_STATUSES} from '../../api/Client';
import {getUserRole, SUPER_ADMIN} from '../../connect/auth';
import {SUMMARY_ORDERING_FIELDS} from './FilterSummaries';
import ProgressBar from '../../components/ProgressBar';

export default class SummariesForOffices extends Component {
  constructor(props) {
    super(props);

    this.handlePageChange = this.handlePageChange.bind(this);
    this.fetchItems = this.fetchItems.bind(this);
    this.filterItems = this.filterItems.bind(this);
    this.updateData = this.updateData.bind(this);
    this.handlerSort = this.handlerSort.bind(this);

    this.state = {
      items: [],
      pageNumber: 1,
      count: 0,
      filters: null,
      isError: false,
      isSuccess: false,
      isLoading: false,
      order: '',
      orderPrev: '',
    };
  }

  handlePageChange(data) {
    this.fetchItems(data.selected + 1);
  }

  filterItems(st) {
    let result = {};

    if (st.contacts) {
      result.contacts = st.contacts;
    }

    if (st.name) {
      result.name = st.name;
    }

    if (st.ordering && st.direction) {
      result.ordering = `${st.direction === 'desc' ? '-' : ''}${st.ordering}`;
    }

    if (typeof st.onlyWithAccess === 'number') {
      result.was_accessed = st.onlyWithAccess;
    }

    if (st.city) {
      result.city = st.city;
    }
    if (st.country) {
      result.country = st.country;
    }

    if (st.type) {
      result.type = st.type;
    }

    if (result) {
      this.setState(
          {filters: result},
          () => this.fetchItems(1),
      );
    }
  }

  handlerSort(e) {
    e.preventDefault();
    let param = e.target.title;
    this.setState({
      order: param,
      orderPrev: this.state.order,
      sortToggle: this.state.order != param ? true : !this.state.sortToggle,
    }, () => this.fetchItems(1, this.state.filters))
  }

  fetchItems(page = 1) {
    let status;
    this.setState({
      isLoading: true,
      isError: false,
      isSuccess: false,
    });
    let stopLoading = () => {
      this.setState({
        isLoading: false,
      });
    };
    let {filters} = this.state;
    if (this.state.order) {
      filters = {
        ...filters,
        ordering: `${this.state.sortToggle?'-':''}${this.state.order}`
      };
    }
    OfficeSummaryAPI.
        fetchList(page, filters).
        then(r => {
          status = r.status;
          return r.json();
        }).
        then(result => {
          if (status === POSITIVE_ACTION_STATUSES.list) {
            this.setState({
              pageNumber: page,
              items: result.results || [],
              count: result.count,
              isError: false,
              isSuccess: false,
            }, () => setTimeout(stopLoading, 300));
          } else {
            this.setState({
              pageNumber: page,
              items: [],
              count: 0,
              isError: false,
              isSuccess: false,
            }, () => setTimeout(stopLoading, 300));
          }
        });
  }

  updateData() {
    OfficeSummaryAPI.
        renewal().
        then(r => {
          if (r.status === POSITIVE_ACTION_STATUSES.list) {
            this.setState({
              isSuccess: true,
              isError: false,
            });
          } else {
            this.setState({
              isError: true,
              isSuccess: false,
            });
          }
        });
  }

  componentDidMount() {
    this.fetchItems(1);
  }

  render() {
    const userRole = getUserRole();
    if (userRole !== SUPER_ADMIN) {
      return null;
    }
    const {items, count, isSuccess, isError, pageNumber, isLoading} = this.state;

    return (
        <div className="animated fadeIn">
          <div className="filter">
            <FilterSummaries onFilter={this.filterItems} onUpdateData={this.updateData}
                             isBlocked={isError || isSuccess || isLoading}
            />
          </div>
          <div className="filter">
            {isSuccess ? (
                <Alert color="success">
                  <strong>
                    Успешно!
                  </strong>
                  Для просмотра обновленных данных обновите страницу в течение минуты.
                </Alert>
            ) : null}
            {isError ? (
                <Alert color="danger">
                  <strong>Данные обновляются. </strong>
                  Повторите запрос позже.
                </Alert>
            ) : null}
          </div>
          {isLoading ? <ProgressBar/> : (
              <Row>
                <Col lg="12">
                  <Card>
                    <CardHeader>
                      <i className="fa fa-align-justify">{''}</i> Всего&nbsp;
                      <span className="badge badge-info">{count}</span>
                    </CardHeader>
                    <CardBlock className="card-block_responsive">
                      <table className="table table-bordered table-striped table-sm">
                        <thead>
                        <tr>
                          <th>Id</th>
                          <th>Город</th>
                          <th>Офис</th>
                          <th>Агентство</th>
                          {
                            SUMMARY_ORDERING_FIELDS.map((item, idx) => (
                                <th key={idx} onClick={this.handlerSort} style={{cursor: 'pointer'}} title={item.value}>
                                  {item.label}
                                  <i className="fa fa-sort ml-2" title={item.value}></i>
                                </th>
                            ))
                          }
                        </tr>
                        </thead>
                        <tbody>
                        {
                          Array.isArray(items) && items.length ? items.map(item => (
                              <tr key={item.office}>
                                <td>
                              <span
                                  className={`float-right badge badge-pill ${
                                      item.was_accessed
                                          ? 'badge-success'
                                          : 'badge-warning'
                                  }`}>
                                {item.office}
                              </span>
                                </td>
                                <td>
                                  {item.city}
                                </td>
                                <td>
                                  <Link to={item.agency ? `/agencies/${item.agency.id}` : `/offices/${item.office}`}>
                                    {item.name}
                                  </Link>
                                </td>
                                <td>
                                  {!!item.agency ? (
                                      <Link to={`/agencies/${item.agency.id}`}>
                                        {item.agency.public_name}
                                      </Link>
                                  ) : null}
                                </td>
                                {
                                  SUMMARY_ORDERING_FIELDS.map((current, idx) => (
                                      <td key={idx}>{item[current.value]}</td>
                                  ))
                                }
                              </tr>
                          )) : null
                        }
                        </tbody>
                      </table>
                      <Paginator total={count} onPageChange={this.handlePageChange}
                                 forcePage={pageNumber - 1}/>
                    </CardBlock>
                  </Card>
                </Col>
              </Row>
          )}
        </div>
    );
  }
}
