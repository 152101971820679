import React, {Component} from 'react';
import file_1 from '../../../files/1.docx'
import file_2 from '../../../files/2.docx'
import file_3 from '../../../files/3.docx'
import file_4 from '../../../files/4.docx'
import file_5 from '../../../files/5.docx'
import file_6 from '../../../files/6.docx'
import file_7 from '../../../files/7.docx'
    export default class SellBook extends Component {

    render() {
        return (
            <div>
                <h1>Книга продаж</h1>
                <ul><a href={file_1} download>1. Правила продажи туров</a></ul>
                <ul><a href={file_2} download>2. Какие касания мы делаем при продаже тура</a></ul>
                <ul><a href={file_3} download>3. Почему клиенту выгодно купить СЕЙЧАС</a></ul>
                <ul><a href={file_4} download>4. Причины БЫТЬ НА СВЯЗИ</a></ul>
                <ul><a href={file_5} download>5. Приглашаем в офис</a></ul>
                <ul><a href={file_6} download>6. ФИНАЛЬНОЕ ПИСЬМО</a></ul>
                <ul><a href={file_7} download>7. ШАБЛОНЫ ПИСЕМ</a></ul>
            </div>
        )
    }
};
