import DatePicker from 'react-datepicker';
import React, { Component } from 'react';
import Select from 'react-select-plus';
import { Alert } from 'reactstrap';

import { AnswersAPI, POSITIVE_ACTION_STATUSES } from '../../../api/Client';

class IssueCommentForm extends Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
    this.successSubmit = this.successSubmit.bind(this);
    this.errorMessage = this.errorMessage.bind(this);
    this.doWithTimeout = this.doWithTimeout.bind(this);
    this.handleComment = this.handleComment.bind(this);

    this.state = {
      id: '',
      title: 'title',
      date: '',
      time: '',
      comment: '',
      created: '',
      resolved: false,
      answers: [],
      showErrorMessage: false,
      showSuccessMessage: false,
      toursSenderType: '',
      toursSmsMessage: '',
      toursEmailMessage: '',
      quoteData: '',
      touristType: this.props.info?.personId ? 'person' : 'tourist',
      person: this.props.info,
    };
  }

  submit(e) {
    e.preventDefault();
    // const { _issue, _text } = this.refs;
    // this.props.onSubmit(_issue.value, _text.value);
    // _text.value = '';
    let typeMap = {
      1: 'comment',
      2: 'meet',
      3: 'cancel',
      4: 'deffered',
      5: 'tours',
      10: 'call',
      client_cancel: 'client_cancel',
      we_cancel: 'we_cancel',
    };
    let status;
    let data = {
      issue: this.props.id,
      title: this.state.title,
      text: this.state.comment,
      type: typeMap[this.props.type],
    };
    if (this.state.date) {
      data.date = this.state.date;
    }
    if (this.state.time) {
      data.time = this.state.time.value;
    }

    AnswersAPI.create(data)
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((result) =>
        status === POSITIVE_ACTION_STATUSES.create
          ? this.successSubmit(result)
          : this.errorMessage(result)
      );
  }

  doWithTimeout(fn, timeout = 2000) {
    setTimeout(fn.bind(this), timeout);
    setTimeout(() => {
      this.setState({
        ...this.state,
        showErrorMessage: false,
        showSuccessMessage: false,
      });
      this.props.onCreate();
    }, timeout);
  }

  successSubmit(r) {
    this.setState({
      ...this.state,
      title: '',
      comment: '',
      showSuccessMessage: true,
    });
    this.doWithTimeout(this.props.onCreate);
    // TouristFpAPI.getIssues(this.tourist, page)
    // .then(result => {
    //     this.setState({
    //     ...this.state,
    //     answers: result
    //     });
    // });
  }

  errorMessage(err) {
    this.setState({
      ...this.state,
      showErrorMessage: err,
    });
  }

  handleComment(e) {
    this.setState({
      ...this.state,
      comment: e.target.value,
    });
  }

  handleDate = (date) => {
    this.setState({
      ...this.state,
      date: date.format('YYYY-MM-DD'),
    });
  };

  handleTime = (time) => {
    this.setState({
      ...this.state,
      time: time,
    });
  };

  render() {
    const { isLock, type } = this.props;
    const { showErrorMessage, showSuccessMessage } = this.state;

    let times = [];
    for (let i = 6; i < 24; i++) {
      let _value = i < 10 ? `0${i}:00` : `${i}:00`;
      times.push({ value: _value, label: _value });
      _value = i < 10 ? `0${i}:15` : `${i}:15`;
      times.push({ value: _value, label: _value });
      _value = i < 10 ? `0${i}:30` : `${i}:30`;
      times.push({ value: _value, label: _value });
      _value = i < 10 ? `0${i}:45` : `${i}:45`;
      times.push({ value: _value, label: _value });
    }

    return (
      <form>
        <div className="form-group row">
          {!isLock ? (
            <div className="col-md-12">
              <textarea
                disabled={isLock}
                autoFocus={true}
                name="textarea-input"
                rows="7"
                className="form-control"
                placeholder="Введите сообщение"
                value={this.state.comment}
                onChange={this.handleComment}
                onKeyPress={(event) => {
                  if (event.key === 'Enter') {
                    this.submit(event);
                  }
                }}
              />
              {showErrorMessage && showErrorMessage.text && (
                <div className="invalid-feedback">{showErrorMessage.text}</div>
              )}
            </div>
          ) : null}
        </div>

        {!isLock && (type == 2 || type == 4 || type == 10) && (
          <div className="form-group row">
            <label
              className="col-md-4 form-control-label"
              htmlFor="textarea-input"
            >
              Выберите день:{' '}
            </label>
            <div className="col-md-4">
              <DatePicker
                className="form-control-label"
                onChange={this.handleDate}
                placeholderText="Выберите дату"
                value={this.state.date}
              />
              {showErrorMessage && showErrorMessage.date && (
                <div className="invalid-feedback">{showErrorMessage.date}</div>
              )}
            </div>
            <div className="col-md-4">
              <Select.Creatable
                closeOnSelect={true}
                onChange={this.handleTime}
                options={times}
                placeholder="Выберите / Введите время"
                promptTextCreator={(label) => `Выбрать "${label}"`}
                removeSelected={true}
                value={this.state.time}
              />
              {showErrorMessage && showErrorMessage.time && (
                <div className="invalid-feedback">{showErrorMessage.time}</div>
              )}
            </div>
          </div>
        )}
        <div>
          {!isLock && (
            <button
              className="issue_btn btn btn-success mr-2 mb-2"
              onClick={this.submit}
            >
              Сохранить
            </button>
          )}
        </div>

        {showErrorMessage && (
          <Alert color="warning">
            <strong>Ошибка!</strong> Не удалось создать
          </Alert>
        )}

        {showSuccessMessage && (
          <Alert color="success">
            <strong>Комментарий</strong> Успешно добавлен
          </Alert>
        )}
      </form>
    );
  }
}

export default IssueCommentForm;
