import React from "react";

class Toggle extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { id, checked, onChange, lang, switchClass } = this.props;

    return (
      <span>
        <span className="switch-container">
          <span>{lang}</span>
          <label
            className={`switch switch-text ${
              switchClass ? switchClass : `switch-success`
            }`}
          >
            <input
              type="checkbox"
              className="switch-input"
              title={id}
              checked={checked}
              onChange={onChange}
            />
            <span className="switch-label">{""}</span>
            <span className="switch-handle">{""}</span>
          </label>
        </span>
      </span>
    );
  }
}

export default Toggle;
