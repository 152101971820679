import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import { AuthRequired, getUserRole, SUBAGENT } from '../../connect/auth'

import Header from './header'
import Sidebar from './sidebar'
import Dashboard from '../../views/Dashboard/'
import DashboardMobile from '../../views/Dashboard/DashboardMobile'
import Leads from '../../views/Leads'
import Lead from '../../views/Lead'
import Persons from '../../views/Persons'
import Person from '../../views/Person'
import PersonEdit from '../../views/Person/components/PersonEdit'
import OrdersFp from '../../views/OrdersFp'
import OrderFp from '../../views/OrderFp'
import OrderFpEdit from '../../views/OrderFp/components/OrderFpEdit'
import Offices from '../../views/Offices'
import Office from '../../views/Office'
import Templates from '../../views/Templates'
import Telebot from '../../views/Telebot/Telebot'
import Mailbox from '../../views/Mailbox/Mailbox'
import Report from '../../views/Mailbox/components/Report'
import Options from '../../views/Options'
import Instructions from '../../views/Instructions'
import Calendar from '../../views/Components/Calendar'
import CalendarDetail from '../../views/Components/Calendar/Components/CalendarDetail'
import Notify from '../../views/Notify'
import Breadcrumb from "../../components/__Breadcrumb/";
import Tourists from '../../views/TouristsFp'
import Tourist from '../../views/TouristFp'
import IssuesReport from '../../views/IssuesReport'
import Issues from '../../views/Issues/IssuesPage'
import Issue from '../../views/Issue'
import Tags from '../../views/Tags'
import Quotes from '../../views/Tours'
import Quote from '../../views/Quote'
import Employee from '../../views/Employees'
import TouristEdit from '../../views/TouristFp/components/TouristFpEdit'
import Employees from '../../views/Employees'
import EmployeesDetail from '../../views/Employees/components/EmployeesDetail'
import TemplateSettings from '../../views/Options/components/TemplateSettings'
import MailigenSetting from '../../views/MailigenSetting';
import CashAccounts from '../../views/CashAccounts/CashAccounts';
import CashAccount from '../../views/CashAccounts/components/CashAccount';
import Invoice from '../../views/Invoices/Invoice';
import Agencies from '../../views/Agencies/Agencies';
import Agency from '../../views/Agencies/components/Agency';
import FormSubagent from '../../views/Employees/components/FormSubagent';
import SendPulseSetting from '../../views/SendPulseSetting/SendPulseSetting';
import {SIMPLE, NOTIFICATION} from '../../views/Mailbox/Mailbox';
import SummariesForOffices from '../../views/Offices/SummariesForOffices';
import FranchiseApplications from '../../views/FranchiseApplicationList/FranchiseApplications';
import FranchiseApplication from '../../views/FranchiseApplicationList/FranchiseApplication';
import FranchiseApplicationAction from '../../views/FranchiseApplicationList/FranchiseApplicationAction';
import SellBook from "../../views/SellBook/components/SellBook";
import Subscription from "../../views/Subscription/Subscription";
import AbandonedCustomers from "../../views/AbandonedCustomers/AbandonedCustomers";
import TouristCabinets from "../../views/TouristCabinet/TouristCabinets";
import TouristCabinet from "../../views/TouristCabinet/TouristCabinet";
/*

Схема приложения
----------------

1. Header <Header/>
2. Body
  2.1 Sidebar <Sidebar/>
  2.2 Main
    2.2.1.Breadcrumb <Breadcrumb/>
    2.2.2 Container
3. Footer <Footer/>

*/


const mainContainerStyle = {
  padding: '0px 16px 0px 16px',
  // height:'100%',
}
const screenWidth = window.screen.width;
const screenWidthMaxMobile = 680;

const containerRender = () => {
  const userRole = getUserRole();
  return (
    <div>
        <Breadcrumb />
        <div className="container-fluid" style={mainContainerStyle}>
          <Switch>

            {/*..Notify..*/}
            <Route exact path="/notify" component={Notify}/>

            {/*..Dashboard..*/}
            <Route exact path='/dashboard' component={ screenWidth > screenWidthMaxMobile ? Dashboard : DashboardMobile}/>

            {/*..Offices..*/}
            <Route exact path='/offices' component={Offices}/>
            <Route path='/offices/:number' component={Office}/>
            <Route exact path='/summaries' component={SummariesForOffices}/>

            {/*..Orders..*/}
            <Route exact path='/ordersfp' component={OrdersFp}/>
            <Route path='/ordersfp/:number/edit' component={OrderFpEdit} />
            <Route path='/ordersfp/:number' component={OrderFp} />

            {/*..Tourists..*/}
            <Route exact path='/tourists' component={Tourists}/>
            <Route path='/tourists/:number/edit/' component={TouristEdit} />
            <Route path='/tourists/:number' component={Tourist} />
            {/*..Leads..*/}
            <Route exact path='/leads' component={Leads}/>
            <Route path='/leads/:number' component={Lead}/>

            {/*..Persons..*/}
            <Route exact path='/persons' component={Persons}/>
            <Route path='/persons/:number/edit' component={PersonEdit} />
            <Route path='/persons/:number' component={Person}/>

            <Route exact path='/issues' component={Issues} />
            <Route path='/issues/:number' component={Issue} />
            <Route exact path='/issues-report' component={IssuesReport} />

            {/*..Employees..*/}
            <Route exact path='/employees' component={Employees}/>
            <Route path='/employees/:number/edit/' component={FormSubagent} />
            <Route path='/employees/:number' component={EmployeesDetail} />

            <Route path='/tags' component={Tags} />
            <Route path='/sellbook' component={SellBook} />

            {/*..User..*/}
            <Route path='/adduser' component={Employee} />

            <Route exact path='/cashaccounts' component={CashAccounts}/>
            <Route exact path='/cashaccounts/:number' component={CashAccount}/>
            <Route exact path='/invoices/:number' component={Invoice}/>
            <Route exact path='/agencies' component={Agencies}/>
            <Route exact path='/agencies/:number' component={Agency}/>

            <Route exact path='/subscription' component={Subscription}/>

            <Route exact path='/abandonedcustomers' component={AbandonedCustomers}/>

            {/*..Templates...*/}
            <Route exact path='/templates' component={Templates}/>
            <Route exact path='/telebot' component={Telebot}/>
            <Route exact path='/mailbox' render={
              (props) => (
                  <Mailbox
                      campaignInitType={SIMPLE}
                      isDesktop={screenWidth > screenWidthMaxMobile}
                      {...props}
                  />)
            }/>
            <Route exact path='/notification' render={
              (props) => (
                  <Mailbox
                      campaignInitType={NOTIFICATION}
                      isDesktop={screenWidth > screenWidthMaxMobile}
                      {...props}
                  />)
            }/>
            <Route path='/mailbox/:number' component={Report} />
            <Route path='/notification/:number' component={Report} />

            <Route path='/template/:number/edit' component={TemplateSettings} />
            <Route path='/template/:number' component={TemplateSettings} />

            <Route exact path='/options' component={Options} />
            <Route exact path='/instructions' component={Instructions} />
            <Route path='/mailigen/:number/edit' component={MailigenSetting} />
            <Route path='/mailigen/:number' component={MailigenSetting} />
            <Route path='/sendpulse/:number/edit/' component={SendPulseSetting} />
            <Route path='/sendpulse/:number' component={SendPulseSetting} />
            <Route path='/franchising/:number/edit' component={FranchiseApplicationAction} />
            <Route path='/franchising/:number' component={FranchiseApplication} />
            <Route path='/franchising' component={FranchiseApplications} />

            <Route path='/touristcabinets/:number' component={TouristCabinet} />
            <Route path='/touristcabinets' component={TouristCabinets} />

            <Route exact path='/calendar' component={Calendar}/>
            <Route path='/calendar/:string' component={CalendarDetail}/>

            <Route exact path='/quotes' component={Quotes} />
            <Route path='/quotes/:string' component={Quote} />
            {/*.redirect..*/}
            {userRole !== SUBAGENT &&
            <Redirect from="/" to="/dashboard"/>}
            {userRole === SUBAGENT &&
            <Redirect from="/" to="/quotes"/>}

          </Switch>
        </div>
    </div>
  )
}

export default (props) => {

  return (
    <AuthRequired>
      <div className="app">
        <Header />
        <div className="app-body">
          <Sidebar/>
           <main className="main">
             {containerRender()}
          </main>
        </div>
      </div>
    </AuthRequired>
  )

}
