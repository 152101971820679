import React, { Component } from "react";
import { Card, CardBlock, CardHeader, CardFooter } from "reactstrap";
import moment from "moment";
import "moment/locale/ru";
import UserRoles from "./UserRoles";
import OfficeSelectContainer from "../../../components/OfficeSelectContainer";
import FormSubagent from "./FormSubagent";

class EmployeesFilter extends Component {
  constructor(props) {
    super(props);

    this.handleReset = this.handleReset.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.formTextChange = this.formTextChange.bind(this);
    this.handleUserRole = this.handleUserRole.bind(this);
    this.changeOffice = this.changeOffice.bind(this);
    this.changeOfficeCity = this.changeOfficeCity.bind(this);

    moment.locale("ru");

    this.state = {
      userRole: null,
      userData: "",
      offices: [],
      offices_city: null,
    };
  }

  changeOffice(offices) {
    this.setState({ offices });
  }
  changeOfficeCity(offices_city) {
    this.setState({ offices_city });
  }

  handleReset() {
    this.setState(
      {
        userRole: null,
        userData: "",
        offices: [],
        offices_city: null,
      },
      this.handleSubmit
    );
  }

  handleUserRole(val) {
    this.setState({
      userRole: val,
    });
  }

  handleSubmit() {
    const { onFilter } = this.props;
    onFilter(this.state);
  }

  formTextChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { userRole, userData, offices } = this.state;
    const { userRoles, isShowForm, toggleFormVisibility, onCreate } =
      this.props;

    return (
      <Card>
        <CardHeader>
          <strong>Поиск</strong> сотрудников
        </CardHeader>
        <CardBlock>
          <form action="" method="post">
            <OfficeSelectContainer
              multi_office={true}
              selected_city={this.state.offices_city}
              selected_office={offices}
              changeCity={this.changeOfficeCity}
              changeOffice={this.changeOffice}
            >
              <div className="filter-field">
                <label htmlFor="public_name">Данные сотрудника</label>
                <input
                  type="text"
                  className="form-control"
                  id="fp_pk"
                  placeholder="ФИО логин email"
                  name="userData"
                  value={userData}
                  onChange={this.formTextChange}
                />
              </div>
              <UserRoles
                handleUserRole={this.handleUserRole}
                userRole={userRole}
                userRoles={userRoles}
              />
            </OfficeSelectContainer>
          </form>
          <div
            className={`card-footer-container ${
              isShowForm ? "card-footer-container-form_active" : ""
            }`}
          >
            <div className="filter">
              <button
                type="button"
                className="btn btn-sm btn-primary"
                style={{ width: "auto", alignSelf: "flex-end" }}
                onClick={toggleFormVisibility}
              >
                <i className="fa fa-dot-circle-o"></i>{" "}
                {isShowForm ? "Скрыть форму" : `Создать сотрудника`}
              </button>
              {isShowForm ? (
                <FormSubagent
                  onCreate={onCreate}
                  userRoles={userRoles}
                  isShow={isShowForm}
                  formAction={"add"}
                  isFormCreate={true}
                />
              ) : null}
            </div>
            <CardFooter>
              <button
                type="submit"
                className="btn btn-sm btn-primary"
                onClick={this.handleSubmit}
              >
                <i className="fa fa-dot-circle-o"></i> Поиск
              </button>
              <button
                type="reset"
                className="btn btn-sm btn-danger"
                onClick={this.handleReset}
              >
                <i className="fa fa-ban"></i> Очистить
              </button>
            </CardFooter>
          </div>
        </CardBlock>
      </Card>
    );
  }
}

export default EmployeesFilter;
