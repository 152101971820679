import React, { Component } from "react";

export class InputGroup extends Component {
  constructor(props) {
    super(props);
  }

  handleInput(inputItem, groupId, e) {
    this.props.onChange(inputItem, groupId, e);
  }

  render() {
    const { id, inputs, onDelete } = this.props;
    return (
      <div className="utm__inputs utm__inputs_custom">
        <input
          name="inputKey"
          value={inputs.inputKey.value}
          onChange={(e) => this.handleInput(inputs.inputKey, id, e)}
          placeholder="Название метки"
          className="utm__input"
          required
          maxLength={100}
        />
        <input
          name="inputValue"
          value={inputs.inputValue.value}
          onChange={(e) => this.handleInput(inputs.inputValue, id, e)}
          placeholder="Значение метки"
          className="utm__input"
          required
          maxLength={100}
        />
        <button
          className="utm__delete-inputs-btn utm__btn utm__btn btn btn-sm btn-danger"
          onClick={() => onDelete(id)}
        >
          Удалить
        </button>
      </div>
    );
  }
}

export default InputGroup;
