import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Row, Col, Nav, NavItem, NavLink, Table, TabContent, TabPane} from 'reactstrap';
import classnames from 'classnames';
import moment from 'moment';
import 'moment/locale/ru';

import {AgenciesAPI, POSITIVE_ACTION_STATUSES} from '../../../api/Client';
import BackButton from '../../../components/BackButton';
import AgencyOptions from './AgencyOptions';


export default class Agency extends Component {
  constructor(props) {
    super(props);

    this.fetchData = this.fetchData.bind(this);

    this.state = {
      data: {
        offices: []
      },
    };
  }

  fetchData() {
    const pk = this.props.match.params.number;
    let status;

    AgenciesAPI.
        fetchJSON(`${pk}/`).
        then(r => {
          status = r.status;
          return r.json();
        }).
        then(r => {
          if (status === POSITIVE_ACTION_STATUSES.retrieve) {
            this.setState({
              data: r || {},
            });
          }
        });
  }

  componentDidMount() {
    this.fetchData();
  }

  render() {
    const {data} = this.state;
    if (!data) {
      return null;
    }
    const office = (!!data.offices.length) ? data.offices[0] : {address: ""};

    let summary = {
      tourists: 0,
      leads: 0,
      leads_to_order: 0,
      issues: 0,
      issues_this_week: 0
    }

    if (!!data.offices.length) {
      summary = data.offices[0].summary
    }
    console.log(data)

    return (
        <div className="animated fadeIn filter filter--green">
            <div className="col-md-12">
              <div className="card card-accent-primary">
                <div className="card-header">
                  <h3>{office.address}</h3>
                </div>
                <div className="card-block">
                  <Row>
                    <Col xs="12" className="agency__col">
                      <label className="mr-2 agency__col_title">Название:</label>
                      <span  className="agency__col_data">{data.public_name}</span>
                      <label className="mr-2 agency__col_title">Email:</label>
                      <span  className="agency__col_data">{data.email}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" className="agency__col">
                      <label className="mr-2 agency__col_title">Дата открытия:</label>
                      <span className="agency__col_data">{moment(data.opened_date).format('DD.MM.YYYY')}</span>
                      <label className="mr-2 agency__col_title">Руководитель:</label>
                      <span  className="agency__col_data">{data.director}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" className="agency__col">
                      <label className="mr-2 agency__col_title">С опытом или без:</label>
                      <span className="agency__col_data">{data.with_experience ? "Да" : "Нeт"}</span>
                      <label className="mr-2 agency__col_title">Телефоны:</label>
                      <span className="agency__col_data">{data.phones}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12" className="agency__col">
                      <label className="mr-2 agency__col_title">номер в CRM:</label>
                      <span className="agency__col_data">{data.id}</span>
                      <label className="mr-2 agency__col_title">номер в ФСУ:</label>
                      <span className="agency__col_data">{data.fp_index}</span>
                    </Col>
                  </Row>
                <Nav tabs className="mt-4">
                  <NavItem>
                    <NavLink
                        className={classnames({
                          active: true,
                        })}
                        onClick={() => {
                          this.toggleTab('1');
                        }}
                    >
                      <i className="fa fa-list"></i> Общие сведения
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent
                  className="col-sm-12 col-md-12 col-lg-12"
                  activeTab={"1"}
                >
                    <TabPane tabId="1">
                    <div>
                      <Table striped bordered size="sm" className="font-xs table--orange">
                        <thead>
                          <tr>
                            <th className="text-center hidden  tableItem">*</th>
                            <th className="text-center tableItem hidden ">Продажи за текущий год</th>
                            <th className="text-center tableItem">Клиентская база</th>
                            <th className="text-center tableItem hidden ">Заявки с сайта</th>
                            <th className="text-center tableItem">Перешли в покупку</th>
                            <th className="text-center tableItem">Запросы на туры</th>
                            <th className="text-center tableItem hidden ">Запросы на тур(за неделю)</th>
                            <th className="text-center tableItem hidden ">Sms рассылка</th>
                            <th className="text-center tableItem">Email рассылка</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th className="text-center hidden  tableItem">
                              <input type="checkbox" />
                            </th>
                            <th className="text-center tableItem hidden ">{data.sales_per_year}</th>
                            <th className="text-center tableItem">{summary ? summary.tourists : null}</th>
                            <th className="text-center tableItem hidden ">{summary ? summary.leads : null}</th>
                            <th className="text-center tableItem">{summary ? summary.leads_to_order : null}</th>
                            <th className="text-center tableItem">{summary ? summary.issues : null}</th>
                            <th className="text-center tableItem hidden ">{summary ? summary.issues_this_week : null}</th>
                            <th className="text-center tableItem hidden ">0</th>
                            <th className="text-center tableItem">0</th>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    <AgencyOptions {...this.props} data={this.state.data} onUpdate={this.fetchData} item={data} />
                  </TabPane>
                </TabContent>
                <Row>
                  <Col xs="12" className="agency__col">
                  </Col>
                </Row>
                { data.comments && data.comments.length ? <div className="mb-3">
                  <h5>Комментарий:</h5>
                  {data.comments.map( comment => <div key={comment.id} className="p-2 mb-1" style={{backgroundColor: '#d9eee0', display:'flex', borderRadius: '5px', justifyContent: 'space-between'}}>
                    <div>{comment.text}</div><div>{moment(comment.created).format('DD.MM.YYYY')}</div>
                  </div>)}
                </div> : null}
                  {/* <div className="row">
                    <label className="col-md-2">
                      Список заявок на франчайзинг:
                    </label>
                    <ul>
                      {Array.isArray(data.franchise_applications) &&
                      data.franchise_applications.length
                          ? data.franchise_applications.map(franchise_application => (
                              <li className="item__employee" key={franchise_application.id}>
                                <Link to={`/franchising/${franchise_application.id}`}>
                                  {franchise_application.applicant.name}{' '}
                                  (№{franchise_application.id})
                                </Link>
                              </li>
                          ))
                          : null}
                    </ul>
                  </div> */}
                  <BackButton {...this.props}/>
                </div>
              </div>
            </div>
        </div>
    );
  }
}
