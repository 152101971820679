import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from '../reducers/reducers'
const composeEnhancers = composeWithDevTools({});

const createStoreWithMiddleware = composeEnhancers(applyMiddleware(thunk))(createStore);

export default function configureStore(initialState) {
    return createStoreWithMiddleware(rootReducer, initialState);
}
