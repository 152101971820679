import React, { PureComponent } from "react";
import ClientAPI, {StagingsAPI, POSITIVE_ACTION_STATUSES} from '../../../api/Client/Client';

export default class ListCabinet extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            count_tourist: 0
        }
    }

    componentDidMount() {
        let status;
        ClientAPI.
            fetchJSON(`/site_cabinet/users/?&page=1`).
            then(r => {
                status = r.status;
                return r.json()
            }).
            then(result => {
                if (status === POSITIVE_ACTION_STATUSES.list) {
                    this.setState({count_tourist: result.count})
                }
            });
        this.props.onFilter({type: 'cabinet'})
    }

    render() {
        return <h4 className="text-success">Выбрано туристов кабинета: {this.state.count_tourist}</h4>
    }
}
