// it6@geograftour.com
// const apiKey = 'd1j5639otybi9kln84fvjptht4pr2mve44a6sy8evrv985ur';

// tusichSasha
const apiKey = 'zj04kn30vys0edwjrd9ha33vsu1ncnupy9wg7b1vrpo0b640';

// artklementev@gmail.com
// const apiKey = 'zn607c2d12ey4xnvogmtky7mjvnk02ns5kr8c6e9iao13ilc';

export default apiKey;
