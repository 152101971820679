import React, {PureComponent} from 'react';
import {Alert, FormGroup, Label, Input, FormText } from 'reactstrap';

import ClientAPI, {POSITIVE_ACTION_STATUSES, PicturesAPI} from "../../api/Client";
import OfficeSettings from '../OfficeSettings';
import EmailTemplatesEditor from '../Templates/components/EmailTemplatesEditor';
import {getUserInfo, getUserRole, SUBAGENT, SUPER_ADMIN, ADMIN} from '../../connect/auth';

import UserTelegramBotSettings from './components/UserTelegramBotSettings';

export default class Options extends PureComponent {
  constructor(props) {
    super(props);

    this.handleAvatarUpload = this.handleAvatarUpload.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSaveInfo = this.handleSaveInfo.bind(this);

    this.state = {
      templates: [],
      templates_count: 0,
      user: {
        info: {
          avatar: null,
          phone: "",
          email: "",
          whatsapp: "",
          telegram: "",
          viber: "",
          vk: "",
          instagram: "",
          fb: "",
          ok: "",
          website: "",
        }
      },
      avatarPreviewUrl: '',
      imageUrl: '',
      image: null,
      isDisabledButton: true,
      isSaving: false,
      isSaved: false,
    };
  }

  componentDidMount() {
    this.fetchUser()
  }
  handleAvatarUpload(e) {
   e.preventDefault();

    let reader = new FileReader();
    let files = e.target.files;
    let file = e.target.files[0];
    let formData = new FormData();
    formData.append('id', this.state.user.id);
    formData.append('avatar', file, file.name);
    reader.onloadend = (e) => {
      let status;
      PicturesAPI.send_avatar_picture(
        formData
      ).then(r => {
        status = r.status;
        return r.json()
      }).then((result) => {
        this.fetchUser();
        if (result.file) {
          this.setState({
            imagePreviewUrl: result.file,
            imageUrl: window.location.origin + result.file
          });

        }
      }).catch((e) => {
        this.setState({
          isSended: false,
          isError: true,
          isViewSpinner: false,
          error_messages: {image: ["не удалось загрузить изображение. Максимальный размер файла до 2 мб."]},
        })
      })

    }

    reader.readAsDataURL(file)
  }
  fetchUser() {
    const user_info = getUserInfo();
    ClientAPI.
      getJSON(`/users/${user_info.user_id}/`)
      .then(res => {this.setState({user: res})});
  }
  handleInputChange(e) {
    this.setState({
      user: {
        ...this.state.user,
        info: {
          ...this.state.user.info,
          [e.target.name]: e.target.value
        }
      },
      isDisabledButton: false,
    })
  }

  handleSaveInfo() {
    this.setState({isSaving: true, isDisabledButton: true});
    const info = {...this.state.user.info};
    delete info.avatar
    ClientAPI.update(`/users/${this.state.user.id}/user_info`, info)
    .then(() => {
      this.setState({isSaving: false, isSaved: true});
      setTimeout(() => {
        this.setState({ isSaved: false })
      }, 3000)
    }).catch((err) => {
      console.log(err)
    })
  }

  render() {
    const { user, isDisabledButton } = this.state;
    const { info } = user;
    const userRole = getUserRole();
    return (
        <div className='profile'>
          <h2>Настройки</h2>
          <h5> Личные настройки для геоподборки</h5>
          <div className="profile__data-container row">
            <div className="profile__avatar-data col-md-2 col-sm-12">
            { user.info && user.info.avatar ? <img style={{
              width: 120,
              borderRadius: "100%",
              marginRight: 20
            }} src={user.info.avatar} /> : null}
            <FormGroup>
              <Label for="imageViber">Выберите аватарку</Label>
              <Input style={{maxWidth: "130px", textWrap: "wrap"}} type="file" name="avatar" id="imageViber"
                      onChange={this.handleAvatarUpload}/>
              <FormText color="muted" style={{maxWidth: "130px", textWrap: "wrap"}}>
                Рекомендуется загружать изображение с размером 400x400
                пикселей.🙂
              </FormText>
            </FormGroup>
            </div>
            <div className="profile__social-data col-md-7 col-sm-12">
              <div className="form-group row">
                <label className="text-right col-md-3 col-form-label" htmlFor="phone-input">
                  <i className="fa fa-phone"></i> Телефон:
                </label>
                <div className="col-md-8">
                  <input
                    className="form-control"
                    type="text"
                    id="phone-input"
                    name="phone"
                    value={info.phone}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="text-right col-md-3 col-form-label" htmlFor="email-input">
                  <i className="fa fa-at"></i> Email:
                </label>
                <div className="col-md-8">
                  <input
                    className="form-control"
                    type="text"
                    id="email-input"
                    name="email"
                    value={info.email}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="text-right col-md-3 col-form-label" htmlFor="whatsapp-input">
                  <i className="fa fa-whatsapp" style={{color: "#1ecd08"}}></i> Whatsapp:
                </label>
                <div className="col-md-8">
                  <input
                    className="form-control"
                    type="text"
                    id="whatsapp-input"
                    name="whatsapp"
                    value={info.whatsapp}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="text-right col-md-3 col-form-label" htmlFor="telegram-input">
                  <i className="fa fa-send" style={{color: "#52a8de"}}></i> Telegram:
                </label>
                <div className="col-md-8">
                  <input
                    className="form-control"
                    type="text"
                    id="telegram-input"
                    name="telegram"
                    value={info.telegram}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="text-right col-md-3 col-form-label" htmlFor="viber-input">
                  <i className="fa fa-wechat" style={{color: "#764f9b"}}></i> Viber:
                </label>
                <div className="col-md-8">
                  <input
                    className="form-control"
                    type="text"
                    id="viber-input"
                    name="viber"
                    value={info.viber}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="text-right col-md-3 col-form-label" htmlFor="vk-input">
                  <i className="fa fa-vk" style={{color: "#2d71b8"}}></i> В Контакте:
                </label>
                <div className="col-md-8">
                  <input
                    className="form-control"
                    type="text"
                    id="vk-input"
                    name="vk"
                    value={info.vk}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="text-right col-md-3 col-form-label" htmlFor="instagram-input">
                  <i className="fa fa-instagram" style={{color: "#b04a68"}}></i> Инстаграм:
                </label>
                <div className="col-md-8">
                  <input
                    className="form-control"
                    type="text"
                    id="instagram-input"
                    name="instagram"
                    value={info.instagram}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="text-right col-md-3 col-form-label" htmlFor="facebook-input">
                  <i className="fa fa-facebook" style={{color: "#154b8e"}}></i> Фейсбук:
                </label>
                <div className="col-md-8">
                  <input
                    className="form-control"
                    type="text"
                    id="facebook-input"
                    name="fb"
                    value={info.fb}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="text-right col-md-3 col-form-label" htmlFor="ok-input">
                  <i className="fa fa-odnoklassniki" style={{color: "#eea655"}}></i> Однокласники:
                </label>
                <div className="col-md-8">
                  <input
                    className="form-control"
                    type="text"
                    id="ok-input"
                    name="ok"
                    value={info.ok}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
              <div className="form-group row">
                <label className="text-right col-md-3 col-form-label" htmlFor="website-input">
                  <i className="fa fa-globe" style={{color: "#2d71b8"}}></i> Вебсайт:
                </label>
                <div className="col-md-8">
                  <input
                    className="form-control"
                    type="text"
                    id="website-input"
                    name="website"
                    value={info.website}
                    onChange={this.handleInputChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="profile__save-container form-group row col">
            <div className={`btn btn-success profile__save-btn profile__save-notify ${this.state.isSaved ? "profile__save-notify_visible" : ""}`}>Сохранено</div>
            <button className={`btn ${isDisabledButton ? "btn-secondary" : "btn-success"} profile__save-btn`} disabled={isDisabledButton ? true : false} onClick={this.handleSaveInfo}>
              {!this.state.isSaving ? "Сохранить" : "Сохранение"}
            </button>
          </div>

          {(userRole === SUPER_ADMIN || userRole === ADMIN) && this.state.user.telegrambotsettings &&
            <UserTelegramBotSettings user_id={this.state.user.id} data={this.state.user.telegrambotsettings}/>
          }

          {userRole !== SUBAGENT &&
            <div>
              <h5> Настройки офиса для email рассылок</h5>
              <OfficeSettings/>
            </div>
          }

          {userRole == SUPER_ADMIN && <EmailTemplatesEditor />}
        </div>
    );

  }
}
