import 'moment/locale/ru';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-select-plus/dist/react-select-plus.css';
import moment from 'moment';
import React, { Component } from 'react';
import Select from 'react-select-plus';
import {
  CardHeader,
  CardFooter,
  CardBlock,
  Button,
  Row,
  Col,
  Alert,
} from 'reactstrap';
import { Link } from 'react-router-dom';

import BackButton from '../../components/BackButton';
import EmployeesSelector from '../Employees/components/EmployeesSelector';
import FieldErrorViewer from '../Passports/components/FieldErrorViewer';
import OfficeSelectContainer from '../../components/OfficeSelectContainer/OfficeSelectContainer';
import { getUserRole, SUPER_ADMIN } from '../../connect/auth';
import { LeadAPI, POSITIVE_ACTION_STATUSES } from '../../api/Client';
import {
  SOURCE_TYPE_DICT,
  STATUS_DESCRIPTION_MAP,
} from '../../views/LeadsTable';
import { LeadTextdata } from './components/LeadTextdata';

const statusOptions = Object.keys(STATUS_DESCRIPTION_MAP).map((k) => ({
  value: k,
  label: STATUS_DESCRIPTION_MAP[k],
}));

export default class Lead extends Component {
  constructor(props) {
    super(props);

    this.getInfo = this.getInfo.bind(this);

    this.changeStatus = this.changeStatus.bind(this);
    this.changeOffice = this.changeOffice.bind(this);
    this.changeOfficeCity = this.changeOfficeCity.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.createIssue = this.createIssue.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.getChangedValues = this.getChangedValues.bind(this);
    this.updateManager = this.updateManager.bind(this);
    this.renderErrorMessages = this.renderErrorMessages.bind(this);

    this.state = {
      info: null,
      tour_info: '',
      itemId: this.props.match.params.number,
      employees: [],
      status: null,
      fp_number: '',
      comment: '',
      office_id: null,
      office_city: null,
      manager: {
        value: 0,
        label: 'выберите сотрудника',
      },
      manager_id: null,
      form_changed: false,
      showErrorMessage: false,
      showSuccessMessage: false,
      errorMessages: {},
      issue: null,
    };
  }

  changeStatus(v) {
    this.setState({
      status: v.value,
      form_changed: true,
      showErrorMessage: false,
      showSuccessMessage: false,
    });
  }

  changeOffice(v) {
    this.setState({
      office_id: v ? v.value : null,
      form_changed: true,
      showErrorMessage: false,
      showSuccessMessage: false,
    });
  }

  changeOfficeCity(office_city) {
    this.setState({ office_city });
  }

  sortOfficesInSelect(a, b) {
    if (a.is_lead_notify !== b.is_lead_notify) {
      // Если поля "status" различны, сортируем по возрастанию (true -> false)
      return a.is_lead_notify ? -1 : 1;
    } else {
      return a.title.localeCompare(b.title);
    }
  }

  handleChange({ target, name }) {
    this.setState({
      [name]: target.value,
      form_changed: true,
      showErrorMessage: false,
      showSuccessMessage: false,
    });
  }

  createIssue() {
    const self = this;
    const data = this.getChangedValues();
    const timeout = 3000;
    let status = 201;

    LeadAPI.createIssue(this.state.itemId, {
      office: data.office_id,
      manager: data.manager,
    })
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === 201) {
          self.setState(
            {
              form_changed: false,
              showSuccessMessage: true,
              issue: r.id,
            },
            () =>
              setTimeout(() => {
                self.props.history.push(`/persons/${r.person}`);
              }, timeout)
          );
        } else {
          self.setState({
            showErrorMessage: true,
            showSuccessMessage: false,
            errorMessages: r,
          });
        }
      });
  }

  handleSave() {
    const self = this;
    let status = 200;
    LeadAPI.modify(this.state.itemId, this.getChangedValues())
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === 200) {
          self.setState({
            showErrorMessage: false,
            showSuccessMessage: true,
            form_changed: false,
          });
        } else {
          self.setState({
            showErrorMessage: true,
            showSuccessMessage: false,
            errorMessages: r,
          });
        }
      });
  }

  handleDelete() {
    let status;
    const timeout = 2000;

    LeadAPI.delete(this.state.itemId)
      .then((r) => {
        status = r.status;
        return status === POSITIVE_ACTION_STATUSES.destroy ? {} : r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.destroy) {
          this.setState(
            {
              form_changed: false,
              showSuccessMessage: true,
            },
            () =>
              setTimeout(() => {
                this.props.history.push('/leads/');
              }, timeout)
          );
        } else {
          this.setState({
            showErrorMessage: true,
            showSuccessMessage: false,
            errorMessages: r,
          });
        }
      });
  }

  getChangedValues() {
    let result = {};
    const { status, fp_number, info, comment, manager_id, office_id } =
      this.state;
    if (status !== info.status) {
      result.status = status;
    }

    if (fp_number !== info.fp_number) {
      result.fp_number = fp_number === '' ? null : fp_number;
    }

    if (comment !== info.comment) {
      result.comment = comment === '' ? null : comment;
    }

    if (manager_id) {
      result.manager = manager_id;
    }

    if (info.office) {
      result.office_id = info.office.id;
      if (office_id !== result.office_id) {
        result.office_id = office_id;
      }
    } else {
      result.office_id = office_id;
    }

    return result;
  }

  getInfo() {
    LeadAPI.getInfo(this.state.itemId).then((result) => {
      const { text, office, office_id, status, fp_number, comment, issues } =
        result;
      this.setState({
        info: result,
        tour_info: text || '',
        office_id: office_id ? office_id : null,
        office_city: office && office.city ? office.city : '',
        status: status,
        fp_number: fp_number || '',
        comment: comment || '',
        manager_id: issues ? issues.manager.id : null,
      });
    });
  }

  updateManager(value) {
    this.setState({
      manager_id: value,
      form_changed: true,
    });
  }

  componentDidMount() {
    this.getInfo();
  }

  renderErrorMessages = () => {
    const { errorMessages } = this.state;
    return Object.keys(errorMessages).map((key) => {
      if (key === 'office') {
        return null;
      }
      return Array.isArray(errorMessages[key]) ? (
        errorMessages[key].map((item) => <Alert color="warning">{item}</Alert>)
      ) : (
        <Alert color="warning">{errorMessages[key]}</Alert>
      );
    });
  };

  render() {
    const {
      info,
      tour_info,
      office_city,
      office_id,
      status,
      fp_number,
      comment,
      manager_id,
      form_changed,
      showErrorMessage,
      showSuccessMessage,
      issue,
    } = this.state;
    const userRole = getUserRole();
    const isSuperAdmin = userRole === SUPER_ADMIN;

    if (!info) {
      return null;
    }
    return (
      <div className="animated fadeIn">
        <Row>
          <Col md="12">
            <div className="card card-accent-primary">
              <CardHeader>
                <i className="icon-paper-plane">{''}</i>
                Заявка &#x02116; {info.id}
                <span className="badge badge-info float-right">
                  {moment(info.created).format('YYYY-DD-MM, HH:mm')}
                </span>
              </CardHeader>
              <CardBlock>
                {info.issues ? (
                  <Row>
                    <label className="text-right col-md-2">Запрос:</label>
                    <p className="col-md-8">
                      <Link to={`/persons/${info.author.id}`}>
                        &#x02116; {info.issues.id}
                      </Link>
                    </p>
                  </Row>
                ) : (
                  ``
                )}
                <Row>
                  <label className="text-right col-md-2">Источник:</label>
                  <p className="col-md-8">
                    {SOURCE_TYPE_DICT[info.source]
                      ? SOURCE_TYPE_DICT[info.source]
                      : info.source}
                  </p>
                </Row>
                <Row>
                  <label className="text-right col-md-2">
                    {(info.office && (
                      <Link to={`/offices/${info.office.id}`}>Офис</Link>
                    )) ||
                      'Офис'}
                    :
                  </label>
                  <div className="col-md-10">
                    <OfficeSelectContainer
                      changeCity={this.changeOfficeCity}
                      changeOffice={this.changeOffice}
                      disabledSelect={!!info.issues}
                      selected_city={office_city}
                      selected_office={office_id ? office_id : info.office_id}
                      sortOffices={this.sortOfficesInSelect}
                    />
                    <FieldErrorViewer
                      errorMessages={this.state.errorMessages}
                      field="office"
                    />
                  </div>
                </Row>
                <Row>
                  <label className="text-right col-md-2">Клиент:</label>
                  <p className="col-md-8">
                    <Link to={`/persons/${info.author.id}`}>
                      {info.author.fio || 'ID:<' + info.author.id + '>'}
                    </Link>
                  </p>
                </Row>
                <Row>
                  <label className="text-right col-md-2">Назначить:</label>
                  <div className="col-md-8">
                    <EmployeesSelector
                      isDisabled={!!info.issues || !info.office_id}
                      updateManager={this.updateManager}
                      key={info.office_id}
                      managerSelected={manager_id ? manager_id : null}
                      officeSelected={office_id ? office_id : info.office_id}
                      isMultiple={false}
                    />
                  </div>
                </Row>
                <Row>
                  <label className="text-right col-md-2">Перелет:</label>
                  <p className="col-md-8">
                    {info.city} - {info.destination_country}
                  </p>
                </Row>
                <Row>
                  <label className="text-right col-md-2">Стоимость:</label>
                  <p className="col-md-8">{info.tour_amount}</p>
                </Row>
                <Row>
                  <label className="text-right col-md-2">Текст:</label>
                  <LeadTextdata
                    source={info.source}
                    text={tour_info}
                  />
                </Row>
                <Row>
                  <label className="text-right col-md-2">UTM-метки:</label>
                  <p className="col-md-8">
                    {info.utm_labels && JSON.stringify(info.utm_labels)}
                  </p>
                </Row>

                {isSuperAdmin ? (
                  <Row>
                    <label className="text-right col-md-2 form-control-label">
                      Статус:
                    </label>
                    <div className="col-md-8">
                      <Select
                        disabled={!!info.issues}
                        clearable={false}
                        onChange={this.changeStatus}
                        options={statusOptions}
                        placeholder="Источник"
                        value={status}
                      />
                    </div>
                  </Row>
                ) : (
                  ``
                )}

                {isSuperAdmin ? (
                  <Row>
                    <label className="text-right col-md-2 form-control-label">
                      ФП:
                    </label>
                    <div className="col-md-8">
                      <input
                        type="email"
                        id="email-input"
                        name="fp_number"
                        disabled={!!info.issues}
                        value={fp_number}
                        onChange={this.handleChange}
                        className="form-control"
                        placeholder="Номер заявки в ФП"
                      />
                    </div>
                  </Row>
                ) : (
                  ``
                )}

                {isSuperAdmin ? (
                  <Row>
                    <label className="text-right col-md-2 form-control-label">
                      Комментарий:
                    </label>
                    <div className="col-md-8">
                      <textarea
                        id="comment"
                        name="comment"
                        rows="9"
                        disabled={!!info.issues}
                        value={comment}
                        onChange={this.handleChange}
                        className="form-control"
                        placeholder="Замечания..."
                      />
                    </div>
                  </Row>
                ) : (
                  ``
                )}

                <BackButton {...this.props} />
              </CardBlock>
              <CardFooter>
                {manager_id ? (
                  <Button
                    size="sm"
                    color={form_changed ? 'success' : 'secondary'}
                    disabled={!form_changed}
                    onClick={this.createIssue}
                  >
                    <i className="fa fa-dot-circle-o">{''}</i> Назначить
                  </Button>
                ) : (
                  <Button
                    size="sm"
                    color={form_changed ? 'success' : 'secondary'}
                    disabled={!form_changed}
                    onClick={this.handleSave}
                  >
                    <i className="fa fa-dot-circle-o">{''}</i> Сохранить
                  </Button>
                )}

                {isSuperAdmin ? (
                  <Button
                    outline
                    color="danger"
                    className="float-right"
                    disabled={!!info.issues}
                    onClick={this.handleDelete}
                  >
                    <i className="fa fa-gavel">{''}</i> Удалить
                  </Button>
                ) : (
                  ``
                )}

                {showErrorMessage && this.renderErrorMessages()}
                {showSuccessMessage && (
                  <Alert color="success">
                    <strong>
                      {issue ? `Запрос №${issue} создан!` : 'Успешно!'}
                    </strong>{' '}
                    {issue && 'Вы будете перенаправлены на него.'}
                  </Alert>
                )}
              </CardFooter>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}
