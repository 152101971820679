import React, { PureComponent } from "react";
import FormatNumber from "../../../../Utils/FormatNumber";

export class TourPriceOnImage extends PureComponent {
  constructor(props) {
    super(props);
    this._handleFormat = this._handleFormat.bind(this);
  }

  _handleFormat(num) {
    return FormatNumber(parseInt(num));
  }

  render() {
    const { currency, price_updated, manager_base_price, color, simple } =
      this.props;
    const handledPrice = this._handleFormat(price_updated);
    const handledManagerPrice = this._handleFormat(manager_base_price);

    return !simple ? (
      <span style={{ position: "relative" }}>
        <span>
          {handledPrice}
          <small>{currency}</small>
        </span>
        <span className="tour__old-price">{handledManagerPrice}</span>
      </span>
    ) : (
      <span style={{ color: color }}>
        {this.props.text ? "" : handledManagerPrice}{" "}
        <small>{this.props.text ? this.props.text : currency}</small>
      </span>
    );
  }
}

export default TourPriceOnImage;
