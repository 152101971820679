import React, {PureComponent} from 'react';
import Select from 'react-select-plus';
import {NotificationSendersAPI} from '../../../api/Client';
import {SENDERS} from '../OfficeSettings';
import {POSITIVE_ACTION_STATUSES} from '../../../api/Client';

class NotificationSender extends PureComponent {
  constructor(props) {
    super(props);

    this.onChangeSender = this.onChangeSender.bind(this);
  }

  onChangeSender(v) {
    const {id, office, sender, actionResultHandler} = this.props;
    let status = 200;
    const senderName = v !== null ? SENDERS.find(item => item.value === v).label : '';

    const responseHandler = (r) => {
      status = r.status;
      return status === 204 ? {} : r.json();
    };
    let isError = false;
    let action;
    let messages;
    const resultHandler = (r) => {
      if (POSITIVE_ACTION_STATUSES[action] === status) {
        if (action === `create` || action === `partial_update`) {
          messages = {message: `Офис ${office} подключен к автоматическим уведомлениям через ${senderName}`};
        } else if (action === `destroy`) {
          messages = {message: `Офис ${office} отключен от автоматических уведомлений`};
        }
      } else {
        messages = r;
        isError = true;
      }
      actionResultHandler(office, action, messages, isError, isError ? {} : r);
    };

    if (id && !v) {
      action = `destroy`;
      NotificationSendersAPI.
          delete(id).
          then(responseHandler).
          then(resultHandler);
    } else if (id && v !== sender) {
      action = `partial_update`;
      NotificationSendersAPI.
          update(id, {
            sender_type: v,
          }).
          then(responseHandler).
          then(resultHandler);
    } else if (!id && v) {
      action = `create`;
      NotificationSendersAPI.
          create({
            sender_type: v,
          }).
          then(responseHandler).
          then(resultHandler);
    }
  }

  render() {
    const {sender, isDisabled} = this.props;
    return (
        <div>
          <Select
              disabled={isDisabled}
              multi={false}
              simpleValue={true}
              onChange={this.onChangeSender}
              options={SENDERS}
              closeOnSelect={true}
              removeSelected={true}
              placeholder={isDisabled ? `офис не подключен` : `Выбор сервиса для уведомлений`}
              value={sender}
          />
        </div>
    );
  }
}

export default NotificationSender;
