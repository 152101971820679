import React from 'react'
import {Link} from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/ru';
import {Col, Row, Card, CardBlock, CardHeader, Button, Alert} from 'reactstrap';

import Toggle from '../../Notify/components/Toggle';

export default class TouristsTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      toggleErrors: {}
    }
  }

  render() {
    const {isSuperAdmin, items, toggleItem, toggleEmail, toggleSMS, toggleErrors, selected} = this.props;
    
    return <div>
      <table
          className="table table--blue table-bordered table-striped
          table-sm animated fadeIn">
        <thead>
        <tr>
          <th className="text-center tableItem">*</th>
          <th className="text-center tableItem">
            номер заявки в СБ
          </th>
          <th className="text-center tableItem">Имя</th>
          <th className="text-center tableItem">ДР</th>
          <th className="text-center tableItem">Телефоны</th>
          <th className="text-center tableItem">
            Эл. почты
          </th>
          <th className="text-center tableItem">
            Менеджер сейчас
          </th>
          <th className="text-center tableItem">
            Менеджер ранее
          </th>
          <th className="text-center tableItem">Уведомления</th>
          <th className="text-center tableItem">Метки</th>
          <th className="">Офис</th>
          <th className="">Город вылета</th>
        </tr>
        </thead>
        <tbody>
        {items.map((item, idx) => {
          const info_id = item.tourist_fp_info.id;
          return (
              <tr key={idx}>
                <td className="text-center tableItem">
                  <input id={`tourist-${item.id}`} type="checkbox" checked={selected.includes(item.id)}
                          onChange={() => toggleItem(item)}/>
                  {/*<div>{selected.includes(${item.id}) ? console.log(${item.id} + "дб") : console.log('${item.id} + "не дб")}</div>*/}
                  {/*<div>{selected.includes(item.id) ? "yes" : "no"}</div>*/}
                </td>
                <td className="text-center tableItem">
                  {item.fp_pk}
                </td>
                <td className="text-center tableItem">
                  <Link
                      to={`/tourists/${item.id}`} target="_blank" rel="noopener noreferrer">
                    {item.full_name}
                  </Link>
                </td>
                <td className="text-center tableItem">
                  {moment(item.born).format('DD.MM.YYYY')}
                </td>
                <td className="text-center tableItem">
                  {Array.isArray(item.phone) &&
                  item.phone.map((i, idx) => (
                      <a key={idx} href={`tel://${i.value}`}>
                        {i.value}
                      </a>
                  ))}
                </td>
                <td className="text-center tableItem">{
                  Array.isArray(item.email) && item.email.length
                      ?
                      item.email.
                          map(i => i.value).
                          filter(i => i).
                          join(`, `)
                      : null
                }</td>
                {/* <td className="text-center tableItem">
                  { item.tourist_fp_info.has_viber ? <i className="fa fa-check" style={{color: "green"}}></i> : "" }
                </td> */}
                <td className="text-center tableItem">{
                  item.managers ? item.managers.map((m, i) => {
                    return (
                        <div key={i}>
                          <b>{m.name ? m.name : m.username}</b>
                        </div>
                    );
                  }) : null
                }</td>
                <td className="text-center tableItem">{
                  item.managers_fp ? item.managers_fp.map((m, i) => {
                    return <div key={i}><b>{m.name}</b></div>;
                  }) : null
                }</td>
                <td className="text-center tableItem">
                  <div
                      className="text-small-mobile flex-column-mobile">
                    <Toggle
                        id={info_id}
                        lang="Email"
                        checked={item.tourist_fp_info.is_email_notify}
                        // onChange={this.toggleEmailNotify}
                        // onChange={() => console.log("Email toggled", item)}
                        onChange={() => toggleEmail(item)}
                    />
                    <Toggle
                        id={info_id}
                        lang="SMS"
                        checked={item.tourist_fp_info.is_sms_notify}
                        // onChange={this.toggleSmsNotify}
                        // onChange={() => console.log("SMS toggled")}
                        onChange={() => toggleSMS(item)}
                    />
                    {!!toggleErrors[info_id] &&
                    Object.keys(toggleErrors[info_id]).length
                        ? Object.keys(toggleErrors[info_id]).map(
                            key => (
                                <Alert color="danger" key={key}>
                                  {toggleErrors[info_id][key]}
                                </Alert>
                            ),
                        ) : null
                    }
                  </div>
                </td>
                <td className="text-center tableItem">
                  {item.tag ? item.tag.map((tag, key) => {
                    return <p
                        className="badge badge-success badge-pill"
                        key={key}>{tag.name}</p>;
                  }) : null}
                </td>
                <td className="">
                  {item.office && <span>{item.office.address}</span>}
                </td>
                <td className="">
                  <span>{item.depart_cities.map(el => el === null ? "" : el + ', ')}</span>
                </td>
              </tr>
          );
        })}
        </tbody>
      </table>
    </div>
  }
}
