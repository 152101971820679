import React, { PureComponent } from "react";
import Select from "react-select-plus";
import { Alert, Label, Input } from "reactstrap";

import ClientAPI, { POSITIVE_ACTION_STATUSES } from "../../../api/Client";
import { SOURCE_OPTIONS } from "../../Components/FilterLeads/FilterLeads";
import TelegramInstructions from "./TelegramInstructions";

export default class UserTelegramBotSettings extends PureComponent {
  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleLeadsSourceChange = this.handleLeadsSourceChange.bind(this);
    this.handleSave = this.handleSave.bind(this);

    this.state = {
      success: false,
      errors: null,
      data: {},
      isManualOpened: false,
    };
  }

  componentDidMount() {
    if (this.props.data) {
      this.setState({ data: this.props.data });
    } else if (this.props.user_id) this.fetchData();
  }

  fetchData() {
    ClientAPI.getJSON(`${this.props.user_id}/`).then((res) => {
      this.setState({ data: res.telegrambotsettings });
    });
  }

  handleInputChange(e) {
    this.setState({
      data: {
        ...this.state.data,
        [e.target.name]: e.target.value,
      },
    });
  }

  handleLeadsSourceChange(leads_source) {
    this.setState({
      data: {
        ...this.state.data,
        parsedlead_sources: leads_source,
      },
    });
  }

  handleSave() {
    let data = this.state.data,
      _parsedlead_sources = [];
    data.parsedlead_sources.forEach((source) => {
      source.value
        ? _parsedlead_sources.push(source.value)
        : _parsedlead_sources.push(source);
    });
    data.parsedlead_sources = _parsedlead_sources;
    ClientAPI.update(`/users/${this.props.user_id}/telegrambotsettings`, data)
      .then((r) => {
        const success =
          r.status === POSITIVE_ACTION_STATUSES.update ? true : false;
        this.setState({ success });
        if (!success) {
          return r.json();
        }
        return null;
      })
      .then((errors) => {
        this.setState({ errors });
      });
  }

  handleManualOpened(e) {
    e.preventDefault();
    this.state.isManualOpened
      ? this.setState({ isManualOpened: false })
      : this.setState({ isManualOpened: true });
  }

  render() {
    const { success, errors, data } = this.state;
    return (
      <div className="card">
        <div className="card-header card-header_telegram">
          <div>
            <i className="fa fa-send" style={{ color: "#52a8de" }}></i>{" "}
            Уведомления через Telegram-бота
          </div>
          <button
            type="button"
            className="btn btn-sm btn-info ml-2"
            onClick={(e) => this.handleManualOpened(e)}
          >
            <i
              className={`mr-2 fa fa-angle-${
                this.state.isManualOpened ? "up" : "down"
              }`}
            ></i>
            {this.state.isManualOpened ? "Закрыть" : "Открыть"} инструкцию
          </button>
        </div>
        <div className="card-block">
          <form
            action=""
            method="post"
            className="form-horizontal"
            onSubmit={this.handleSubmit}
          >
            <div className="row">
              <div className="col-md-8 col-sm-12">
                {success && (
                  <Alert color="success">
                    <strong>Успешно!</strong>
                  </Alert>
                )}

                {errors &&
                  Array.isArray(errors.non_field_errors) &&
                  errors.non_field_errors.length && (
                    <Alert color="danger">
                      <strong>{errors.non_field_errors.join("<br/>")}</strong>
                    </Alert>
                  )}

                <div className="form-group row">
                  <label className="text-right col-md-2 col-form-label">
                    <i className="fa fa-phone" style={{ color: "#2d71b8" }}></i>{" "}
                    Телефон:
                  </label>
                  <div className="col-md-8">
                    <input
                      type="text"
                      className="form-control"
                      id="telegram_phone-input"
                      name="telegram_phone"
                      value={data.telegram_phone}
                      onChange={this.handleInputChange}
                    />
                    <small className="form-text text-muted">
                      Должен совпадать с номером в Телеграм-аккаунте, к которому
                      подключен бот
                    </small>
                    <small className="form-text invalid-feedback">
                      При смене номера нужно будет снова авторизоваться в боте!
                    </small>
                  </div>
                  {errors && errors.telegram_phone && (
                    <div className="col-md-2 invalid-feedback">
                      {errors.telegram_phone.join("<br/>")}
                    </div>
                  )}
                </div>

                <div className="form-group">
                  <label>Источники заявок:</label>
                  <Select
                    multi={true}
                    onChange={this.handleLeadsSourceChange}
                    options={SOURCE_OPTIONS}
                    placeholder="Источник"
                    value={data.parsedlead_sources}
                  />
                  <small className="form-text text-muted">
                    По этим источникам будут приходить уведомления о новых
                    заявках
                  </small>
                  <small className="form-text invalid-feedback">
                    Если пусто, то уведомления о новых заявках не придут!
                  </small>
                  {errors && errors.parsedlead_sources && (
                    <div className="invalid-feedback">
                      {errors.parsedlead_sources.join("<br/>")}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>

        <div className="card-footer">
          <button
            type="submit"
            className="btn btn-sm btn-primary"
            onClick={this.handleSave}
          >
            <i className="fa fa-dot-circle-o"></i> Сохранить
          </button>
        </div>
        <TelegramInstructions isOpened={this.state.isManualOpened} />
      </div>
    );
  }
}
