import React, { Component } from 'react';
import QuotesOptions from '../../Quotes/components/QuotesOptions';
import { Alert } from 'reactstrap';
import APIClient, {
  AnswersAPI,
  PersonAPI,
  POSITIVE_ACTION_STATUSES,
  TouristFpAPI,
} from '../../../api/Client/Client';
import Swal from 'sweetalert';

export class IssueQuoteAttach extends Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
    this.successSubmit = this.successSubmit.bind(this);
    this.errorMessage = this.errorMessage.bind(this);
    this.doWithTimeout = this.doWithTimeout.bind(this);
    this.handleOptions = this.handleOptions.bind(this);
    this.sendQuoteToWhatsapp = this.sendQuoteToWhatsapp.bind(this);
    this.handleToursSenderType = this.handleToursSenderType.bind(this);
    this.handleCopyMessageToBuffer = this.handleCopyMessageToBuffer.bind(this);
    this.attachClientToQuote = this.attachClientToQuote.bind(this);
    this.addQuoteToComment = this.addQuoteToComment.bind(this);
    this.sendMessage = this.sendMessage.bind(this);

    this.state = {
      id: '',
      title: 'title',
      date: '',
      time: '',
      comment: '',
      created: '',
      resolved: false,
      answers: [],
      showErrorMessage: false,
      showSuccessMessage: false,
      toursSenderType: '',
      toursSmsMessage: '',
      toursEmailMessage: '',
      quoteData: '',
      touristType: !this.props.info?.fullName ? 'person' : 'tourist',
      person: this.props.info,
      isTextCopied: false,
    };
  }

  doWithTimeout(fn, timeout = 2000) {
    setTimeout(fn.bind(this), timeout);
    setTimeout(() => {
      this.setState({
        ...this.state,
        showErrorMessage: false,
        showSuccessMessage: false,
      });
      this.props.onCreate();
    }, timeout);
  }

  handleOptions(value) {
    this.setState({
      ...this.state,
      quote: value,
    });
    if (value) {
      APIClient.getJSON(`/quotes/${value}/`).then((res) => {
        this.setState({
          quoteData: {
            id: res.id,
            tours: res.tours,
            persons: res.persons,
            touristsfp: res.touristsfp,
            title: res.title ? res.title : res.id,
            quoteId: res.quote_id,
          },
        });
      });
    } else {
      this.setState({ quoteData: '' });
    }
  }

  handleToursSenderType(e) {
    this.setState({
      ...this.state,
      toursSenderType: e.target.value,
    });
  }

  handleCopyMessageToBuffer() {
    const { touristType, quoteData } = this.state;
    const { info: person } = this.props;
    const filter =
      touristType === 'tourist' ? { id: person.itemId } : { id: person.id };
    const params = `?${touristType}=${filter.id}&i=${this.props.id}`;
    let text = `${quoteData.title}

      \nhttps://www.geograftour.com/geoclient/${quoteData.quoteId}/${params}
      `;
    this.setState({ isTextCopied: true }, () => this.copyToClipboard(text));
    this.attachClientToQuote(touristType, quoteData, person);
  }

  copyToClipboard = (text) => {
    var textField = document.createElement('textarea');
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
  };

  attachClientToQuote(touristType, quoteData, client) {
    if (quoteData.id) {
      if (touristType === 'person') {
        if (!quoteData.persons.includes(client.id * 1)) {
          const persons = [...quoteData.persons, client.id];
          APIClient.update(`/quotes/${quoteData.quoteId}`, {
            persons,
          });
        }
      } else if (touristType === 'tourist') {
        if (!quoteData.touristsfp.includes(client.itemId * 1)) {
          const touristsfp = [...quoteData.touristsfp, client.itemId];
          APIClient.update(`/quotes/${quoteData.quoteId}`, {
            touristsfp,
          });
        }
      }
    }
  }

  addQuoteToComment(data) {
    let status;

    AnswersAPI.create(data)
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((result) =>
        status === POSITIVE_ACTION_STATUSES.create
          ? this.successSubmit(result)
          : this.errorMessage(result)
      );
  }

  submit(e) {
    e.preventDefault();
    let data = {
      issue: this.props.id,
      title: this.state.title,
      text: this.state.quote,
      type: 'tours',
    };

    const { toursSenderType, quoteData, touristType } = this.state;
    const { info: person } = this.props;
    if (toursSenderType === 'email') {
      this.sendQuoteToEmail();
    } else if (toursSenderType === 'sms') {
      this.sendQuoteToSms();
    }

    this.addQuoteToComment(data);
    this.attachClientToQuote(touristType, quoteData, person);
  }

  sendQuoteToSms() {
    const { touristType, quoteData } = this.state;
    const { info: person } = this.props;
    let isValidPhone = false;
    const phones = person.phone;
    const filter =
      touristType === 'tourist' ? { id: person.itemId } : { id: person.id };
    const params = `?${touristType}=${filter.id}&i=${this.props.id}`;

    if (!phones.length) {
      console.log('Необходимо заполнить контактный телефон для туриста');
    }
    phones.map((phone) => {
      if (phone.value && /(\+7)(\d{10})/.test(phone.value)) {
        isValidPhone = true;
      }
    });
    if (!isValidPhone) {
      console.log('Возможно контактный телефон туриста заполнен некорректно');
    }

    let data = {
      dispatch_type: 1,
      sender_type: 2,
      title: `Подборка ${quoteData.quoteId}`,
      address_book: {},
    };
    const quote_link = `https://www.geograftour.com/geoclient/${quoteData.quoteId}/${params}`;
    data['sms_campaign'] = {
      message: `Мы подготовили для вас подборку туров. Она доступна по ссылке ${quote_link}`,
    };

    this.sendMessage(touristType, data, filter);
  }

  sendQuoteToEmail() {
    const { touristType, quoteData } = this.state;
    const { info: person } = this.props;
    let isValidEmail = false;
    const emails = person.email;
    const filter =
      touristType === 'tourist' ? { id: person.itemId } : { id: person.id };
    const params = `?${touristType}=${filter.id}&i=${this.props.id}`;
    if (!emails.length) {
      this.setState({
        errorMessage: 'Необходимо заполнить email для туриста',
      });
      return false;
    }
    emails.map((email) => {
      if (email.value && /^[^@]+@[^@.]+\.[^@]+$/.test(email.value)) {
        isValidEmail = true;
      }
    });
    if (!isValidEmail) {
      this.setState({
        errorMessage: 'Возможно email туриста заполнен некорректно',
      });
      return false;
    }

    let data = {
      dispatch_type: 2,
      sender_type: 3,
      title: `Подборка туров ${quoteData.quoteId}`,
      address_book: {},
    };
    const quote_link = `https://www.geograftour.com/geoclient/${quoteData.quoteId}/${params}`;
    data['email_campaign'] = {
      message: `<p>Дорогой клиент! Ваша персональная подборка для классного отдыха готова!
      Просто перейдите по ссылке и выбирайте. Мы предлагаем варианты отдыха основываясь
      на знании курортов и отелей по всему миру и всегда максимально учитываем ваши пожелания!</p>
      <p><br/>
      <a href="${quote_link}" target="_blank" rel="noopener noreferrer">Ваша геоподборка здесь</a></p>`,
      template: 108,
    };

    this.sendMessage(touristType, data, filter);
  }

  sendQuoteToWhatsapp(e) {
    e.preventDefault();
    const { touristType, quoteData } = this.state;
    const { info: person } = this.props;
    let data = {
      issue: this.props.id,
      title: `Подборка туров ${quoteData.quoteId}`,
      text: this.state.comment,
      type: 'tours',
    };

    let isValidPhone = false;
    const phones = person.phone;
    const filter =
      touristType === 'tourist' ? { id: person.itemId } : { id: person.id };
    const params = `?${touristType}=${filter.id}&i=${this.props.id}`;

    if (!phones.length) {
      Swal('Необходимо заполнить контактный телефон для туриста');
    }
    phones.map((phone) => {
      if (phone.value && /(\+7)(\d{10})/.test(phone.value)) {
        isValidPhone = true;
      }
    });
    if (!isValidPhone) {
      Swal('Возможно контактный телефон туриста заполнен некорректно');
    }

    const quote_link = `https://www.geograftour.com/geoclient/${quoteData.quoteId}/${params}`;
    const textMessage = `Мы для вас подготовили подборку туров. Она доступна по c ссылке ${quote_link}`;

    const message = encodeURIComponent(textMessage);
    const phoneNumber = phones[0].value;
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${message}`;
    this.attachClientToQuote(touristType, quoteData, person);
    this.addQuoteToComment(data);

    // Новое окно и переход по сформированой ссылке
    if (this.state.quote) {
      window.open(whatsappLink, '_blank');
    } else {
      Swal('Необходимо выбрать геоподборку из списка');
    }
  }

  sendMessage(touristType, data, filter) {
    let status;
    const responseHandler = (response) => {
      status = response.status;
      return response.json();
    };
    const resultHandler = (result) => {
      if (status === POSITIVE_ACTION_STATUSES.create) {
        console.log('Подборка отправлена');
      } else {
        console.log('Подборка не отправлена');
      }
    };

    if (touristType === 'person') {
      PersonAPI.oneMessage(data, filter)
        .then(responseHandler)
        .then(resultHandler);
    } else if (touristType === 'tourist') {
      TouristFpAPI.oneMessage(data, filter)
        .then(responseHandler)
        .then(resultHandler);
    }
  }

  successSubmit(r) {
    this.setState({
      ...this.state,
      title: '',
      comment: '',
      showSuccessMessage: true,
    });
    this.doWithTimeout(this.props.onCreate);
  }

  errorMessage(err) {
    this.setState({
      ...this.state,
      showErrorMessage: err,
    });
  }

  render() {
    const { isLock, info } = this.props;
    const {
      showErrorMessage,
      showSuccessMessage,
      quoteData,
      isTextCopied,
      toursSenderType,
    } = this.state;

    return (
      <form className="mt-3 mb-2">
        <div className="form-group row">
          {!isLock ? (
            <div>
              <QuotesOptions
                onSelectQuote={this.handleOptions}
                quoteData={this.state.quoteData}
              />
              <div className="mt-3">
                {info && info.email.length ? (
                  <label className="clickable col-md-12">
                    <input
                      type="radio"
                      name="type"
                      onChange={this.handleToursSenderType}
                      value="email"
                      className="mr-2"
                    />
                    Отправить на email
                  </label>
                ) : null}
                {info && info.phone.length ? (
                  <label className="clickable col-md-12">
                    <input
                      type="radio"
                      name="type"
                      onChange={this.handleToursSenderType}
                      value="sms"
                      className="mr-2"
                    />
                    Отправить по смс
                  </label>
                ) : null}
              </div>
              <div className="col-md-12">
                {(info && info.phone.length) || (info && info.email.length) ? (
                  <button
                    className="issue_btn btn btn-success mr-2 mb-2"
                    onClick={this.submit}
                    disabled={!quoteData.id || !toursSenderType ? true : false}
                  >
                    Отправить
                  </button>
                ) : null}
                {info && info.phone.length ? (
                  <button
                    className={`btn btn-warning mr-2 mb-2`}
                    onClick={this.sendQuoteToWhatsapp}
                    disabled={!quoteData.id ? true : false}
                  >
                    Отправить в Whatsapp
                  </button>
                ) : null}
                {quoteData ? (
                  <button
                    className="btn btn-sm btn-primary mr-2 mb-2"
                    disabled={isTextCopied}
                    onClick={this.handleCopyMessageToBuffer}
                  >
                    {isTextCopied ? 'Скопировано' : 'Скопировать ссылку'}
                  </button>
                ) : null}
              </div>
            </div>
          ) : null}
        </div>

        {showErrorMessage && (
          <Alert color="warning" className={'mb-2'}>
            <strong>Ошибка!</strong> Не удалось создать
          </Alert>
        )}

        {showSuccessMessage && (
          <Alert color="success" className={'mb-2'}>
            <strong>Подборка</strong> успешно отправлена
          </Alert>
        )}
      </form>
    );
  }
}

export default IssueQuoteAttach;
