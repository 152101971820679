import React, { PureComponent} from 'react';
import {LeadAPI, PersonAPI} from '../../../api/Client/Client';
import collapsible from '../../../decorators/collapsible';

import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ru from "react-phone-input-2/lang/ru.json";

/**
 * Добавление нового лида через блок статистики
 * потенциальных клиентов
 */
class LeadAddForm extends PureComponent {
  constructor(props) {
    super(props);
    this.fetchItems = this.fetchItems.bind(this);
    this.handleLeadAdd = this.handleLeadAdd.bind(this);
    this.handleName = this.handleName.bind(this);
    this.handlePhone = this.handlePhone.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handleComment = this.handleComment.bind(this);

    // ..
    this.state = {
      // ...
      name: '',
      phone: '',
      email: '',
      comment: '',
      showError: false,
      showSuccess: false,
      errorMessage: {},
    }
  }

  getBlankState(){
    return {
      // ...
      name: '',
      phone: '',
      email: '',
      comment: '',
      showError: false,
      showSuccess: false,
      errorMessage: {},
    }
  }

  fetchItems() {
    const self = this;
  }

  getValidPhone(phone) {
    return "+" + phone;
  }

  handleLeadAdd() {

    const {
      term} = this.props
    // ..
    const {
      name,
      phone,
      email,
      comment} = this.state
    const data = {
        name: name,
        issues: [{
          text: comment,
          source: term,
        }],
        person_info: {
          is_sms_notify: true,
          is_email_notify: true,
        },
        tag: []
      };
    if (phone) {
      data.phone = [{value: this.getValidPhone(phone)}]
    }
    if (email) {
      data.email = [{value: email}]
    }

    PersonAPI.create(data).then((r) => {
      try {
        const INVALID_FORM = new Error("Заполните все поля в форме")
        if (r.person_info) {
          this.setState({
            ...this.state,
            showSuccess: true,
            showError: false,
          })
          setTimeout(() => {
            this.setState(this.getBlankState())
          }, 10000);
        } else {
          throw INVALID_FORM
        }

      } catch (e) {
        this.setState({
          ...this.state,
          showError: true,
          showSuccess: false,
          errorMessage: e
        })
      }
    })
  }

  handleName(e) {
    this.setState({name: e.target.value});
  }
  handlePhone(e) {
    this.setState({phone: e.target.value});
  }
  handleEmail(e) {
    this.setState({email: e.target.value});
  }
  handleComment(e) {
    this.setState({comment: e.target.value});
  }
  componentDidMount() {
      this.fetchItems();
  }

  render() {
    return (
      <div>
        <div className="row mt-3">
          <div className="col-sm-12">
            <div className="form-group">
              <label htmlFor="name">Фамилия Имя Отчество</label>
              <input
                type="text"
                className="form-control"
                placeholder="Иванов Иван Иванович"
                value={this.state.name}
                onChange={this.handleName}
              />
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <label htmlFor="phone">Телефон</label>
              <PhoneInput
                country={"ru"}
                localization={ru}
                onlyCountries={["ru", "kz", "by"]}
                countryCodeEditable={false}
                value={this.state.phone}
                onChange={(phone) => this.setState({ phone })}
              />
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <label htmlFor="name">Электронная почта</label>
              <input
                type="text"
                className="form-control"
                placeholder="email@mail.com"
                value={this.state.email}
                onChange={this.handleEmail}
              />
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <label htmlFor="name">Комментарий</label>
              <input
                type="text"
                className="form-control"
                placeholder=""
                value={this.state.comment}
                onChange={this.handleComment}
              />
            </div>
          </div>

          {this.state.showError ? ( // сообщение об ошибке
            <div className="col-sm-12">
              <div className="error-message">
                <div className="alert alert-danger" role="alert">
                  Заполните все поля в форме
                </div>
              </div>
            </div>
          ) : null}

          {this.state.showSuccess ? ( // сообщение об успешном добавлении запроса
            <div className="col-sm-12">
              <div className="success-message">
                <div className="alert alert-success" role="alert">
                  Запрос успешно создан
                </div>
              </div>
            </div>
          ) : null}

          <div className="col-sm-12">
            <div className="form-group">
              <button
                type="submit"
                className="float-right btn btn-sm btn-success"
                onClick={this.handleLeadAdd}
              >
                Создать заявку
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default collapsible(LeadAddForm)
