import React, { Component } from 'react'

export default class IssueApproach extends Component {
  render() {
    return (
      <div>
        <h4>Выявление потребностей</h4>
      </div>
    )
  }
}
