import React, { Component } from "react";
import Select from "react-select-plus";
import DatePicker from "react-datepicker";
import "moment/locale/ru";

import {
  FranchiseApplicationAPI,
  POSITIVE_ACTION_STATUSES,
} from "../../api/Client";

import "react-datepicker/dist/react-datepicker.css";

const FRANCHAISE_STATUS_OPTIONS = [
  { label: "новая", value: "new" },
  { label: "фрачзайзинг", value: "franchasing" },
  { label: "субагент", value: "subagent" },
  { label: "мы отказали", value: "refuse" },
  { label: "звонок 3 дня", value: "call_three_days" },
  { label: "звонок неделя", value: "call_week" },
  { label: "звонок месяц", value: "call_month" },
  { label: "звонок 3 месяца", value: "call_three_months" },
  { label: "звонок полгода", value: "call_half_year" },
  { label: "выбрали конкурента", value: "choose_competitor" },
  { label: "отказ от бизнеса", value: "cancel_business" },
  { label: "турагент онлайн", value: "touragent_online" },
  { label: "не дозвонились", value: "no_call_answered" },
  { label: "потенциал", value: "potential" },
  { label: "фрилансер", value: "freelancer" },
  { label: "Для рассылки", value: "for_mailing" },
  { label: " ", value: " " },
];

const FRANCHAISE_SOURCE_OPTIONS = [
  { label: "одноклассники", value: "odnoklassniki" },
  { label: "заявка с сайта", value: "lead_from_site" },
  { label: "в контакте", value: "VK" },
  { label: "рассылка", value: "mailing" },
  { label: "инстаграм", value: "instagram" },
  { label: "фейсбук", value: "facebook" },
  { label: "контекстная Гугл", value: "google" },
  { label: "контекстная Яндекс", value: "yandex" },
  { label: "Head Hunter", value: "headhunter" },
  { label: "Туршкола", value: "geo_site_tourschool" },
];

const FRANCHAISE_EXPERIENCE_OPTIONS = [
  { label: "да", value: true },
  { label: "нет", value: false },
];

const FRANCHAISE_COUNTRY_OPTIONS = [
  // {label: 'ВСЕ', value: 'all'},
  { label: "Россия", value: "russia" },
  { label: "Казахстан", value: "kazakhstan" },
  { label: "Беларусь", value: "belorussia" },
];

const FRANCHAISE_MANAGER_OPTIONS = [
  { label: "Бородина Оксана", value: "59" },
  { label: "Горкуша Алексей", value: "398" },
  { label: "Малофеева Алёна", value: "487" },
  { label: "Оборин Максим", value: "546" },
  { label: "Петрищева Ирина", value: "633" },
  { label: "Талипова Анжелика", value: "709" },
  { label: "Уймина Алена", value: "22" },
  { label: "Яшпаева Нина Михайловна ", value: "400" },
];

export class FranchiseApplicationFilter extends Component {
  constructor(props) {
    super(props);
    this.onFilter = this.onFilter.bind(this);
    this.onReset = this.onReset.bind(this);
    this.handleExperience = this.handleExperience.bind(this);
    this.handleStatus = this.handleStatus.bind(this);
    this.handleSource = this.handleSource.bind(this);
    this.handleCountry = this.handleCountry.bind(this);
    this.handleCity = this.handleCity.bind(this);
    this.handleManager = this.handleManager.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.fetchCities = this.fetchCities.bind(this);
    this.startDateChange = this.startDateChange.bind(this);
    this.endDateChange = this.endDateChange.bind(this);
    this.startContractDateChange = this.startContractDateChange.bind(this);
    this.endContractDateChange = this.endContractDateChange.bind(this);

    this.state = {
      status: "",
      experience: "",
      source: "",
      country: "",
      manager: "",
      startDate: "",
      endDate: "",
      startContractDate: "",
      endContractDate: "",
      city: "",
      cities: [],
      search: "",
    };
  }
  handleSource(source) {
    this.setState({ source }, () => {
      this.onFilter();
    });
  }
  handleStatus(status) {
    this.setState({ status }, () => {
      this.onFilter();
    });
  }

  handleExperience(experience) {
    this.setState({ experience }, () => {
      this.onFilter();
    });
  }
  handleCountry(country) {
    this.setState({ country }, () => {
      this.onFilter();
    });
  }

  startDateChange(startDate) {this.setState({ startDate });}
  endDateChange(endDate) {this.setState({ endDate });}

  startContractDateChange(startContractDate) {this.setState({ startContractDate });}
  endContractDateChange(endContractDate) {this.setState({ endContractDate });}

  handleManager(manager) {
    console.log("manager==>", manager, FRANCHAISE_MANAGER_OPTIONS);

    if (typeof manager === "number") {
      return this.setState({ manager: manager.toString() }, () => {
        this.onFilter();
      });
    } else {
      return this.setState({ manager }, () => {
        this.onFilter();
      });
    }
  }
  handleCity(city) {
    this.setState({ city }, () => {
      this.onFilter();
    });
  }
  handleSearch(e) {
    this.setState({ search: e.target.value });
  }

  onFilter() {
    let filter = {};
    if (this.state.experience !== "") {
      filter.have_tourism_experience = this.state.experience;
    }
    if (this.state.status.length === 0) {
      // filter.status = this.state.status;
    } else if (this.state.status.length !== 0) {
      filter.status = this.state.status.split(",");
    }
    if (this.state.source.length === 0) {
      // filter.source = this.state.source;
    } else if (this.state.source.length !== 0) {
      filter.source = this.state.source.split(",");
    }
    if (this.state.country.length === 0) {
    } else if (this.state.country.length !== 0) {
      filter.country = this.state.country.split(",");
    }
    if (!this.state.manager) {
      this.state.manager = "";
    } else if (
      (this.state.manager.length !== 0) &
      (typeof this.state.manager !== "number")
    ) {
      // console.log("до сплита", this.state.manager)
      filter.manager = this.state.manager;
      // console.log("после сплита", this.state.manager)
    }
    if (this.state.startDate) {
      filter.created__gte = this.state.startDate.format("DD.MM.YYYY");
    }
    if (this.state.endDate) {
      filter.created__lte = this.state.endDate.format("DD.MM.YYYY");
    }
    if (this.state.startContractDate) {
      filter.contract_date__gte = this.state.startContractDate.format("DD.MM.YYYY");
    }
    if (this.state.endContractDate) {
      filter.contract_date__lte = this.state.endContractDate.format("DD.MM.YYYY");
    }
    if (this.state.city.length === 0) {
    } else if (this.state.city.length !== 0) {
      filter.city = this.state.city.split(",");
    }
    if (this.state.search) {
      filter.search = this.state.search;
    }

    this.props.onFilter(filter);
  }

  onReset() {
    this.setState({
      status: "",
      experience: "",
      source: "",
      country: "",
      manager: "",
      startDate: "",
      endDate: "",
      startContractDate: "",
      endContractDate: "",
      city: "",
      cities: [],
      search: "",
    }, () => {
      this.onFilter();
    });
  }


  fetchCities() {
    let self = this;
    let status;
    FranchiseApplicationAPI.fetchJSON(`filters/city/`)
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        self.setState({
          cities: status === POSITIVE_ACTION_STATUSES.list ? r : [],
        });
      });
  }

  componentDidMount() {
    this.fetchCities();
  }
  render() {
    const { status, experience, source, country, manager, city, cities, search } =
      this.state;

    const FRANCHAISE_CITIES_OPTIONS = cities.map((city) => {
      return {
        value: city.name.toString(),
        label: city.name.toString(),
      };
    });
    const customStyles = {
      ///.....
      menuPortal: (provided) => ({ ...provided, zIndex: 5 }),

      ///.....
    };

    return (
      <div className="animated fadeIn">
        <div className="filter filter--blue">
          <div className="card card--search-bg">
            <div className="card-header">
              <strong>Заявки на фрачзайзинг</strong>
            </div>
            <div className="card-block">
              <div className="filter-row">
                <div className="filter-field date-from">
                  <div className="date-from">
                    <DatePicker
                      dateFormat="DD.MM.YYYY"
                      selected={this.state.startDate}
                      selectsStart
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      onChange={this.startDateChange}
                      className="d-block"
                      placeholderText="дата создания заявки ОТ"
                    />
                  </div>
                </div>
                <div className="filter-field date-to">
                  <div className="date-to">
                    <DatePicker
                      dateFormat="DD.MM.YYYY"
                      selected={this.state.endDate}
                      selectsEnd
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      onChange={this.endDateChange}
                      placeholderText="дата создания заявки ДО"
                    />
                  </div>
                </div>
                <div className="filter-field filter__source">
                  <Select
                    id="franchaise_source"
                    name="source"
                    placeholder="Источник"
                    multi={true}
                    simpleValue={true}
                    closeOnSelect={true}
                    removeSelected={true}
                    value={source}
                    onChange={this.handleSource}
                    options={FRANCHAISE_SOURCE_OPTIONS}
                  />
                </div>
                <div className="filter-field filter__source">
                  <Select
                    id="franchaise_status"
                    name="status"
                    placeholder="Статус"
                    multi={true}
                    simpleValue={true}
                    closeOnSelect={true}
                    removeSelected={true}
                    value={status}
                    onChange={this.handleStatus}
                    options={FRANCHAISE_STATUS_OPTIONS}
                  />
                </div>
              </div>
              <div className="filter-row ">
                <div className="filter-field filter__type">
                  <Select
                    id="franchaise_country"
                    name="country"
                    placeholder="Страна"
                    multi={true}
                    simpleValue={true}
                    closeOnSelect={true}
                    removeSelected={true}
                    value={country}
                    onChange={this.handleCountry}
                    options={FRANCHAISE_COUNTRY_OPTIONS}
                  />
                </div>
                <div className="filter-field">
                  <Select
                    id="franchaise_experience"
                    name="experience"
                    placeholder="Опыт в туризме"
                    simpleValue={true}
                    closeOnSelect={true}
                    removeSelected={true}
                    value={experience}
                    onChange={this.handleExperience}
                    options={FRANCHAISE_EXPERIENCE_OPTIONS}
                  />
                </div>
                <div className="filter-field">
                  <Select
                    id="manager"
                    name="manager"
                    placeholder="Выберите менеджера"
                    multi={true}
                    simpleValue={true}
                    // closeOnSelect={true}
                    removeSelected={true}
                    value={manager}
                    onChange={this.handleManager}
                    options={FRANCHAISE_MANAGER_OPTIONS}
                  />
                </div>
                <div className="filter-field filter__type">
                  <Select
                    id="franchaise_office_city"
                    name="office_city"
                    placeholder="Город"
                    multi={true}
                    simpleValue={true}
                    closeOnSelect={true}
                    removeSelected={true}
                    value={city}
                    onChange={this.handleCity}
                    options={FRANCHAISE_CITIES_OPTIONS}
                  />
                </div>
              </div>
              <div className="filter-row ">
                <div className="filter-field">
                  <label>Телефон или почта</label>
                  <input
                    type="text"
                    className="form-control"
                    id="search"
                    placeholder="Телефон или почта"
                    name="search"
                    value={search}
                    onChange={this.handleSearch}
                  />
                </div>
                <div className="filter-field date-from">
                  <div className="date-from">
                    <DatePicker
                      dateFormat="DD.MM.YYYY"
                      selected={this.state.startContractDate}
                      selectsStart
                      startDate={this.state.startContractDate}
                      endDate={this.state.endContractDate}
                      onChange={this.startContractDateChange}
                      className="d-block"
                      placeholderText="дата договора ОТ"
                    />
                  </div>
                </div>
                <div className="filter-field date-to">
                  <div className="date-to">
                    <DatePicker
                      dateFormat="DD.MM.YYYY"
                      selected={this.state.endContractDate}
                      selectsEnd
                      startDate={this.state.startContractDate}
                      endDate={this.state.endContractDate}
                      onChange={this.endContractDateChange}
                      placeholderText="дата договора ДО"
                    />
                  </div>
                </div>
              </div>
              <div
                className={`card-footer-container ${
                  this.props.isShowForm
                    ? "card-footer-container-form_active"
                    : ""
                }`}
              >
                {this.props.children}
                <div className="card-footer">
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={this.onFilter}
                  >
                    Найти
                  </button>
                  <button
                    type="reset"
                    className="btn btn-sm btn-danger"
                    onClick={this.onReset}
                  >
                    <i className="fa fa-ban"></i> Очистить
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
