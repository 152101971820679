import React, { Component } from "react";
import { Card, CardBlock, CardHeader, CardFooter } from "reactstrap";
import Select from "react-select-plus";
import DatePicker from "react-datepicker";

import moment from "moment";
import "moment/locale/ru";

import { POSITIVE_ACTION_STATUSES, AgenciesAPI } from "../../../api/Client";

export default class FilterAgencies extends Component {
  constructor(props) {
    super(props);

    this.fetchFilterAddresses = this.fetchFilterAddresses.bind(this);
    this.fetchFilterCountries = this.fetchFilterCountries.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.formTextChange = this.formTextChange.bind(this);
    this.handleAgencyType = this.handleAgencyType.bind(this);
    this.handleAddress = this.handleAddress.bind(this);
    this.handleCountry = this.handleCountry.bind(this);
    this.handleFpCity = this.handleFpCity.bind(this);
    this.handleAgencyStatus = this.handleAgencyStatus.bind(this);
    this.startDateChange = this.startDateChange.bind(this);
    this.endDateChange = this.endDateChange.bind(this);
    this.onChangeOffice = this.onChangeOffice.bind(this);

    moment.locale("ru");

    this.state = {
      address: "",
      addresses: [],
      agency_type: "",
      countries: [],
      country: "",
      fp_city: "",
      fp_index: "",
      fp_pk: "",
      offices: "",
      opened_date__gte: "",
      opened_date__lte: "",
      public_name: "",
    };
  }

  componentDidMount() {
    this.fetchFilterAddresses();
    this.fetchFilterCountries();
  }

  fetchFilterAddresses() {
    let status;
    AgenciesAPI.fetchList(1, {}, "filter/addresses/")
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.list) {
          this.setState({
            addresses: r || [],
          });
        }
      });
  }

  fetchFilterCountries() {
    let status;
    AgenciesAPI.fetchList(1, {}, "filter/countries/")
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.list) {
          this.setState({
            countries: r || [],
          });
        }
      });
  }

  handleReset() {
    this.setState(
      {
        address: "",
        agency_type: "",
        country: "",
        fp_city: "",
        fp_index: "",
        fp_pk: "",
        id: "",
        offices: "",
        opened_date__gte: "",
        opened_date__lte: "",
        public_name: "",
      },
      this.handleSubmit
    );
  }
  onChangeOffice(v) {
    this.setState({ offices: v });
  }
  handleAddress(e) {
    this.setState({ address: e });
  }
  handleAgencyType(e) {
    this.setState({ agency_type: e });
  }
  handleCountry(e) {
    this.setState({ country: e });
  }
  handleSubmit() {
    const { onFilter } = this.props;
    onFilter(this.state);
  }
  startDateChange(date) {
    this.setState({ opened_date__gte: date });
  }

  endDateChange(date) {
    this.setState({ opened_date__lte: date });
  }

  handleAgencyStatus(e) {
    this.setState({ fp_status: e });
  }
  handleFpCity(e) {
    this.setState({ fp_city: e.target.value });
  }
  formTextChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const {
      addresses,
      agency_type,
      countries,
      country,
      id,
      fp_city,
      fp_index,
      fp_status,
      public_name,
    } = this.state;
    const AGENCY_TYPE_OPTIONS = [
      { value: 1, label: "Фирменный" },
      { value: 2, label: "Франчайзи" },
      { value: 3, label: "Субагент" },
      { value: 4, label: "Фрилансер" },
    ];
    const COUNTRY_OPTIONS = countries.length
      ? countries.map((c) => {
          return { value: c.value, label: c.label };
        })
      : [];
    // статус	решение по регистрации	город	дата регистрации от 	дата регистрации до
    const FP_STATUS_OPTIONS = [
      { value: "accepted", label: "регистрация подтверждена" },
      { value: "rejected", label: "в регистрации отказано" },
      { value: "waiting", label: "решение не принято" },
    ];
    return (
      <Card>
        <CardHeader>
          <strong>Поиск агентств</strong>
        </CardHeader>
        <CardBlock>
          <p style={{ marginBottom: "0px" }}>Дата регистрации: </p>
          <div className="filter-row">
            <div className="filter-field date-from">
              <label htmlFor="leadDate" className="col-form-label">
                Дата от
              </label>
              <DatePicker
                dateFormat="DD.MM.YYYY"
                selected={this.state.opened_date__gte}
                selectsStart
                startDate={this.state.opened_date__gte}
                endDate={this.state.opened_date__lte}
                onChange={this.startDateChange}
                className="d-block"
                placeholderText="Дата начала"
              />
            </div>
            <div className="filter-field date-to">
              <label htmlFor="leadDate" className="col-form-label">
                Дата до
              </label>
              <DatePicker
                dateFormat="DD.MM.YYYY"
                selected={this.state.opened_date__lte}
                selectsEnd
                startDate={this.state.opened_date__gte}
                endDate={this.state.opened_date__lte}
                onChange={this.endDateChange}
                placeholderText="Дата окончания"
              />
            </div>
            <div className="filter-field">
              <label htmlFor="fpStatus" className="mr-2">
                Решение по регистрации
              </label>
              <Select
                id="fpStatus"
                placeholder="Решение по регистрации"
                name="fpStatus"
                removeSelected={true}
                value={fp_status}
                simpleValue={true}
                searchable={false}
                onChange={this.handleAgencyStatus}
                options={FP_STATUS_OPTIONS}
              />
            </div>
          </div>
          <p style={{ marginBottom: "0px" }}>Найти агента</p>
          <div className="filter-row">
            <div className="filter-field">
              <label htmlFor="id" className="mr-2">
                CRM Id
              </label>
              <input
                type="text"
                className="form-control"
                id="id"
                placeholder="CRM ID"
                name="id"
                value={id}
                onChange={this.formTextChange}
              />
            </div>
            <div className="filter-field">
              <label htmlFor="fp_index" className="mr-2">
                ФСУ Id
              </label>
              <input
                type="text"
                className="form-control"
                id="fp_index"
                placeholder="ФСУ ID"
                name="fp_index"
                value={fp_index}
                onChange={this.formTextChange}
              />
            </div>
            <div className="filter-field">
              <label htmlFor="public_name" className="mr-2">
                Название
              </label>
              <input
                type="text"
                className="form-control"
                id="public_name"
                placeholder="Название"
                name="public_name"
                value={public_name}
                onChange={this.formTextChange}
              />
            </div>
            <div className="filter-field">
              <label htmlFor="agencyType" className="mr-2">
                Тип агентства
              </label>
              <Select
                id="agencyType"
                placeholder="Выберите тип агентства"
                name="agencyType"
                removeSelected={true}
                value={agency_type}
                simpleValue={true}
                searchable={false}
                onChange={this.handleAgencyType}
                options={AGENCY_TYPE_OPTIONS}
              />
            </div>
          </div>
          <div className="filter-row">
            <div className="filter-field">
              <label htmlFor="country" className="mr-2">
                Страна
              </label>
              <Select
                id="agency-type-selector"
                placeholder="Страна"
                name="country"
                removeSelected={true}
                value={country}
                simpleValue={true}
                searchable={false}
                onChange={this.handleCountry}
                options={COUNTRY_OPTIONS}
              />
            </div>
            <div className="filter-field">
              <label htmlFor="fp_city" className="mr-2">
                Город
              </label>
              <input
                type="text"
                className="form-control"
                id="fp_city"
                placeholder="Город"
                name="fp_city"
                value={fp_city}
                onChange={this.handleFpCity}
              />
            </div>
          </div>
          <CardFooter>
            <button
              type="submit"
              className="btn btn-sm btn-primary"
              onClick={this.handleSubmit}
            >
              <i className="fa fa-dot-circle-o"></i>
              Поиск
            </button>
            <button
              type="reset"
              className="btn btn-sm btn-danger"
              onClick={this.handleReset}
            >
              <i className="fa fa-ban"></i>
              Очистить
            </button>
          </CardFooter>
        </CardBlock>
      </Card>
    );
  }
}
