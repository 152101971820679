import React from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';

const CampaignListMobile = (props) => {
  const campaigns = props.items || [];
  return (
      <div>
        {campaigns.length ? (
            campaigns.map(item => {
              let className = '';
              if ([3, 6].indexOf(item.status.id) !== -1) {
                className += 'fa fa-check-circle text-success';
              } else if ([4, 7].indexOf(item.status.id) !== -1) {
                className += 'fa fa-times-circle  text-danger';
              } else if ([2, 5].indexOf(item.status.id) !== -1) {
                className += 'fa fa-check-circle text-success';
              } else {
                className += 'fa fa-refresh text-primary';
              }
              return (
                  <li key={item.id} style={{
                    borderBottom: '1px solid #ccc',
                    listStyle: 'none',
                    paddingTop: '15px',
                  }}>
                    <div>
                      <i className={className}>{}</i>
                      <span className="ml-1">
                        {moment(item.created).format('DD.MM.YYYY HH:mm')}
                      </span>
                      <p className="float-right">
                        {<span
                            className={
                              `badge badge-${item.dispatch_type.id === 1
                                  ? `warning`
                                  : `info`
                              }`}>
                          <i className="fa fa-envelope-o mr-2" aria-hidden="true">{}</i>
                          {item.dispatch_type.name}
                        </span>}
                      </p>
                    </div>
                    <div>
                      <p className="ml-3 mb-0">
                        <Link to={`/mailbox/${item.id}`}>
                          {item.title || 'без названия'}
                        </Link>
                      </p>
                    </div>
                    <p style={{textAlign: 'right'}}>
                      <small>
                        {`${item.creator.last_name} ${item.creator.first_name} (${item.creator.username})`}
                      </small>
                    </p>
                  </li>
              );
            })
        ) : null}
      </div>
  );
};

export default CampaignListMobile;
