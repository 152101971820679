import 'moment/locale/ru';
import moment from 'moment';

import React, {Component} from 'react';
import Select from 'react-select-plus';
import {Card, CardBlock, CardHeader} from 'reactstrap';
import {CITY_POPULATION} from './City';
import {FranchiseApplicationAPI, POSITIVE_ACTION_STATUSES, UsersAPI} from '../../api/Client';
import {LEGAL_ENTITIES} from './Applicant';
import {Link} from 'react-router-dom';
import {OFFICE_AFFILIATION} from './Office';

const FRANCHAISE_STATUS_OPTIONS = [
    {label: 'новая', value: 'new'},
    {label: 'франчайзинг', value: 'franchasing'},
    {label: 'субагент', value: 'subagent'},
    {label: 'мы отказали', value: 'refuse'},
    {label: 'звонок 3 дня', value: 'call_three_days'},
    {label: 'звонок неделя', value: 'call_week'},
    {label: 'звонок месяц', value: 'call_month'},
    {label: 'звонок 3 месяца', value: 'call_three_months'},
    {label: 'звонок полгода', value: 'call_half_year'},
    {label: 'выбрали конкурента', value: 'choose_competitor'},
    {label: 'отказ от бизнеса', value: 'cancel_business'},
    {label: 'турагент онлайн', value: 'touragent_online'},
    {label: 'не дозвонились', value: 'no_call_answered'},
    {label: 'потенциал', value: 'potential'},
    {label: 'фрилансер', value: 'freelancer'},
    {label: 'для рассылки', value: 'for_mailing'},
];

const FRANCHAISE_SOURCE_OPTIONS = [
    {label: 'одноклассники', value: 'odnoklassniki'},
    {label: 'заявка с сайта', value: 'lead_from_site'},
    {label: 'в контакте', value: 'VK'},
    {label: 'рассылка', value: 'mailing'},
    {label: 'инстаграм', value: 'instagram'},
    {label: 'фейсбук', value: 'facebook'},
    {label: 'контекстная Гугл', value: 'google'},
    {label: 'контекстная Яндекс', value: 'yandex'},
    {label: 'Head Hunter', value: 'headhunter'},
    {label: 'туршкола', value: 'geo_site_tourschool'},
];

const FRANCHAISE_MANAGER_OPTIONS = [
  {label: 'Бородина Оксана', value: '59'},
  {label: 'Горкуша Алексей', value: '398'},
  {label: 'Малофеева Алёна', value: '487'},
  {label: 'Оборин Максим', value: '546'},
  {label: 'Петрищева Ирина', value: '633'},
  {label: 'Талипова Анжелика', value: '709'},
  {label: 'Уймина Алена', value: '22'},
  {label: 'Яшпаева Нина Михайловна ', value: '400'},
];

export default class FranchiseApplication extends Component {
    constructor(props) {
        super(props);

        this.fetchFranchiseApplication = this.fetchFranchiseApplication.bind(this);
        this.updateStatus = this.updateStatus.bind(this);
        this.updateSource = this.updateSource.bind(this);
        this.handleManagersOnChange = this.handleManagersOnChange.bind(this);

        this.state = {
            data: {},
            status: '',
            source: '',
            manager: '',
            managers: [],
        };
    }

    updateStatus(value) {
        let status;
        const franchiseApplicationId = this.props.match.params.number;
        this.setState({
            status: value
        }, () => {
            FranchiseApplicationAPI.update(`${FranchiseApplicationAPI.resource_url}${franchiseApplicationId}`, {status: this.state.status}).then(r => {
                status = r.status;
                return r.json();
            }).then(r => {
                if (status === POSITIVE_ACTION_STATUSES.partial_update) {
                    console.log('статус обновлен');
                } else {
                    console.log('статус не обновлен');
                }
            });
        })
    }

    updateSource(value) {
        let status;
        console.log(this.props.match.params.number)
        const franchiseApplicationId = this.props.match.params.number;
        this.setState({
            source: value
        }, () => {
            FranchiseApplicationAPI.update(`${FranchiseApplicationAPI.resource_url}${franchiseApplicationId}`, {source: this.state.source}).then(r => {
                status = r.status;
                return r.json();
            }).then(r => {
                if (status === POSITIVE_ACTION_STATUSES.partial_update) {
                    console.log('источник обновлен');
                } else {
                    console.log('источник не обновлен');
                }
            });
        })
    }

    handleManagersOnChange(value) {
        console.log(value)
        this.setState({
            ...this.state,
            manager: value,
        });

        let status;
        const franchiseApplicationId = this.props.match.params.number;
        this.setState({
            manager: value
        }, () => {
            FranchiseApplicationAPI.update(`${FranchiseApplicationAPI.resource_url}${franchiseApplicationId}`, {manager: this.state.manager}).then(r => {
                status = r.status;
                return r.json();
            }).then(r => {
                if (status === POSITIVE_ACTION_STATUSES.partial_update) {
                    console.log('менеджер обновлен');
                } else {
                    console.log('менеджер не обновлен');
                }
            });
        })
    }

    fetchFranchiseApplication() {
        const franchiseApplicationId = this.props.match.params.number;
        let status;
        FranchiseApplicationAPI.fetchJSON(`${franchiseApplicationId}/`).then(r => {
            status = r.status;
            return r.json();
        }).then(r => {
            if (status === POSITIVE_ACTION_STATUSES.retrieve) {
                this.setState({
                    data: r || {},
                    status: r.status,
                    source: r.source,
                    manager: r.manager ? r.manager.id : '',
                });
            }
        });
    }

    componentDidMount() {
        this.fetchFranchiseApplication();
    }

    render() {
        const {data, status, source, manager} = this.state;
        if (!Object.keys(data).length) {
            return null;
        }
        
        return (
            <div className="animated fadeIn filter filter--green">
                <Card>
                    <CardHeader>
                        <h4>Заявка № {data.id}</h4>
                    </CardHeader>
                    <CardBlock className="card-block_responsive">
                        <div className="row">
                            <div className="col-md-6 col-lg-6 mb-4">
                                <h5>Заявитель</h5>
                                <p>ФИО: <b>{data.applicant.name}</b></p>
                                <p>телефон: <b>{data.applicant.phone}</b></p>
                                <p>электронная почта: <b>{data.applicant.email}</b></p>
                                <p>возраст (года): <b>{data.applicant.age}</b></p>
                                <p>
                                    Имеет опыт работы в туризме: {' '}
                                    {data.applicant.have_tourism_experience ? 'Да' : 'Нет'}
                                </p>
                                <p>
                                    Посещенные страны: {' '}
                                    {data.applicant.countries}
                                </p>
                                <p>
                                    Опыт ведения бизнеса: {' '}
                                    {data.applicant.business_experience}
                                </p>
                                <p>
                                    Тип юридического лица: {' '}
                                    {LEGAL_ENTITIES[data.applicant.legal_entity]}
                                </p>
                                <p>
                                    Дополнительные сведения: {' '}
                                    {data.applicant.additional_information}
                                </p>
                                <p>
                                    Регистрация в ФСУ: {' '}
                                    {data.applicant.fp_registration}
                                </p>
                                <p>
                                    Договор: {' '}
                                    {data.applicant.contract}
                                </p>
                                <p>
                                    Дата договора: {' '}
                                    {(data.applicant.contract_date) ? moment(data.applicant.contract_date).format('DD.MM.YY') : null}
                                </p>
                                <p>
                                    Обучение: {' '}
                                    {data.applicant.training}
                                </p>
                                <p>
                                    Публикация на сайте: {' '}
                                    {data.applicant.site_publication}
                                </p>
                                <p>
                                    Коммуникатор: {' '}
                                    {data.applicant.communicator}
                                </p>
                                <p>
                                    Бонусы: {' '}
                                    {data.applicant.bonuses}
                                </p>
                                <p>
                                    Рейтинг в сети: {' '}
                                    {data.applicant.rating}
                                </p>
                                <p>
                                    UTM-метки: {' '}
                                    {data.utm_labels && JSON.stringify(data.utm_labels)}
                                </p>
                            </div>
                            <div className="col-md-6 col-lg-6 mb-4">
                                <h5>Офис</h5>
                                <p>Адрес офиса: {data.office.address}</p>
                                <p>
                                    Условия по офису: {' '}
                                    {OFFICE_AFFILIATION[data.office.affiliation]}
                                </p>
                                <p>
                                    Есть ли отдельный вход: {' '}
                                    {data.office.is_separate_entrance ? 'Да' : 'Нет'}
                                </p>
                                <p>
                                    Возможность размещения вывески: {' '}
                                    {data.office.is_possibility_of_placing_signage ? 'Да' : 'Нет'}
                                </p>
                                <p>
                                    Город: {data.office.city.name}
                                </p>
                                <p>
                                    Численность населения: {CITY_POPULATION[data.office.city.population]}
                                </p>
                                <div className="mb-2">статус:
                                    <Select
                                        id="franchaise_status"
                                        name="status"
                                        style={{width: '400px'}}
                                        placeholder="Статус"
                                        simpleValue={true}
                                        closeOnSelect={true}
                                        value={status}
                                        onChange={this.updateStatus}
                                        options={FRANCHAISE_STATUS_OPTIONS}
                                    />
                                </div>
                                <div>источник:
                                    <Select
                                        id="franchaise_source"
                                        name="source"
                                        style={{width: '400px'}}
                                        placeholder="Источник"
                                        simpleValue={true}
                                        closeOnSelect={true}
                                        value={source}
                                        onChange={this.updateSource}
                                        options={FRANCHAISE_SOURCE_OPTIONS}
                                    />
                                </div>
                                <div> менеджер:
                                    <Select
                                        style={{width: '400px'}}
                                        id="manager"
                                        placeholder="Выберите менеджера из спиcка..."
                                        removeSelected={true}
                                        name="manager"
                                        value={manager.toString()}
                                        simpleValue={true}
                                        onChange={this.handleManagersOnChange}
                                        options={FRANCHAISE_MANAGER_OPTIONS}
                                    />
                                </div>
                                <h5 className="py-2">Примечание</h5>
                                <p>Текст: <b>{data.comment}</b></p>
                            </div>
                            <div className="col-md-6 col-lg-6 mb-4">
                                <Link to={`/franchising/${data.id}/edit/`}>
                                    редактировать
                                </Link>
                            </div>
                        </div>
                        {/*<BackButton {...this.props} />*/}
                    </CardBlock>
                </Card>
            </div>
        );
    }

}
