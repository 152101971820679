import React from 'react'
import { Collapse } from 'reactstrap';

function collapsible(Component) {
    class CollapseWrap extends React.Component {
        constructor(props) {
            super(props);

            this.toggle = this.toggle.bind(this);
            this.state = { collapse: false };
            
        }

        componentWillReceiveProps(nextProps) {
            // console.log(this.props);
        }
        toggle() {
            this.setState({ collapse: !this.state.collapse });
        }
        render() {
            return (
                <div>
                    <div className="clickable" onClick={this.toggle}>
                        <i className={`fa ${this.props.iconClass} fa-sm mr-2`}></i>
                        <span className="">{this.props.source}</span>
                        { this.props.count ? (
                            <span className="float-right pink-bg badge badge-success badge-pill">{this.props.count}</span>
                        ) : null }
                        
                    </div>
                    <Collapse isOpen={this.state.collapse}>
                        <Component {...this.props} />
                    </Collapse>
                </div>
            )
        }
    }
    CollapseWrap.displayName = `CollapseWrap(${Component.displayName || Component.Name|| 'Component' })`;
    return CollapseWrap;
}

export default collapsible;