import React from 'react';
import paginate from '../../../decorators/paginator';
import 'moment/locale/ru';
import { Link } from 'react-router-dom';

const getColor = () => {
  return (
    '#' +
    _getRandomHex(0, 2 ** 24 - 1)
      .toString(16)
      .padStart(6, 0)
  );
};
const _getRandomHex = (min, max) => {
  return Math.ceil(Math.random() * (max - min) + min);
};

const TouristList = ({ tourists, total, onSelect, selected, type }) => {
  return (
    <div style={{ marginTop: '10px' }}>
      {type === 'touristToChoose' ? (
        total ? (
          <p className="badge badge-success">Всего: {total}</p>
        ) : (
          <p className="badge badge-default">Список пуст</p>
        )
      ) : selected.length ? (
        <p className="badge badge-warning">
          Выбрано: {selected.length}
        </p>
      ) : (
        ''
      )}

      <ul className="list-group col-md-12">
        {tourists.map((t) => (
          <li
            key={t.id}
            className="justify-content-between list-group-item item-header"
          >
            {t.person ? (
              <div>
                <input
                  type="checkbox"
                  className="mr-2"
                  checked={!!~selected.indexOf(t.id)}
                  onChange={(e) => onSelect(e, t)}
                  value={t.id}
                />
                <i
                  className="fa fa-sm fa-circle fa-1 mr-2"
                  style={{ color: getColor() }}
                  aria-hidden="true"
                ></i>
                <img
                  src="/img/avatars/tourist-avatar.png"
                  className="mr-2"
                  width="32"
                  height="32"
                />
                <span>
                  <Link to={`/persons/${t.person.id}`} target="blank">
                    {t.person.full_name}
                  </Link>
                </span>
                <span className="float-right text-right">
                  {t.person.email.map((email, i) => (
                    <span key={`person-email-${i}`}>
                      {email.value}
                      <br />
                    </span>
                  ))}
                  {t.person.phone.map((phone, j) => (
                    <span key={`person-phone-${j}`}>
                      {phone.value}
                      <br />
                    </span>
                  ))}
                </span>
              </div>
            ) : (
              <div>
                <input
                  type="checkbox"
                  className="mr-2"
                  checked={!!~selected.indexOf(t.id)}
                  onChange={(e) => onSelect(e, t)}
                  value={t.id}
                />
                <i
                  className="fa fa-sm fa-circle fa-1 mr-2"
                  style={{ color: getColor() }}
                  aria-hidden="true"
                ></i>
                <img
                  src="/img/avatars/tourist-avatar.png"
                  className="mr-2"
                  width="32"
                  height="32"
                />
                <span>
                  <Link to={`/tourists/${t.tourist.id}`} target="blank">
                    {t.tourist.full_name}
                  </Link>
                </span>
                <span className="float-right text-right">
                  {t.tourist.email.map((email, i) => (
                    <span key={`tourist-email-${i}`}>
                      {email.value}
                      <br />
                    </span>
                  ))}
                  {t.tourist.phone.map((phone, j) => (
                    <span key={`tourist-phone-${j}`}>
                      {phone.value}
                      <br />
                    </span>
                  ))}
                </span>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default paginate(TouristList);
