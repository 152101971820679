import React from 'react';
import BackButton from '../../../components/BackButton';
import {UsersAPI} from '../../../api/Client';
import {Link} from 'react-router-dom';

export default class EmployeesDetail extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      is_active: false,
      id: 0,
      last_name: '',
      first_name: '',
      username: '',
      email: '',
      offices: [],
      manager_manager_fp: [],
      profile: {},
    };
  }

  componentDidMount() {
    let status;
    UsersAPI.
        fetchJSON(this.props.match.params.number).
        then(r => {
          status = r.status;
          return r.json();
        }).
        then(res => this.setState({
          id: res.id,
          last_name: res.last_name || '',
          first_name: res.first_name || '',
          username: res.username || '',
          email: res.email || '',
          is_active: res.is_active,
          offices: res.offices,
          manager_manager_fp: res.manager_manager_fp,
          profile: res.profile,
          info: res.info
        }));
  }

  render() {
    let {id, is_active, last_name, first_name, username, email, manager_manager_fp, offices, profile, info} = this.state;

    const basic_profile_img = "/img/avatars/tourist-avatar.png";

    if (!id) {
      return null;
    }

    return (
        <div className="card p-4">
          <div className="row">
            <div className="col-md-6 col-lg-6 mb-4">
              <h4>{last_name} {first_name}</h4>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-lg-6 mb-4">
              <img width="120" style={{borderRadius: "50%"}} className="float-left mr-3" src={info.avatar ? info.avatar : basic_profile_img}/>
              {is_active && <p><span className="badge badge-success">активен</span></p>}
              <p>логин: {username}</p>
              <p>тип: {profile.role.name}</p>
              <p>электронная почта: <b>{email}</b></p>
            </div>
            <Link to={`/employees/${id}/edit/`}>редактировать</Link>
          </div>
          {Array.isArray(offices) && offices.length ? (
            <div className="row">
              <div className="col-md-6 col-lg-6 mb-4">
                {offices.map(office => (
                    <p key={office.id} className="badge badge-success mr-1">
                      <b>{office.name}</b>
                    </p>
                ))}
              </div>
            </div>
          ) : null}
          {Array.isArray(manager_manager_fp) && manager_manager_fp.length ? (
            <div className="row">
              <p>назначены заявки из системы бронирования:</p>
              <div className="col-md-6 col-lg-6 mb-4">
                {manager_manager_fp.map(manager => (
                    <p key={manager.manager_fp.id} className="badge badge-success">
                      {`${manager.manager_fp.name} ${manager.manager_fp.fp_pk}`}
                    </p>
                ))}
              </div>
            </div>
          ) : null}
          <div className="row">
            <div className="col-md-6 col-lg-6 mb-4">
              <BackButton {...this.props} />
            </div>
          </div>
        </div>
    );
  }
}