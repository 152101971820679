import React, {Component} from 'react';
import ClientAPI, {AnswersAPI, POSITIVE_ACTION_STATUSES, UsersAPI} from '../../../api/Client';
import {Alert} from 'reactstrap';
import Select from 'react-select-plus';

class IssueManagerForm extends Component {
    constructor(props) {
        super(props);

        this.submit = this.submit.bind(this);
        this.successSubmit = this.successSubmit.bind(this);
        this.errorMessage = this.errorMessage.bind(this);
        this.doWithTimeout = this.doWithTimeout.bind(this);
        this.handleOptions = this.handleOptions.bind(this);
        this.handleUsersOnChange = this.handleUsersOnChange.bind(this);
        this.handleComment = this.handleComment.bind(this);

        this.state = {
            id: '',
            showErrorMessage: false,
            showSuccessMessage: false,
            manager_id: this.props.manager ? this.props.manager.id : null,
            manager_name: this.props.manager ? `${this.props.manager.first_name} ${this.props.manager.last_name}` : '',
            users: [],
            formChanged: false,
            form_changed: false,
            comment: '',
        };
    }

    submit(e) {
        e.preventDefault();
        const data = {
            manager: this.state.manager_id,
            issue: this.props.id,
            text: this.state.comment,
            type: 'transfer',
        };
        let status;
        AnswersAPI.
            create(data).
            then(r => {
              status = r.status;
              return r.json();
            }).
            then(result => status === POSITIVE_ACTION_STATUSES.create
                ? this.successSubmit(result)
                : this.errorMessage(result),
            );
    }

    doWithTimeout(fn, timeout = 2000) {
        setTimeout(fn.bind(this), timeout);
        setTimeout(() => {
            this.setState({
                ...this.state,
                showErrorMessage: false,
                showSuccessMessage: false,
            });
            this.props.onCreate()
        }, timeout);
    }

    successSubmit(r) {
        this.setState({
            ...this.state,
            manager_id: r.manager,
            showSuccessMessage: true
        });
        this.doWithTimeout(this.props.onCreate);
    }

    errorMessage(err) {
        this.setState({
            ...this.state,
            showErrorMessage: err
        });
        this.doWithTimeout(this.props.onCreate);
    }

    handleOptions(value) {
        this.setState({
            ...this.state,
            manager_id: value
        })
    }

    componentDidMount() {
        let status;
        UsersAPI.
            fetchJSON(`full_list/`).
            then(r => {
                status = r.status;
                return r.json();
            }).
            then((res) => this.setState({
                users: res,
            }));
    }

    handleUsersOnChange = (selected) => {
        this.setState({
            ...this.state,
            manager_id: selected ? selected.value : null,
            manager_name: selected ? selected.label : '',
            formChanged: true,
            form_changed: true,
        })
    };

    handleComment(e) {
        this.setState({
            ...this.state,
            comment: e.target.value
        })
    }

    render() {
        const EMPLOYEES_OPTIONS = this.state.users.map((user) => {
            return {value: user.id, label: `${user.first_name} ${user.last_name}`}
        });
        const selected = {label: this.state.manager_name, value: this.state.manager_id};
        return (
            <form>
                <div className="form-group row">
                    <label className="text-right col-md-2 col-form-label">Выбрать менеджера</label>
                    <div className="col-md-8 ">
                        {(this.props.type == 7) ? (
                            <Select
                                id="managerID"
                                className="mb-3"
                                name="users"
                                placeholder="Выберите сотрудника из спиcка..."
                                closeOnSelect={true}
                                removeSelected={true}
                                value={selected}
                                onChange={this.handleUsersOnChange}
                                options={EMPLOYEES_OPTIONS}
                                required={true}
                                disabled={this.props.isLock}
                            />
                        ) : ''}
                        <p className="bg-danger">{/* {error.manager_manager_fp} */}</p>
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-md-12">
                        <textarea
                            autoFocus={true}
                            name="textarea-input"
                            rows="7"
                            className="form-control"
                            placeholder="Введите комметарий"
                            value={this.state.comment}
                            onChange={this.handleComment}
                            disabled={this.props.isLock}></textarea>
                    </div>
                </div>
                {!this.props.isLock ?
                    <button className="issue_btn btn btn-success" onClick={this.submit}>
                        Сохранить
                    </button> : ''}
                {this.state.showErrorMessage ?
                    (<Alert color="warning">
                        <strong>Ошибка!</strong> Не удалось создать
                    </Alert>
                    )
                    : ''
                }
                {this.state.showSuccessMessage ?
                    (<Alert color="success">
                        <strong>Комментарий</strong> Успешно добавлен
                    </Alert>
                    )
                    : ''
                }
            </form>
        )
    }
}

export default IssueManagerForm;
