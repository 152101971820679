import React, {PureComponent} from 'react';
import {Link} from 'react-router-dom';
import Paginator from '../../../components/Paginator';
import {Alert, Card, CardBlock, CardHeader, Col, Row} from 'reactstrap';
import ClientAPI, {CashAccountsAPI, InvoicesAPI, POSITIVE_ACTION_STATUSES} from '../../../api/Client';
import InvoiceAddForm from './InvoiceAddForm';
import moment from 'moment';
import 'moment/locale/ru';
import BackButton from '../../../components/BackButton';
import CashAccountBalance from './CashAccountBalance';
import Spendings from '../../Spendings/Spendings';

import {getUserRole, ADMIN, SUPER_ADMIN} from '../../../connect/auth';

export default class CashAccount extends PureComponent {
  constructor(props) {
    super(props);

    this.addInvoice = this.addInvoice.bind(this);
    this.fetchItems = this.fetchItems.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);

    this.state = {
      data: {},
      items: [],
      count: 0,
      errors: {},
      isSuccess: false,
    };
  }

  fetchData() {
    let status;

    CashAccountsAPI.
        fetchJSON(`${this.props.match.params.number}/`).
        then(r => {
          status = r.status;
          return r.json();
        }).
        then(r => {
          if (status === POSITIVE_ACTION_STATUSES.retrieve) {
            this.setState({
              data: r || {},
            });
          }
        });
  }

  fetchItems(page = 1) {
    let status;

    CashAccountsAPI.
        fetchList(page, {}, `${this.props.match.params.number}/invoices`).
        then(r => {
          status = r.status;
          return r.json();
        }).
        then(r => {
          if (status === POSITIVE_ACTION_STATUSES.list) {
            this.setState({
              items: r.results || [],
              count: r.count || 0,
            });
          }
        });
  }

  handlePageChange(data) {
    let page = data.selected + 1;
    this.setState(
        {pageNumber: page},
        () => this.fetchItems(page),
    );
  }

  addInvoice(data) {
    let status;
    const userRole = getUserRole();
    if (userRole == SUPER_ADMIN) {
      ClientAPI.
          create('/invoices/manual',{
            value: data.value,
            uuid: data.uuid,
            cash_account: this.props.match.params.number,
          }).
          then(r => {
            status = r.status;
            return r.json();
          }).
          then(r => {
            if (status === POSITIVE_ACTION_STATUSES.create) {
              this.setState({
                isSuccess: true,
              }, () => this.fetchItems());
            } else {
              this.setState({
                errors: r,
              });
            }
          });
    } else {
      InvoicesAPI.
          create({
            value: data.value,
            cash_account: this.props.match.params.number,
          }).
          then(r => {
            status = r.status;
            return r.json();
          }).
          then(r => {
            if (status === POSITIVE_ACTION_STATUSES.create) {
              this.setState({
                isSuccess: true,
              }, () => this.fetchItems());
            } else {
              this.setState({
                errors: r,
              });
            }
          });
    }
  }

  componentDidMount() {
    this.fetchData();
    this.fetchItems();
  }

  render() {
    const {items, count, data, errors, isSuccess} = this.state;
    const errorKeys = typeof errors === 'object' && !!errors ? Object.keys(errors) : [];
    const awaitingStatus = 2;
    const userRole = getUserRole();
    return (
        <div className="animated fadeIn">
          {
            typeof data === 'object' && data !== null && Object.keys(data).length ? (
                <Row>
                  <Col lg="12">
                    <h2>Денежный счет #{data.id}</h2>
                    <p>
                      Создан: {moment(data.created).format('DD.MM.YY HH:mm')}
                    </p>
                    <p>
                      Создатель:{' '}
                      {`${['first_name', 'last_name'].map(i => data.creator[i]).join(' ')} (${data.creator.username})`}
                    </p>
                    <p>
                      Обновлен: {moment(data.created).format('DD.MM.YY HH:mm')}
                    </p>
                    <p>
                      Обновивший:{' '}
                      {`${['first_name', 'last_name'].map(i => data.updater[i]).join(' ')} (${data.updater.username})`}
                    </p>
                  </Col>
                </Row>
            ) : null
          }
          <Row>
            <Col lg="12">
              <div>
                <CashAccountBalance cash_account_id={this.props.match.params.number}/>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <InvoiceAddForm isShow={true} addInvoice={this.addInvoice}/>
              {isSuccess ? (
                  <Alert key={`alert-success`}>
                    <b>Успешно</b>. { userRole == SUPER_ADMIN ? 'Баланс пополнен.' : 'Счет будет обработан в течение нескольких минут и появится ссылка на оплату.'}
                  </Alert>
              ) : null}
              {
                errorKeys.map((key, index) => {
                  return (
                      <Alert className="bg-danger" key={`alert-danger-${index}`}>
                        {errors[key]}
                      </Alert>
                  );
                })
              }
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <Card>
                <CardHeader>
                  <i className="fa fa-align-justify">{''}</i> Всего счетов на оплату&nbsp;
                  <span className="badge badge-info">
                    {count}
                  </span>
                </CardHeader>
                <CardBlock className="card-block_responsive">
                  <table className="table table-bordered table-striped table-sm">
                    <thead>
                    <tr>
                      <th>Id</th>
                      <th>Создан</th>
                      <th>Создатель</th>
                      <th>Сумма</th>
                      <th>Валюта</th>
                      <th>Статус</th>
                      <th>Оплата</th>
                      <th>{''}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {items.map(item => (
                        <tr key={`invoice-${item.id}`}>
                          <td>
                            {item.id}
                          </td>
                          <td>
                            {moment(item.created).format('DD.MM.YY HH:mm')}
                          </td>
                          <td>{
                            `${['first_name', 'last_name'].map(
                                i => item.creator[i]).join(' ')} (${item.creator.username})`
                          }</td>
                          <td>{item.value}</td>
                          <td>{item.currency.name}</td>
                          <td>{item.status.name}</td>
                          <td>
                            {!item.payment ? null : (
                                <span>
                                  {item.payment.value}{' '}
                                  {item.payment.currency.name}{' / '}
                                  {moment(item.created).format('DD.MM.YY HH:mm')}{' '}
                                </span>
                            )}
                          </td>
                          <td>
                            {item.status.id === awaitingStatus && !!item.link ? (
                                <a href={item.link} rel={`nofollow noopener noreferrer`} target="_blank">
                                  Ссылка на оплату
                                </a>
                            ) : null}
                          </td>
                        </tr>
                    ))}
                    </tbody>
                  </table>
                  <Paginator total={count} onPageChange={this.handlePageChange}/>
                </CardBlock>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <Spendings cashAccount={this.props.match.params.number}/>
            </Col>
          </Row>
          <BackButton {...this.props} />
        </div>
    );
  }
}
