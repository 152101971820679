import React, { PureComponent } from 'react';
import IssueItem from './IssueItem';
import APIClient from '../../../api/Client/Client';

export default class IssuesList extends PureComponent {
  constructor(props) {
    super(props);
    this.onCreate = this.onCreate.bind(this);
    this.onRefresh = this.onRefresh.bind(this);

    this.state = {
      tours: [],
      items: [],
    };
  }
  onRefresh() {
    APIClient.getJSON('/tours/').then((result) => {
      this.setState({
        ...this.state,
        tours: result.results,
      });
    });
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      items: this.props.items,
    });
    this.onRefresh();
  }

  onCreate() {
    this.props.onCreate();
  }

  render() {
    const { items, info, onChangeActivity } = this.props;
    const { tours } = this.state;
    return (
      <ul>
        {items.map((item, key) => (
          <li key={key}>
            <IssueItem
              item={item}
              comments={[]}
              info={info}
              tours={tours}
              onCreate={this.onCreate}
              onChangeActivity={onChangeActivity}
            />
          </li>
        ))}
      </ul>
    );
  }
}
