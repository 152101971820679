import React from 'react';
import {Link} from 'react-router-dom';
import moment from 'moment';

const CampaignList = (props) => {
  const campaigns = props.items || [];
  const handleClickItem = props.handleClickItem
  return (
      <table className="table table--green table-bordered table-striped table-sm">
        <thead>
        <tr>
          <th className="hidden-mobile">Id</th>
          <th className="hidden-mobile">Тип</th>
          <th className="hidden-mobile">Провайдер</th>
          <th>Превью</th>
          <th>Тема (название) рассылки</th>
          <th>Статус</th>
          <th>Дата создания</th>
          <th>Тип получателей</th>
          <th className="hidden-mobile">Офис</th>
          <th>Создатель</th>
          <th className="hidden-mobile">Модерация</th>
          <th className="hidden-mobile">Ошибки</th>
        </tr>
        </thead>
        <tbody>
        {campaigns.length ? (
            campaigns.map(item => {
              let className = 'badge badge-pill ';
              if ([3, 6].indexOf(item.status.id) !== -1) {
                className += 'badge-success';
              } else if ([4, 7].indexOf(item.status.id) !== -1) {
                className += 'badge-danger';
              } else if ([2, 5].indexOf(item.status.id) !== -1) {
                className += 'badge-warning';
              } else {
                className += 'badge-default';
              }
              let previewImage = null;
              if (item.email_campaign
                && item.email_campaign.template
                && item.email_campaign.template.email_template
                && item.email_campaign.template.email_template.preview) {
                previewImage = item.email_campaign.template.email_template.preview.file;
              }
              return (
                  <tr key={item.id}>
                    <td className="hidden-mobile">
                      <Link to={`/mailbox/${item.id}`}>
                        {item.id}
                      </Link>
                    </td>
                    <td className="hidden-mobile">
                      {<span className={
                        `badge badge-${item.dispatch_type.id === 1 ? `warning` : `info`}`
                      } style={item.dispatch_type.id === 3 ? {background: '#6a47d8', cursor: "pointer"} : {cursor: "pointer"}} onClick={() => handleClickItem(item.dispatch_type.id)}
                      >
                        {item.dispatch_type.name}
                      </span>}
                    </td>
                    <td className="hidden-mobile">
                      {item.sender_type.name}
                    </td>
                    <td>
                      {previewImage && <img src={previewImage} height={80} alt="" />}
                    </td>
                    <td>
                      <Link to={`/mailbox/${item.id}`}>
                        {item.title || 'без названия'}
                      </Link>
                    </td>
                    <td>
                      <span className={className}>
                        {item.status.name}
                      </span>
                    </td>
                    <td>
                      {moment(item.created).format('DD.MM.YYYY HH:mm')}
                    </td>
                    <td>
                      {item.entity_type.name}
                    </td>
                    <td className="hidden-mobile">{`${item.office.address}`}</td>
                    <td>
                      {`${item.creator.last_name} ${item.creator.first_name} (${item.creator.username})`}
                    </td>
                    <td className="hidden-mobile">{
                      item.dispatch_type.id === 1 ?
                          item.sms_campaign.moderation_status.name :
                          null
                    }</td>
                    <td className="hidden-mobile">{
                      Array.isArray(item.errors) && item.errors.length
                          ? item.errors.map((err, idx) => <span key={idx}>{err}</span>)
                          : null
                    }</td>
                  </tr>
              );
            })
        ) : null}
        </tbody>
      </table>
  );
};

export default CampaignList;
