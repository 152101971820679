import React, {Component} from 'react';
import './style.css';
import {checkTokenExpirationDate, getUserInfo, pushToken} from '../../connect/auth';
import APIClient, {POSITIVE_ACTION_STATUSES} from '../../api/Client';
import {Redirect} from 'react-router-dom';

export default class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            login: '',
            password: '',
            showErrorMessage: false,
            errorMessages: {},
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.error = this.error.bind(this);
        this.success = this.success.bind(this);
    }

    handleChange(e) {
        const target = e.target;
        const name = target.name;
        this.setState({
            showErrorMessage: false,
            [name]: target.value,
        });
    }

    error() {
        this.setState({
            showErrorMessage: true,
        });
    }

    success(r) {
        const {history} = this.props;
        pushToken(r.access);
        history.push('/officein');
    }

    handleSubmit(e) {
        e.preventDefault();
        const {login, password} = this.state;
        const self = this;
        let status;
        APIClient.fetchAuthToken(login, password).then(r => {
            status = r.status;
            return r.json();
        }).then(r => {
            status === POSITIVE_ACTION_STATUSES.retrieve ? self.success(r) : self.error(r);
        });
    }

    render() {
        const userInfo = getUserInfo();

        if (!!userInfo && checkTokenExpirationDate(userInfo.exp)) {
            return <Redirect to="/officein"/>;
        }
        // у некоторых пользователей возникает проблема с авторизацией
        // возможно необходимо подчистить хранилище. сбросим 1 раз
        if (!localStorage.getItem('auth')) {
            localStorage.clear();
            localStorage.setItem('auth', 1);
        }

        return (
            <div className="app flex-row align-items-center auth--bg-img">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-4 ">
                            <img height="64" src="../img/logo.client.png"/>
                            <div className="hidden-mobile">
                                <p>Геоклиент - система управления эффективной коммуникацией с клиентами в офисах
                                    туристической сети "География".
                                    Внедрение программы позволит вам оперативно и сегментированно работать с клиентсткой
                                    базой,
                                    автоматизировать отправку типовых и обязательных обращений клиентам. </p>
                                <p>Сохранить в копилке агентства каждое обращение и не потерять ни одного контакта
                                    потенциального
                                    клиента. </p>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card-group mb-0">
                                <div className="card p-4">
                                    <div className="card-block">

                                        <form action="" onSubmit={this.handleSubmit}>

                                            <h1>Вход</h1>

                                            <div className="input-group mb-3">
                                                <span className="input-group-addon"><i
                                                    className="icon-user"> </i></span>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Логин"
                                                    name="login"
                                                    value={this.state.login}
                                                    onChange={this.handleChange}
                                                />
                                            </div>

                                            <div className="input-group mb-4">
                                                <span className="input-group-addon"><i
                                                    className="icon-lock"> </i></span>
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    placeholder="Пароль"
                                                    name="password"
                                                    value={this.state.password}
                                                    onChange={this.handleChange}
                                                />
                                            </div>

                                            <div className="row">
                                                <div className="col-6">
                                                    <button type="submit" className="btn btn-primary px-4">Войти
                                                    </button>
                                                </div>
                                            </div>

                                        </form>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-md-4">
                            {this.state.showErrorMessage &&
                                <div className="alert alert-danger fade show" role="alert">
                                    <strong>Ошибка!</strong> Не удалось войти
                                </div>}
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}
