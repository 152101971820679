const routes = {
  '/': 'Главная',
  '/components': 'Components',
  '/charts': 'Charts',
  '/components/buttons': 'Buttons',
  '/components/social-buttons': 'Buttons',
  '/components/cards': 'Cards',
  '/components/forms': 'Forms',
  '/components/modals': 'Modals',
  '/components/switches': 'Switches',
  '/components/tables': 'Tables',
  '/components/tabs': 'Tabs',
  '/icons': 'Icons',
  '/icons/font-awesome': 'Font Awesome',
  '/icons/simple-line-icons': 'Simple Line Icons',
  '/widgets': 'Widgets',
  '/leads': 'Заявки с сайта',
  '/sellbook': 'Книга продаж',
  '/touristcabinets': 'Кабинет туриста',
  '/persons': 'Потенциальные клиенты',
  '/offices': 'Офисы',
  '/employees': 'Сотрудники',
  '/ordersfp': 'Мои заявки',
  '/touristsfp': 'Мои клиенты',
  '/calendar': 'Мой календарь',
  '/notify': 'Уведомления',
  '/dashboard': 'Рабочий стол менеджера',
  '/instructions': 'Инструкция по CRM',
  '/templates': 'СМС и Email'
};
export default routes;
