import React, {PureComponent} from 'react';
import {CashAccountsAPI, POSITIVE_ACTION_STATUSES, UsersAPI} from '../../../api/Client';

export default class CashAccountBalance extends PureComponent {
  constructor(props) {
    super(props);

    this.fetchData = this.fetchData.bind(this);

    this.state = {
      data: 0.0,
      balance: {value: 0, currency: 'RUB'},
    };
  }

  fetchData() {
    let status;
    const {cash_account_id} = this.props;

    CashAccountsAPI.
        fetchJSON(`${cash_account_id}/balance/`).
        then(r => {
          status = r.status;
          return r.json();
        }).
        then(r => {
          if (status === POSITIVE_ACTION_STATUSES.retrieve) {
            this.setState({
              data: r || {},
            });
          }
        });

    // UsersAPI.
    // fetchBalance().
    // then(r => {
    //   status = r.status;
    //   return r.json();
    // }).
    // then(r => {
    //   // console.log("fetchingBalance ден счет")
    //   if (status === POSITIVE_ACTION_STATUSES.retrieve) {
    //     this.setState({
    //       balance : r
    //     });
    //     // console.log(r)
    //   }
    //   else console.log(r)
    // })
  }

  componentDidMount() {
    this.fetchData();
  }

  render() {
    const {data} = this.state;
    return (
        <div><b>Баланс</b>: {data.value} {data.currency}</div>
        // <div><b>Баланс</b>: {this.state.balance.value} {this.state.balance.currency}</div>
    );
  }
}
