import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import { Collapse, Col, Row, Card, CardBlock, CardHeader } from 'reactstrap';

import moment from 'moment';
import 'moment/locale/ru';

class OrderFpWorkItem extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);

    this.state = { collapse: false };
  }

  toggle() {
    this.setState({ collapse: !this.state.collapse });
  }
  currencyIcon (currencyCode) {
    switch (currencyCode) {
      case 'USD':
        return <i className="fa fa-usd" style={{color: '#85bb65'}}></i>
      case 'RUB':
        return <i className="fa fa-rub"></i>
      case 'EUR':
        return <i className="fa fa-eur" style={{color: 'orange'}}></i>
    }
  }

  render() {
    // console.log(this.props);
    return (
      <li className="justify-content-between list-group-item item-header">
        <Link to={`/ordersfp/${this.props.item.id}`}>
              <div>
                <i className="fa fa-circle fa-1" aria-hidden="true"></i>
                  <span className="mr-30">№{this.props.item.fp_pk}</span>
                  <i className="order__icon-info order__icon-info--depart"></i>
                  {this.props.item.destination_country}
                  <span className="float-right">
                    <i className="order__icon-info order__icon-info--arrive"></i>
                    {moment(this.props.item.begin_date).format('DD.MM.YY')}
                  </span>
                  {this.props.item.tourists_fp.map((tourist, idx) =>
                    <p key={idx}><i className="order__icon-info order__icon-info--place"></i>{tourist.full_name}</p>
                  )}

              </div>
        </Link>
      </li>        
    )
  }
}

export default OrderFpWorkItem;