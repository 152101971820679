import React, {Component} from 'react';
import Select from 'react-select-plus';
import {CurrenciesAPI, POSITIVE_ACTION_STATUSES} from '../../api/Client';
import PropTypes from 'prop-types';

export const getCurrencyIcon = (currencyCode) => {
  switch (currencyCode) {
    case 'USD':
      return <i className="fa fa-usd" style={{color: '#85bb65'}}>{''}</i>;
    case 'RUB':
      return <i className="fa fa-rub">{''}</i>;
    case 'EUR':
      return <i className="fa fa-eur" style={{color: 'orange'}}>{''}</i>;
    case 'KZT':
      return <i className="fa fa-tenge" style={{color: 'orange'}}>{''}</i>;
  }

  return null;
};

export default class CurrencySelector extends Component {
  constructor(props) {
    super(props);
    this.getCurrencies = this.getCurrencies.bind(this);
    this.changeCurrency = this.changeCurrency.bind(this);

    this.state = {
      currencies: [],
    };
  }

  getCurrencies() {
    let status;
    CurrenciesAPI.
        fetchList(1).
        then(r => {
          status = r.status;
          return r.json();
        }).
        then(r => {
          if (status === POSITIVE_ACTION_STATUSES.list) {
            this.setState({
              currencies: r.results || [],
            });
          }
        });
  }

  componentDidMount() {
    this.getCurrencies();
  }

  changeCurrency(v) {
    const {changeCurrency} = this.props;
    changeCurrency(v);
  }

  render() {
    const {currencySelected, identifier, isDisabled} = this.props;
    const {currencies} = this.state;
    return (
        <Select
            clearable={true}
            disabled={!!isDisabled}
            onChange={this.changeCurrency}
            options={currencies.map(k => ({'value': k.id, 'label': k.name}))}
            placeholder={`Валюта тура`}
            value={currencySelected}
            simpleValue={true}
            id={identifier}
        />
    );
  }
}

CurrencySelector.propTypes = {
  changeCurrency: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  identifier: PropTypes.string,
};

CurrencySelector.defaultProps = {
  isDisabled: false,
  identifier: `currency-selector`,
};