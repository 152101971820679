import React, { Component } from 'react';
import Select from 'react-select-plus';
import {
  EmployeeAPI,
  POSITIVE_ACTION_STATUSES,
  UsersAPI,
} from '../../../api/Client';
import PropTypes from 'prop-types';

export const transformSelectedOffice = (officeSelected) => {
  let office = 0;

  if (!!officeSelected) {
    if (typeof officeSelected === 'object') {
      office = parseInt(officeSelected.value, 10);
    } else if (
      typeof officeSelected === 'string' ||
      typeof officeSelected === 'number'
    ) {
      office = parseInt(officeSelected, 10);
    }

    if (isNaN(office)) {
      office = 0;
    }
  }

  return office;
};

export const equivalentSelectedOfficesByStates = (
  prevStateSelectedOffice,
  newStateSelectedOffice
) => {
  return (
    transformSelectedOffice(prevStateSelectedOffice) ===
    transformSelectedOffice(newStateSelectedOffice)
  );
};

export default class EmployeesSelector extends Component {
  constructor(props) {
    super(props);
    this.getEmployees = this.getEmployees.bind(this);
    this.changeEmployee = this.changeEmployee.bind(this);

    this.state = {
      employees: [],
    };
  }

  getEmployees() {
    const office = transformSelectedOffice(this.props.officeSelected);
    if (!office) {
      return;
    }

    let status;
    UsersAPI.fetchJSON(`full_list/?offices=${office}`)
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.list) {
          this.setState({ employees: r || [] });
        } else {
          this.setState({ employees: [] });
        }
      });
  }

  componentDidMount() {
    this.getEmployees();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { officeSelected } = this.props;
    if (
      !equivalentSelectedOfficesByStates(
        prevProps.officeSelected,
        officeSelected
      ) ||
      prevProps.officeCitySelected !== this.props.officeCitySelected
    ) {
      this.getEmployees();
    }
  }

  changeEmployee(v) {
    const { updateManager } = this.props;
    updateManager(v);
  }

  render() {
    const {
      managerSelected,
      isDisabled,
      officeSelected,
      identifier,
      isMultiple,
    } = this.props;
    const { employees } = this.state;
    return (
      <Select
        clearable={true}
        disabled={isDisabled}
        onChange={this.changeEmployee}
        options={employees.map((e) => {
          const full_name =
            e.last_name || e.first_name
              ? `${e.last_name} ${e.first_name}`
              : e.email || e.username;
          return { value: e.id, label: `${e.id}  (${full_name})` };
        })}
        placeholder={
          !officeSelected ? `сначала выберите офис` : `Выберите менеджера CRM`
        }
        value={managerSelected}
        multi={isMultiple}
        simpleValue={true}
        id={identifier}
        joinValues={true}
      />
    );
  }
}
