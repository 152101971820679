import React, { Component } from 'react';
import BackButton from '../../components/BackButton';
import LeadsTable from '../../views/LeadsTable';
import { PersonAPI, IssuesAPI, LeadAPI } from '../../api/Client';
import { Card, CardHeader, CardBlock } from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import Issues from '../Issues/Issues';
import { Link } from 'react-router-dom';
import Mergers from '../Mergers/Mergers';

export default class Person extends Component {
  constructor(props) {
    super(props);

    this.handlePageChange = this.handlePageChange.bind(this);
    this.fetchItems = this.fetchItems.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.getChangedValues = this.getChangedValues.bind(this);
    this.onChangeActivity = this.onChangeActivity.bind(this);

    this.state = {
      id: this.props.match.params.number,
      info: {},
      items: [],
      issues: [],
      count: 0,
      personId: this.props.match.params.number,
      form_changed: false,
      name: '',
      middle_name: '',
      last_name: '',
      phones: '',
      emails: '',
      phone: [],
      email: [],
      born: '',
      passport_series: '',
      passport_number: '',
      passport_expires: '',
      person_info: '',
      activeTab: '1',
      tag: [],
      pageNumber: 1,
    };
  }

  handleChange(event) {
    const target = event.target;
    const name = target.name;
    this.setState({
      [name]: target.value,
      form_changed: true,
    });
  }

  toggleTab = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  handleSave() {
    const self = this;
    PersonAPI.modify(this.state.personId, this.getChangedValues()).then(
      function (r) {
        self.setState({ form_changed: false });
      }
    );
  }

  getChangedValues() {
    let result = {};
    if (this.state.name !== this.state.info.name) {
      result.name = this.state.name || null;
    }
    if (this.state.last_name !== this.state.info.last_name) {
      result.last_name = this.state.last_name;
    }
    if (this.state.middle_name !== this.state.info.middle_name) {
      result.middle_name = this.state.middle_name;
    }
    if (this.state.phones !== this.state.info.phones) {
      if (this.state.phones) {
        result.phones = this.state.phones.split(',');
      } else {
        result.phones = this.state.phones;
      }
    }
    if (this.state.emails !== this.state.info.emails) {
      if (this.state.emails) {
        result.emails = this.state.emails.split(',');
      } else {
        result.emails = this.state.emails;
      }
    }
    if (this.state.born !== this.state.info.born) {
      result.born = this.state.born;
    }
    if (this.state.passport_series !== this.state.info.passport_series) {
      result.passport_series = this.state.passport_series;
    }
    if (this.state.passport_number !== this.state.info.passport_number) {
      result.passport_number = this.state.passport_number;
    }
    if (this.state.passport_expires !== this.state.info.passport_expires) {
      result.passport_expires = this.state.passport_expires;
    }
    if (this.state.person_info !== this.state.info.info) {
      result.info = this.state.person_info;
    }
    for (let k in result) {
      if (result[k] === '') {
        result[k] = null;
      }
    }
    return result;
  }

  handlePageChange(data) {
    let page = data.selected + 1;
    this.setState({ pageNumber: page }, () => this.fetchItems(page));
  }

  updateIssues = () => {
    this.getIssues();
  };

  fetchItems(page = 1) {
    const self = this;

    // Заявки клиента
    LeadAPI.fetchList(page, { author: this.state.personId })
      .then((r) => r.json())
      .then((result) => {
        self.setState({
          items: result.results || [],
          count: result.count || 0,
        });
      });
  }

  getIssues = (page = 1) => {
    IssuesAPI.getList(page, { person: this.state.personId }).then((result) => {
      this.setState({
        ...this.state,
        issues: result.results,
      });
    });
  };

  componentDidMount() {
    // Клиент
    PersonAPI.getInfo(this.state.personId).then((result) => {
      this.setState({
        id: result.id,
        info: result || {},
        name: result.name || '',
        middle_name: result.middle_name || '',
        last_name: result.last_name || '',
        phones: result.phones || [],
        emails: result.emails || [],
        phone: result.phone || [],
        email: result.email || [],
        born: result.born || '',
        passport_series: result.passport_series || '',
        passport_number: result.passport_number || '',
        passport_expires: result.passport_expires || '',
        person_info: result.info || '',
        tag: result.tag || [],
      });
    });

    this.fetchItems(1);
    this.getIssues(1);
  }

  onChangeActivity() {
    this.props.history.push('/issues/');
  }

  render() {
    // let person = this.state.info;
    let { issues, phone, phones, email, emails, born, pageNumber } = this.state;
    const { tag } = this.state;
    // this.state.tag.map(tag => TAG_MAP[tag.id] = tag.name)
    return (
      <div className="animated fadeIn filter filter--green">
        <Card>
          <CardHeader>
            <h4>
              {this.state.last_name} {this.state.name} {this.state.middle_name}
            </h4>
          </CardHeader>
          <CardBlock>
            <div className="row">
              <div className="col-md-6 col-lg-6 mb-4">
                <p>
                  телефоны:{' '}
                  <b>
                    {Array.isArray(phone) &&
                      phone.map((i, idx) => (
                        <a key={idx} href={`tel://${i.value}`}>
                          {i.value}
                        </a>
                      ))}
                  </b>
                </p>

                <p>
                  электронная почта:{' '}
                  <b>
                    {Array.isArray(email) && email.length
                      ? email
                          .filter((x) => x.value)
                          .map((x) => x.value)
                          .join(', ')
                      : null}
                  </b>
                </p>

                <p>
                  дата рождения: <b>{born}</b>
                </p>
                <p>
                  метки:
                  {tag.map((t, idx) => (
                    <span
                      className="badge badge-pill badge-success ml-2"
                      key={idx}
                    >
                      {t.name}
                    </span>
                  ))}
                </p>
                <Link to={`/persons/${this.state.id}/edit/`}>
                  отредактировать
                </Link>
              </div>
            </div>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === '1',
                  })}
                  onClick={() => {
                    this.toggleTab('1');
                  }}
                >
                  <i className="fa fa-list"></i> Запросы
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === '2',
                  })}
                  onClick={() => {
                    this.toggleTab('2');
                  }}
                >
                  <i className="fa fa-list-alt"></i> Паспорт
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === '3',
                  })}
                  onClick={() => {
                    this.toggleTab('3');
                  }}
                >
                  <i className="fa fa-plane"></i> Заявки
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: this.state.activeTab === '4',
                  })}
                  onClick={() => {
                    this.toggleTab('4');
                  }}
                >
                  <i className="fa fa-tasks"></i> Слияния
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent className="" activeTab={this.state.activeTab}>
              <TabPane tabId="1">
                <div>
                  <Issues
                    tourist={this.state.id}
                    type="person"
                    info={this.state}
                    issues={issues || []}
                    onCreate={this.updateIssues}
                    onChangeActivity={this.onChangeActivity}
                  />
                </div>
              </TabPane>
              <TabPane tabId="2">
                <div className="col-sm-12 col-md-12 col-lg-6"></div>
              </TabPane>
              <TabPane tabId="3">
                <LeadsTable
                  count={this.state.count}
                  items={this.state.items}
                  handleRefresh={this.fetchItems}
                  handlePageChange={this.handlePageChange}
                  isShowActions={false}
                  filters={null}
                  handleDeleteLeads={() => ''}
                  toggleItem={() => ''}
                  errors={null}
                  isSuccess={false}
                  selected={[]}
                  initialPage={pageNumber - 1}
                  forcePage={pageNumber - 1}
                />
              </TabPane>
              <TabPane tabId="4">
                <div>
                  <Mergers client_id={this.state.personId} client={'person'} />
                </div>
              </TabPane>
            </TabContent>
            <BackButton {...this.props} />
          </CardBlock>
        </Card>
      </div>
    );
  }
}
