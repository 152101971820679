export const getLabelByOfficeTypeAsAdmin = (office) => {
  let label = '';
  const { type, owner_name, city, title, is_lead_notify, active } = office;
  const leadIcon = is_lead_notify ? `✅` : `❌`;

  if (type == 'online' && owner_name) {
    label = `${city}, ${owner_name} (онлайн) ${leadIcon}`;
  } else if (type == 'freelancer' ) {
    label = `${city}, ${owner_name} (фрилансер)`;
  } else {
    label = `${title} (${active ? `Раб.` : `Нераб.`}) ${leadIcon}`
  }
  return label;
}

export const getLabelByOfficeType = (office) => {
  const { type, owner_name, city, title, active } = office;

  let label = `${title}, ${owner_name}`
  if (type == 'online') {
    label = `${city}, ${owner_name} (онлайн)`;
  }
  if (type == 'freelancer') {
    label = `${city}, ${owner_name} (фрилансер)`;
  }
  return label;
}
