import React, { PureComponent } from 'react';
import { LeadAPI, PersonAPI } from '../../../api/Client/Client';
import collapsible from '../../../decorators/collapsible';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ru from 'react-phone-input-2/lang/ru.json';
import Select from 'react-select-plus/lib/Select';

const sourceOptions = [
  { label: 'Вайбер', value: 'viber' },
  { label: 'В контакте', value: 'vkontakte' },
  { label: 'Инстаграм', value: 'instagram' },
  { label: 'Однокласники', value: 'odnoklasniki' },
  { label: 'Телеграм', value: 'telegram' },
  { label: 'Фейсбук', value: 'facebook' },
  { label: 'WhatsApp', value: 'whatsapp' },
  { label: 'Соцсети', value: 'socials' },
  { label: 'Рассылки', value: 'mailing' },
  { label: 'Звонок в офис', value: 'call_to_office' },
  { label: 'Звонок на сотовый', value: 'call_to_phone' },
  { label: 'Посетитель', value: 'visitor' },
  { label: 'Обращение по рекомендации', value: 'recomendation' },
  { label: 'Постоянный клиент', value: 'permanent' },
  { label: 'Посетитель', value: 'visitor' },
  { label: 'Яндекс', value: 'yandex' },
  { label: 'Google', value: 'google' },
  { label: 'Сайт (чат)', value: 'geo_site_chat' },
];

/**
 * Добавление нового лида через блок статистики
 * потенциальных клиентов
 */
class LeadAddCustom extends PureComponent {
  constructor(props) {
    super(props);
    this.fetchItems = this.fetchItems.bind(this);
    this.handleLeadAdd = this.handleLeadAdd.bind(this);
    this.handleName = this.handleName.bind(this);
    this.handlePhone = this.handlePhone.bind(this);
    this.handleEmail = this.handleEmail.bind(this);
    this.handleComment = this.handleComment.bind(this);
    this.handleSource = this.handleSource.bind(this);

    // ..
    this.state = {
      // ...
      name: '',
      phone: '',
      email: '',
      comment: '',
      source: '',
      showError: false,
      showSuccess: false,
      errorMessage: {},
    };
  }

  getBlankState() {
    return {
      // ...
      name: '',
      phone: '',
      email: '',
      comment: '',
      showError: false,
      showSuccess: false,
      errorMessage: {},
    };
  }

  fetchItems() {
    const self = this;
  }

  handleSource(options) {
    this.setState({
      source: options,
    });
  }

  getValidPhone(phone) {
    return '+' + phone;
  }

  handleLeadAdd() {
    // ..
    const { name, phone, email, comment, source } = this.state;
    const data = {
      name: name,
      issues: [
        {
          text: comment,
          source: source,
        },
      ],
      person_info: {
        is_sms_notify: true,
        is_email_notify: true,
      },
      tag: [],
    };
    if (phone) {
      data.phone = [{ value: this.getValidPhone(phone) }];
    }
    if (email) {
      data.email = [{ value: email }];
    }

    PersonAPI.create(data)
      .then((r) => {
        if (r.person_info) {
          this.setState({
            ...this.state,
            showSuccess: true,
            showError: false,
          });
          setTimeout(() => {
            this.setState(this.getBlankState());
          }, 10000);
        } else {
          throw new Error('Заполните все поля в форме');
        }
      })
      .catch((err) => {
        this.setState({
          ...this.state,
          showError: true,
          showSuccess: false,
          errorMessage: err.message,
        });
      });
  }

  handleName(e) {
    this.setState({ name: e.target.value });
  }
  handlePhone(e) {
    this.setState({ phone: e.target.value });
  }
  handleEmail(e) {
    this.setState({ email: e.target.value });
  }
  handleComment(e) {
    this.setState({ comment: e.target.value });
  }
  componentDidMount() {
    this.fetchItems();
  }

  render() {
    return (
      <div>
        <div className="row mt-3">
          <div className="col-sm-12">
            <div className="form-group">
              <label htmlFor="name">Фамилия Имя Отчество</label>
              <input
                type="text"
                className="form-control"
                placeholder="Иванов Иван Иванович"
                value={this.state.name}
                onChange={this.handleName}
              />
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <label htmlFor="phone">Телефон</label>
              <PhoneInput
                country={'ru'}
                localization={ru}
                onlyCountries={['ru', 'kz', 'by']}
                countryCodeEditable={false}
                value={this.state.phone}
                onChange={(phone) => this.setState({ phone })}
              />
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <label htmlFor="name">Электронная почта</label>
              <input
                type="text"
                className="form-control"
                placeholder="email@mail.com"
                value={this.state.email}
                onChange={this.handleEmail}
              />
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <label htmlFor="">Откуда пришел клиент</label>
              <Select
                multi={false}
                joinValues={true}
                simpleValue={true}
                onChange={this.handleSource}
                options={sourceOptions}
                placeholder="Источник"
                value={this.state.source}
              />
            </div>
          </div>
          <div className="col-sm-12">
            <div className="form-group">
              <label htmlFor="name">Комментарий</label>
              <input
                type="text"
                className="form-control"
                placeholder=""
                value={this.state.comment}
                onChange={this.handleComment}
              />
            </div>
          </div>

          {this.state.showError ? (
            <div className="col-sm-12">
              <div className="error-message">
                <div className="alert alert-danger" role="alert">
                  {this.state.errorMessage}
                </div>
              </div>
            </div>
          ) : null}

          {this.state.showSuccess ? (
            <div className="col-sm-12">
              <div className="success-message">
                <div className="alert alert-success" role="alert">
                  Запрос успешно создан
                </div>
              </div>
            </div>
          ) : null}

          <div className="col-sm-12">
            <div className="form-group">
              <button
                type="submit"
                className="float-right btn btn-sm btn-success"
                onClick={this.handleLeadAdd}
              >
                Создать запрос
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LeadAddCustom;
