import React from 'react';
import Select from 'react-select-plus';
import FieldErrorViewer from '../Passports/components/FieldErrorViewer';
import {Alert} from 'reactstrap';

export function Action(props) {
  let {
    data,
    structure,
    structureType,
    actions,
    id,
    franchiseApplicationId,
    errors = {},
    isSuccess = false,
    isCreation = false,
  } = props;

  data.id = franchiseApplicationId

  const onChangeInput = (name, event) => {
    event.preventDefault();
    actions.onChangeInput(Object.assign(data, {[name]: event.target.value}));
  };
  const onChangeSelect = (name, val) => {
    actions.onChangeInput(Object.assign(data, {[name]: val}));
  };
  const onUpdate = (event) => {
    event.preventDefault();
    console.log("data", data)
    console.log("event", event.target)
    actions.onUpdateData(data);
  };
  const items = structure.map((item, idx) => {
    let element;
    if (item.type === 'select') {
      element = (
          <Select
              id={`input-${structureType}-${item.name}-${idx}`}
              onChange={val => onChangeSelect(item.name, val)}
              options={item.options}
              placeholder={item.lang}
              name={item.name}
              value={data[item.name]}
              simpleValue={true}
          />
      );
    } else if (item.type === 'textarea') {
      element = (
          <textarea
              id={`input-${structureType}-${item.name}-${idx}`}
              className="form-control"
              rows="3"
              name={item.name}
              onChange={val => onChangeInput(item.name, val)}
              placeholder={item.lang}
              value={data[item.name] === null ? '' : data[item.name]}>{}</textarea>
      );
    } else {
      element = (
          <input
              type={item.type}
              id={`input-${structureType}-${item.name}-${idx}`}
              name={item.name}
              value={data[item.name] === null ? '' : data[item.name]}
              onChange={val => onChangeInput(item.name, val)}
              className="form-control"
              placeholder={item.placeholder}
          />
      );
    }
    return (
        <div className="form-group row" key={idx}>
          <label className="col-md-3 col-form-label"
                 htmlFor={`input-${item.name}-${idx}`}>
            {item.lang}
          </label>
          <div className="col-md-9">
            {element}
            <FieldErrorViewer errorMessages={errors} field={item.name}/>
          </div>
        </div>
    );
  });
  return (
      <div>
        {!isCreation ? (
            <form id={id}>
              {items}
              <button className="btn btn-success" onClick={onUpdate} type="submit">
                Обновить
              </button>
            </form>
        ) : items}<br/>
        {isSuccess ? <div><Alert color="success">Успешно</Alert></div> : null}<br/>
      </div>
  );
}
