import React, { PureComponent } from 'react';
import TourItem from './TourItem/TourItem';

export default class ToursList extends PureComponent {
  constructor(props) {
    super(props);
    this.toggleTemplate = this.toggleTemplate.bind(this);

    this.state = {
      is_simple: false,
    };
  }
  toggleTemplate() {
    this.setState({
      ...this.state,
      is_simple: !this.state.is_simple,
    });
  }
  componentDidMount() {}
  render() {
    const { items, quote, qid, onDelete, onManagerDataSave } = this.props;
    const { is_simple } = this.state;

    return (
      <div>
        {items ? (
          <div>
            {!is_simple ? (
              <div>
                <div className="row">
                  <p className="col-md-12">
                    <span className="badge badge-success">ПОДБОРКА ТУРОВ</span>
                    <i
                      className="tours-list__type fa fa-list-alt float-right"
                      onClick={this.toggleTemplate}
                    ></i>
                  </p>
                </div>
                <ul className="tour__container" style={{ listStyle: 'none' }}>
                  {items.map((tour) => (
                    <li key={tour.tour_id}>
                      <TourItem
                        tour={tour}
                        quote={quote}
                        qid={qid}
                        onDelete={onDelete}
                        onManagerDataSave={onManagerDataSave}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            ) : (
              <div>
                <div className="row">
                  <p className="col-md-12">
                    <span className="badge badge-success">ПОДБОРКА ТУРОВ</span>
                    <i
                      className="tours-list__type fa fa-th-list float-right"
                      onClick={this.toggleTemplate}
                    ></i>
                  </p>
                </div>
                <ul>
                  {items.map((tour) => (
                    <li className="mb-2" key={tour.tour_id}>
                      <p>
                        <i className="badge badge-success badge-pill mr-2">
                          {tour.status}
                        </i>
                        <img
                          src={tour.thumbnail}
                          className="mr-2"
                          width="30"
                          style={{ borderRadius: '3px' }}
                        />
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            gap: '6px',
                          }}
                        >
                          <span style={{ textWrap: 'wrap' }}>
                            {tour.name} на {tour.nights} ночей{' '}
                          </span>
                          <span style={{ textWrap: 'nowrap' }}>
                            {tour.price ? tour.price : tour.initial_price}{' '}
                            <i className="fa fa-rouble"></i>
                          </span>{' '}
                        </div>
                      </p>
                      {!!tour.manager_data.length &&
                      !!tour.manager_data.comment ? (
                        <p style={{ paddingBottom: 10 }}>
                          <i className="fa fa-comment pr-2"></i>
                          {tour.manager_data.comment}
                        </p>
                      ) : null}
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  }
}
