import React, {Component} from 'react';
import {Table} from 'reactstrap';

export const ISSUE_SOURCE_MAP = {
  call_to_office: {
    name: "звонок в офис",
    icon: "fa-phone",
    position: 1
  },
  call_to_phone: {
    name: "звонок на сотовый",
    icon: "fa-mobile",
    position: 2,
  },
  visitor: {
    name: "посетитель",
    icon: "fa-user",
    position: 3,
  },
  geo_site_chat: {
    name: 'Сайт (чат)',
    icon: "fa-commenting-o",
    position: 4,
  },
  geo_site_form_hot: {
    name: 'Сайт (горящая заявка)',
    icon: "fa-star",
    position: 5,
  },
  cabinet: {
    name: 'ЛК (офис)',
    icon: "fa-star-half-o",
    position: 6,
  },
  cabinet_online: {
    name: 'ЛК (покупка онлайн)',
    icon: 'fa-star-half-o',
    position: 7,
  },
  excel: {
    name: 'выгрузка excel',
    icon: 'fa-file',
    position: 8,
  },
  recommendation: {
    name: 'рекомендация',
    icon: 'fa-hand-peace-o',
    position: 9,
  },
  permanent: {
    name: 'постоянный клиент',
    icon: 'fa-star',
    position: 10,
  },
  instagram: {
    name: 'инстаграм',
    icon: 'fa-instagram',
    position: 11,
  },
  vkontakte: {
    name: 'в контакте',
    icon: 'fa-vk',
    position: 12,
  },
  facebook: {
    name: 'фейсбук',
    icon: 'fa-facebook',
    position: 13,
  },
  odnoklasniki: {
    name: 'однокласники',
    icon: 'fa-odnoklassniki',
    position: 14,
  },
  socials: {
    name: "соцсети",
    icon: "fa-share-alt",
    position: 15,
  },
  mailing: {
    name: 'рассылки',
    icon: 'fa-mail',
    position: 16,
  },
  viber: {
    name: 'вайбер',
    icon: 'fa-weixin',
    position: 17,
  },
  telegram: {
    name: 'телеграм',
    icon: 'fa-send',
    position: 18,
  },
  whatsapp: {
    name: 'whatsapp',
    icon: 'fa-whatsapp',
    position: 19,
  },
  geo_site_quote: {
    name: 'геоподборка с сайта',
    icon: 'fa-check',
    position: 20,
  },
  quotes_from_crm: {
    name: 'геоподборка из CRM',
    icon: 'fa-check',
    position: 21,
  },
  yandex: {
    name: 'Яндекс',
    icon: 'fa-check',
    position: 22,
  },
  google: {
    name: 'Google',
    icon: 'fa-check',
    position: 23,
  },
};

export default class IssuesManagerSourceReportTable extends Component {
  constructor(props) {
    super(props);

    this.handlerSort = this.handlerSort.bind(this);

  }

  handlerSort(e) {
    this.props.onSort(e.target.title);
  }
  render() {
    const { items, total } = this.props;
    let summ = 0;
    for( let k in total) {
      summ += total[k];
    }
    return <div>
      <Table striped bordered size="sm" className="font-xs table--green">
        <thead>
        <tr style={{fontSize: 10}}>
          <th>Менеджер</th>
          <th onClick={this.handlerSort} style={{cursor: 'pointer', width: '40px'}} title="geo_site_form_hot">
            сайт<br/>
            (го<br/>ря<br/>щая<br/>
            за<br/>яв<br/>ка)<br/>
            <i className="fa fa-sort ml-2"></i>
          </th>
          <th onClick={this.handlerSort} style={{cursor: 'pointer', width: '40px'}} title="cabinet">
            ЛК<br/>
            (оф<br/>ис)<br/>
            <i className="fa fa-sort ml-2"></i>
          </th>
          <th onClick={this.handlerSort} style={{cursor: 'pointer', width: '40px'}} title="permanent">
            по<br/>сто<br/>ян<br/>ный<br/>
            <i className="fa fa-sort ml-2"></i>
          </th>
          <th onClick={this.handlerSort} style={{cursor: 'pointer', width: '40px'}} title="recommendation">
            ре<br/>ко<br/>мен<br/>да<br/>ция<br/>
            <i className="fa fa-sort ml-2"></i>
          </th>
          <th onClick={this.handlerSort} style={{cursor: 'pointer', width: '40px'}} title="quotes_from_crm">
            под<br/>бор<br/>ка<br/>
            из<br/>
            crm<br/>
            <i className="fa fa-sort ml-2"></i>
          </th>
          <th onClick={this.handlerSort} style={{cursor: 'pointer', width: '40px'}} title="geo_site_quote">
            под<br/>бор<br/>ка<br/>
            с<br/>
            сай<br/>та<br/>
            <i className="fa fa-sort ml-2"></i>
          </th>
          <th onClick={this.handlerSort} style={{cursor: 'pointer', width: '40px'}} title="whatsapp">
            what<br/>sapp<br/>
            <i className="fa fa-sort ml-2"></i>
          </th>
          <th onClick={this.handlerSort} style={{cursor: 'pointer', width: '40px'}} title="call_to_phone">
            зво<br/>нок<br/>
            на<br/>
            со<br/>то<br/>вый<br/>
            <i className="fa fa-sort ml-2"></i>
          </th>
          <th onClick={this.handlerSort} style={{cursor: 'pointer', width: '40px'}} title="call_to_office">
            зво<br/>нок<br/>
            в<br/>
            оф<br/>ис<br/>
            <i className="fa fa-sort ml-2"></i>
          </th>
          <th onClick={this.handlerSort} style={{cursor: 'pointer', width: '40px'}} title="visitor">
            по<br/>се<br/>ти<br/>тель<br/>
            <i className="fa fa-sort ml-2"></i>
          </th>
          <th onClick={this.handlerSort} style={{cursor: 'pointer', width: '40px'}} title="socials">
            соц<br/>се<br/>ти<br/>
            <i className="fa fa-sort ml-2"></i>
          </th>
          <th onClick={this.handlerSort} style={{cursor: 'pointer', width: '40px'}} title="instagram">
            ин<br/>ста<br/>грам<br/>
            <i className="fa fa-sort ml-2"></i>
          </th>
          <th onClick={this.handlerSort} style={{cursor: 'pointer', width: '40px'}} title="vkontakte">
            вкон<br/>так<br/>те<br/>
            <i className="fa fa-sort ml-2"></i>
          </th>
          <th onClick={this.handlerSort} style={{cursor: 'pointer', width: '40px'}} title="facebook">
            фейс<br/>бук<br/>
            <i className="fa fa-sort ml-2"></i>
          </th>
          <th onClick={this.handlerSort} style={{cursor: 'pointer', width: '40px'}} title="odnoklasniki">
            од<br/>нок<br/>лас<br/>ни<br/>ки<br/>
            <i className="fa fa-sort ml-2"></i>
          </th>
          <th onClick={this.handlerSort} style={{cursor: 'pointer', width: '40px'}} title="mailing">
            рас<br/>сыл<br/>ки<br/>
            <i className="fa fa-sort ml-2"></i>
          </th>
          <th onClick={this.handlerSort} style={{cursor: 'pointer', width: '40px'}} title="viber">
            вай<br/>бер<br/>
            <i className="fa fa-sort ml-2"></i>
          </th>
          <th onClick={this.handlerSort} style={{cursor: 'pointer', width: '40px'}} title="telegram">
            те<br/>лег<br/>рам<br/>
            <i className="fa fa-sort ml-2"></i>
          </th>
          <th onClick={this.handlerSort} style={{cursor: 'pointer', width: '40px'}} title="excel">
            ex<br/>cel<br/>
            <i className="fa fa-sort ml-2"></i>
          </th>
          <th onClick={this.handlerSort} style={{cursor: 'pointer', width: '40px'}} title="yandex">
            ян<br/>декс<br/>
            <i className="fa fa-sort ml-2"></i>
          </th>
          <th onClick={this.handlerSort} style={{cursor: 'pointer', width: '40px'}} title="google">
            гугл<br/>
            <i className="fa fa-sort ml-2"></i>
          </th>
          <th onClick={this.handlerSort} style={{cursor: 'pointer', width: '40px'}} title="geo_site_chat">
            чат<br/>
            с<br/>
            сай<br/>та<br/>
            <i className="fa fa-sort ml-2"></i>
          </th>
          <th onClick={this.handlerSort} style={{cursor: 'pointer', width: '40px'}} title="total">
            все<br/>го<br/>
            <i className="fa fa-sort ml-2"></i>
          </th>
        </tr>
        </thead>
        <tbody>
      { items.length && items.map( (item, idx) => <tr key={idx} >
        <td><b>{`${item.manager__last_name || ''} ${item.manager__first_name || ''}`}</b></td>
        <td>{item.geo_site_form_hot}</td>
        <td>{item.cabinet}</td>
        <td>{item.permanent}</td>
        <td>{item.recommendation}</td>
        <td>{item.quotes_from_crm}</td>
        <td>{item.geo_site_quote}</td>
        <td>{item.whatsapp}</td>
        <td>{item.call_to_phone}</td>
        <td>{item.call_to_office}</td>
        <td>{item.visitor}</td>
        <td>{item.socials}</td>
        <td>{item.instagram}</td>
        <td>{item.vkontakte}</td>
        <td>{item.facebook}</td>
        <td>{item.odnoklasniki}</td>
        <td>{item.mailing}</td>
        <td>{item.viber}</td>
        <td>{item.telegram}</td>
        <td>{item.excel}</td>
        <td>{item.yandex}</td>
        <td>{item.google}</td>
        <td>{item.geo_site_chat}</td>
        <td><b>{item.total}</b></td>
        </tr>)}
        <tr style={{background: "#5c9353", color: "#fff"}}>
          <td><b>ВСЕГО</b></td>
          <td><b>{total.geo_site_form_hot_total}</b></td>
          <td><b>{total.cabinet_total}</b></td>
          <td><b>{total.permanent_total}</b></td>
          <td><b>{total.recommendation_total}</b></td>
          <td><b>{total.quotes_from_crm_total}</b></td>
          <td><b>{total.geo_site_quote_total}</b></td>
          <td><b>{total.whatsapp_total}</b></td>
          <td><b>{total.call_to_phone_total}</b></td>
          <td><b>{total.call_to_office_total}</b></td>
          <td><b>{total.visitor_total}</b></td>
          <td><b>{total.socials_total}</b></td>
          <td><b>{total.instagram_total}</b></td>
          <td><b>{total.vkontakte_total}</b></td>
          <td><b>{total.facebook_total}</b></td>
          <td><b>{total.odnoklasniki_total}</b></td>
          <td><b>{total.mailing_total}</b></td>
          <td><b>{total.viber_total}</b></td>
          <td><b>{total.telegram_total}</b></td>
          <td><b>{total.excel_total}</b></td>
          <td><b>{total.yandex_total}</b></td>
          <td><b>{total.google_total}</b></td>
          <td><b>{total.geo_site_chat_total}</b></td>
          <td><big><b>{summ}</b></big></td>
        </tr>
        </tbody>
      </Table>
    </div>
  }
}
