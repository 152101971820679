import React, { PureComponent } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import TabUsers from '../../../TabUsers/TabUsers';
import './UserTypeModal.css';

export default class UserTypeModal extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    if (
      (this.props.personsForView &&
        this.props.personsForView.length === 0 &&
        this.props.touristfpsForView &&
        this.props.touristfpsForView.length === 0) ||
      (!this.props.personsForView && !this.props.touristfpsForView)
    ) {
      return (
        <Modal
          className="modal-table-warning"
          toggle={this.props.toggleLikesListModal}
          isOpen={this.props.isOpen}
        >
          <ModalHeader
            className="modal-table-header-warning"
            toggle={this.props.toggleLikesListModal}
          >
            Нет пользователей для отображения
          </ModalHeader>
        </Modal>
      );
    }

    return (
      <Modal
        toggle={this.props.toggleLikesListModal}
        isOpen={this.props.isOpen}
        className="modal-table"
      >
        <div className="modal-container">
          <ModalHeader
            className="modal-table-header"
            toggle={this.props.toggleLikesListModal}
          >
            {`${this.props.modalTitle} пользователи`}
          </ModalHeader>
          <ModalBody className="modal-table-content">
            <TabUsers
              persons={this.props.personsForView}
              touristfps={this.props.touristfpsForView}
            />
          </ModalBody>
        </div>
      </Modal>
    );
  }
}
