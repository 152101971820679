export const UP_TO_100_THOUSAND = 'up_to_100_thousand';
export const FROM_100_TO_500_THOUSAND = 'from_100_to_500_thousand';
export const MORE_THAN_500_THOUSAND = 'more_than_500_thousand';
export const CITY_POPULATION = {
  [UP_TO_100_THOUSAND]: 'до 100 тысяч человек',
  [FROM_100_TO_500_THOUSAND]: '100-500 тысяч человек',
  [MORE_THAN_500_THOUSAND]: 'более 500 тысяч человек',
};

export const CITY_ACTION_STRUCTURE = [
  {
    name: 'name',
    lang: 'Название',
    type: 'text',
    placeholder: 'Екатеринбург',
  },
  {
    name: 'population',
    lang: 'Численность населения города',
    type: 'select',
    options: Object.keys(CITY_POPULATION).map(key => (
        {value: key, label: CITY_POPULATION[key]}
    )),
  },
];
