import React, { Component } from 'react'

export default class IssuePresentation extends Component {
  render() {
    return (
      <div>

        <h4>Презентация</h4>
        <h5>делаем на языке выгод клиента</h5>

        <ul className="list-group">
          <li className="list-group-item list-group-item-warning">
            «Попасть в карту потребностей туриста»
          </li>
          <li className="list-group-item list-group-item-warning">
            Презентовать факт «попадания в карту потребностей» - <b>«как вы и хотели, я …»</b>
          </li>
          <li className="list-group-item list-group-item-warning">
            «Продать» ценность продукта, основанную на выгодах и преимуществах конкретного для этого клиента (основанную на карте
        потребностей)
          </li>
          <li className="list-group-item list-group-item-warning">
            <h6>Заложить убеждения:</h6>
              <ul className="list-group">
                <li className="list-group-item">
                  <p>Что это самое выгодное предложение (соответствует тому, что хотел на 100%)</p>
                </li>
                <li className="list-group-item">
                  <p>Цена самая лучшая (выгодная для этого клиента)</p>
                </li>
              </ul>
          </li>
          <li className="list-group-item list-group-item-warning">
            Договорится о следующее касании
          </li>
          <li className="list-group-item list-group-item-warning">
            Использовать дедлайны: обосновываем клиенту – почему здесь, сейчас, не нужно медлить с принятием решения
          </li>
        </ul>
      </div>
    )
  }
}
