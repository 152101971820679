import React, { PureComponent } from 'react';
import {NavLink} from 'react-router-dom';
import { Collapse, Col, Row, Card, CardBlock, CardHeader } from 'reactstrap';
import moment from 'moment';
import 'moment/locale/ru';

class CalendarEventList extends PureComponent {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);

        this.state = { collapse: false };
        }

        toggle() {
        this.setState({ collapse: !this.state.collapse });
    }
  render() {
    if (!this.props.eventList.length) {
        return (
            <ul className="list-group">

            </ul>
        )
    }
    return (
      <ul className="list-group" onClick={this.toggle}>
      <li className="justify-content-between list-group-item">
        <span className={'badge badge-' + this.props.color + ' badge-pill'}> {this.props.title}</span>
        <span className={'float-right badge badge-' + this.props.color + ' badge-pill'}>{ this.props.eventList.length }</span>
      </li>
      <Collapse isOpen={this.state.collapse}>
          { this.props.eventList.map( (event, idx) =>
              <li className="justify-content-between list-group-item" style={{display: 'flex', justifyContent: "space-between", maxHeight: 60}} key={idx}>
                <div style={{minWidth: 90}}>
                  <i className="fa fa-circle fa-1" aria-hidden="true"></i>
                  <NavLink to={`/calendar/${event.day}`}>
                      <span className="">{ moment(event.day).format('DD.MM.YY') }</span>
                  </NavLink>
                </div>
                <div style={{textAlign: "right"}}>
                  <span>{ event.notes }</span>
                </div>
              </li>
              )
          }
      </Collapse>
      </ul>
    )
  }
}

export default CalendarEventList;
