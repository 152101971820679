import React from 'react';
import {TemplateDataAPI, OfficeAPI, POSITIVE_ACTION_STATUSES} from '../../../api/Client/';
import {Alert} from 'reactstrap';
import BackButton from '../../../components/BackButton';
import FieldErrorViewer from '../../Passports/components/FieldErrorViewer';
import OfficeSelectContainer from '../../../components/OfficeSelectContainer';

const waImage = '/img/social/wa.png';
const vbImage = '/img/social/vb.png';
const fbImage = '/img/social/fb.png';
const vkImage = '/img/social/vk.png';
const tgImage = '/img/social/tg.png';
const okImage = '/img/social/ok.png';
const igImage = '/img/social/ig.png';
const ytImage = '/img/social/youtube.png';
const TIMEOUT = 2000;

export default class TemplateSettings extends React.PureComponent {
  constructor(props) {
    super(props);

    this.onSaveData = this.onSaveData.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.onDeleteData = this.onDeleteData.bind(this);
    this.onHandleChange = this.onHandleChange.bind(this);
    this.onChangeOffice = this.onChangeOffice.bind(this);

    this.state = {
      officeName: '',
      officeId: '0',
      header_office_link: '',
      footer_office_link: '',
      header_office_line: '',
      content_review_link: '',
      footer_office_line: '',
      footer_vk_link: '',
      footer_office_phone: '',
      footer_instagram_link: '',
      footer_facebook_link: '',
      footer_viber: '',
      footer_telegram: '',
      footer_whats_app: '',
      footer_youtube_link: '',
      footer_ok_link: '',
      footer_phone: '',

      errorMessages: {},
      isSuccess: false,
      isError: false,
      isDisabledButtons: false,
    };
  }

  onChangeOffice(v) {
  }

  onSaveData() {
    let status = 200;
    const responseHandler = (r) => {
      status = r.status;
      return r.json();
    };
    let action;
    const resultHandler = (r) => {
      if (POSITIVE_ACTION_STATUSES[action] === status) {
        this.setState({
          errorMessages: {},
          successMessage: action === 'create'
              ? 'Данные для шаблонов созданы. Вы будете перенаправлены.'
              : 'Данные для шаблонов обновлены.',
          isSuccess: true,
          isError: false,
          isDisabledButtons: action === 'create',
        }, () => {
          if (action === 'create') {
            setTimeout(() => {
              this.props.history.push(`/options/`);
            }, TIMEOUT);
          }
          setTimeout(() => {
            this.fetchData();
          }, TIMEOUT);
        });
      } else {
        this.setState({
          errorMessages: r,
          successMessage: '',
          isDisabledButtons: false,
          isSuccess: false,
          isError: true,
        });
      }
    };
    const data = {
      id: this.state.id,
      header_office_link: this.state.header_office_link,
      footer_office_link: this.state.footer_office_link,
      header_office_line: this.state.header_office_line,
      footer_office_line: this.state.footer_office_line,
      content_review_link: this.state.content_review_link,
      footer_vk_link: this.state.footer_vk_link,
      footer_office_phone: this.state.footer_office_phone,
      footer_instagram_link: this.state.footer_instagram_link,
      footer_facebook_link: this.state.footer_facebook_link,
      footer_viber: this.state.footer_viber,
      footer_telegram: this.state.footer_telegram,
      footer_whats_app: this.state.footer_whats_app,
      footer_youtube_link: this.state.footer_youtube_link,
      footer_ok_link: this.state.footer_ok_link,
      footer_phone: this.state.footer_phone,
      office: this.props.match.params.number,
    };

    if (this.state.id) {
      action = `update`;
      this.setState({
        isDisabledButtons: true,
      }, () => {
        TemplateDataAPI.
            update(this.state.id, data).
            then(responseHandler).
            then(resultHandler);
      });
    } else if (!this.state.id) {
      action = `create`;
      this.setState({
        isDisabledButtons: true,
      }, () => {
        TemplateDataAPI.
            create(data).
            then(responseHandler).
            then(resultHandler);
      });
    }
  }

  onDeleteData() {
    let status;
    this.setState({
      isDisabledButtons: true,
    }, () => {
      TemplateDataAPI.
          delete(this.state.id).
          then((r) => {
            status = r.status;
            if (POSITIVE_ACTION_STATUSES.destroy !== status) {
              return r.json();
            }
            return {};
          }).
          then((r) => {
            if (POSITIVE_ACTION_STATUSES.destroy === status) {
              this.setState({
                errorMessages: {},
                successMessage: 'Удаление произведено успешно. Вы будете перенаправлены.',
                isSuccess: true,
                isError: false,
              }, () => {
                setTimeout(() => {
                  this.props.history.push(`/options/`);
                }, TIMEOUT);
              });
            } else {
              this.setState({
                errorMessages: r,
                successMessage: '',
                isSuccess: false,
                isError: true,
              });
            }
          });
    });
  }

  fetchData() {
    let status;
    OfficeAPI.
        fetchJSON(`${this.props.match.params.number}/template_data/`).
        then((r) => {
          status = r.status;
          return r.json();
        }).
        then((result) => {
          if (status === POSITIVE_ACTION_STATUSES.retrieve) {
            if (!!result.template_data) {
              this.setState({
                id: result.template_data.id,
                header_office_link: result.template_data.header_office_link,
                footer_office_link: result.template_data.footer_office_link,
                header_office_line: result.template_data.header_office_line,
                content_review_link: result.template_data.content_review_link,
                footer_office_line: result.template_data.footer_office_line,
                footer_vk_link: result.template_data.footer_vk_link,
                footer_office_phone: result.template_data.footer_office_phone,
                footer_instagram_link: result.template_data.footer_instagram_link,
                footer_facebook_link: result.template_data.footer_facebook_link,
                footer_viber: result.template_data.footer_viber,
                footer_telegram: result.template_data.footer_telegram,
                footer_whats_app: result.template_data.footer_whats_app,
                footer_youtube_link: result.template_data.footer_youtube_link,
                footer_ok_link: result.template_data.footer_ok_link,
                footer_phone: result.template_data.footer_phone,
                officeId: result.id,
                officeName: result.name,

                errorMessages: {},
                successMessage: '',
                isSuccess: false,
                isError: false,
              });
            } else {
              this.setState({
                officeId: result.id,
                officeName: result.name,

                errorMessages: {},
                successMessage: '',
                isSuccess: false,
                isError: false,
              });
            }
          }
        });
  }

  onHandleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
      isSuccess: false,
      isError: false,
    });
  }

  componentDidMount() {
    this.fetchData();
  }

  render() {
    const {officeName, officeId} = this.state;
    return (
        <div>
          <div className="row">
            <div className="col-sm-6 col-md-6">
              <div className="card card-accent-primary">
                <div className="card-header">
                  <h4>Настройка шаблона для email рассылки</h4>
                </div>
                <div className="form-group row">
                  <label className="text-right col-md-2 col-form-label">Офис:</label>
                  <div className="col-md-6">
                    <OfficeSelectContainer
                        disabledSelect={true}
                        selected_office={{label: officeName, value: officeId}}
                        changeOffice={this.onChangeOffice}
                    />
                    <FieldErrorViewer errorMessages={this.state.errorMessages} field="office"/>
                  </div>
                </div>

                <Alert color="info">Верхняя часть шаблона</Alert>

                <div className="card-block">
                  {
                    [
                      {
                        name: 'header_office_line',
                        lang: 'Название офиса',
                        type: 'text',
                        placeholder: 'География',
                      },
                      {
                        name: 'header_office_link',
                        lang: 'Ссылка на офис',
                        type: 'url',
                        placeholder: 'http://geograftour.com/my_office',
                      },
                    ].map((item, idx) => {
                      return (
                          <div className="form-group row" key={idx}>
                            <label className="text-right col-md-2 col-form-label" htmlFor={`input-${item.name}-${idx}`}>
                              {item.lang}
                            </label>
                            <div className="col-md-8">
                              <input
                                  type={item.type}
                                  id={`input-${item.name}-${idx}`}
                                  name={item.name}
                                  value={this.state[item.name]}
                                  onChange={this.onHandleChange}
                                  className="form-control"
                                  placeholder={item.placeholder}
                              />
                              <FieldErrorViewer errorMessages={this.state.errorMessages} field={item.name}/>
                            </div>
                          </div>
                      );
                    })
                  }
                </div>

                <Alert color="info">Нижняя часть шаблона</Alert>

                <div className="card-block">

                  <h6 className="mb-4">Контактная информация:</h6>
                  {
                    [
                      {
                        name: 'footer_office_line',
                        lang: 'Название офиса',
                        type: 'text',
                        placeholder: 'География',
                      },
                      {
                        name: 'footer_office_link',
                        lang: 'Ссылка на офис',
                        type: 'url',
                        placeholder: 'http://geograftour.com/my_office',
                      },
                      {
                        name: 'footer_phone',
                        lang: 'Телефон',
                        type: 'text',
                        placeholder: '+79077777777',
                      },
                      {
                        name: 'content_review_link',
                        lang: 'Ссылка на Яндекс отзыв',
                        type: 'text',
                        placeholder: 'укажите ссылку на организацию',
                      },
                    ].map((item, idx) => {
                      return (
                          <div className="form-group row" key={idx}>
                            <label className="text-right col-md-2 col-form-label" htmlFor={`input-${item.name}-${idx}`}>
                              {item.lang}
                            </label>
                            <div className="col-md-8">
                              <input
                                  type={item.type}
                                  id={`input-${item.name}-${idx}`}
                                  name={item.name}
                                  value={this.state[item.name]}
                                  onChange={this.onHandleChange}
                                  className="form-control"
                                  placeholder={item.placeholder}
                              />
                              <FieldErrorViewer errorMessages={this.state.errorMessages} field={item.name}/>
                            </div>
                          </div>
                      );
                    })
                  }

                  <h6 className="mb-4">Мессенджеры:</h6>
                  {
                    [
                      {
                        name: 'footer_whats_app',
                        placeholder: '+79077777777',
                        image: {
                          src: waImage,
                          alt: 'WhatsApp',
                        },
                      },
                      {
                        name: 'footer_telegram',
                        placeholder: '+79077777777',
                        image: {
                          src: tgImage,
                          alt: 'Telegram',
                        },
                      },
                      {
                        name: 'footer_viber',
                        placeholder: '+79077777777',
                        image: {
                          src: vbImage,
                          alt: 'Viber',
                        },
                      },
                    ].map((item, idx) => {
                      return (
                          <div className="form-group row" key={idx}>
                            <label className="text-right col-md-2 col-form-label" htmlFor={`input-${item.name}-${idx}`}>
                              <img src={item.image.src} alt={item.image.alt} width="16" className="mr-2"/>
                              {item.image.alt}
                            </label>
                            <div className="col-md-8">
                              <input type="text"
                                     id={`input-${item.name}-${idx}`}
                                     name={item.name}
                                     value={this.state[item.name]}
                                     onChange={this.onHandleChange}
                                     className="form-control"
                                     placeholder={item.placeholder}
                              />
                              <FieldErrorViewer errorMessages={this.state.errorMessages} field={item.name}/>
                            </div>
                          </div>
                      );
                    })
                  }

                  <h6 className="mb-4">Соц. сети:</h6>
                  {
                    [
                      {
                        name: 'footer_vk_link',
                        lang: 'ВКонтакте',
                        placeholder: 'https://vk.com/geograftur/',
                        image: {
                          src: vkImage,
                          alt: 'VK',
                        },
                      },
                      {
                        name: 'footer_instagram_link',
                        lang: 'Instagram',
                        placeholder: 'https://instagram.com/geograftur_rus/',
                        image: {
                          src: igImage,
                          alt: 'Instagram',
                        },
                      },
                      {
                        name: 'footer_facebook_link',
                        lang: 'Facebook',
                        placeholder: 'https://facebook.com/geograftur/',
                        image: {
                          src: fbImage,
                          alt: 'Facebook',
                        },
                      },
                      {
                        name: 'footer_youtube_link',
                        lang: 'YouTube',
                        placeholder: 'https://youtube.com/geograftur/',
                        image: {
                          src: ytImage,
                          alt: 'Ютуб',
                        },
                      },
                      {
                        name: 'footer_ok_link',
                        lang: 'ОК',
                        placeholder: 'https://odnoklasniki.ru/geograftur/',
                        image: {
                          src: okImage,
                          alt: 'Odnoklasniki',
                        },
                      },
                    ].map((item, idx) => {
                      return (
                          <div className="form-group row" key={idx}>
                            <label className="text-right col-md-2 col-form-label" htmlFor={`input-${item.name}-${idx}`}>
                              <img src={item.image.src} alt={item.image.alt} width="16" className="mr-2"/>
                              {item.lang}
                            </label>
                            <div className="col-md-8">
                              <input type="url"
                                     id={`input-${item.name}-${idx}`}
                                     name={item.name}
                                     value={this.state[item.name]}
                                     onChange={this.onHandleChange}
                                     className="form-control"
                                     placeholder={item.placeholder}
                              />
                              <FieldErrorViewer errorMessages={this.state.errorMessages} field={item.name}/>
                            </div>
                          </div>
                      );
                    })
                  }
                </div>

                {this.state.isSuccess && <Alert color="success">{this.state.successMessage}</Alert>}
                {this.state.isError && !!this.state.errorMessages.non_field_errors
                    ? this.state.errorMessages.non_field_errors.map((item, idx) => {
                      return <Alert color="warning" key={idx}>{item}</Alert>;
                    }) : ``}
                {this.state.isError && !!this.state.errorMessages.detail
                    ? <Alert color="warning">Ошибка запроса: {this.state.errorMessages.detail}</Alert>
                    : ``}

                <div className="card-footer">
                  <BackButton {...this.props}/>
                  <button
                      className="btn btn-success"
                      onClick={this.onSaveData}
                      disabled={this.state.isDisabledButtons}
                  >Применить
                  </button>
                  {this.state.id
                      ? <button
                          className="btn btn-danger float-right"
                          onClick={this.onDeleteData}
                          disabled={this.state.isDisabledButtons}
                      >Удалить</button>
                      : ``}
                </div>
              </div>

            </div>
          </div>
        </div>
    );
  }
}
