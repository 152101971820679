import React from 'react'

const ProgressBar = () => {
  return <div className="progress-bar dot-loader">
    <i className="dot dot--blue"></i>
    <i className="dot dot--green"></i>
    <i className="dot dot--yellow" style={{background: '#B78902'}}></i>
    <i className="dot dot--pink"></i>
    <span id="progress_bar" className="search__state-text"></span>
  </div>
}

export default ProgressBar
