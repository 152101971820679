export const OFFICE_RENT = 'office_rent';
export const OWNED = 'owned';
export const OFFICE_AFFILIATION = {
  [OFFICE_RENT]: 'аренда офиса',
  [OWNED]: 'в собственности',
};

export const OFFICE_ACTION_STRUCTURE = [
  {
    name: 'address',
    lang: 'Адрес офиса',
    type: 'text',
    placeholder: 'ФИО',
  },
  {
    name: 'affiliation',
    lang: 'Условия по офису',
    type: 'select',
    options: Object.keys(OFFICE_AFFILIATION).map(key => (
        {value: key, label: OFFICE_AFFILIATION[key]}
    )),
  },
  {
    name: 'is_separate_entrance',
    lang: 'Есть ли отдельный вход',
    type: 'select',
    options: [{value: true, label: 'Да'}, {value: false, label: 'Нет'}],
  },
  {
    name: 'is_possibility_of_placing_signage',
    lang: 'Возможность размещения вывески',
    type: 'select',
    options: [{value: true, label: 'Да'}, {value: false, label: 'Нет'}],
  },
  {
    name: 'area',
    lang: 'Площадь помещения (кв.м)',
    type: 'number',
    placeholder: '25.2',
  },
];
