import React, { Component } from 'react';
import { TouristFpAPI } from '../../../api/Client';
import { Link } from 'react-router-dom';

export default class TouristFpLink extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: 0,
      full_name: '',
    };
  }

  componentDidMount() {
    // Клиент
    TouristFpAPI.getInfo(this.props.touristfpId).then((result) => {
      if (!result.id) {
        this.setState({
          errors: result,
        });
      } else {
        this.setState({
          id: result.id,
          full_name: result.full_name,
        });
      }
    });
  }

  render() {
    const tourist = this.state;
    return (
      <Link
        to={`/tourists/${tourist.id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {tourist.full_name}
      </Link>
    );
  }
}
