import 'moment/locale/ru';
import moment from 'moment';
import React, { PureComponent } from 'react';
import Swal from 'sweetalert';

import APIClient, { QuotesAPI } from '../../api/Client';
import BackButton from '../../components/BackButton';
import QuoteTourists from './components/QuoteTourists';
import ToursList from '../Tours/components/ToursList';
import { getUserRole, SUBAGENT } from '../../connect/auth';
import UserTypeModal from '../Tours/components/UserTypeModal/UserTypeModal';
import formatNumber from '../../Utils/FormatNumber';

export default class Quote extends PureComponent {
  constructor(props) {
    super(props);
    this.handleSaveTitle = this.handleSaveTitle.bind(this);
    this.handleTitle = this.handleTitle.bind(this);
    this.handleDescription = this.handleDescription.bind(this);
    this.handleBottomText = this.handleBottomText.bind(this);
    this.handleCopyMessageToBuffer = this.handleCopyMessageToBuffer.bind(this);
    this.handleCopyMessageWithTextToBuffer =
      this.handleCopyMessageWithTextToBuffer.bind(this);
    this.handleSharedVK = this.handleSharedVK.bind(this);
    this.handleTourDelete = this.handleTourDelete.bind(this);
    this.handleTourManagerDataSave = this.handleTourManagerDataSave.bind(this);
    this.handleActualize = this.handleActualize.bind(this);
    this.toggleHideAfterDays2 = this.toggleHideAfterDays2.bind(this);
    this.toggleActiveOnSite = this.toggleActiveOnSite.bind(this);
    this.toggleNotifyAuthorByTelegram =
      this.toggleNotifyAuthorByTelegram.bind(this);
    this.handleQuoteDelete = this.handleQuoteDelete.bind(this);
    this.fetchItems = this.fetchItems.bind(this);
    this.toggleShowModal = this.toggleShowModal.bind(this);

    this.state = {
      isOpenModal: false,
      id: null,
      tours: [],
      quote_id: '',
      title: '',
      description: '',
      bottom_text: '',
      created_at: null,
      updated_at: null,
      actualization_status: 0,
      provider: '',
      stat: {},
      persons: [],
      saveDisabled: true,
      author: {
        first_name: '',
        last_name: '',
      },
      is_buffer: false,
      is_buffer_with_text: false,
      isDisabledActualizeButton: false,
      hide_after_days_2: null,
      is_active_on_site: true,
      notify_author_by_telegram: true,
    };
  }

  handleSharedVK() {
    let url = `https://geograftour.com/geoclient/${this.state.quote_id}/`;
    let image = `https://geograftour.com/static/index/img/quote_vk.jpg`;
    let title = this.state.title
      ? this.state.title
      : `Геоподборка ${this.state.quote_id}`;
    let description = `${this.state.tours
      .map(
        (tour) =>
          `${tour.checkin}, ${tour.nights}н, ${tour.name}, ${tour.board}, ${
            tour.manager_data && tour.manager_data.initial_price
              ? tour.manager_data.initial_price
              : tour.initial_price
          } ${tour.currency} \n`
      )
      .join('')}\nhttps://www.geograftour.com/geoclient/${this.state.quote_id}/
      `;
    this.handleCopyMessageToBuffer();
    window.open(
      `https://vk.com/share.php?url=${url}&title=${encodeURIComponent(
        title
      )}&description=descriptionofquote&image=${image}&noparse=true`
    );
  }

  handleActualize() {
    this.setState({
      isDisabledActualizeButton: true,
    });
    QuotesAPI.actualizeQuote(this.state.quote_id)
      .then((r) => r.json())
      .then((response) => {
        if (response.id) {
          Swal('Цены на туры в подборке успешно обновлены!');
        } else {
          Swal('Не удалось обновить цены. Повторите попытку позже');
        }
        this.setState(
          {
            isDisabledActualizeButton: false,
          },
          () => this.fetchItems()
        );
      });
  }

  toggleHideAfterDays2() {
    QuotesAPI.toggleQuoteHideAfterDays2(this.state.quote_id)
      .then((r) => r.json())
      .then((response) => {
        this.setState({hide_after_days_2: response.hide_after_days_2});
      });
  }

  toggleActiveOnSite() {
    QuotesAPI.toggleQuoteIsActiveOnSite(this.state.quote_id)
      .then((r) => r.json())
      .then((response) => {
        if (response.is_active_on_site) {
          Swal('Подборка доступна клиентам!');
        } else {
          Swal('Подборка скрыта от клиентов!');
        }
        this.setState(
          {
            is_active_on_site: response.is_active_on_site,
          },
          () => this.fetchItems()
        );
      });
  }

  toggleNotifyAuthorByTelegram() {
    QuotesAPI.toggleQuoteNotifyAuthorByTelegram(this.state.quote_id)
      .then((r) => r.json())
      .then((response) => {
        if (response.notify_author_by_telegram) {
          Swal('Телеграм-уведомления включены!');
        } else {
          Swal('Телеграм-уведомления отключены!');
        }
        this.setState({
          notify_author_by_telegram: response.notify_author_by_telegram,
        });
      });
  }

  handleCopyMessageToBuffer() {
    //10.10, 7н, Bm Beach Resort 4*, HB - Завтрак, Ужин, 193 783 RUB
    let text = `${this.state.title}
https://www.geograftour.com/geoclient/${this.state.quote_id}/`;
    this.copyToClipboard(text);
  }

  handleCopyMessageWithTextToBuffer() {
    //10.10, 7н, Bm Beach Resort 4*, HB - Завтрак, Ужин, 193 783 RUB
    let text = `${this.state.title}
https://www.geograftour.com/geoclient/${this.state.quote_id}/

${this.state.tours.map(
  (tour) =>
    `${moment(tour.checkin, 'YYYY-MM-DD').format('DD.MM.YYYY')}, ${
      tour.nights
    }н, ${tour.name}, ${tour.board}, ${
      tour.manager_data && tour.manager_data.initial_price
        ? formatNumber(parseInt(tour.manager_data.initial_price))
        : formatNumber(parseInt(tour.initial_price))
    } ${tour.currency}`
).join(`

`)}`;
    this.copyToClipboard(text, true);

    setTimeout(
      function () {
        this.setState({ is_buffer_with_text: false });
      }.bind(this),
      5000
    );
  }

  copyToClipboard = (text, withText = false) => {
    let textField = document.createElement('textarea');
    let stateField = withText ? 'is_buffer_with_text' : 'is_buffer';
    textField.innerHTML = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand('copy');
    textField.remove();
    this.setState({
      [stateField]: true,
    });
  };

  handleSaveTitle() {
    const quote_id = this.props.match.params.string;
    this.setState(
      {
        saveDisabled: true,
      },
      () => {
        APIClient.update(`/quotes/${quote_id}`, {
          title: this.state.title,
          description: this.state.description,
          bottom_text: this.state.bottom_text,
        }).then((res) => {
          console.log('save title', this.state.title);
        });
      }
    );
  }

  handleTitle(e) {
    this.setState({
      title: e.target.value,
      saveDisabled: false,
    });
  }

  handleDescription(e) {
    this.setState({
      description: e.target.value,
      saveDisabled: false,
    });
  }

  handleBottomText(e) {
    this.setState({
      bottom_text: e.target.value,
      saveDisabled: false,
    });
  }

  toggleShowModal() {
    this.setState({
      isOpenModal: !this.state.isOpenModal,
    });
  }

  handleQuoteDelete() {
    Swal({
      title: `Вы уверены?`,
      text: `Это необратимая операция`,
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        QuotesAPI.deleteQuote(this.state.quote_id).then((r) => {
          if (r.ok) {
            Swal(`Геоподборка ${this.state.quote_id} удалена`);
            this.props.history.push('/quotes');
          } else {
            Swal({
              text: `Что-то пошло не так`,
              dangerMode: true,
            });
          }
        });
      }
    });
  }

  handleTourDelete() {
    const quote_id = this.props.match.params.string;
    APIClient.getJSON(`/quotes/${quote_id}/`).then((res) => {
      this.setState({
        ...this.state,
        id: res.id,
        tours: res.tours,
        title: res.title,
        description: res.description,
        bottom_text: res.bottom_text,
        quote_id: res.quote_id,
        created_at: res.created_at,
        provider: res.provider,
        author: res.author,
        persons: res.persons,
        stat_likes_count: res.stat_likes_count || 0,
        stat_views_count: res.stat_views_count || 0,
        stat: res.stat && res.stat.common ? res.stat.common[0] : {},
      });
    });
  }

  handleTourManagerDataSave(tour_id, manager_data) {
    var tours = this.state.tours.map((tour) => {
      if (tour.id === tour_id) {
        return { ...tour, manager_data };
      } else {
        return tour;
      }
    });
    this.setState({ ...this.state, tours });
  }

  fetchItems() {
    const quote_id = this.props.match.params.string;
    APIClient.getJSON(`/quotes/${quote_id}/`).then((res) => {
      this.setState({
        ...this.state,
        id: res.id,
        hide_after_days_2: res.hide_after_days_2,
        is_active_on_site: res.is_active_on_site,
        notify_author_by_telegram: res.notify_author_by_telegram,
        tours: res.tours,
        title: res.title,
        description: res.description,
        bottom_text: res.bottom_text,
        quote_id: res.quote_id,
        created_at: res.created_at,
        provider: res.provider,
        author: res.author,
        persons: res.persons,
        touristsFp: res.touristsfp,
        stat_likes_count: res.stat_likes_count || 0,
        stat_clicks_count: res.stat_clicks_count || 0,
        stat_views_count: res.stat_views_count || 0,
        stat: res.stat && res.stat.common ? res.stat.common[0] : {},
        stat_views_persons: res.stat_views_persons,
        stat_views_touristfps: res.stat_views_touristfps,
        actualization_status: res.tours[0].actualization_status,
        updated_at: res.tours[0].dt_actualization,
      });
    });
  }

  componentDidMount() {
    this.fetchItems();
  }

  render() {
    const {
      id,
      hide_after_days_2,
      is_active_on_site,
      quote_id,
      tours,
      title,
      description,
      bottom_text,
      created_at,
      provider,
      author,
      stat_likes_count,
      stat_views_count,
      stat_clicks_count,
      persons,
      touristsFp,
      isDisabledActualizeButton,
      notify_author_by_telegram,
      updated_at,
      actualization_status,
      saveDisabled,
      is_buffer,
      is_buffer_with_text,
    } = this.state;
    let text = `${this.state.title} https://www.geograftour.com/geoclient/${this.state.quote_id}/`;
    const userRole = getUserRole();
    const toursUpdatedAt = moment(updated_at).format('DD.MM.YY в H:mm');
    let views_customers_exists = (this.state.stat_views_persons) ? this.state.stat_views_persons.length > 0 : false;
    if (!views_customers_exists) {
      views_customers_exists = (this.state.stat_views_touristfps) ? this.state.stat_views_touristfps.length > 0 : false;
    }

    return (
      <div>
        <UserTypeModal
          toggleLikesListModal={this.toggleShowModal}
          isOpen={this.state.isOpenModal}
          modalTitle={'Открывшие подборку'}
          personsForView={this.state.stat_views_persons}
          touristfpsForView={this.state.stat_views_touristfps}
        />

        <h3>
          {` ${title ? title : 'Геоподборка'}`} {quote_id}
        </h3>
        <div className="geopick__btn-container">
          <a
            href={`https://www.geograftour.com/geoclient/${quote_id}/`}
            className="btn btn-primary"
            target="_blank"
            rel="noopener noreferrer"
          >
            Просмотр
          </a>
          <button
            className="btn btn-warning"
            onClick={this.handleActualize}
            disabled={isDisabledActualizeButton}
          >
            Обновить цены
          </button>
          <button
            className="btn btn-secondary"
            onClick={this.toggleActiveOnSite}
          >
            {is_active_on_site ? 'Скрыть на сайте' : 'Показать на сайте'}
          </button>
          <button
            className="btn btn-secondary"
            onClick={this.toggleHideAfterDays2}
            disabled={!is_active_on_site}
          >
            {hide_after_days_2 ? `Не скрывать на сайте ${moment(hide_after_days_2).format('DD.MM.YY')}` : 'Скрыть на сайте через 2 дня'}
          </button>
          <button className="btn btn-custom" onClick={this.handleQuoteDelete}>
            Удалить подборку
          </button>
          <button
            className={`btn ${
              notify_author_by_telegram ? 'btn-danger' : 'btn-success'
            }`}
            onClick={this.toggleNotifyAuthorByTelegram}
          >
            {notify_author_by_telegram ? 'Отключить' : 'Включить'}{' '}
            телеграм-уведомления
          </button>
        </div>
        <div className="p-2">
          <p>
            <span>Создана: </span>
            <b>{moment(created_at).format('DD.MM.YY HH:mm')}</b>
          </p>
          <p>
            <span>Менеджер: </span>
            <b>
              {author.first_name} {author.last_name}
            </b>
          </p>
          <p>
            <span>Источник: </span>
            <b>{provider}</b>
          </p>
          <p>
            <span>Туры обновлены: </span>
            <b>{actualization_status ? toursUpdatedAt : 'не обновлялись'}</b>
          </p>
          <div className="geopick__input-container">
            <div className="geopick__input" style={{ position: 'relative' }}>
              <input
                type="text"
                className="form-control"
                onChange={this.handleTitle}
                title="Название подборки"
                placeholder="Название подборки"
                value={title}
              />
            </div>
            <div className="geopick__input">
              <textarea
                type="text"
                className="form-control"
                onChange={this.handleDescription}
                title="Описание подборки"
                placeholder="Описание подборки"
                value={description}
              ></textarea>
            </div>
            <div className="geopick__input">
              <textarea
                type="text"
                className="form-control"
                onChange={this.handleBottomText}
                title="Нижний текст"
                placeholder="Нижний текст"
                value={bottom_text}
              ></textarea>
            </div>
            {!saveDisabled ? (
              <button
                className={`btn btn-success geopick__save-btn${
                  saveDisabled ? '_hidden' : ''
                }`}
                onClick={this.handleSaveTitle}
              >
                Cохранить
              </button>
            ) : null}
          </div>
        </div>
        {userRole !== SUBAGENT && (
          <QuoteTourists
            quote_id={quote_id}
            title={` ${title ? title : 'Геоподборка'} ${quote_id}`}
            personList={persons}
            touristsFpList={touristsFp}
          />
        )}
        <div>
          <div className="report__list">
            <div className="report__item">
              <div className="delivered">
                <b>{stat_views_count}</b>
                <br />
                <span>Просмотров</span>
                {views_customers_exists &&
                  <span
                    style={{ display: 'inline-flex', fontSize: '14px' }}
                    title="показать просмотревших пользователей"
                    onClick={this.toggleShowModal}
                  >
                    <i className="eye fa fa-eye mr-1"></i>
                  </span>
                }
              </div>
            </div>
            <div className="report__item">
              <div className="read">
                <b>{stat_clicks_count}</b>
                <br />
                <span>Кликов</span>
              </div>
            </div>
            <div className="report__item">
              <div className="error">
                <b>{stat_likes_count}</b>
                <br />
                <span>Лайков</span>
              </div>
            </div>
          </div>
        </div>
        <ToursList
          items={tours}
          quote={id}
          qid={quote_id}
          onDelete={this.handleTourDelete}
          onManagerDataSave={this.handleTourManagerDataSave}
        />
        <div className="geopick__btn-container">
          <button
            className="btn btn-success"
            onClick={this.handleCopyMessageToBuffer}
            disabled={is_buffer}
          >
            {is_buffer ? 'Скопировано' : 'Скопировать ссылку'}
          </button>
          <button
            className="btn btn-warning"
            onClick={this.handleCopyMessageWithTextToBuffer}
          >
            {is_buffer_with_text ? `Скопировано` : `Ссылка с текстом`}
          </button>
          <button onClick={this.handleSharedVK} className="btn btn-primary">
            <i className="fa fa-vk pr-2"></i> Поделиться Вконтакте
          </button>
          <a
            className="btn btn-success"
            href={`whatsapp://send?text=${text}&phone=&abid=`}
          >
            <i className="fa fa-whatsapp pr-2"></i> Отправить в whatsapp
          </a>
        </div>
        <div className="m-5">
          <BackButton {...this.props} />
        </div>
      </div>
    );
  }
}
