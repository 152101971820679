import React, { Component } from 'react'

export default class IssueHandlingObjections extends Component {
  render() {
    return (
      <table className="table table-bordered">
    <tbody>

        <tr>
            <th><b>Возражение</b></th>
            <th>Как закрывать</th>
        </tr>
        <tr>
            <td><b>
                Мы подумаем
                </b>
            </td>
            <td>
                <p>Можно подумать, только потом <b>цена может быть выше</b></p>
                <hr/>
                <p><b>Только не говорите мне «я подумаю».</b>  Если вариант нравится — надо брать</p>
                <hr/>
                <p>Вы знаете (Имя), обычно, когда клиенты говорят,  что им нужно подумать, это означает две вещи:<br/>

                  Либо <b>поездка не особо актуальна</b>,<br/>
                  Либо <b>мы подобрали не идеальный тур :)</b><br/>
                  Мне очень важно понимать, <b>что Вы думаете на самом деле?</b> Возможно есть что-то, что нам следует еще обсудить, чтобы у Вас не осталось сомнений?
                </p>
                <hr/>
                <p>
                    Я понимаю Ваше желание все взвесить. Но мне бы хотелось услышать от Вас <b>над чем конкретно вы хотите подумать</b>?
                </p>
                <hr/>
                <p>
                  (Имя), я понимаю, что Вам нужно все взвесить, но учтите, что курс нестабилен, плюс еще и места в хорошие отели уходят, а кроме нас бронирует еще и вся Европа).<br/>

                    Пока вы будете думать, отель уже может оказаться в стопе. И нам придется проделать все сначала и выбирать из того что
                    осталось на Ваши даты.
                </p>
                <hr/>
                <p>
                    Конечно (Имя), важно подумать, а что именно Вас смущает? <br/>
                    <b>Что мешает принять решение прямо сейчас?</b>
                </p>
                <hr/>
                <p>
                  <b>Вы больше за или против поездки?</b><br/>
                  Если «ЗА» – давайте документы,
                  я пока предварительно оформлю документы,  а вы в это время подумаете.
                </p>
                <hr/>
                <p>
                  Вообще, этот отель я выбрала не случайно. Просто обычно он стоит гораздо дороже. А сейчас на этот отель действует спецппредложение. И Вас есть возможность забронировать его по хорошей цене. И не откладывать отдых на потом, чтобы не переплатить в последствии.
                </p>
                <hr/>
                <p>
                  Конечно. Только уточните пожалуйста, над каким вопросом вы конкретно будете думать? Если Вы назовете мне сейчас те моменты, которые Вас смущают, мы в течение дня подготовим для Вас индивидуальную презентацию, с ответами конкретно на Ваши вопросы.
                </p>
                <p>
                  <b>Структура закрытья возраженья</b><br/>
                  <br/>
                  1. Согласие<br/>
                  2. Вопросы фильтры<br/>
                  Вы в целом планируете приобрести или нет<br/>
                  Да, конечно подумайте. Как вам предложение в целом?<br/>
                  Что понравилось, а что нет?<br/>
                  Какие плюсы и минусы для себя увидели?<br/>
                  Если вы хотите подумать, значит что-то смущает.  В чем сомнения?<br/>
                  А что для вас «подумать»?<br/>
                  Хотите посоветоваться?<br/>
                  Хотите посмотреть аналоги или больше информации о туре узнать?<br/>
                  3. Закрытие истинного возражения
                </p>
            </td>
        </tr>
        <tr>
            <td><b>Это дорого,
                мы рассчитывали на меньшую цену</b></td>
                <td><p>
                    (Имя), я могу подыскать этот отель дешевле, но только на другие даты.
                    Назовите мне крайние ограничения по датам, если мы будет <b>отталкиваться от минимальной цены</b>
                </p>
                <hr/>
                <p>
                    (Имя), в принципе, могу Вам предложить <b>варианты подешевле</b>.
                    Есть, например, отель (название отеля), он дешевле (говорим на сколько, дешевле). <b>Но в нем уже нет</b> (и называем, то чем он отличается от предыдущего отеля).<br/>
                    Если для Вас это не является важным, то отель Вам подходит идеально, т.к. Вы хотели......
                </p>
                <hr/>
                <p>
                    (Имя), скажите в сравнении с чем дорого?
                    Мы можем предложить Вам <b>рассрочку или кредит</b> на хороших условиях. Что скажете?
                </p>
                <hr/>
                <p>
                  (Имя), давайте поменяем количество ночей  (тип номера, тип питания, туроператора) <b>Чем вы готовы пожертвовать?</b>

                  Но знаете, вы сейчас сэкономите,  но по опыту, <b>когда приезжаешь на отдых — уже не думаешь про эти расходы, а просто наслаждаешься</b>
                </p>
                <hr/>
                <p>
                  (Имя), Я с Вами согласна, данный продукт стоит не мало. Но согласитесь, что при покупке по низкой цене всегда есть риск получить продукт более низкого качества. И <b>если нас приобретение радует, то согласитесь, мы уже не обращаем внимание на цену.</b>
                  И знаете, я предпочитаю, чтобы Вы вспоминали меня добрым словом (приподнести в шутливой форме). Понимаете, о чем я?
                </p>
                <hr/>
                <p>
                  Скажите (Имя), цена это единственный вопрос, который нужно решить? <b>Все остальное Вас устраивает</b> или есть что-то еще?
                </p>
                <hr/>
                <p>
                  (Имя), Ваше решение зависит от цены или от <b>качества тура по хорошей цене?</b>
                </p>
            </td>
        </tr>
        <tr>
          <td><b>Другое агентство дает скидку</b></td>
          <td>
            <p>Вообще это странно, скажите у кого конкретно? И <b>Вы уверенны в их финансовой стабильности, чтобы не получилось, что у Вас будет неприятности</b> с обратными билетами или отелем?</p>
            <hr/>
            <p>(Имя), не всегда дешевле значит лучше (с иронией). (Имя), поймите <b>никто не захочет работать себе в убыток.</b><br/>
            Такая цена может означать, что <b>Вам не будет  предоставлен ряд услуг.</b>
            </p>
            <p>Вам не кажется подозрительным, что компания «...»  <b>завлекаем вас всеми возможными способами,  работает в убыток лишь бы получить ваши деньги, слышали про турагентства, которые взяли деньги  с туристов и не отправили их на отдых? …</b>
            </p>
            <hr/>
            <p>(Имя), что хочу Вам сказать: мы работаем напрямую по <b>ценам туроператора без накруток</b> и если у кого-то такой же тур дешевле, это <b>как минимум подозрительно.</b>
            </p>
            <hr/>
            <p>(Имя), я с Вами согласна данный продукт стоит не мало, как вы думаете, <b>стоит ли экономить на качестве обслуживания,</b> питании и безопасности ваших близких?
            </p>
            <hr/>
            <p>(Имя), говорите, вам в другом турагентстве скидку обещают? Ну что ж — удачи вам и приятного путешествия. <b>Когда Вам понадобится хороший юрист — обращайтесь, мы поможем.</b>
            </p>
            <hr/>
            <p>(Имя), агентства живут за счет своего вознаграждения от туроператора.
              Соответственно, то <b>агентство, которое всю свою комиссию раздает туристам в виде скидок, живет за счет оборотных средств</b> и в какой-то момент их может не хватить для погашения своих обязательств и Вы столкнетесь с тем, что будут проблемы с вылетом и туром, а деньги вернуть Вам они будут не в состоянии.
              Поэтому решайте сами, но мы не играем в игры "Кто даст скидку больше"
            </p>
            <hr/>
            <p>(Имя), давайте я подберу Вам самый дешевый вариант тура в том же направлении, но у другого туроператора. <b>Цена без скидки будет меньше, чем Вы считали со скидкой в другом агентстве.</b>
            </p>
            <hr/>
            <p>(Имя),процент оператора — это наш заработок, он у турагента невелик. Если мы всем нашим туристам скидки сделаем, то в этом месяце <b>получим лишь половину зарплаты. А вы своей зарплатой тоже делитесь с клиентами?</b>
            </p>
            <hr/>
            <p>(Имя), мы сейчас стараемся отказываться от скидок, т.к. скидки напрямую ведут к разорению не только турагентств, но и туроператоров что, к сожалению, уже случилось с несколькими крупными, а сколько разоряется турагентств каждый год - трудно сосчитать. Дело в том, что <b>в туризме накрутка так мала, что скидку давать просто не с чего</b></p>
            <hr/>
            <p>(Имя), к сожалению Вам придётся обратиться в другое агентство, так как мы все путёвки оформляем официально, и если мы дадим Вам такую скидку, то затраты на налоги и банковское обслуживание съедят нашу прибыль. <b>Нам выгоднее не продавать Вам этот тур вообще</b></p>
            <hr/>
            <p>Мы не предоставляем скидок, потому что <b>мы берем ответственность на себя за Ваш отдых</b> и Вашу безопастность. Как вы считаете те агентства, которые Вам предоставляют скидки, будут об этом беспокоиться? И хочу Вам заметить, что туристы, которые попадают в неприятные ситуации за пределами своей страны, первым делом вспоминают об агенте. <b>А мы предоставляем качественное обслуживание – это когда 24 часа в сутки агент готов прийти Вам на помощь.</b></p>
          </td>
        </tr>
            <tr>
                <td><b>
                    Хочу скидку</b>
                </td>
                <td>
                    <p>Знаете, обычно в <b>ресторанах чаевые оставляют</b>, так вот туристы  нам тоже оставляют, потому что довольны сервисом. А вы ещё и скидку хотите <b>Разве я плохо работаю?  Может вам что-то не понравилось?</b></p>
                    <hr/>
                    <p>Да при условии, что когда вы приезжаете в аэропорт —  <b>удаляете мой номер из телефона)</b> Ведь если вы хотите скидку, значит вам явно не важна моя  поддержка во время поездки</p>
                    <hr/>
                    <p>(Имя), Вы знаете, но мы не даем скидок на туры по промо-прайсу, в этом туре <b>скидка заложена от туроператора</b>,  у нас здесь минимальная комиссия.</p>
                </td>
                </tr>
                <tr>
                    <td><b>
                        Мне нужно посоветоваться с женой/мужем</b>
                    </td>
                    <td>
                        <p>
                            (Имя), вам понравился отель? Вы бы хотели туда поехать отдыхать?
                            Вы знаете, я уверена, что жена\муж одобрит Ваш выбор, тем более что этот отель пользуется большим спросом, поэтому будет
                            обидно, если Вы не сможете там отдохнуть.
                            <b>Может Вам стоит обсудить сейчас этот момент с мужем(женой) по телефону?</b>
                        </p>
                        <hr/>
                        <p>Я уверена, что жена\муж убедится в правильности вашего выбора относительно отдыха. <b>Можно пригласить ее(его) к нам в офис, и мы могли бы все вместе обсудить этот тур.</b><br/> Давайте завтра в 14-00, Вам удобно?</p>
                    </td>
                </tr>
                <tr>
                    <td><b>
                        Я слишком сейчас занят, нет времени разговаривать</b>
                    </td>
                    <td>
                        <p>Мы договаривались с Вами, (Имя) сегодня созвониться во второй половине дня. Поэтому я звоню вам с целью (называем цель звонка).<b>Скажите, через сколько Вы освободитесь, чтобы мы могли пообщаться? Через 30 минут Вы сможете уделить мне время?</b>
                        </p>
                        <hr/>
                        <p>Я Вас поняла. Когда Вам будет удобно уделить мне несколько минут?<b>Я хотела бы Вам лично рассказать/пригласить о новом направлении /услуги/акции и т.д.</b>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td><b>Я пока бронировать не буду, позже</b></td>
                    <td>
                        <p>Ну что ж, будем рады, если Вы в будущем обратитесь в нашу компанию. <b>Вы бы хотели получать от нас информацию о самых выгодных турах на почту?</b> Уверена, что-то, обязательно Вам подойдет.</p>
                    </td>
                </tr>
                <tr>
                    <td><b>Спасибо, но я уже купил в другом агентстве (имеется в виду потерянный клиент)</b></td>
                    <td>
                        <p>Мне, действительно, жаль, что мы потеряли Вас как клиента!!!
                            А что послужило причиной перехода в другое агентство?
                            <b>Нам важно понимать причины отказа клиентов</b> обслуживаться у нас, чтоб впредь предотвратить подобное и <b>завоевать Ваше внимание снова.</b>
                        </p>
                        <hr/>
                        <p>Как жаль, как раз по вашему направлению <b>у нас были 2 тура со специальной ценой</b>. И я просто не смогла с вами связаться, чтобы сообщить об этом. Звонила, но вы не отвечали. Хотели бы вы в будущем получать от нас рассылку с выгодными вариантами отдыха?
                        </p>
                    </td>
                </tr>
            </tbody>
        </table>
    )
  }
}
