import React, {Component} from 'react';
import ReactPaginate from 'react-paginate';

const PER_PAGE = 20;

export default class Paginator extends Component {

    render() {
        const perPage = Number.isInteger(this.props.perPage) ? this.props.perPage : PER_PAGE;
        const initialPage = Number.isInteger(this.props.initialPage)
            ? this.props.initialPage
            : 0;

        if (this.props.total < perPage) {
            return null;
        }


        return (
            <ReactPaginate
                initialPage={initialPage}
                previousLabel={<i className="fa fa-chevron-left"></i>}
                nextLabel={<i className="fa fa-chevron-right"></i>}
                breakLabel={<a>...</a>}
                breakClassName={'break-me'}
                pageCount={Math.ceil(this.props.total / perPage)}
                marginPagesDisplayed={1}
                pageRangeDisplayed={4}
                onPageChange={this.props.onPageChange}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                forcePage={this.props.forcePage}
                disableInitialCallback={true}
            />
        );
    }
}
