import React, { Component } from "react";
import InputGroup from "./InputGroup";

class InputOnClick extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputs: [],
    };
    this.handleAddInput = this.handleAddInput.bind(this);
  }

  handleAddInput() {
    console.log(this.state.inputs);
    const inputKey = { value: "" };
    const inputValue = { value: "" };
    const inputGroupId = Date.now();
    this.setState((prevState) => ({
      inputs: [...prevState.inputs, { inputGroupId, inputKey, inputValue }],
    }));
  }

  handleDeleteInput = (id) => {
    this.setState((prevState) => ({
      inputs: prevState.inputs.filter(
        (inputGroup) => inputGroup.inputGroupId !== id
      ),
    }),
    () => {
      this.props.onChange(this.state.inputs);
    }
  );
  };

  handleInputChange = (inputItem, groupId, e) => {
    const { value } = e.target;
    this.setState(
      (prevState) => ({
        inputs: prevState.inputs.map((input) => {
          if (input.inputGroupId === groupId) {
            inputItem.value = value;
          }
          return input;
        }),
      }),
      () => {
        this.props.onChange(this.state.inputs);
      }
    );
  };

  render() {
    const { inputs } = this.state;

    return (
      <div className="utm__inputs_custom-container">
        <button
          className="utm__add-inputs-btn utm__btn  utm__btn btn btn-sm btn-primary"
          onClick={this.handleAddInput}
        >
          Добавить доп-метки
        </button>
        {inputs.map((inputGroup) => (
          <InputGroup
            key={inputGroup.inputGroupId}
            id={inputGroup.inputGroupId}
            inputs={inputGroup}
            onChange={this.handleInputChange}
            onDelete={this.handleDeleteInput}
          />
        ))}
      </div>
    );
  }
}

export default InputOnClick;
