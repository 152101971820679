export class UrlSearchParamsParser {
  constructor() {
    this.hash = '';
    this.urlParams = '';
    this.parseHash();
  }

  parseHash() {
    if (!window.location.hash) {
      return false;
    }

    let splitData = window.location.hash.split('?').filter(i => !!i);

    if (splitData.length === 1 || splitData.length === 2) {
      this.hash = splitData[0];

      if (splitData.length === 2) {
        this.urlParams = splitData[1];
      }

      return true;
    }

    this.hash = '';
    this.urlParams = '';

    return false;
  }
}

export class UrlSearchParamsModifier {
  constructor(urlSearchParams, param, value) {
    this.urlSearchParams = urlSearchParams;
    this.param = param;
    this.value = value;
  }

  updateUrlSearchParams() {
    const urlSearchParams = new URLSearchParams(this.urlSearchParams.urlParams);
    if (urlSearchParams.has(this.param)) {
      if (!this.value) {
        urlSearchParams.delete(this.param);
      } else {
        urlSearchParams.set(this.param, this.value);
      }
    } else {
      if (!!this.value) {
        urlSearchParams.append(this.param, this.value);
      }
    }
    return urlSearchParams;
  }

  getNewUrl(urlSearchParams) {
    return `${window.location.origin}${window.location.pathname}${this.urlSearchParams.hash}?${urlSearchParams.toString()}`;
  }

  execute() {
    window.history.pushState(
        window.history.state,
        '',
        this.getNewUrl(this.updateUrlSearchParams()),
    );
  }
}

export const initPageNumber = function(page_name) {
  const parser = new UrlSearchParamsParser();
  const urlSearchParams = new URLSearchParams(parser.urlParams);
  let page = Number.parseInt(urlSearchParams.get(page_name), 10);
  return Number.isInteger(page) ? page : 1;
};

export const changePageNumber = function(page_name, page) {
  const urlSearchParams = new UrlSearchParamsModifier(
      new UrlSearchParamsParser(),
      page_name,
      page,
  );
  urlSearchParams.execute();
};
