import React, { Component } from 'react';
import {
  PersonAPI,
  TouristFpAPI,
  AnswersAPI,
  POSITIVE_ACTION_STATUSES,
} from '../../../api/Client';
import { Alert } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';
import { EMAIL_PERSON_SUBJECT, EMAIL_TOURIST_SUBJECT } from '../constants';
import apiKey from '../../../constants/TinyMceApiKey';

class IssueSendEmailForm extends Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
    this.successSubmit = this.successSubmit.bind(this);
    this.errorMessage = this.errorMessage.bind(this);
    this.doWithTimeout = this.doWithTimeout.bind(this);
    this.handleComment = this.handleComment.bind(this);
    this.sendMailer = this.sendMailer.bind(this);
    this.handleEditorChange = this.handleEditorChange.bind(this);

    this.state = {
      id: '',
      title: 'title',
      date: '',
      comment: '',
      message: '',
      created: '',
      subject: '',
      resolved: false,
      answers: [],
      showErrorMessage: false,
      showSuccessMessage: false,
      isDisabledSendButton: false,
    };
  }

  sendMailer(e) {
    let isValidEmail = false;
    const emails = this.state.email;
    const filter = this.state.id ? { id: this.state.id } : 0;
    const touristType = this.props.item.person ? 'person' : 'tourist';

    e.preventDefault();
    if (!this.state.message.length) {
      this.setState({
        errorMessage: 'Заполните текст сообщения',
      });
      return false;
    }
    // номеров может быть несколько
    this.setState(
      {
        isDisabledSendButton: true,
        showSuccessMessage: false,
        showErrorMessage: false,
        errorMessage: '',
        isViewSpinner: true,
      },
      () => {
        if (!emails.length) {
          this.setState({
            errorMessage: 'Необходимо заполнить email для туриста',
          });
          return false;
        }
        emails.map((email) => {
          if (email.value && /^[^@]+@[^@.]+\.[^@]+$/.test(email.value)) {
            isValidEmail = true;
          }
        });
        if (!isValidEmail) {
          this.setState({
            errorMessage: 'Возможно email туриста  заполнен некорректно',
          });
          return false;
        }
        this.setState(
          {
            isDisabledSendButton: true,
            showSuccessMessage: false,
            showErrorMessage: false,
            isViewSpinner: true,
          },
          () => {
            let data = {
              dispatch_type: 2,
              sender_type: 3,
              title: this.state.subject,
              address_book: {},
            };
            data['email_campaign'] = {
              message: this.state.message,
              template: 19,
            };

            let status;
            const responseHandler = (response) => {
              status = response.status;
              return response.json();
            };
            const resultHandler = (result) => {
              if (status === POSITIVE_ACTION_STATUSES.create) {
                this.setState({
                  showSuccessMessage: true,
                  isViewSpinner: false,
                });
              } else {
                this.setState({
                  showSuccessMessage: false,
                  showErrorMessage: true,
                  isViewSpinner: false,
                });
              }
            };
            if (touristType === 'person') {
              PersonAPI.oneMessage(data, filter)
                .then(responseHandler)
                .then(resultHandler);
            } else if (touristType === 'tourist') {
              TouristFpAPI.oneMessage(data, filter)
                .then(responseHandler)
                .then(resultHandler);
            }
          }
        );
      }
    );
  }

  handleEditorChange(e) {
    this.setState({
      message: e.target.getContent(),
    });
  }

  submit(e) {
    e.preventDefault();

    let typeMap = {
      1: 'comment',
    };
    let status;

    let data = {
      issue: this.props.id,
      title: this.state.title,
      text: this.state.comment,
      type: typeMap[this.props.type],
    };
    if (this.state.date) {
      data.date = this.state.date;
    }
    if (typeMap[this.props.type] == 'tours') {
      data.text = this.state.quote;
    }

    AnswersAPI.create(data)
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((result) =>
        status === POSITIVE_ACTION_STATUSES.create
          ? this.successSubmit(result)
          : this.errorMessage(result)
      );
  }

  doWithTimeout(fn, timeout = 2000) {
    setTimeout(fn.bind(this), timeout);
    setTimeout(() => {
      this.setState({
        ...this.state,
        showErrorMessage: false,
        showSuccessMessage: false,
      });
      this.props.onCreate();
    }, timeout);
  }

  successSubmit(r) {
    this.setState({
      ...this.state,
      title: '',
      comment: '',
      showSuccessMessage: true,
    });
    this.doWithTimeout(this.props.onCreate);
  }

  errorMessage(err) {
    this.setState({
      ...this.state,
      showErrorMessage: err,
    });
  }

  handleComment(e) {
    this.setState({
      ...this.state,
      comment: e.target.value,
    });
  }

  componentDidMount() {
    // получим данные по туристу
    const touristType = this.props.item.person ? 'person' : 'tourist';
    if (touristType === 'person') {
      PersonAPI.getInfo(this.props.item.person.id).then((result) =>
        this.setState({
          id: result.id,
          email: result.email || [], // телефонов может быть несколько
          subject: EMAIL_PERSON_SUBJECT,
        })
      );
    } else if (touristType === 'tourist') {
      TouristFpAPI.getInfo(this.props.item.tourist.id).then((result) =>
        this.setState({
          id: result.id,
          email: result.email || [], // телефонов может быть несколько
          subject: EMAIL_TOURIST_SUBJECT,
        })
      );
    }
  }

  render() {
    const { isLock, type } = this.props;
    const {
      message,
      showErrorMessage,
      showSuccessMessage,
      isDisabledSendButton,
      errorMessage,
      isViewSpinner,
    } = this.state;
    return (
      <form>
        <div className="form-group row">
          <div className="col-md-12">
            <Editor
              apiKey={apiKey}
              initialValue={message}
              init={{
                height: 500,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount',
                ],
                toolbar:
                  'undo redo | formatselect | bold italic backcolor | \
alignleft aligncenter alignright alignjustify | \
bullist numlist outdent indent | removeformat | help',
              }}
              onChange={this.handleEditorChange}
            />
          </div>
          <button
            className="btn btn-sm btn-success mr-2"
            onClick={this.sendMailer}
            disabled={isDisabledSendButton}
          >
            Отправить
          </button>
          {isViewSpinner && (
            <span className="fa fa-spinner fa-spin fa-2x mr-2">{''}</span>
          )}
          {isViewSpinner && <span>Подождите ...</span>}
        </div>
        {showErrorMessage && (
          <Alert color="warning">
            <strong>Ошибка!</strong> Не удалось отправить. Попробуйте позже
          </Alert>
        )}
        {errorMessage && (
          <Alert color="warning">
            <strong> {errorMessage} </strong>
          </Alert>
        )}
        {showSuccessMessage && (
          <Alert color="success">
            Успешно! Ваше сообщение будет доставлено в течении нескольких минут.
            О статусе отправки вы можете посмотреть{' '}
            <Link to={`/mailbox/`}>здесь</Link>
          </Alert>
        )}
      </form>
    );
  }
}

export default IssueSendEmailForm;
