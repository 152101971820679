import React from 'react'
import "./style.css"
const ProgressBar = () => {
  return <div className="loader">
    <div className="loader-inner">
      <div className="loader-line-wrap">
        <div className="loader-line"></div>
      </div>
      <div className="loader-line-wrap">
        <div className="loader-line"></div>
      </div>
      <div className="loader-line-wrap">
        <div className="loader-line"></div>
      </div>
      <div className="loader-line-wrap">
        <div className="loader-line"></div>
      </div>
    </div>
</div>
}

export default ProgressBar
