import React from 'react';
import { Button } from 'reactstrap';
import {
  AiFillEdit,
  AiFillDelete,
  AiFillEye,
  AiFillCheckCircle,
} from 'react-icons/ai';
import { Editor } from '@tinymce/tinymce-react';
import moment from 'moment';
import apiKey from '../../../constants/TinyMceApiKey';

class EmailTemplateItem extends React.Component {
  constructor(props) {
    super(props);

    this.switchToEditMode = this.switchToEditMode.bind(this);
    this.switchToReadMode = this.switchToReadMode.bind(this);
    this.handleTitle = this.handleTitle.bind(this);

    this.state = {
      mode: 'read',
      template: {
        id: '',
        title: '',
      },
      default_message: '',
    };
  }

  showPreviewTemplate = (e) => {
    e.preventDefault();
    const { template, default_message } = this.state;

    let message_default = 'ТЕКСТ EMAIL ШАБЛОНА';
    if (template.email_template.message) {
      message_default = template.email_template.message;
    }
    if (!template) {
      // необходимо выбрать шаблон
      return false;
    }

    var newWindowEmailTemplate = window.open(
      'about:blank',
      'просмотр шаблона рассылки',
      'width=900,height=1000'
    );
    newWindowEmailTemplate.document.write(
      template.email_template.html
        .replace(default_message, message_default)
        .replace(
          '<body>',
          '<body><button class="btn btn-success" onclick="window.close()" style="float: right; cursor: pointer; margin: 20px; padding: 20px 40px; display: inline-block; color: #fff; font-size: 18px; border: none; border-radius: 5px; background: #20a8d8;">закрыть</button>'
        )
    );
  };

  handleTitle(e) {
    this.setState({
      template: {
        ...this.state.template,
        title: e.target.value,
        name: e.target.value,
      },
    });
  }
  handleMessage = (value) => {
    this.setState({
      template: {
        ...this.state.template,
        email_template: {
          ...this.state.template.email_template,
          message: value,
        },
      },
    });
  };
  switchToEditMode() {
    this.setState({
      mode: 'edit',
    });
  }
  switchToReadMode() {
    this.setState(
      {
        mode: 'read',
      },
      () => this.props.onEdit(this.state.template)
    );
  }

  componentDidMount() {
    const { template } = this.props;
    this.setState({
      template: template || null,
      default_message: template.email_template.message || null,
    });
  }

  render() {
    const { mode } = this.state;
    const { email_template } = this.state.template;
    const { template, onDelete } = this.props;

    return (
      <article
        className={`email-template ${
          mode === 'edit' && 'email-template_edit-mode'
        }`}
      >
        {mode === 'edit' && (
          <div className="email-template__edit-container">
            <input
              type="text"
              autoFocus
              onChange={this.handleTitle}
              value={this.state.template.title}
              className="email-template__edit-title"
            />
            <Editor
              apiKey={apiKey}
              init={{
                language: 'ru',
                height: 400,
                menubar: false,
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount',
                ],
                toolbar:
                  'undo redo | formatselect | bold italic forecolor | \
                                                              alignleft aligncenter alignright | link code | \
                                                              bullist numlist  | removeformat',
                color_map: [
                  '000000',
                  'Black',
                  '808080',
                  'Gray',
                  'FFFFFF',
                  'White',
                  'e5087f',
                  'Pink',
                  'ffcc4d',
                  'Yellow',
                  '019a47',
                  'Green',
                  '00a0e2',
                  'Blue',
                ],
              }}
              value={email_template.message}
              onEditorChange={(newValue, editor) =>
                this.handleMessage(newValue)
              }
            />
          </div>
        )}
        <div className="email-template__container">
          <div className="email-template__data-container">
            <span className="email-template__id">{template.id}</span>
            <img
              className="email-template__preview"
              src={
                email_template && email_template.preview
                  ? email_template.preview.file
                  : ''
              }
            />
            <span className="badge badge-success email-template__source">
              {template.dispatch_type.name}
            </span>
            <span className="email-template__created">
              {moment(template.created).format('DD.MM.YY')}
            </span>
            {mode === 'read' && (
              <span className="email-template__title">{template.name}</span>
            )}
          </div>
          <div className="email-template__button-container">
            <Button
              color="primary"
              onClick={this.showPreviewTemplate}
              className="email-template__button"
              title="Посмотреть шаблон"
            >
              <AiFillEye />
            </Button>
            {mode === 'read' && (
              <Button
                color="warning"
                onClick={this.switchToEditMode}
                className="email-template__button"
                title="Редактировать"
              >
                <AiFillEdit />
              </Button>
            )}
            {mode === 'edit' && (
              <Button
                color="success"
                onClick={this.switchToReadMode}
                className="email-template__button"
                title="Сохранить"
              >
                <AiFillCheckCircle />
              </Button>
            )}
            <Button
              color="danger"
              onClick={() => onDelete(template)}
              className="email-template__button"
              title="Удалить"
            >
              <AiFillDelete />
            </Button>
          </div>
        </div>
      </article>
    );
  }
}

export default EmailTemplateItem;
