import React, { Component } from 'react';
import { Card, CardBlock, CardFooter, Button } from 'reactstrap';
import Select from 'react-select-plus';
import DatePicker from 'react-datepicker';
import OfficeSelectContainer from '../../../components/OfficeSelectContainer';
import ManagersSelectContainer from '../../../components/ManagersSelectContainer/ManagersSelectContainer';
import { ISSUE_STATUSES_LANG, ISSUE_SOURCE_MAP } from './IssueItem';
import { ADMIN, getUserRole, SUPER_ADMIN } from '../../../connect/auth';
import './style.css';
import moment from 'moment';

const statusOptions = Object.keys(ISSUE_STATUSES_LANG).map((k) => ({
  label: ISSUE_STATUSES_LANG[k],
  value: k,
}));

const sourceOptions = Object.keys(ISSUE_SOURCE_MAP).map((k) => ({
  label: ISSUE_SOURCE_MAP[k].name,
  value: k,
}));

class IssueFilter extends Component {
  constructor(props) {
    super(props);
    this.handleStatus = this.handleStatus.bind(this);
    this.handleSource = this.handleSource.bind(this);
    this.startDateChange = this.startDateChange.bind(this);
    this.endDateChange = this.endDateChange.bind(this);
    this.changeOffice = this.changeOffice.bind(this);
    this.changeOfficeCity = this.changeOfficeCity.bind(this);
    // this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleManagersOnChange = this.handleManagersOnChange.bind(this);
    this.loadFilter = this.loadFilter.bind(this);
    this.handleFilterUpdate = this.handleFilterUpdate.bind(this);

    const filter = this.loadFilter();
    this.state = filter || {
      id: '',
      text: '',
      status: this.getStatusDefault(),
      managers: [],
      offices: [],
      offices_city: null,
      startDate: '',
      endDate: '',
      tourist: '',
      fp_number: '',
      source: null,
      phone: '',
      email: '',
    };
    this.handleInitialLoad();
  }

  getStatusDefault() {
    return [
      { label: 'новый', value: 'new' },
      { label: 'звонок', value: 'call' },
      { label: 'отправлена подборка', value: 'tours' },
      { label: 'встреча', value: 'meet' },
      { label: 'отложить до', value: 'deffered' },
      { label: 'открыт', value: 'active' },
    ];
  }

  startDateChange(date) {
    this.setState({ startDate: date });
  }

  endDateChange(date) {
    this.setState({ endDate: date });
  }

  handleStatus(options) {
    this.setState({
      status: options,
    });
  }

  handleSource(options) {
    this.setState({
      source: options,
    });
  }

  handleInput(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  filterItems(st) {
    const result = {};
    if (st.id) {
      result.id = st.id;
    }
    if (st.text) {
      result.content = st.text;
    }
    if (st.startDate) {
      result.created__gte = st.startDate.format('DD.MM.YYYY');
    }
    if (st.endDate) {
      result.created__lte = st.endDate.format('DD.MM.YYYY');
    }
    if (st.source) {
      result.source = st.source.split(',');
    }
    if (st.status) {
      result.status = st.status.map((status) => status.value);
    }
    if (st.fp_number) {
      result.fp = st.fp_number;
    }
    if (st.tourist) {
      result.client = st.tourist;
    }
    if (st.managers) {
      result.manager = st.managers;
    }
    if (st.offices) {
      result.office = st.offices.map((office) => office.value);
    }
    if (st.phone) {
      result.client = st.phone;
    }
    if (st.email) {
      result.client = st.email;
    }
    return result;
  }

  _get_valid_serialize_state(data) {
    const fields = [
      'id',
      'text',
      'status',
      'startDate',
      'endDate',
      'tourist',
      'fp_number',
      'source',
      'managers',
      'offices',
    ];
    const validated_data = {};

    for (let field in data) {
      if (~fields.indexOf(field)) {
        if (field == 'startDate' || field == 'endDate') {
          validated_data[field] = data[field] ? moment(data[field]) : '';
        } else {
          validated_data[field] = data[field];
        }
      }
    }
    return validated_data;
  }

  saveFilter(state) {
    const valid_state = this._get_valid_serialize_state(state);
    localStorage.setItem('issues_filter', JSON.stringify(valid_state));
  }

  resetFilter() {
    localStorage.removeItem('issues_filter');
  }

  loadFilter() {
    if (localStorage.getItem('issues_filter')) {
      const data = JSON.parse(localStorage.getItem('issues_filter'));
      return this._get_valid_serialize_state(data);
    }
  }

  handleInitialLoad() {
    this.saveFilter(this.state);
    let filters = this.filterItems(this.state);
    this.props.onInitialFilter(filters);
  }

  handleFilterUpdate() {
    this.saveFilter(this.state);
    let filters = this.filterItems(this.state);
    this.props.onChangeFilter(filters);
  }

  handleReset() {
    this.resetFilter();
    this.setState(
      {
        id: '',
        text: '',
        status: this.getStatusDefault(),
        managers: [],
        offices: [],
        offices_city: null,
        startDate: '',
        endDate: '',
        tourist: '',
        fp_number: '',
        source: null,
        phone: '',
        email: '',
      },
      this.handleFilterUpdate
    );
  }

  handleManagersOnChange(v) {
    this.setState({
      ...this.state,
      managers: v,
    });
  }

  changeOffice(offices) {
    this.setState({ offices });
  }
  changeOfficeCity(offices_city) {
    this.setState({ offices_city });
  }

  componentDidMount() {
    this.loadFilter();
  }

  render() {
    const userRole = getUserRole();
    const { disabled: isDisabled, isFormVisible } = this.props;

    return (
      <div>
        <div className="card-block">
          <div className="filter-row">
            <div className="filter-field">
              <label htmlFor="" className="col-form-label">
                Клиент
              </label>
              <input
                type="text"
                className="form-control"
                id="tourist"
                placeholder="ФИО клиента"
                name="tourist"
                value={this.state.tourist}
                onChange={this.handleInput}
              />
            </div>
            <div className="filter-field date-from">
              <label htmlFor="leadDate" className="col-form-label">
                Дата от
              </label>
              <DatePicker
                dateFormat="DD.MM.YYYY"
                selected={this.state.startDate}
                selectsStart
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onChange={this.startDateChange}
                isClearable={this.state.startDate ? true : false}
                className="d-block"
                placeholderText="Дата начала"
              />
            </div>
            <div className="filter-field date-to">
              <label htmlFor="leadDate" className="col-form-label">
                Дата до
              </label>
              <DatePicker
                dateFormat="DD.MM.YYYY"
                selected={this.state.endDate}
                selectsEnd
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onChange={this.endDateChange}
                isClearable={this.state.endDate ? true : false}
                placeholderText="Дата окончания"
              />
            </div>
            <div className="filter-field filter__source">
              <label htmlFor="" className="col-form-label">
                Источник
              </label>
              <Select
                multi={true}
                joinValues={true}
                simpleValue={true}
                onChange={this.handleSource}
                options={sourceOptions}
                placeholder="Источник"
                value={this.state.source}
              />
            </div>
          </div>
          <div className="filter-row">
            <div className="filter-field filter__status">
              <label htmlFor="" className="col-form-label">
                Статус
              </label>
              <Select
                name="status"
                multi={true}
                closeOnSelect={true}
                onChange={this.handleStatus}
                options={statusOptions}
                placeholder="Укажите статус"
                value={this.state.status}
              />
            </div>
            <div className="filter-field">
              <label htmlFor="" className="col-form-label">
                Номер заявки в СБ
              </label>
              <input
                type="text"
                className="form-control"
                id="fp_number"
                name="fp_number"
                placeholder="Номер заявки в СБ"
                value={this.state.fp_number}
                onChange={this.handleInput}
              />
            </div>
          </div>
          <div className="filter-row pb-0">
            <OfficeSelectContainer
              changeCity={this.changeOfficeCity}
              changeOffice={this.changeOffice}
              multi_office={true}
              selected_city={this.state.offices_city}
              selected_office={this.state.offices}
            >
              {(userRole === SUPER_ADMIN || userRole === ADMIN) && (
                <div className="filter-field">
                  <label htmlFor="" className="col-form-label">
                    Менеджер
                  </label>
                  <ManagersSelectContainer
                    manager_selected={this.state.managers}
                    changeManager={this.handleManagersOnChange}
                    multi={true}
                    disabled={isDisabled}
                  />
                </div>
              )}
              <div className="filter-field">
                <label htmlFor="" className="col-form-label">
                  Номер запроса
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="issue_id"
                  name="id"
                  placeholder="Номер запроса"
                  value={this.state.id}
                  onChange={this.handleInput}
                />
              </div>
            </OfficeSelectContainer>
          </div>
          <div className="filter-row pb-0">
            Поиск клиента по номеру телефона и Email
          </div>
          <div className="filter-row">
            <div className="filter-field">
              <label htmlFor="" className="col-form-label">
                Номер телефона
              </label>
              <input
                type="text"
                className="form-control"
                id="phone"
                placeholder="Номер телефона"
                name="phone"
                value={this.state.phone}
                onChange={this.handleInput}
              />
            </div>
            <div className="filter-field">
              <label htmlFor="" className="col-form-label">
                Email
              </label>
              <input
                type="text"
                className="form-control"
                id="email"
                placeholder="Email"
                name="email"
                value={this.state.email}
                onChange={this.handleInput}
              />
            </div>
          </div>
          <div
            className={`card-footer-container ${
              isFormVisible ? 'card-footer-container-form_active' : ''
            }`}
          >
            {this.props.children}
            <div className="card-footer">
              <Button
                className="btn btn-sm btn-primary"
                style={{
                  color: 'white',
                }}
                onClick={() => {
                  this.handleFilterUpdate();
                }}
              >
                Найти
              </Button>
              <Button
                className="btn btn-sm btn-danger"
                style={{
                  color: '#009436',
                  backgroundColor: 'transparent',
                }}
                onClick={this.handleReset}
              >
                Сбросить
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default IssueFilter;
