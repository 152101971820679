import React from 'react';
import {Link} from 'react-router-dom';
import {Card, CardBlock, CardHeader, Col, Row} from 'reactstrap';
import {POSITIVE_ACTION_STATUSES, CalendarApi} from '../../../../api/Client';
import Paginator from '../../../../components/Paginator';
import {NOTIFICATION_LANG} from '../../../Notify/components/NotifyTable';
import {NOTIFICATION_FIELD_LANG} from '../../../Notify/components/NotifyType';

const PER_PAGE = 5;

export class Notifications extends React.Component {
  constructor(props) {
    super(props);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.fetchItems = this.fetchItems.bind(this);

    this.state = {
      count: 0,
      items: [],
      page: 1,
    };
  }

  fetchItems(page = 1) {
    const {day, type} = this.props;
    let status;
    CalendarApi.
        fetchList(page, {day: day.format('YYYY-MM-DD')}, `${type}`).
        then(r => {
          status = r.status;
          if (status === POSITIVE_ACTION_STATUSES.list) {
            return r.json();
          }
          return {};
        }).
        then((r) => {
          if (status === POSITIVE_ACTION_STATUSES.list) {
            this.setState({
              count: r.count || 0,
              items: r.results || [],
              page: page,
            });
          }
        });
  }

  handlePageChange(data) {
    let page = data.selected + 1;
    this.fetchItems(page);
  }

  componentDidMount() {
    this.fetchItems(1);
  }

  render() {
    const {items, count} = this.state;
    const {type} = this.props;
    if (!items.length) {
      return null;
    }

    return (
        <div className="animated fadeIn">
          <Row>
            <Col lg="12">
              <Card>
                <CardHeader>
                  <i className="fa fa-align-justify"> </i>{''}
                  <span className="badge badge-info">{count}</span>{' '}
                  <span>{NOTIFICATION_LANG.find(i => i.type === type).lang}</span>
                </CardHeader>
                <CardBlock>
                  <table className="table table--blue table-bordered table-striped table-sm animated fadeIn">
                    <thead>
                    <tr>
                      <th className="text-center tableItem">ФИО</th>
                      <th className="text-center tableItem">Менеджер</th>
                      <th className="text-center tableItem">{NOTIFICATION_FIELD_LANG[type]}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {items.map(item => (
                        <tr key={item.id}>
                          <td className="text-center tableItem">
                            <Link to={`/tourists/${item.id}`}>{item.full_name}</Link>
                          </td>
                          <td className="text-center tableItem">
                            {item.manager ? 
                            <Link to={`/employees/${item.manager.id}`}>
                              {item.manager.last_name + " " + item.manager.first_name}
                            </Link> : <p>Менеджер в CRM не назначен</p>
                            }
                          </td>
                          <td className="text-center tableItem">{item.char_value}</td>
                        </tr>
                    ))}
                    </tbody>
                  </table>
                  <Paginator total={count} onPageChange={this.handlePageChange} perPage={PER_PAGE}/>
                </CardBlock>
              </Card>
            </Col>
          </Row>
        </div>
    );
  }
}
