// from https://vladimirponomarev.com/blog/authentication-in-react-apps-jwt

import jwt_decode from 'jwt-decode'


export default class Auth {

  static authenticateUser(token) {
    localStorage.setItem('token', token);
  }

  static isUserAuthenticated() {
    const now = (new Date().getTime()/1000);
    const token = Auth.getToken();
    if (token === null) {
      return false;
    }
    const token_info = Auth.getUserInfo(token);
    return token_info.exp > now
  }

  static deauthenticateUser() {
    localStorage.removeItem('token');
  }

  static getToken() {
    return localStorage.getItem('token');
  }

  static getUserInfo(token) {
    return jwt_decode(token);
  }

}
