import React, { Component } from "react";
import moment from "moment";
import "moment/locale/ru";
import Select from "react-select-plus";
import { POSITIVE_ACTION_STATUSES, OfficeSummaryAPI } from "../../api/Client";

export const SUMMARY_ORDERING_FIELDS = [
  { value: "tourists", label: "Клиентская база" },
  { value: "leads", label: "Заявки с сайта" },
  { value: "leads_to_order", label: "Перешли в покупку" },
  { value: "issues", label: "Запросы на тур" },
  // {'value': 'issues_this_week', 'label': 'Запросы на тур (за неделю)'},
  { value: "orders", label: "Мои заявки" },
  { value: "email_campaigns", label: "Email рассылки" },
  // {'value': 'email_campaigns_this_week', 'label': 'Email рассылки (за неделю)'},
  { value: "quotes", label: "Геоподборки" },
  // {'value': 'sms_campaigns_this_week', 'label': 'Смс рассылки (за неделю)'},
];
export const ORDERING_DIRECTIONS = [
  { value: "asc", label: "По возрастанию" },
  { value: "desc", label: "По убыванию" },
];

export default class FilterSummaries extends Component {
  constructor(props) {
    super(props);

    this.handleReset = this.handleReset.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.formTextChange = this.formTextChange.bind(this);
    this.handleUpdateData = this.handleUpdateData.bind(this);
    this.fetchCities = this.fetchCities.bind(this);

    moment.locale("ru");

    this.state = {
      cities: [],
      city: null,
      contacts: "",
      countries: [],
      country: null,
      direction: null,
      name: "",
      onlyWithAccess: null,
      ordering: null,
      type: null,
    };
  }

  handleReset() {
    this.setState(
      {
        city: null,
        contacts: "",
        country: null,
        direction: null,
        name: "",
        onlyWithAccess: null,
        ordering: null,
        type: null,
      },
      this.handleSubmit
    );
  }

  handleSubmit() {
    this.props.onFilter(this.state);
  }

  fetchCities() {
    let status;
    OfficeSummaryAPI.fetchJSON(`cities/`)
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.list) {
          this.setState({
            cities: r || [],
          });
        }
      });
  }

  fetchCountries() {
    let status;
    OfficeSummaryAPI.fetchJSON(`countries/`)
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.list) {
          this.setState({
            countries: r || [],
          });
        }
      });
  }

  handleUpdateData() {
    this.props.onUpdateData();
  }

  formTextChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleOnChange(field, value) {
    this.setState({
      [field]: value,
    });
  }

  componentDidMount() {
    this.fetchCities();
    this.fetchCountries();
  }

  render() {
    const {
      cities,
      city,
      contacts,
      countries,
      country,
      direction,
      name,
      onlyWithAccess,
      ordering,
      type,
    } = this.state;
    const { isBlocked } = this.props;
    const ACCESS_FILTER_PARAMS = [
      { value: 1, label: "Только получившие доступ в CRM" },
      { value: 0, label: "Только НЕ получившие доступ в CRM" },
    ];
    const CITIES = cities.length
      ? cities.map((item) => ({ value: item, label: item }))
      : [];
    const COUNTRIES = countries.length
      ? countries.map((item) => ({ value: item, label: item }))
      : [];
    const TYPES = [
      { value: "normal", label: "Обычный" },
      { value: "online", label: "Он-лайн" },
      { value: "freelancer", label: "Фрилансер" },
    ];
    return (
      <div className="card card--search-bg">
        <div className="card-header">
          <strong>Сводка по агентствам</strong>
        </div>
        <div className="card-block">
          <form action="" method="post">
            <div className="filter-row">
              <div className="filter-field filter__source">
                <label htmlFor="email">Контакты</label>
                <input
                  type="text"
                  className="form-control"
                  id="contacts"
                  placeholder="Поиск по контактам"
                  name="contacts"
                  value={contacts}
                  onChange={this.formTextChange}
                  readOnly={isBlocked}
                />
              </div>
              <div className="filter-field filter__source">
                <label htmlFor="name" className="col-2 col-form-label">
                  Агентство
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  id="name"
                  placeholder="Поиск по названию"
                  value={name}
                  onChange={this.formTextChange}
                  readOnly={isBlocked}
                />
              </div>
              <div className="filter-field">
                <Select
                  id="cities-field"
                  name="cities"
                  placeholder="Город"
                  simpleValue={true}
                  closeOnSelect={true}
                  removeSelected={true}
                  value={city}
                  onChange={(val) => this.handleOnChange("city", val)}
                  options={CITIES}
                  disabled={isBlocked}
                />
              </div>
              <div className="filter-field">
                <Select
                  id="countries-field"
                  name="countries"
                  placeholder="Страна"
                  simpleValue={true}
                  closeOnSelect={true}
                  removeSelected={true}
                  value={country}
                  onChange={(val) => this.handleOnChange("country", val)}
                  options={COUNTRIES}
                  disabled={isBlocked}
                />
              </div>
            </div>
            <div className="filter-row">
              <div className="filter-field filter__source">
                <Select
                  id="ordering-field"
                  name="orderings"
                  placeholder="Поле сортировки"
                  simpleValue={true}
                  closeOnSelect={true}
                  removeSelected={true}
                  value={ordering}
                  onChange={(val) => this.handleOnChange("ordering", val)}
                  options={SUMMARY_ORDERING_FIELDS}
                  disabled={isBlocked}
                />
              </div>
              <div className="filter-field filter__source">
                <Select
                  id="ordering-direction"
                  name="directions"
                  placeholder="Направление сортировки"
                  simpleValue={true}
                  closeOnSelect={true}
                  value={direction}
                  onChange={(val) => this.handleOnChange("direction", val)}
                  options={ORDERING_DIRECTIONS}
                  disabled={isBlocked}
                />
              </div>
              <div className="filter-field filter__source">
                <Select
                  id="ordering-direction"
                  name="directions"
                  placeholder="Получен доступ"
                  simpleValue={true}
                  closeOnSelect={true}
                  value={onlyWithAccess}
                  onChange={(val) => this.handleOnChange("onlyWithAccess", val)}
                  options={ACCESS_FILTER_PARAMS}
                  disabled={isBlocked}
                />
              </div>
              <div className="filter-field filter__source">
                <Select
                  id="types-field"
                  name="types"
                  placeholder="Тип"
                  simpleValue={true}
                  closeOnSelect={true}
                  removeSelected={true}
                  value={type}
                  onChange={(val) => this.handleOnChange("type", val)}
                  options={TYPES}
                />
              </div>
            </div>
          </form>
          <div className="card-footer">
            <button
              type="submit"
              className="btn btn-sm btn-primary"
              onClick={this.handleSubmit}
              disabled={isBlocked}
            >
              <i className="fa fa-dot-circle-o"></i>Поиск
            </button>
            <button
              type="reset"
              className="btn btn-sm btn-danger"
              onClick={this.handleReset}
              disabled={isBlocked}
            >
              <i className="fa fa-ban"></i>Очистить
            </button>
            <button
              type="submit"
              className="btn btn-sm btn-warning"
              onClick={this.handleUpdateData}
              disabled={isBlocked}
            >
              <i className="fa fa-refresh"></i>Обновить данные
            </button>
          </div>
        </div>
      </div>
    );
  }
}
