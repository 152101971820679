import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import moment from 'moment';
import 'moment/locale/ru';

class IssueMerger extends Component {
  constructor(props) {
    super(props);

    this.handleSkip = this.handleSkip.bind(this);
  }

  handleSkip(e) {
    e.stopPropagation();
    e.preventDefault();
    const {issue, handleSkipIssue} = this.props;
    if (!!issue.id && typeof handleSkipIssue === 'function') {
      handleSkipIssue(issue.id);
    }
  }

  render() {
    const {issue} = this.props;

    if (!issue) {
      return null;
    }

    const {person = {}, manager = {}, order = {}} = issue;

    return (
        <li className="justify-content-between list-group-item item-header">
          <Link to={`/persons/${person.id}`}>
            <div>

              <i className="fa fa-circle fa-1" aria-hidden="true">{``}</i>
              <span className="mr-30">Запрос №{issue.id} </span>
              <span>
                <i className="order__icon-info order__icon-info--arrive">{``}</i>
                Создан {moment(issue.created).format('DD.MM.YY')}
              </span>
              <span className="float-right" onClick={this.handleSkip}>
                <i className="fa fa-2x fa-close" title="Пропустить">{``}</i>
              </span>

              <p>
                <i className="fa fa-exchange order__icon-info--place" aria-hidden="true">{``}</i>
                Менеджер: {' '}
                <span className="badge badge-default">{
                  [manager.first_name, manager.last_name, `(${manager.username})`].join(' ')
                }</span>
              </p>

              <p>
                <i className="fa fa-user fa-1 order__icon-info--place" aria-hidden="true">{``}</i>
                Потенциальный клиент: &nbsp;
                <span className="badge badge-success">{`${person.full_name}`}</span>
              </p>

              <p>
                <b>Заявка из ФСУ №{order.fp_pk}</b>
              </p>

              {!!order.tourists_fp && order.tourists_fp.map((tourist, idx) => (
                  <p key={idx}>
                    <i className="fa fa-user-plus order__icon-info--place">{``}</i>
                    Турист: &nbsp;
                    <span className="badge badge-primary">{tourist.full_name}</span>
                  </p>
              ))}

            </div>
          </Link>
        </li>
    );
  }
}

export default IssueMerger;