import React, { Component } from 'react'

export default class IssueSaleRules extends Component {
  render() {
    return (
      <ol className="list-group">
          <li className="list-group-item" style={{listStyle: 'auto !important'}}>Оперативно (до 10 минут после получения контакта) связаться</li>
          <li className="list-group-item" style={{listStyle: 'auto !important'}}>Квалифицировать лид</li>
          <li className="list-group-item" style={{listStyle: 'auto !important'}}>Вовлечь, открыть к диалогу, заинтересовать</li>
          <li className="list-group-item" style={{listStyle: 'auto !important'}}>Глубоко выявить потребности (явные, скрытые, ключевой мотив)</li>
          <li className="list-group-item" style={{listStyle: 'auto !important'}}>Создать аффинити, дружеское отношение</li>
          <li className="list-group-item" style={{listStyle: 'auto !important'}}>«Продать» себя, как эксперта</li>
          <li className="list-group-item" style={{listStyle: 'auto !important'}}>«Продать» преимущества компании</li>
          <li className="list-group-item" style={{listStyle: 'auto !important'}}>Убедить, что вы слушали и услышали клиента</li>
          <li className="list-group-item" style={{listStyle: 'auto !important'}}>Подвести итог «правильно ли я вас понял, ничего ли не упустил важного»</li>
          <li className="list-group-item" style={{listStyle: 'auto !important'}}>«Продать» приход в офис</li>
          <li className="list-group-item" style={{listStyle: 'auto !important'}}>Продать ценность дальнейшего общения</li>
          <li className="list-group-item" style={{listStyle: 'auto !important'}}>Договориться о следующем касании (день, время)</li>
          <li className="list-group-item" style={{listStyle: 'auto !important'}}>В презентации тура «попасть в карту потребностей»</li>
          <li className="list-group-item" style={{listStyle: 'auto !important'}}>Презентовать факт «попадания в карту потребностей»</li>
          <li className="list-group-item" style={{listStyle: 'auto !important'}}>Использовать факты, основанные на выявленных потребностях, почему именно эти туры идеальны именно для этого туриста</li>
          <li className="list-group-item" style={{listStyle: 'auto !important'}}>Использовать не только характеристики, а главное – выгоды и ценности именно для этого туриста</li>
          <li className="list-group-item" style={{listStyle: 'auto !important'}}>Убедить, что цена на тур действительно лучшая</li>
          <li className="list-group-item" style={{listStyle: 'auto !important'}}>Позвонить и презентовать подборку</li>
          <li className="list-group-item" style={{listStyle: 'auto !important'}}>Удивить фишкой и в очередной раз показать, что вы эксперт</li>
          <li className="list-group-item" style={{listStyle: 'auto !important'}}>Рассказать о личном опыте путешествий и опыту туристов, которые ездили туда же</li>
          <li className="list-group-item" style={{listStyle: 'auto !important'}}>Дать личную рекомендацию</li>
          <li className="list-group-item" style={{listStyle: 'auto !important'}}>Показать честно недостатки и ваш интерес, чтобы турист отдохнул лучшим образом</li>
          <li className="list-group-item" style={{listStyle: 'auto !important'}}>Вовлекать туриста в правильный выбор тура</li>
          <li className="list-group-item" style={{listStyle: 'auto !important'}}>Привести твердые факты, почему не стоит медлить с покупкой</li>
          <li className="list-group-item" style={{listStyle: 'auto !important'}}>«Продать» ценность следующего общения</li>
          <li className="list-group-item" style={{listStyle: 'auto !important'}}>Договориться о следующем касании (пролонгировать коммуникацию)</li>
          <li className="list-group-item" style={{listStyle: 'auto !important'}}>Работая с возражениями – найти истинное, и закрыть сразу</li>
          <li className="list-group-item" style={{listStyle: 'auto !important'}}>Вносить ясность – выбрали уже тур, если нет, готовы ли дальше выбирать, актуально ли это</li>
          <li className="list-group-item" style={{listStyle: 'auto !important'}}>Давить, используя честные дедлайны (авиа, отель, цена) и приводя факты и доказательства</li>
          <li className="list-group-item" style={{listStyle: 'auto !important'}}>Сделать с клиентом максимум касаний в цикле продаж 5-7 дней</li>
          <li className="list-group-item" style={{listStyle: 'auto !important'}}>Сделать все типовые касания за 7 дней</li>
          <li className="list-group-item" style={{listStyle: 'auto !important'}}>Довести до конечного статуса – отказа или покупки</li>
      </ol>
    )
  }
}
