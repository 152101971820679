import React, { Component } from 'react';
import { Form, FormGroup, FormText, Label, Input, Button } from 'reactstrap';
import { PicturesAPI, TemplatesAPI } from '../../../api/Client/Client';
import { Editor } from '@tinymce/tinymce-react';
import swal from 'sweetalert';
import apiKey from '../../../constants/TinyMceApiKey';

export default class EmailTemplateCreate extends Component {
  constructor(props) {
    super(props);
    this.handleImageEmail = this.handleImageEmail.bind(this);
    this.switchModeEdit = this.switchModeEdit.bind(this);
    this.switchModeNew = this.switchModeNew.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.setStateDefault = this.setStateDefault.bind(this);
  }

  state = {
    title: '',
    image: '',
    message: '',
    imagePreviewUrl: null,
    imageUrl: null,
    imageId: null,
    mode: 'new',
  };

  setStateDefault() {
    this.setState({
      title: '',
      image: '',
      message: '',
      imagePreviewUrl: null,
      imageUrl: null,
      imageId: null,
      mode: 'new',
    });
  }

  handleTitle = (e) => {
    this.setState({
      title: e.target.value,
    });
  };

  handleMessage = (e) => {
    this.setState({
      message: e.target.value,
    });
  };

  switchModeEdit() {
    this.setState({
      mode: 'edit',
    });
  }

  switchModeNew() {
    this.setState({
      mode: 'new',
    });
  }

  handleSave() {
    if (!this.state.title) {
      return swal('Укажите название шаблона');
    }
    if (!this.state.imageId) {
      return swal('Загрузите изображение для шаблона');
    }

    const data = {
      name: this.state.title,
      title: this.state.title,
      email_template: {
        pictures: [this.state.imageId],
        message: this.state.message,
      },
      sms_template: {},
    };
    TemplatesAPI.create(data).then((r) => {
      this.props.onCreate(data);
      this.setStateDefault();
    });
  }

  handleImageEmail(e) {
    e.preventDefault();

    let reader = new FileReader();
    let files = e.target.files;
    let file = e.target.files[0];
    let formData = new FormData();
    formData.append('file', file, file.name);
    console.log(files);
    reader.onloadend = (e) => {
      let status;
      PicturesAPI.send_email_picture(formData)
        .then((r) => {
          status = r.status;
          return r.json();
        })
        .then((result) => {
          if (result.file) {
            this.setState({
              imagePreviewUrl: result.file,
              imageUrl: window.location.origin + result.file,
              imageId: result.id,
            });
          }
        })
        .catch((e) => {
          console.log(
            'не удалось загрузить изображение. Максимальный размер файла до 2 мб.',
            status
          );
          this.setState({
            isSended: false,
            isError: true,
            isViewSpinner: false,
            error_messages: {
              image: [
                'не удалось загрузить изображение. Максимальный размер файла до 2 мб.',
              ],
            },
          });
        });
    };
    reader.readAsDataURL(file);
  }

  render() {
    const { imagePreviewUrl, mode, title, imageId, message } = this.state;
    return (
      <div>
        {mode == 'new' && (
          <Button color="primary" onClick={this.switchModeEdit}>
            <i className="fa fa-plus mr-2"></i>Добавить шаблон
          </Button>
        )}

        {mode == 'edit' && (
          <Form>
            <div style={{ width: '100%' }}>
              <FormGroup>
                <Label>Название шаблона</Label>
                <Input type="text" onInput={this.handleTitle} value={title} />
              </FormGroup>
            </div>
            <div style={{ width: '100%' }}>
              <FormGroup>
                <Label>Текст шаблона</Label>
                <Editor
                  apiKey={apiKey}
                  init={{
                    language: 'ru',
                    height: 500,
                    menubar: false,
                    plugins: [
                      'advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount',
                    ],
                    toolbar:
                      'undo redo | formatselect | bold italic forecolor | \
                                                              alignleft aligncenter alignright | link code | \
                                                              bullist numlist  | removeformat',
                    color_map: [
                      '000000',
                      'Black',
                      '808080',
                      'Gray',
                      'FFFFFF',
                      'White',
                      'e5087f',
                      'Pink',
                      'ffcc4d',
                      'Yellow',
                      '019a47',
                      'Green',
                      '00a0e2',
                      'Blue',
                    ],
                  }}
                  value={this.state.message}
                  onEditorChange={(newValue, editor) =>
                    this.setState({ message: newValue })
                  }
                />
              </FormGroup>
            </div>
            <div>
              <FormGroup>
                <Label for="imageEmail">Выберите картинку</Label>
                <Input
                  id="imageEmail"
                  type="file"
                  name="file"
                  multiple={false}
                  onChange={this.handleImageEmail}
                />
                <FormText color="muted">
                  Загрузите изображение для email шаблона.🙂
                </FormText>
                {imagePreviewUrl && (
                  <img
                    src={imagePreviewUrl}
                    style={{ height: 200, margin: '10px auto' }}
                  />
                )}
              </FormGroup>
              <FormGroup>
                <Button color="default" onClick={this.switchModeNew}>
                  Закрыть
                </Button>
                {(!!title || !!imageId) && (
                  <Button color="success" onClick={this.handleSave}>
                    Сохранить
                  </Button>
                )}
              </FormGroup>
            </div>
          </Form>
        )}
      </div>
    );
  }
}
