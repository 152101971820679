import React, {PureComponent} from 'react'
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {TouristFpAPI, DocumentsAPI, POSITIVE_ACTION_STATUSES} from '../../../api/Client';
import FileUpload from '../../../components/FileUpload';
import ProgressBar from '../../../components/ProgressBar';
import * as actions from '../../../actions/actions'
import {Alert} from 'reactstrap'

class TouristFiles extends PureComponent {
    constructor(props) {
        super(props);
        this.fetchFiles = this.fetchFiles.bind(this)
        this.deleteFile = this.deleteFile.bind(this)
        this.showImageFile = this.showImageFile.bind(this)
        this.downloadFile = this.downloadFile.bind(this)
        this.handleFileUpload = this.handleFileUpload.bind(this)
        this.checkTypeIcon = this.checkTypeIcon.bind(this)
        this.isImage = this.isImage.bind(this)

        this.state = {
            currentImage: '',
        }
    }

    handleFileUpload(files) {
        if (files.length) {
            var formData = new FormData();
            formData.append('tourist', this.props.tourist);
            files.map(file => formData.append('file_path', file));
            this.props.upload();
            const self = this;
            DocumentsAPI.upload(formData).then(r => {
                try {
                    if (!r.id) {
                        let message = r.file_path.length ?
                            r.file_path.join('<br />') :
                            'Тут какая то ошибка которую нужно обработать'
                        throw  new Error(message)
                    } else {
                        self.props.uploadSuccess(r)
                    }
                } catch (e) {
                    self.props.uploadFailure(e.message)
                }
            });
        }
    }

    checkTypeIcon(filename) {
        if (/pdf$/.test(filename)) {
            return 'text-danger fa fa-3 fa-file-pdf-o'
        }
        if (/jpg$/.test(filename)) {
            return 'text-success fa fa-file-image-o'
        }
        if (/png$/.test(filename)) {
            return 'text-primary fa fa-file-image-o'
        }
        return 'fa fa-file-doc'
    }

    isImage(filename) {
        return (/png|jpg|jpeg$/.test(filename)) ? true : false
    }

    deleteFile(file) {
        const self = this;
        self.setState({
            currentImage: ''
        })
        this.props.deleteFile()
        DocumentsAPI.delete(file.id).then(res => {
            if (res.status === POSITIVE_ACTION_STATUSES.destroy) {
                self.props.deleteSuccess(file);
            } else {
                self.props.deleteFailure(file);
            }
        });
    }

    showImageFile(file) {
        let self = this;
        DocumentsAPI.getFileAsBase64(file.id)
            .then(function (data) {
                if (/base64/.test(data.data)) {
                    self.setState({
                        currentImage: data.data
                    },)
                } else {
                    self.setState({
                        currentImage: ''
                    })
                }
            });
    }

    downloadFile(file) {
        let self = this;
        DocumentsAPI.get(file.id)
            .then(function (data) {
                // not impementation
            })
    }

    fetchFiles(page = 1) {
        const self = this;
        const touristId = this.props.tourist;
        self.props.loading()
        TouristFpAPI.getFiles(touristId, 1)
            .then(function (data) {
                let files = data.results;
                self.props.loadSuccess(files);
            });
    }

    componentDidMount() {
        this.fetchFiles();
    }

    render() {
        const documents = this.props.touristFiles.data || [];
        return (
            <div>
                <FileUpload
                    multiple={false}
                    onChange={this.handleFileUpload}
                    accept="image/*, application/pdf,"
                    name="filename"
                />
                {this.state.currentImage && <div className="text-center">
                    <img src={this.state.currentImage} style={{borderRadius: 5, margin: "10px 0px"}} width="300"/>
                </div>}
                <div className="error">
                    {!this.props.touristFiles.isFetching && <ProgressBar/>}
                    {this.props.touristFiles.message &&
                        <Alert color="warning">Ошибка загрузки: {this.props.touristFiles.message}</Alert>}
                </div>
                <ul className="list-group doc__list">
                    {documents.map((file, idx) => (
                        <li className=" justify-content-between list-group-item item-header doc__item" key={idx}>
                            <i className={`${this.checkTypeIcon(file.name)} mr-2`}></i>
                            <span title={file.id} href={'/frontend/api/tfpdocuments/' + file.id}
                                  onClick={() => this.currentFile(file)}>
                  {file.name}
              </span>
                            <span title={file.id} href={'/frontend/api/tfpdocuments/' + file.id}>
                <small>{parseInt(file.size / 1000)}кБ</small>
              </span>
                            <button className="float-right btn btn-danger btn-sm" title={file.id}
                                    onClick={() => this.deleteFile(file)}>
                                <i className="fa fa-remove mr-2"></i>удалить
                            </button>
                            {this.isImage(file.name) ? (
                                <button className="float-right btn btn-success text-center btn-sm mr-2"
                                        onClick={() => this.showImageFile(file)} title={file.id}>
                                    <i className="fa fa-eye"></i>
                                </button>
                            ) : (
                                <button className="float-right btn fa-primary text-center btn-sm mr-2"
                                        onClick={() => this.downloadFile(file)} title={file.id}>
                                    <i className="fa fa-download text-primary"></i>
                                </button>
                            )}
                        </li>
                    ))}
                </ul>
            </div>
        )
    }
}

TouristFiles.displayName = 'TouristFiles';

const mapStateToProps = store => {
    return {
        touristFiles: store.touristFiles,
        touristFile: store.touristFile
    }
}

const matchDispatchToProps = dispatch => {
    return bindActionCreators({
        upload: actions.requestTouristFileUpload,
        uploadSuccess: actions.requestTouristFileUploadSuccess,
        uploadFailure: actions.requestTouristFileUploadFailure,

        deleteFile: actions.requestTouristFileDelete,
        deleteSuccess: actions.requestTouristFileDeleteSuccess,
        deleteFailure: actions.requestTouristFileDeleteFailure,

        loading: actions.requestTouristFiles,
        loadSuccess: actions.requestTouristFilesSuccess,

    }, dispatch)
}

export default connect(mapStateToProps, matchDispatchToProps)(TouristFiles)
