import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select-plus';
import moment from 'moment';
import { Button } from 'reactstrap';
import 'moment/locale/ru';
import OfficeSelectContainer from '../../../components/OfficeSelectContainer';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-select-plus/dist/react-select-plus.css';
import 'react-input-range/lib/css/index.css';
import {
  OrderFpAPI,
  POSITIVE_ACTION_STATUSES,
  RequestForOrdersAPI,
} from '../../../api/Client';
import './FilterOrdersFp.css';
import { Alert } from 'reactstrap';
import EmployeesSelector, {
  equivalentSelectedOfficesByStates,
} from '../../Employees/components/EmployeesSelector';
import {
  ADMIN,
  getCurrentOfficeId,
  getUserRole,
  SUPER_ADMIN,
} from '../../../connect/auth';
import CurrencySelector from '../../OrdersFpTable/CurrencySelector';
import ManagerFpSelector from '../../OrdersFpTable/MangerFpSelector';

export const ORDER_STATUS_IN_WORK = 1;
export const ORDER_STATUS_CANCELED = 2;
export const ORDER_STATUS_CONFIRMED = 3;
export const ORDER_STATUS_WAITING_LIST = 4;
export const ORDER_STATUS_HOTEL_WAIVER = 5;
export const ORDER_STATUS_CANCELLATION_REQUEST = 6;

export const ORDER_STATUSES = [
  { value: ORDER_STATUS_IN_WORK, label: 'В работе' },
  { value: ORDER_STATUS_CANCELED, label: 'Аннулирована' },
  { value: ORDER_STATUS_CONFIRMED, label: 'Подтверждена' },
  { value: ORDER_STATUS_WAITING_LIST, label: 'Лист ожидания' },
  { value: ORDER_STATUS_HOTEL_WAIVER, label: 'Отказ по отелю' },
  { value: ORDER_STATUS_CANCELLATION_REQUEST, label: 'Запрос аннуляции' },
];

const DATE_PICKER_DATE_FORMAT = 'DD.MM.YYYY';

class FilterOrdersFp extends Component {
  constructor(props) {
    super(props);

    this.handleReset = this.handleReset.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDownload = this.handleDownload.bind(this);

    this.changeOffice = this.changeOffice.bind(this);
    this.changeOfficeCity = this.changeOfficeCity.bind(this);
    this.changeInput = this.changeInput.bind(this);
    this.changeValue = this.changeValue.bind(this);

    moment.locale('ru');

    this.state = this.getDefaultState();
  }

  getDefaultState() {
    const isSuperAdmin = getUserRole() === SUPER_ADMIN;
    return {
      fpNumber: '',
      status: '',
      selectedOffice: isSuperAdmin ? null : getCurrentOfficeId(),
      selectedOfficeCity: null,
      tourist: '',
      manager: '',
      managerFp: '',
      departCity: '',
      destinationCountry: '',

      createDate: moment().subtract(1, 'months'),

      arrivalStart: '',
      arrivalEnd: '',
      departureStart: '',
      departureEnd: '',
      createdStart: '',
      createdEnd: '',

      currency: null,
      orderAmountFrom: '',
      orderAmountTo: '',
      nightsFrom: '',
      nightsTo: '',

      isErrorDownload: false,
      isSuccessDownload: false,
      errorDownloadMessages: {},
      isChangedForm: false,
    };
  }

  getDefaultErrorData() {
    return {
      isErrorDownload: false,
      isSuccessDownload: false,
      errorDownloadMessages: {},
    };
  }

  changeValue(val, name) {
    const obj = Object.assign(
      {
        [name]: val,
        isChangedForm: true,
      },
      this.getDefaultErrorData()
    );
    this.setState(obj);
  }

  changeInput(e) {
    this.setState(
      Object.assign(
        {
          [e.target.name]: e.target.value,
          isChangedForm: true,
        },
        this.getDefaultErrorData()
      )
    );
  }

  handleReset() {
    this.setState(this.getDefaultState(), () => this.handleSubmit(true));
  }

  handleSubmit(isForce = false) {
    const { isChangedForm } = this.state;

    if (isForce) {
      this.props.onFilter(this.state);
    } else {
      if (isChangedForm) {
        this.setState(
          {
            isChangedForm: false,
          },
          () => this.props.onFilter(this.state)
        );
      }
    }
  }

  changeOffice(v) {
    const { selectedOffice } = this.state;
    if (!equivalentSelectedOfficesByStates(selectedOffice, v)) {
      this.setState(
        Object.assign(
          {
            selectedOffice: v,
            manager: '',
            managerFp: '',
            isChangedForm: true,
          },
          this.getDefaultErrorData()
        )
      );
    }
  }
  changeOfficeCity(selectedOfficeCity) {
    this.setState({ selectedOfficeCity });
  }

  handleDownload() {
    let status;
    RequestForOrdersAPI.create({ office: getCurrentOfficeId() })
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.create) {
          this.setState({
            isErrorDownload: false,
            isSuccessDownload: true,
            errorDownloadMessages: {},
          });
        } else {
          this.setState({
            isErrorDownload: true,
            isSuccessDownload: false,
            errorDownloadMessages: r,
          });
        }
      });
  }

  render() {
    const {
      selectedOffice,
      fpNumber,
      status,
      createdStart,
      createdEnd,
      arrivalStart,
      arrivalEnd,
      departureStart,
      departureEnd,
      departCity,
      destinationCountry,
      currency,
      orderAmountFrom,
      orderAmountTo,
      nightsFrom,
      nightsTo,
      tourist,
      manager,
      managerFp,
      isErrorDownload,
      isSuccessDownload,
      errorDownloadMessages,
    } = this.state;
    const userRole = getUserRole();
    const isSuperAdmin = userRole === SUPER_ADMIN;
    const isAdmin = userRole === ADMIN;
    return (
      <div className="card geo-filter">
        <div className="card-header">
          <strong>Все мои заявки из СБ (обновляется каждые 24 часа)</strong>
        </div>

        <div className="card-block">
          <form action="" method="post">
            <div className="filter-row">
              <div className="filter-field">
                <label htmlFor="orderFpNumber" className="col-form-label">
                  Номер
                </label>
                <input
                  className="form-control"
                  type="number"
                  value={fpNumber}
                  name="fpNumber"
                  id="orderFpNumber"
                  onChange={this.changeInput}
                  min="0"
                  step="1"
                  placeholder="Номер заявки"
                />
              </div>
              <div className="filter-field">
                <label className="col-form-label">Статус</label>
                <Select
                  multi={false}
                  joinValues={true}
                  simpleValue={true}
                  value={status}
                  options={ORDER_STATUSES}
                  onChange={(val) => this.changeValue(val, 'status')}
                  placeholder="Статус"
                />
              </div>
              <div className="filter-field">
                <label className="col-form-label">Создан с:</label>
                <DatePicker
                  className="d-block"
                  dateFormat={DATE_PICKER_DATE_FORMAT}
                  selected={createdStart}
                  selectsStart
                  startDate={createdStart}
                  endDate={createdEnd}
                  onChange={(v) => this.changeValue(v, 'createdStart')}
                  placeholderText="Создание заявки от"
                />
              </div>

              <div className="filter-field">
                <label className="col-form-label">до</label>
                <DatePicker
                  dateFormat={DATE_PICKER_DATE_FORMAT}
                  selected={createdEnd}
                  startDate={createdStart}
                  selectsEnd
                  endDate={createdEnd}
                  onChange={(v) => this.changeValue(v, 'createdEnd')}
                  placeholderText="Создание заявки до"
                />
              </div>
            </div>

            <div className="filter-row">
              <div className="filter-field">
                <label className="col-form-label">Заезд с:</label>
                <DatePicker
                  className="d-block"
                  dateFormat={DATE_PICKER_DATE_FORMAT}
                  selected={arrivalStart}
                  selectsStart
                  startDate={arrivalStart}
                  endDate={arrivalEnd}
                  onChange={(v) => this.changeValue(v, 'arrivalStart')}
                  placeholderText="Дата заезда от"
                />
              </div>

              <div className="filter-field">
                <label className="col-form-label">до:</label>
                <DatePicker
                  dateFormat={DATE_PICKER_DATE_FORMAT}
                  selected={arrivalEnd}
                  selectsEnd
                  startDate={arrivalStart}
                  endDate={arrivalEnd}
                  onChange={(v) => this.changeValue(v, 'arrivalEnd')}
                  placeholderText="Дата заезда до"
                />
              </div>
              <div className="filter-field">
                <label className="col-form-label">Выезд с:</label>
                <DatePicker
                  className="d-block"
                  dateFormat={DATE_PICKER_DATE_FORMAT}
                  selected={departureStart}
                  selectsStart
                  startDate={departureStart}
                  endDate={departureEnd}
                  onChange={(v) => this.changeValue(v, 'departureStart')}
                  placeholderText="Дата выезда от"
                />
              </div>

              <div className="filter-field">
                <label className="col-form-label">до</label>
                <DatePicker
                  dateFormat={DATE_PICKER_DATE_FORMAT}
                  selected={departureEnd}
                  selectsEnd
                  startDate={departureStart}
                  endDate={departureEnd}
                  onChange={(v) => this.changeValue(v, 'departureEnd')}
                  placeholderText="Дата выезда до"
                />
              </div>
            </div>
            <div className="filter-row">
              <div className="filter-field">
                <label htmlFor="tourist-data" className="col-form-label">
                  Данные туриста
                </label>
                <input
                  className="form-control"
                  type="text"
                  name="tourist"
                  value={tourist}
                  id="tourist-data"
                  onChange={this.changeInput}
                  placeholder="ФИО, email, телефон"
                />
              </div>
              <div className="filter-field">
                <label htmlFor="ordersDepartCity" className="col-form-label">
                  Город вылета
                </label>
                <input
                  type="text"
                  name="departCity"
                  className="form-control"
                  id="orderDepartCity"
                  placeholder="Город вылета"
                  value={departCity}
                  onChange={this.changeInput}
                />
              </div>
              <div className="filter-field">
                <label
                  htmlFor="ordersDestinationCountry"
                  className="col-form-label"
                >
                  Страна назначения
                </label>
                <input
                  type="text"
                  name="destinationCountry"
                  className="form-control"
                  id="orderDestinationCountry"
                  placeholder="Страна назначения"
                  value={destinationCountry}
                  onChange={this.changeInput}
                />
              </div>
              <div className="filter-field">
                <label className="col-form-label" htmlFor="currency-selector">
                  Валюта тура
                </label>
                <CurrencySelector
                  currencySelected={currency}
                  isDisabled={false}
                  identifier="currency-selector"
                  changeCurrency={(v) => this.changeValue(v, 'currency')}
                />
              </div>
            </div>
            <div className="filter-row">
              <div className="filter-field">
                <label htmlFor="orderAmountFrom" className="col-form-label">
                  {' '}
                  Цена от{' '}
                </label>
                <input
                  className="form-control"
                  id="orderAmountFrom"
                  name="orderAmountFrom"
                  type="number"
                  value={orderAmountFrom}
                  min="0.00"
                  step="0.01"
                  onChange={this.changeInput}
                  placeholder="Цена от"
                />
              </div>
              <div className="filter-field">
                <label htmlFor="orderAmountTo" className="col-form-label">
                  {' '}
                  Цена до{' '}
                </label>
                <input
                  className="form-control"
                  id="orderAmountTo"
                  name="orderAmountTo"
                  type="number"
                  value={orderAmountTo}
                  min="0.00"
                  step="0.01"
                  onChange={this.changeInput}
                  placeholder="Цена до"
                />
              </div>

              <div className="filter-field">
                <label htmlFor="nightsFrom" className="col-form-label">
                  {' '}
                  Ночей от{' '}
                </label>
                <input
                  className="form-control"
                  id="nightsFrom"
                  name="nightsFrom"
                  type="number"
                  value={nightsFrom}
                  min="0"
                  step="1"
                  onChange={this.changeInput}
                  placeholder="Ночей от"
                />
              </div>

              <div className="filter-field">
                <label htmlFor="nightsTo" className="col-form-label">
                  {' '}
                  Ночей до{' '}
                </label>
                <input
                  className="form-control"
                  id="nightsTo"
                  name="nightsTo"
                  type="number"
                  value={nightsTo}
                  min="0"
                  step="1"
                  onChange={this.changeInput}
                  placeholder="Ночей до"
                />
              </div>
            </div>

            {isSuperAdmin || isAdmin ? (
              <div className="filter-row">
                {isSuperAdmin && (
                  <OfficeSelectContainer
                    selected_city={this.state.selectedOfficeCity}
                    selected_office={selectedOffice}
                    changeCity={this.changeOfficeCity}
                    changeOffice={this.changeOffice}
                  />
                )}
                {selectedOffice && (
                  <div className="filter-field">
                    <label
                      className="col-form-label"
                      htmlFor="order-manager-selector"
                    >
                      Менеджер CRM
                    </label>
                    <EmployeesSelector
                      officeSelected={selectedOffice}
                      managerSelected={manager}
                      isDisabled={false}
                      updateManager={(v) => this.changeValue(v, 'manager')}
                      identifier="order-manager-selector"
                      isMultiple={true}
                    />
                  </div>
                )}
                {selectedOffice && (
                  <div className="filter-field">
                    <label
                      className="col-form-label"
                      htmlFor="order-manager-fp-selector"
                    >
                      Менеджер ФП
                    </label>
                    <ManagerFpSelector
                      officeSelected={selectedOffice}
                      managerFpSelected={managerFp}
                      isDisabled={false}
                      updateManagerFp={(v) => this.changeValue(v, 'managerFp')}
                      identifier="order-manager-fp-selector"
                    />
                  </div>
                )}
              </div>
            ) : null}
          </form>
          <div
            className="card-footer"
            style={{ paddingLeft: 0, paddingRight: 0 }}
          >
            <a
              href="https://bron.geograftour.com"
              className=""
              target="_blank"
              rel="noopener noreferrer"
            >
              <button type="button" className="btn btn-sm btn-success to-bron">
                <i className="fa fa-arrow-right">{''}</i> Вход в систему
                бронирования(СБ)
              </button>
            </a>

            <button
              type="reset"
              className="btn btn-sm btn-success"
              onClick={this.handleDownload}
            >
              <i className="fa fa-download">{''}</i> Загрузить мои заявки из СБ
            </button>
            <Button
              className="btn btn-sm btn-primary"
              style={{ color: 'white' }}
              onClick={() => this.handleSubmit(false)}
            >
              Найти
            </Button>
            <Button
              className="btn btn-sm btn-danger"
              style={{
                color: '#DE4596',
                backgroundColor: 'transparent',
              }}
              onClick={this.handleReset}
            >
              Сбросить
            </Button>

            {isErrorDownload &&
            typeof errorDownloadMessages === 'object' &&
            !!errorDownloadMessages
              ? Object.keys(errorDownloadMessages).map((item, index) => (
                  <Alert color="warning" key={index}>
                    {errorDownloadMessages[item]}
                  </Alert>
                ))
              : null}

            {isSuccessDownload && (
              <Alert>{`Загрузка из финансовой системы будет произведена в течение часа`}</Alert>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default FilterOrdersFp;
