import "moment/locale/ru";
import moment from "moment";
import React, { Component } from "react";
import { Alert } from "reactstrap";
import { Link } from "react-router-dom";

import BackButton from "../../../../components/BackButton";
import Paginator from "../../../../components/Paginator";
import { DJANGO_BASE_DATE_FORMAT } from "../Calendar";
import { EventsAPI, POSITIVE_ACTION_STATUSES } from "../../../../api/Client";
import { getUserInfo } from "../../../../connect/auth";
import { ISSUE_STATUS_ICONS } from "../../../Issues/IssuesPage";
import { ISSUE_STATUSES_LANG } from "../../../Issues/components/IssueItem";
import {
  ARRIVED_FROM_REST,
  BIRTHDAY,
  DEPARTURE_TOMORROW,
  PASSPORT_EXPIRES,
  PAY_EXPIRES,
  VISA_APPLICATION_DEADLINE,
} from "../../../Notify/components/NotifyType";

import { Notifications } from "./Notifications";

const PER_PAGE = 5;
const TIMEOUT = 2000;

class CalendarDetail extends Component {
  constructor(props) {
    super(props);
    this.remove = this.remove.bind(this);
    this.done = this.done.bind(this);
    this.scroll = this.scroll.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    let day = this.props.match.params.string;
    let momentDate = moment(day, "YYYY-MM-DD");
    const pathHash = props.history.location.hash.replace(/\#/g, ""); // set current pathname without the "/"
    this.state = {
      day: momentDate,
      dayLabel: momentDate.format("DD MMMM YYYY"),

      events: [],
      count: 0,
      page: 1,

      isError: false,
      isSuccess: false,

      pathHash,
    };
  }

  componentDidMount() {
    this.fetchItems();
  }

  done(e) {
    const self = this;
    let status;
    e.preventDefault();

    EventsAPI.update(`${EventsAPI.resource_url}${e.target.id}`, { done: true })
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.partial_update) {
          self.setState(
            (prevState) => {
              const events = [...prevState.events];
              const index = events.findIndex((e) => e.id === r.id);
              events[index] = { ...events[index], done: r.done };

              return {
                events: events,
                isError: false,
                isSuccess: true,
              };
            },
            () =>
              setTimeout(() => {
                self.setState({
                  isError: false,
                  isSuccess: false,
                });
              }, TIMEOUT)
          );
        }
      });
  }

  fetchItems(page = 1) {
    const self = this;
    const { day } = this.state;
    let status;

    EventsAPI.fetchList(page, { day: day.format(DJANGO_BASE_DATE_FORMAT) })
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.list) {
          self.setState({
            page: page,
            events: r.results || [],
            count: r.count,
            isError: false,
            isSuccess: false,
          });
        }
        self.scroll();
      });
  }

  handlePageChange(data) {
    this.fetchItems(data.selected + 1);
  }

  remove(e) {
    const self = this;
    let status;
    e.preventDefault();

    EventsAPI.delete(e.target.id)
      .then((r) => {
        status = r.status;
        if (status === POSITIVE_ACTION_STATUSES.destroy) {
          return {};
        }
        return r.json();
      })
      .then((r) => {
        self.setState(
          {
            isError: status !== POSITIVE_ACTION_STATUSES.destroy,
            isSuccess: status === POSITIVE_ACTION_STATUSES.destroy,
          },
          () =>
            setTimeout(() => {
              if (status === POSITIVE_ACTION_STATUSES.destroy) {
                self.fetchItems(1);
              }
            }, TIMEOUT)
        );
      });
  }

  scroll() {
    const { pathHash } = this.state;
    if (pathHash) {
      console.log("refs: ", this.refs[pathHash], "pahHash: ", pathHash);
      const event = this.refs[pathHash];
      window.scrollTo({
        behavior: "smooth",
        top: event.offsetTop - 60,
      });
      setTimeout(function () {
        event.style.background = "#b9e6f1";
      }, 500);
      setTimeout(function () {
        event.style.removeProperty("background-color");
      }, 1000);
    }
  }

  render() {
    const { events, dayLabel, count, page, isError, isSuccess, day } =
      this.state;
    const userId = getUserInfo().user_id;
    return (
      <div className="container">
        <div id="calendar-table">
          <h3>{dayLabel}</h3>

          <ul className="event__list">
            {events.map((event, idx) => {
              let { start_time, end_time, notes, issue, done, comment_type } =
                event;
              let client = null;
              let link = null;
              if (issue) {
                if (issue.tourist) {
                  client = issue.tourist;
                  link = `tourists`;
                } else if (issue.person) {
                  client = issue.person;
                  link = `persons`;
                }
              }
              [start_time, end_time] = [
                ...[start_time, end_time].map((item) => {
                  if (typeof item === "string") {
                    return item.split(":").slice(0, 2).join(":");
                  }
                  return item;
                }),
              ];

              return (
                <li className={"event " + done} ref={event.id} key={idx}>
                  <p className="event__start">
                    {start_time} - {end_time}
                  </p>
                  <p className="event__notes">{notes}</p>

                  {comment_type &&
                    ISSUE_STATUS_ICONS[comment_type] &&
                    ISSUE_STATUSES_LANG[comment_type] && (
                      <p>
                        <span className="event__status-badge badge badge-success badge-pill">
                          <i
                            className={`fa fa-2x ${ISSUE_STATUS_ICONS[comment_type]}`}
                          >
                            {""}
                          </i>{" "}
                          {ISSUE_STATUSES_LANG[comment_type]}
                        </span>
                      </p>
                    )}

                  {userId != event.user.id && (
                    <p>назначена: {event.user.full_name}</p>
                  )}

                  {client && (
                    <p>
                      клиент:{" "}
                      <Link to={`/${link}/${client.id}`}>
                        {client.full_name || `ID:<${client.id}>`}
                      </Link>
                    </p>
                  )}
                  <div className="event__button-container">
                    {!done && (
                      <button
                        className="btn btn-sm btn-primary"
                        title="Решена"
                        id={event.id}
                        onClick={this.done}
                        type="button"
                      >
                        решена
                      </button>
                    )}

                    <button
                      className="btn btn-sm btn-danger"
                      title="Удалить"
                      id={event.id}
                      onClick={this.remove}
                      type="button"
                    >
                      удалить
                    </button>
                  </div>
                </li>
              );
            })}
            <BackButton {...this.props} />
            {events.length > count && (
              <Paginator
                total={count}
                onPageChange={this.handlePageChange}
                forcePage={page - 1}
                perPage={PER_PAGE}
              />
            )}
          </ul>
          {isError && <Alert color="warning">Ошибка</Alert>}
          {isSuccess && <Alert>Успешно</Alert>}
        </div>
        <Notifications day={day} type={BIRTHDAY} />
        <Notifications day={day} type={ARRIVED_FROM_REST} />
        <Notifications day={day} type={DEPARTURE_TOMORROW} />
        <Notifications day={day} type={PASSPORT_EXPIRES} />
        <Notifications day={day} type={VISA_APPLICATION_DEADLINE} />
        <Notifications day={day} type={PAY_EXPIRES} />
      </div>
    );
  }
}

export default CalendarDetail;
