import React, { PureComponent } from "react";

export class LeadTextdata extends PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const urlForTourView = this.props.source === "cabinet_excursion_tour" ?
            'https://www.geograftour.com/excursions/russia'
            : 'https://www.geograftour.com/tours/tour-info'


        if (this.props.text && typeof this.props.text === 'string') {
            return <p className="col-md-8">{this.props.text}</p>;
        } else {
            return (
                <div className="col-md-8">
                {this.props.text && (
                    <div>
                        <div>
                            <span>Название тура:</span> <span>{this.props.text.name}</span>
                        </div>
                        <div>
                            <span>Дата:</span> <span>{`${this.props.text.dt1} - ${this.props.text.dt2}`}</span>
                        </div>
                        {this.props.text.adult !== undefined && (
                            <div>
                                <span>Взрослых:</span> <span>{this.props.text.adult}</span>
                            </div>
                        )}
                        {this.props.text.child !== undefined && (
                            <div>
                                <span>Детей:</span> <span>{this.props.text.child}</span>
                            </div>
                        )}
                        {this.props.text.hotel && (
                            <div>
                                <span>Отель:</span> <span>{this.props.text.hotel}</span>
                            </div>
                        )}
                        {this.props.text.provider === 'Magput' && (
                            <div>
                                <span>Туроператор:</span> <span>Магазин путешествий</span>
                            </div>
                        )}
                        {this.props.text.offer_id !== undefined && (
                            <div>
                                <span><a target={"_blank"} href={`${urlForTourView}/${this.props.text.offer_id}/`}>Ссылка на тур</a></span>
                            </div>
                        )}
                    </div>
                )}
            </div>
            );
        }
    }
}
