import React, { Component } from 'react';
import { Row } from 'reactstrap';

import BackButton from '../../components/BackButton';
import { OfficeAPI } from '../../api/Client';

export default class Office extends Component {
  constructor(props) {
    super(props);

    this.fetchItems = this.fetchItems.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);

    this.state = {
      info: {},
      template: {},
      items: [],
      count: 0,
      itemPk: this.props.match.params.number,
      username: '',
      employers: [],
      errormessage: '',
    };
  }

  handlePageChange(data) {
    let page = data.selected + 1;
    this.setState({ pageNumber: page }, () => this.fetchItems(page));
  }

  fetchItems(page = 1) {
    const self = this;

    // Заявки клиента
    OfficeAPI.getList(page, {}, `${this.state.itemPk}/leads/`).then(
      (result) => {
        self.setState({
          items: result.results || [],
          count: result.count,
        });
      }
    );
  }

  componentDidMount() {
    let pk = this.props.match.params.number;

    OfficeAPI.getTemplateInfo(pk).then((result) =>
      this.setState({
        template: result || {},
      })
    );
    OfficeAPI.getInfo(pk).then((result) =>
    this.setState({
      info: result || {},
    })
  );

    this.fetchItems(1);
  }

  render() {
    const { state, address, email, name } = this.state.info;

    return (
      <div className="animated fadeIn">
        <Row>
          <div className="col-md-12">
            <div className="card card-accent-primary">
              <div className="card-header">
                <h3>{name}</h3>
              </div>
              <div className="card-block">
                <div className="row">
                  <label className="text-right col-md-2">Область:</label>
                  <p className="col-md-8">{state}</p>
                </div>
                <div className="row">
                  <label className="text-right col-md-2">Адрес:</label>
                  <p className="col-md-8">{address}</p>
                </div>
                <div className="row">
                  <label className="text-right col-md-2">Email:</label>
                  <p className="col-md-8">{email}</p>
                </div>
                <BackButton {...this.props} />
              </div>
            </div>
          </div>
        </Row>
      </div>
    );
  }
}
