import {
  OrderFpAPI,
  LeadAPI,
  PersonAPI,
  TouristFpAPI,
  TouristListsAPI,
  StagingsAPI,
  AgenciesAPI,
  SubscriptionAPI,
  FranchiseApplicationAPI,
  AbandonedCustomersAPI,
  CabinetAPI,
} from '../../api/Client';

const filterNames = [
  'tourists_filter',
  'persons_filter',
  'touristsfp_filter',
  'leads_filter',
  'tourists_cabinet_filter',
  'franchise_filter',
  'franchise_leads_filter',
  'tourists_report_filter',
  'subscription_filter',
  'abandonedcustomers_filter',
];

const filterDescriptions = {
  tourists_filter: 'Рассылка по заявкам из системы',
  persons_filter: 'Рассылка по потенциальным клиентам',
  touristsfp_filter: 'Рассылка по туристам',
  leads_filter: 'Рассылка по заявкам с сайта',
  tourists_cabinet_filter: 'Рассылка по туристам ЛК',
  franchise_filter: 'Рассылка по франчам и субагентам',
  franchise_leads_filter: 'Рассылка по заявкам на франчайзинг',
  tourists_report_filter: 'Рассылка для клиентов из списка из рассылок',
  subscription_filter:
    'Рассылка для получателей из раздела агенты и клиенты подписка',
  abandonedcustomers_filter:
    'Рассылка для получателей из раздела клиенты закрывшихся агентов',
};

export function getFilterAbout() {
  for (const filterName in filterDescriptions) {
    if (localStorage.getItem(filterName)) {
      return filterDescriptions[filterName];
    }
  }

  return false;
}

export function getFilterName() {
  for (const name of filterNames) {
    // console.log(name)
    const filter = localStorage.getItem(name);
    if (filter) {
      return filter;
    }
  }

  return false;
}

export function getFilterCount() {
  for (const name of filterNames) {
    const filter = localStorage.getItem(name);
    if (filter) {
      const clearName = name.replace("_filter", "")
      return localStorage.getItem(`${clearName}_total`);
    }
  }

  return 0;
}


export function addLinkToUrlWithGeoclient(htmlString) {
    const pattern = /<p>(?!<a href="https?:\/\/\S+">)(.*?geoclient.*?)<\/p>/g;
    return htmlString.replace(pattern, '<p><a href="$1">$1</a></p>');
}

export function sendToRecepients(
  filter,
  touristType,
  data,
  responseHandler,
  resultHandler
) {
  if (
    filter && filter.type === 'all' ||
    filter && filter.type === 'tags' ||
    filter && filter.type === 'personal'
  ) {
    StagingsAPI.mailigen(data, filter)
      .then(responseHandler)
      .then(resultHandler);
  } else if (touristType === 'cabinet') {
    swal('отправляем туристам кабинета');
    CabinetAPI.mailigen(data).then(responseHandler).then(resultHandler);
  } else if (touristType === 'leads') {
    LeadAPI.mailigen(data).then(responseHandler).then(resultHandler);
  } else if (touristType === 'touristfp') {
    TouristFpAPI.mailigen(data).then(responseHandler).then(resultHandler);
  } else if (touristType === 'persons') {
    PersonAPI.mailigen(data).then(responseHandler).then(resultHandler);
  } else if (touristType === 'tourist') {
    OrderFpAPI.mailigen(data).then(responseHandler).then(resultHandler);
  } else if (touristType === 'franchise') {
    swal('отправляем рассылку по субам, франчам и контролю за продажами');
    AgenciesAPI.mailigen(data).then(responseHandler).then(resultHandler);
  } else if (touristType === 'report') {
    swal('отправляем рассылку для списка из рассылок');
    TouristListsAPI.mailigen(data).then(responseHandler).then(resultHandler); // frontend/api/addressbooks/ID-АДРЕСНОЙ-КНИГИ/campaign/
  } else if (touristType === 'franchise_leads') {
    swal('отправляем рассылку для списка из заявок на франчайзинг');
    FranchiseApplicationAPI.mailigen(data)
      .then(responseHandler)
      .then(resultHandler);
  } else if (touristType === 'subscription') {
    swal('отправляем рассылку для списка из раздела Агенты и клиенты подписка');
    SubscriptionAPI.mailigen(data).then(responseHandler).then(resultHandler);
  } else if (touristType === 'abandonedcustomers') {
    swal(
      'отправляем рассылку для списка из раздела клиенты закрывшихся агентов'
    );
    AbandonedCustomersAPI.mailigen(data)
      .then(responseHandler)
      .then(resultHandler);
  }
}

export function encodeObject(obj) {
  let filters = [];
  // console.log('filters', filters);
  // console.log('obj', obj);
  if (obj) {
    Object.keys(obj).forEach(function (k) {
      const value = obj[k];
      if (Array.isArray(value)) {
        value.forEach(function (v) {
          filters.push(`${encodeURIComponent(k)}=${encodeURIComponent(v)}`);
        });
      } else {
        filters.push(`${encodeURIComponent(k)}=${encodeURIComponent(value)}`);
      }
    });
  }
  // console.log(`&${filters.join('&')}`);
  return `&${filters.join('&')}`;
}
