import React, {Component} from 'react';
import {POSITIVE_ACTION_STATUSES, OfficeAPI, UsersAPI} from '../../api/Client';
import Select from 'react-select-plus';
import {
  checkTokenExpirationDate,
  deauthenticateUser,
  getCurrentOfficeId,
  getUserInfo,
  pushCurrentOffice,
} from '../../connect/auth';
import './style.css';
import {Redirect} from 'react-router-dom';
import Swal from 'sweetalert';

export default class Officein extends Component {
  constructor(props) {
    super(props);

    this.state = {
      office: getCurrentOfficeId(),
      offices: [],
      showErrorMessage: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(value) {
    this.setState({
      office: value,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    const {history} = this.props;
    const {office, offices} = this.state;
    const officeData = offices.find(i => i.id === office);
    if (!!officeData) {
      // OfficeAPI.getInfo(officeData.id)
      // .then(r => r.json())
      // .then(response => {
      //   if (!response.template_data) {
      //     Swal(`От вашего офиса не уходят автоуведомления, потому что не заполены данные шаблона`);
      //   }
      // })
      pushCurrentOffice(officeData);
      history.push('/');
    }
    this.setState({
      showErrorMessage: true,
    });
  }

  handleOptionLoad() {
    let status;

    UsersAPI.
        fetchOffices().
        then(r => {
          status = r.status;
          return r.json();
        }).
        then(r => {
          if (status === POSITIVE_ACTION_STATUSES.retrieve) {
            let offices = r || []
            this.setState({offices: offices})
            if (offices.length == 1) {
              pushCurrentOffice(offices[0])
              this.props.history.push('/')
            }
          }
        });
  }

  componentDidMount() {
    this.handleOptionLoad();
  }

  render() {
    const userInfo = getUserInfo();

    if (!userInfo || !checkTokenExpirationDate(userInfo.exp)) {
      deauthenticateUser();
      return <Redirect to="/login"/>;
    }

    const {office, offices, showErrorMessage} = this.state;
    const OPTIONS = offices.length ? offices.map((office) => {
      const name = office.address ? office.address : office.name;
      return {value: office.id, label: `${name} (${office.id})`};
    }) : [];

    return (
        <div className="app flex-row align-items-center auth--bg-img">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-4">
                <img height="64" src="../img/logo.client.png"/>
                <div className="hidden-mobile">
                  <p className="">Геоклиент - система управления эффективной коммуникацией с клиентами в офисах
                    туристической сети "География".
                    Внедрение программы позволит вам оперативно и сегментированно работать с клиентсткой базой,
                    автоматизировать отправку типовых и обязательных обращений клиентам. </p>
                  <p>Сохранить в копилке агентства каждое обращение и не потерять ни одного контакта потенциального
                    клиента. </p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card-group mb-0">
                  <div className="card p-4">

                    <form action="" onSubmit={this.handleSubmit}>

                      <div className="card-block">
                        <h1>Выберите офис</h1>
                        <div className="row">
                          <div className="col-md-12" style={{
                            marginTop: 20,
                            marginBottom: 20,
                          }}>
                            <Select
                                name="office"
                                placeholder="Выбор из списка ..."
                                multi={false}
                                closeOnSelect={true}
                                onChange={this.handleChange}
                                value={office}
                                searchPromptText="Введите сивол для поиска..."
                                loadingPlaceholder="Загрузка..."
                                noResultsText="Записей не найдено..."
                                simpleValue={true}
                                options={OPTIONS}
                            />
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-6">
                            <button type="submit" disabled={!office} className="btn btn-primary px-4">
                              Войти
                            </button>
                          </div>
                        </div>

                      </div>

                    </form>

                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-md-4">
                {showErrorMessage &&
                <div className="alert alert-danger fade show" role="alert">
                  <strong>Ошибка!</strong> Не удалось войти
                </div>}
              </div>
            </div>

          </div>
        </div>
    );
  }
}
