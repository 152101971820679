import React, { Component } from 'react';
import moment from 'moment';
import { ISSUE_STATUSES_LANG } from '../../Issues/components/IssueItem';

export default class IssueListItem extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { issue } = this.props;
    return (
      <div className="issue__item">
        <div className='issue__item-title'>{issue.title}</div>
        <div className="issue__item-params">
          <span className="badge badge-success badge-pill float-right">
            {ISSUE_STATUSES_LANG[issue.status]}
          </span>
          <div>ID запроса: {issue.id}</div>
          <div> Создан: {moment(issue.created).format('DD-MM-YYYY')}</div>
        </div>
      </div>
    );
  }
}
