import React, { Component } from 'react';
import BackButton from '../../../components/BackButton';
import { MailingAPI, POSITIVE_ACTION_STATUSES } from '../../../api/Client';
import moment from 'moment';
import 'moment/locale/ru';
import TouristLists from './TouristLists';
import TouristViberLists from './TouristViberLists';
import EmailTemplatePreview from './EmailTemplatePreview';
import { Editor } from '@tinymce/tinymce-react';
import {
  getCurrentOfficeId,
  getUserRole,
  SUPER_ADMIN,
} from '../../../connect/auth';
import apiKey from '../../../constants/TinyMceApiKey';

class Report extends Component {
  constructor(props) {
    super(props);
    // this.handleFilter = this.handleFilter.bind(this);
    this.fetchItems = this.fetchItems.bind(this);
    this.filterItems = this.filterItems.bind(this);
    this.onChangeModerationStatus = this.onChangeModerationStatus.bind(this);

    this.state = {
      campaign: {},
      emailPreviewHtml: '',
    };
  }
  filterItems(st) {
    let result = {};
    if (st.name) {
      result.name = st.name;
    }
    if (st.email) {
      result.emails = st.email;
    }
    if (st.phone) {
      result.phones = st.phone;
    }
    if (result) {
      this.setState({ filters: result }, () => this.fetchItems(1));
    }
  }

  fetchItems(page) {
    let status;
    MailingAPI.get(this.props.match.params.number)
      .then((response) => {
        status = response.status;
        return response.json();
      })
      .then((result) => {
        if (status === POSITIVE_ACTION_STATUSES.retrieve) {
          this.setState({
            campaign: result,
          });
        }
      });
  }

  onChangeModerationStatus(e) {
    let status;
    const val = e.target.value;
    MailingAPI.update(
      `${MailingAPI.resource_url}${this.props.match.params.number}`,
      { sms_campaign: { moderation_status: val } }
    )
      .then((response) => {
        status = response.status;
        return response.json();
      })
      .then((result) => {
        if (status === POSITIVE_ACTION_STATUSES.update) {
          this.setState({
            campaign: result,
          });
        }
      });
  }

  getEmailPreviewHtml(bookId) {
    return MailingAPI.getEmailPreviewHtml(bookId);
  }

  componentDidMount() {
    this.fetchItems(1);
    this.getEmailPreviewHtml(String(this.props.match.params.number))
      .then((r) => (r.url.includes('localhost') ? r.text() : r.json()))
      .then((r) => {
        this.setState({
          emailPreviewHtml: r,
        });
      });
  }

  render() {
    const { campaign, isDisabledSendButton, emailPreviewHtml } = this.state;
    // console.log("campaign==>", campaign)
    if (!Object.keys(campaign).length) {
      return null;
    }
    const userRole = getUserRole();

    return (
      <div className="animated fadeIn">
        <h2>отчет о рассылке №{this.props.match.params.number}</h2>
        <p>Статус: {campaign.status.name}</p>
        {campaign.dispatch_type.id === 2 && !!campaign.email_campaign ? (
          <Editor
            disabled={true}
            apiKey={apiKey}
            value={campaign.email_campaign.message}
          />
        ) : null}
        {campaign.dispatch_type.id === 1 && !!campaign.sms_campaign ? (
          <p>Сообщение: {campaign.sms_campaign.message}</p>
        ) : null}
        <p>
          дата создания: {moment(campaign.created).format('DD.MM.YYYY HH:mm')}
        </p>
        {/*<p>Уведомление: <span>{campaign.is_notification ? `да` : `нет`}</span></p>*/}
        <p>
          Тип рассылки: <span>{campaign.dispatch_type.name}</span>
        </p>
        {!!campaign.user ? (
          <p>
            Создатель рассылки:{' '}
            {`${campaign.creator.last_name} ${campaign.creator.first_name} (${campaign.creator.username})`}
          </p>
        ) : null}
        {userRole !== SUPER_ADMIN &&
        campaign.dispatch_type.id === 1 &&
        !!campaign.sms_campaign ? (
          <p>Модерация: {campaign.sms_campaign.moderation_status.name}</p>
        ) : null}
        {userRole === SUPER_ADMIN &&
        campaign.dispatch_type.id === 1 &&
        !!campaign.sms_campaign ? (
          <div className="row">
            <div className="col-md-2">
              <select
                onChange={this.onChangeModerationStatus}
                className="form-control"
                placeholder="Статус модерации"
                disabled={campaign.sms_campaign.moderation_status.id !== 1}
                defaultValue={
                  campaign.sms_campaign.moderation_status.id ||
                  campaign.sms_campaign.moderation_status
                }
              >
                <option value="1">Ожидает модерации</option>
                <option value="2">Одобрена</option>
                <option value="3">Отклонена</option>
              </select>
            </div>
          </div>
        ) : null}

        {campaign.dispatch_type.name.toLowerCase() === 'email' && (
          // <EmailTemplatePreview id={this.props.match.params.number} url={campaign.email_preview}/>
          <EmailTemplatePreview
            html={emailPreviewHtml}
            id={this.props.match.params.number}
            url={campaign.email_preview}
          />
        )}

        {campaign.dispatch_type.id && (
          <div>
            <h5>Статистика:</h5>
            {Array.isArray(campaign.report) && campaign.report.length ? (
              <div className="report__list">
                {campaign.report.map((report, idx) => (
                  <div key={idx} className={`report__item ${report.alias}`}>
                    {report.name && (
                      <div className={report.alias}>
                        <b>{report.value}</b>
                        <br />
                        <span>{report.name}</span>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <div>нет статистики</div>
            )}
            {campaign.report && campaign.report.viber_reports && (
              <div className="report__list">
                <div className="report__item">
                  <div className="delivered">
                    <b>{campaign.report.viber_reports.sent}</b>
                    <br />
                    <span>Отправлено</span>
                  </div>
                </div>
                <div className="report__item">
                  <div className="sent">
                    <b>{campaign.report.viber_reports.delivered}</b>
                    <br />
                    <span>
                      <strong>
                        {Math.round(
                          campaign.report.viber_reports.delivered /
                            (campaign.report.viber_reports.sent / 100)
                        )}
                        %
                      </strong>{' '}
                      доставлено
                    </span>
                  </div>
                </div>
                <div className="report__item">
                  <div className="read">
                    <b>{campaign.report.viber_reports.read}</b>
                    <br />
                    <span>
                      <strong>
                        {Math.round(
                          campaign.report.viber_reports.read /
                            (campaign.report.viber_reports.sent / 100)
                        )}
                        %
                      </strong>{' '}
                      прочитано
                    </span>
                  </div>
                </div>
                <div className="report__item">
                  <div className="redirected">
                    <b>{campaign.report.viber_reports.redirected}</b>
                    <br />
                    <span>
                      <strong>
                        {Math.round(
                          campaign.report.viber_reports.redirected /
                            (campaign.report.viber_reports.sent / 100)
                        )}
                        %
                      </strong>{' '}
                      переходы
                    </span>
                  </div>
                </div>
                <div className="report__item">
                  <div className="undelivered">
                    <b>{campaign.report.viber_reports.undelivered}</b>
                    <br />
                    <span>
                      <strong>
                        {Math.round(
                          campaign.report.viber_reports.undelivered /
                            (campaign.report.viber_reports.sent / 100)
                        )}
                        %
                      </strong>{' '}
                      недоставлено
                    </span>
                  </div>
                </div>
                <div className="report__item">
                  <div className="errors">
                    <b>{campaign.report.viber_reports.errors}</b>
                    <br />
                    <span>
                      <strong>
                        {Math.round(
                          campaign.report.viber_reports.errors /
                            (campaign.report.viber_reports.sent / 100)
                        )}
                        %
                      </strong>{' '}
                      ошибок
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        <div>
          Список ошибок:{' '}
          {Array.isArray(campaign.errors) && campaign.errors.length
            ? campaign.errors.map((err, idx) => <span key={idx}>{err}</span>)
            : null}
        </div>
        <div>Список получателей:</div>
        {campaign.dispatch_type.id !== 0 ? (
          <TouristViberLists campaign={campaign} />
        ) : (
          <TouristLists campaign={campaign} />
        )}

        <BackButton {...this.props} />
      </div>
    );
  }
}

export default Report;
