import React, {PureComponent} from 'react';
import { Table } from 'reactstrap';

class SubscriptionTable extends PureComponent {
  constructor(props) {
    super(props);

  }
  render() {
    const {
        items,
        selected,
        toggleItem,
    } = this.props;

    return (<div>
        <Table striped bordered size="sm" className="font-xs table--orange">
          <thead>
          <tr>
              <th className="text-center tableItem">Название / ФИО</th>
              <th className="text-center tableItem">Email</th>
              <th className="text-center tableItem">Город</th>
              <th className="text-center tableItem">Страна</th>
              <th className="text-center tableItem">Источник</th>
              <th className="text-center tableItem">Тип</th>
          </tr>
          </thead>
          <tbody>
          {items.length ? items.map(item => (
              <tr key={item.id}>
                  <td className="text-center tableItem">
                          {item.name}
                  </td>
                  <td className="text-center tableItem">
                      {item.email}
                  </td>
                  <td className="text-center tableItem">
                      {item.city}
                  </td>
                  <td className="text-center tableItem">
                      {item.country}
                  </td>
                  <td className="text-center tableItem">
                      {item.source}
                  </td>
                  <td className="text-center tableItem">
                      {item.type}
                  </td>
              </tr>
          )) : null}
          </tbody>
      </Table>
    </div>
    )
  }
}

export default SubscriptionTable;
