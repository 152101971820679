import React, { PureComponent } from 'react'

export class TelegramInstructions extends PureComponent {
  render() {
    if (this.props.isOpened) {
      this.myRef.scrollIntoView({behavior: "smooth"});
    }
    return (
      <div ref={(ref) => this.myRef=ref} className={`manual ${this.props.isOpened ? "manual_opened" : ""}`}>
        <h2 className='manual__title'>Настройка уведомлений CRM в телеграмм бот</h2>
        <div className='manual__point'>
          <div className='manual__text-container'>
            <p className='manual__text'>
              Зайдите в раздел Личные настройки в правом верхнем углу CRM
            </p>
            <p className='manual__text'>
              Найдите блок Telegram уведомлений, введите номер для уведомлений своего офиса
            </p>
          </div>
          <div className='manual__img-container'>
            <img alt="" src="/img/tg_notifications/1.png" className='manual__img_horizontal'></img>
          </div>
        </div>
        <div className='manual__point'>
          <div className='manual__text-container'>
            <p className='manual__text'>
              Зайдите в телеграмм бот с того номера, который указали  https://t.me/GeoClientBot 
            </p>
            <p className='manual__text'>
              Нажмите кнопку Начать и поделитесь своим контактом
            </p>
          </div>
          <div className='manual__img-container'>
            <img alt="" src="/img/tg_notifications/2.png" className='manual__img_vertical'></img>
            <img alt="" src="/img/tg_notifications/3.png" className='manual__img_vertical'></img>
          </div>
        </div>
        <div className='manual__point'>
          <div className='manual__text-container'>
            <p className='manual__text'>
              После авторизации, при получении заявки с сайта или заявки с Геоподборки вы получаете уведомление
            </p>
          </div>
          <div className='manual__img-container'>
            <img alt="" src="/img/tg_notifications/4.png" className='manual__img_vertical'></img>
            <img alt="" src="/img/tg_notifications/5.png" className='manual__img_vertical'></img>
          </div>
        </div>
        <div className='manual__point'>
          <div className='manual__text-container'>
            <h3 className='manual__point-title'>Уведомление по действиям Геоподборки</h3>
            <p className='manual__text'>
              Для включения уведомлений у отдельных менеджеров, вы можете в разделе мои сотрудники, через редактирование менеджера. Такой же раздел Уведомление через телеграмм бота.
            </p>
            <p className='manual__text'>
              Если вы сделали персональную подборку и хотите отследить действия клиента для оперативного включения (Не выключайте у Геоподборкии телеграмм уведомления)
            </p>
            <p className='manual__text'>
              Вы так же в телеграмм бот получите информацию об открытии подборки и по переходам на отели.
            </p>
          </div>
          <div className='manual__img-container'>
            <img alt="" src="/img/tg_notifications/6.png" className='manual__img_vertical'></img>
            <img alt="" src="/img/tg_notifications/7.png" className='manual__img_vertical'></img>
          </div>
        </div>
        <div className='manual__point'>
          <div className='manual__text-container'>
            <p className='manual__text'>
              Включены или нет уведомления по определенной подборке вы видите в реестре подборок
            </p>
          </div>
          <div className='manual__img-container'>
            <img alt="" src="/img/tg_notifications/8.png" className='manual__img_horizontal'></img>
          </div>
        </div>
        <div className='manual__point'>
          <div className='manual__text-container'>
            <h3 className='manual__point-title'>Отключить уведомления по конкретной подборке</h3>
            <p className='manual__text'>
              Отключить уведомления по конкретной подборке можно зайдя в нее и нажав «Телеграм уведомления отключить
            </p>
          </div>
          <div className='manual__img-container'>
            <img alt="" src="/img/tg_notifications/9.png" className='manual__img_horizontal'></img>
          </div>
        </div>
      </div>
    )
  }
}

export default TelegramInstructions