import React, { Component } from 'react';
import ClientAPI, { PersonAPI } from '../../../api/Client';
import { Link } from 'react-router-dom';

export default class PersonNameLink extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: 0,
      fio: '',
    };
  }

  componentDidMount() {
    // Клиент
    PersonAPI.getInfo(this.props.person_id).then((result) => {
      if (!result.id) {
        this.setState({
          errors: result,
        });
      } else {
        this.setState({
          id: result.id,
          fio: result.fio,
        });
      }
    });
  }

  render() {
    const person = this.state;
    return (
      <Link
        to={`/persons/${person.id}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {person.fio}
      </Link>
    );
  }
}
