import React, {PureComponent} from 'react';
import {IssuesAPI, POSITIVE_ACTION_STATUSES} from '../../../api/Client';
import {Link} from 'react-router-dom';
import Paginator from '../../../components/Paginator/Paginator';
import moment from 'moment';
import 'moment/locale/ru';
import {
  initPageNumber,
  changePageNumber,
} from '../../../lib/url-search-params';

const PAGE_NAME = 'issue_page';

export const ISSUE_STATUS_COLOR = {
  new: '#f35050',
  active: '#f0b505',
  tours: '#f0b505',
  call: '#f0b505',
  meet: '#f0b505',
  deffered: '#9c9c9c',
  cancel: '#9c9c9c',
  order: '#66bb6a',
  transer: '#f0b505',
};

export default class IssuesListDash extends PureComponent {
  constructor(props) {
    super(props);

    this.fetchItems = this.fetchItems.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);

    this.state = {
      items: [],
      previous: '',
      next: '',
      count: 0,
      page: initPageNumber(PAGE_NAME),
    };
  }

  fetchItems(page = 1) {
    let status;
    IssuesAPI.
        fetchList(page).
        then(r => {
          status = r.status;
          return r.json();
        }).
        then(r => {
          if (status === POSITIVE_ACTION_STATUSES.list) {
            this.setState({
              items: r.results || [],
              previous: !!r.previous,
              next: !!r.next,
              count: r.count,
              page: page,
            });
          }
        });
  }

  handlePageChange(data) {
    let page = data.selected + 1;
    changePageNumber(PAGE_NAME, page);
    this.fetchItems(page);
  }

  componentDidMount() {
    const {page} = this.state;
    this.fetchItems(page);
  }

  render() {
    const {items, count, page} = this.state;

    const ISSUE_SOURCE_MAP = {
      socials: 'соцсети',
      call_to_office: 'звонок в офис',
      call_to_phone: 'звонок на сотовый',
      visitor: 'посетитель',
      recommendation: 'по рекомендации',
      excel: 'из файла excel',
    };

    return (
      <div>
        <div style={{display: 'flex'}}>
          <p className="mr-3">
            <i
              className={`fa fa-circle fa-2`}
              style={{ color:ISSUE_STATUS_COLOR['new']}}
              aria-hidden="true"
              ></i><span style={{fontSize: '12px'}}>новый</span>
          </p>
          <p className="mr-3">
            <i
              className={`fa fa-circle fa-2`}
              style={{ color:ISSUE_STATUS_COLOR['active']}}
              aria-hidden="true"
            ></i><span style={{ fontSize: '12px' }}>в работе</span>
          </p>
          <p className="mr-3">
            <i
              className={`fa fa-circle fa-2`}
              style={{ color:ISSUE_STATUS_COLOR['order']}}
              aria-hidden="true"
            ></i><span style={{ fontSize: '12px' }}>покупка</span>
          </p>
          <p className="mr-3">
            <i
              className={`fa fa-circle fa-2`}
              style={{ color:ISSUE_STATUS_COLOR['cancel']}}
              aria-hidden="true"
            ></i><span style={{fontSize: '12px' }}>отменен</span>
          </p>
        </div>
        <ul id="dashboard__orders" className="list-group">
          {items.map((item, idx) => {
            return (
              <li className={`justify-content-between list-group-item item-header`}
              key={idx}
              >
                  {item.person && <Link to={`/persons/${item.person.id}`}>
                    <div>
                      <i
                        className={`fa fa-circle fa-2 circle--${item.resolved}`}
                        style={{ color:ISSUE_STATUS_COLOR[item.status]}}
                        aria-hidden="true"
                        >{''}</i>
                      <b>
                        {item.person.full_name}
                      </b>
                      <span className="ml-2 badge badge-pill badge-default">
                        {ISSUE_SOURCE_MAP[item.source]}
                      </span>
                      <span className="float-right">
                        <i className="order__icon-info order__icon-info--arrive">{''}</i>
                        {moment(item.created).format('DD.MM.YY H:mm')}
                      </span>
                      <p>
                      </p>
                      <p className="short-text">
                        {item.text}
                      </p>
                    </div>
                  </Link>}
                </li>
            );
          })}
          <Paginator total={count}
                     onPageChange={this.handlePageChange}
                     initialPage={page - 1}
                     forcePage={0}
                     />
        </ul>
      </div>
    );
  }
}
