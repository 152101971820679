import React, {PureComponent} from 'react';
import {Table} from 'reactstrap';
import {Link} from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/ru';
import {POSITIVE_ACTION_STATUSES, UsersAPI} from "../../api/Client";
// import {useState, useEffect} from "react";

const FRANCHAISE_STATUS = {
    deciding: 'новая',
    franchasing: 'франчайзинг',
    subagent: 'субагент',
    refuse: 'мы отказали',
    new: 'новая',
    call_three_days: 'звонок 3 дня',
    call_week: 'звонок неделя',
    call_month: 'звонок месяц',
    call_three_months: 'звонок 3 месяца',
    call_half_year: 'звонок полгода',
    choose_competitor: 'выбрали конкурента',
    cancel_business: 'отказ от бизнеса',
    touragent_online: 'турагент онлайн',
    no_call_answered: 'не дозвонились',
    potential: 'потенциал',
    freelancer: 'фрилансер',
    for_mailing: 'для рассылки',
};

const FRANCHAISE_SOURCE = {
    odnoklassniki: 'одноклассники',
    lead_from_site: 'заявка с сайта',
    VK: 'в контакте',
    mailing: 'рассылка',
    instagram: 'инстаграм',
    facebook: 'фейсбук',
    google: 'контекстная Гугл',
    yandex: 'контекстная Яндекс',
    headhunter: 'Head Hunter',
    geo_site_tourschool: 'туршкола',
};

const FRANCHAISE_STATUS_CLASS = {
    new: 'badge-danger',
    deciding: 'badge-danger',
    franchasing: 'badge-success',
    subagent: 'badge-primary',
    refuse: 'badge-brown',
    call_three_days: 'badge-purple',
    call_week: 'badge-purple',
    call_month: 'badge-purple',
    call_three_months: 'badge-purple',
    call_half_year: 'badge-purple',
    choose_competitor: 'badge-brown',
    cancel_business: 'badge-red',
    touragent_online: 'badge-primary',
    no_call_answered: 'badge-gray',
    potential: 'badge-pink',
    freelancer: 'badge-orange',
    for_mailing: 'badge-gray',
}

const FRANCHAISE_COUNTRY = {
    russia: 'Россия',
    belorussia: 'Беларусь',
    kazakhstan: 'Казахстан',
}

export class FranchiseApplicationList extends PureComponent {
    constructor(props) {
        super(props);
        this.fetchManagers = this.fetchManagers.bind(this);
        this.state = {
            managers: [],
        };
    }

    fetchManagers() {
        // let self = this;
        let status;

        UsersAPI.fetchJSON(`full_list/`).then(r => {
            status = r.status;
            return r.json();
        }).then(r => {
            this.setState({
                managers: status === POSITIVE_ACTION_STATUSES.list ? r : [],
            });
        });
    }

    componentDidMount() {
        // this.fetchFranchiseApplication();
        this.fetchManagers();
        // console.log(this.state)
    }

    // componentDidUpdate(prevProps, prevState, snapshot) {
    //     console.log(this.state)
    // }

    render() {
        // const [managers, setManagers] = useState([])
        // const {managers} = this.state;

        // const FRANCHAISE_MANAGER_OPTIONS = this.state.managers.map((manager) => {
        //     return {
        //         value: manager.id,
        //         label: !manager.last_name && !manager.first_name
        //             ? manager.username
        //             : [manager.last_name, manager.first_name].join(' '),
        //     };
        // });

        const {
            items,
            selected,
            toggleItem,
        } = this.props;

        return (
            <Table striped bordered size="sm" className="font-xs table--orange">
                <thead>
                <tr>
                    <th className="text-center tableItem">*</th>
                    <th className="text-center tableItem">Номер заявки</th>
                    <th className="text-center tableItem">Дата</th>
                    <th className="text-center tableItem">Дата договора</th>
                    <th className="text-center tableItem">Статус</th>
                    <th className="text-center tableItem">Источник</th>
                    <th className="text-center tableItem">Заявитель</th>
                    <th className="text-center tableItem">Опыт в туризме</th>
                    <th className="text-center tableItem">Офис</th>
                    <th className="text-center tableItem">Страна</th>
                    <th className="text-center tableItem">Менеджер</th>
                    <th className="text-center tableItem">Примечание</th>
                </tr>
                </thead>
                <tbody>
                {items.length ? items.map(item => (
                    <tr key={item.id}>
                        <td className="text-center tableItem">
                            <input type="checkbox"
                                   id={`application-${item.id}`}
                                   onChange={() => toggleItem(item.id)}
                                   checked={selected.includes(item.id)}
                            />
                        </td>
                        <td className="text-center tableItem">
                            <Link to={`/franchising/${item.id}`} target="_blank" rel="noopener noreferrer" itemID={item.id}>
                            {/*<Link to={`/franchising/${item.id}`} target="_blank" rel="noopener noreferrer"}>*/}
                            {/*<Link to={`/franchising/${item.id}`}>*/}
                                {item.id}
                            </Link>
                        </td>
                        <td className="text-center tableItem">
                            <Link to={`/franchising/${item.id}`} target="_blank" rel="noopener noreferrer">
                            {/*<Link to={`/franchising/${item.id}`}>*/}
                                {moment(item.created).format('DD.MM.YY HH:mm')}
                            </Link>
                        </td>
                        <td className="text-center tableItem">
                            {(item.applicant.contract_date) ? moment(item.applicant.contract_date).format('DD.MM.YY') : null}
                        </td>
                        <td className="text-center tableItem">
                            {FRANCHAISE_STATUS.hasOwnProperty(item.status) ? <span
                                    className={`badge badge-pill ${FRANCHAISE_STATUS_CLASS[item.status]}`}>{FRANCHAISE_STATUS[item.status]}</span> :
                                <span className="badge badge-default badge-pill">не задан</span>}
                        </td>
                        <td className="text-center tableItem">
                            {FRANCHAISE_SOURCE.hasOwnProperty(item.source) ?
                                <span>{FRANCHAISE_SOURCE[item.source]}</span> :
                                <span className="badge badge-default badge-pill">{item.source}</span>}
                        </td>
                        <td className="text-center tableItem">
                            {item.applicant.name} {' '}
                            ({item.applicant.email}, {item.applicant.phone})
                        </td>
                        <td className="text-center tableItem">
                            {item.applicant.have_tourism_experience ? <span>да</span> : <span>нет</span>}
                        </td>
                        <td className="text-center tableItem">
                            {item.office.address} {' '}
                            ({item.office.city ? item.office.city.name : ''})
                        </td>
                        <td className="text-center tableItem">
                            {FRANCHAISE_COUNTRY[item.applicant.country]}
                        </td>
                        <td className="text-center tableItem">
                            {item.manager ? item.manager.last_name + " " + item.manager.first_name : ""}
                        </td>
                        <td className="text-center tableItem">
                            {item.comment === "" ? "---" : item.comment}
                        </td>
                    </tr>
                )) : null}
                </tbody>
            </Table>
        );
    }
}
