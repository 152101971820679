import React from 'react';
// import { TabContent, TabPane, Nav, NavItem, NavLink, Progress } from 'reactstrap';


const BackButton = props => (
    <button type="button" className="btn btn-outline-secondary"
            onClick={() => props.history.goBack()}>
      <i className="icon-arrow-left"></i>
        &nbsp;Назад
    </button>
);

export default BackButton
