import React, { Component } from "react";
import Select from "react-select-plus";

import { POSITIVE_ACTION_STATUSES, EventsAPI } from "../../../../api/Client";

class CalendarToolbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
    };
  }

  componentDidMount() {
    let status;

    EventsAPI.fetchList(1, {}, `user_list/`)
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.list) {
          this.setState({
            users: r || [],
          });
        }
      });
  }

  render() {
    const USERS_OPTIONS = [
      { value: "all", label: <i>События всех сотрудников</i> },
      ...this.state.users.map((user) => ({
        value: user.id,
        label: user.owner ? <b>Только мои события</b> : user.full_name,
      })),
    ];

    return (
      <div id="sidebar" className="container">
        <div className="calendar calendar-body container">
          <div className="calendar-date">
            <button
              id="prev"
              onClick={this.props.onPrev}
              className="btn btn-sm btn-primary"
            >
              <i className="fa fa-chevron-left"></i>
            </button>
            <span id="current" className="calendar-date__current">
              {this.props.currentMonth}
            </span>
            <button
              id="next"
              onClick={this.props.onNext}
              className="btn btn-sm btn-primary"
            >
              <i className="fa fa-chevron-right"></i>
            </button>
            <button
              id="today"
              onClick={this.props.onDefault}
              className="btn btn-sm btn-success"
            >
              Сегодня
            </button>
            {!this.props.isHideFilter && this.state.users.length > 1 && (
              <Select
                clearable={false}
                closeOnSelect={true}
                name="user"
                onChange={this.props.userChange}
                options={USERS_OPTIONS}
                placeholder="выберите сотрудника"
                value={this.props.userValue}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default CalendarToolbar;
